import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import AllOptions from './AllOptions';
import { useSelector } from 'react-redux';
import Search from './Search';
import ToggleButtons from './ToggleButtons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 500,
    minHeight: 400,
    width: 380,
    paddingBottom: 0,
    padding: 24,
    borderRight: '1px dotted rgba(128, 128, 128, 0.19)',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    fontSize: 16,
    color: theme.colors.blue2,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    marginBottom: 16,
  },
}));

const SearchSection = ({
                         selectedOptions = [],
                         setSelectedOptions,
                         dataKey,
                         handleClose,
                         open,
                         tableName,
                         fields,
                         tableFilter,
                       }) => {
  const classes = useStyles();
  const { en: { tables } } = useSelector((state) => state.i18n);
  const [searchValue, setSearchValue] = useState('');
  const [alignment, setAlignment] = React.useState('left');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    if (!open) {
      setSearchValue('');
      setAlignment('left');
    }
  }, [open]);

  return <div className={classes.wrapper}>
    <Typography className={classes.title}>
      {tables.availableOptions}:
    </Typography>
    <div className={classes.searchContainer}>
      <Search
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <ToggleButtons
        alignment={alignment}
        handleChange={handleChange}
      />
    </div>
    <AllOptions
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      dataKey={dataKey}
      handleClose={handleClose}
      open={open}
      tableName={tableName}
      searchValue={searchValue}
      fields={fields}
      tableFilter={tableFilter}
      showOnlyAvailableOptions={alignment === 'left'}
    />
  </div>;
};

export default SearchSection;
