import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-fetching-library';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { useParams } from 'react-router';
import { compare } from 'fast-json-patch';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import ContentWrapper from '../../Wrappers/ContentWrapper';
import BackButton from '../../Shared/BackButton';
import RoundedButton from '../../Shared/RoundedButton';
import showServerError from '../../../helpers/showError';
import { AppRouteContext } from '../../../contexts';
import { routes } from '../../../config/routes';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import ClaimForm from './widgets/ClaimForm';
import { checkPermission } from '../../../helpers/checkPermissions';
import permissions from '../../../config/permissions';
import PatientInfoBlock from '../../Patients/PatientInfoBlock';
import { ActivePatientContext } from '../../../contexts/ActivePatientProvider';
import { object, string, array, number, addMethod } from 'yup';
import {
  addDays,
  getFormattedDate,
  getLocalizedDateIgnoreTime,
  getUtcDate,
} from '../../../helpers/localize';
import AutoFillingRule from './helpers';
import { BILLING_CLAIMS_STATUSES } from '../constants';
import PatientEditModal from '../../Patients/PatientEditModal';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  patientName: {
    color: theme.colors.lightBlue,
    fontWeight: 500,
  },
  additionInfo: {
    color: theme.colors.blue2,
    fontWeight: 400,
  },
  patientInfoContainer: {
    marginLeft: 6,
  },
  shimmer: {
    width: '100%',
    height: '100%',
  },
}));

const EditClaim = () => {
  const classes = useStyles();
  const { claimUuid, accountUuid } = useParams();
  const { setAppRoute } = useContext(AppRouteContext);
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const [backendErrors, setBackendErrors] = useState({});
  const [initClaim, setInitClaim] = useState(null);
  const [claim, setClaim] = useState({});
  const [insuranceProviders, setInsuranceProviders] = useState(null);
  const [appointmentProviders, setAppointmentProviders] = useState(null);
  const [cptCodes, setCptCodes] = useState(null);
  const [modifiers, setModifiers] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [openPatientEditModal, togglePatientEditModal] = useState(false);
  const [initActivities, setInitActivities] = useState([]);
  const dispatch = useDispatch();

  const {
    user,
    metadata: {
      currentAccount,
      currentAccountPermissions,
      childAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const { patientInfo } = useContext(ActivePatientContext);

  const isClaimBelongsToCurrentAccount = accountUuid === currentAccount.uuid;
  let isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );
  const canEditClaim = isDistributionSponsor ? checkPermission(
    isClaimBelongsToCurrentAccount
      ? currentAccountPermissions
      : childAccountPermissions,
    permissions.CLAIM_PATCH,
  ) : false;

  const { query, loading: loadingClaim } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/claims/${claimUuid}`,
    },
    false,
  ]);

  const { query: queryInfo, loading: loadingClaimInfo } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'GET',
        endpoint: `/accounts/${accountUuid}/patients/${
          claim && claim.patientUUID
        }/claims/lookup-info`,
      },
      false,
    ],
  );

  useEffect(() => {
    query().then(({ error, payload }) => {
      if (!error) {
        const initActivities = AutoFillingRule.generateInitActivitiesIfNeed(payload);
        setInitActivities(initActivities);
        const newClaim = {
          ...payload,
          activities: initActivities,
          appointmentDate: payload.appointmentDate
            ? getLocalizedDateIgnoreTime(payload.appointmentDate, 'UTC')
            : new Date().toISOString(),
          checkDate: payload.checkDate
            ? getLocalizedDateIgnoreTime(payload.checkDate, 'UTC')
            : '',
          claimGeneratedTimestamp: payload.claimGeneratedTimestamp
            ? getLocalizedDateIgnoreTime(payload.claimGeneratedTimestamp, 'UTC')
            : '',
          entityOrderTimestamp: payload.entityOrderTimestamp
            ? getLocalizedDateIgnoreTime(payload.entityOrderTimestamp, 'UTC')
            : '',
          lastModifiedTimestamp: payload.lastModifiedTimestamp
            ? getLocalizedDateIgnoreTime(payload.lastModifiedTimestamp, 'UTC')
            : '',
          paymentDate: payload.paymentDate
            ? getLocalizedDateIgnoreTime(payload.paymentDate, 'UTC')
            : '',
          documentDate: payload.documentDate
            ? getLocalizedDateIgnoreTime(payload.documentDate, 'UTC')
            : payload.appointmentDate
              ? getLocalizedDateIgnoreTime(payload.appointmentDate, 'UTC')
              : new Date().toISOString(),
        };
        setClaim(newClaim);
        setInitClaim({
          ...payload,
          appointmentProvider: payload.appointmentProvider || initClaim && initClaim.appointmentProvider || null,
        });
      }
    });
  }, [query]);

  useEffect(() => {
    if (queryInfo && claim && !insuranceProviders && claim.patientUUID) {
      queryInfo().then(({ error, payload }) => {
        if (!error) {
          if (payload.insuranceProviders) {
            const newInsuranceProviders = payload?.insuranceProviders?.filter((insurance) => !!insurance).map(
              (name) => {
                return {
                  label: name,
                  value: name,
                };
              },
            );
            setInsuranceProviders(newInsuranceProviders);
          }
          if (payload.appointmentProviders) {
            const newProviders = payload.appointmentProviders.filter((provider) => !!provider).map(
              (provider) => {
                return {
                  label: provider,
                  value: provider,
                };
              },
            );
            if (!claim.appointmentProvider && newProviders.length === 1) {
              setClaim({ ...claim, 'appointmentProvider': newProviders[0].value });
            }
            setAppointmentProviders(newProviders);
          }
          if (payload.cptCodes) {
            const newCptCodes = payload.cptCodes.map((name) => {
              return {
                label: name,
                value: name,
              };
            });
            setCptCodes(newCptCodes);
          }
          if (payload.modifiers) {
            const newModifiers = payload.modifiers.map((name) => {
              return {
                label: name,
                value: name,
              };
            });
            setModifiers(newModifiers);
          }
        }
      });
    }
  }, [queryInfo, claim]);

  const claimEditAction = (patches) => ({
    method: 'PATCH',
    endpoint: `/accounts/${accountUuid}/claims/${claimUuid}`,
    body: patches,
  });

  const { loading, mutate } = useMetadataUpdate(useMutation, [claimEditAction]);

  const editClaim = async () => {
    let formattedClaim = JSON.parse(JSON.stringify(claim));
    const activities = formattedClaim.billingStatus === BILLING_CLAIMS_STATUSES.insuranceIssue
    || formattedClaim.billingStatus === BILLING_CLAIMS_STATUSES.appointmentCancelled
      ? initActivities
      : formattedClaim.activities;

    const clearActivitiesIds = activities && activities.map((activity) => {
      const newActivity = { ...activity };
      delete newActivity.id;
      return newActivity;
    });

    const patches = compare(initClaim, {
      ...formattedClaim,
      activities: clearActivitiesIds,
    });

    if (patches.length) {
      setBackendErrors({});
      const { payload, error, status } = await mutate(patches);
      if (error) {
        setIsFailed(true);
        setTimeout(() => {
          setIsFailed(false);
        }, 2000);
        const options = payload && {
          correlationUUID: payload.correlationUUID,
          userUUID: user.uuid,
        };
        switch (status) {
          case 422: {
            showServerError(dispatch, t('errors:validationError'));
            const parsedErrorList = {};
            Object.keys(payload.validationErrors).forEach((key) => {
              if (key.startsWith('activities') && key !== 'activities') {
                const newKey = key.substring(11, key.length - 1);
                parsedErrorList[formattedClaim.activities[newKey].id] =
                  payload.validationErrors[key];
              } else {
                parsedErrorList[key] = payload.validationErrors[key];
              }
            });
            setBackendErrors(parsedErrorList);
            break;
          }
          case 401: {
            showServerError(dispatch, t('notifications:notPermissionForEditingClaim'));
            break;
          }
          default: {
            showServerError(dispatch, '', options);
            break;
          }
        }
      } else if (payload) {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
        setAppRoute({
          path: routes.claimsReportingList.path,
          title: routes.claimsReportingList.additional.title,
          clearBreadcrumbs: true,
        });
        dispatch(
          enqueueSnackbar({
            message: t('notifications:claimEdited'),
            options: {
              variant: SNACKBAR_VARIANTS.success,
            },
          }),
        );
      }
    }
  };

  const isAllDataNotLoaded =
    !claim ||
    (!insuranceProviders || !cptCodes) ||
    loadingClaim ||
    loadingClaimInfo;

  const patientFullName =
    claim && patientInfo && claim.patientUUID === patientInfo.uuid
      ? `${patientInfo.lastName}, ${patientInfo.firstName}`
      : null;

  addMethod(string, 'checkCPTCodes', function(errorMessage) {
    return this.test(`check-cpt-codes`, errorMessage, function(value) {
      const { path, createError } = this;

      if (!value) {
        return true;
      }
      const appointmentDate = claim.appointmentDate ? new Date(claim.appointmentDate) : new Date();
      const currentData = value ? new Date(value.includes('/') ? getUtcDate(value) : value) : null;

      const minDate = addDays(claim.appointmentDate ? new Date(claim.appointmentDate) : new Date(), -30);
      if (currentData < minDate) {
        return createError({
          path,
          message: `The DOS cannot be less than 30 days before the ${getFormattedDate(appointmentDate)} (Appointment Date)`,
        });
      }

      const maxDate = addDays(claim.appointmentDate ? new Date(claim.appointmentDate) : new Date(), 30);
      if (currentData > maxDate) {
        return createError({
          path,
          message: `The DOS cannot be more than 30 days after the ${getFormattedDate(appointmentDate)} (Appointment Date)`,
        });
      }

      return true;
    });
  });

  const CptSchema = object({
    code: string().required('CPT Code is a required field.'),
    pr: number()
      .min(0, 'Value should not be less than 0 and greater than $5,000')
      .max(5000, 'Value should not be less than 0 and greater than $5,000'),
    paid: number()
      .min(0, 'Value should not be less than 0 and greater than $5,000')
      .max(5000, 'Value should not be less than 0 and greater than $5,000'),
    icd: string().required('ICD is a required field.'),
    dos: string()
      .checkCPTCodes()
      .required('Date of Service (DOS) is a required field.'),
    prType: string().when('pr', {
      is: (pr) => pr > 0,
      then: () => string().required('PR Type is a required field if PR > 0.'),
      otherwise: () => string().notRequired(),
    }),
  });

  const ClaimsSchema = object({
    appointmentDate: string().required('Appointment Date is a required field.'),
    insurance: string().when('billingStatus', {
      is: (billingStatus) => {
        return billingStatus !== 'insuranceIssue' && billingStatus !== 'appointmentCancelled';
      },
      then: () => string().required('Insurance is a required field.'),
      otherwise: () => string().notRequired(),
    }),
    documentDate: string().required('Document Date is a required field.'),
    isPatientSeen: string().required('Patient Seen is a required field.'),
    isDocumentUploaded: string().required('Document Uploaded is a required field.'),
    billingStatus: string().required('Billing Status is a required field.'),
    location: string().required('Location is a required field.'),
    activities: claim.billingStatus === BILLING_CLAIMS_STATUSES.insuranceIssue
    || claim.billingStatus === BILLING_CLAIMS_STATUSES.appointmentCancelled ? null : array(CptSchema),
    paymentDate: string().when(['activities', 'billingStatus'], {
      is: (activities, billingStatus) => {
        if (billingStatus === 'insuranceIssue' || billingStatus === 'appointmentCancelled') {
          return false;
        }
        return activities && !!activities.reduce((accumulator, currentValue) => {
          return accumulator + (+currentValue?.paid ?? 0);
        }, 0);
      },
      then: () => string().required('Payment Date is a required field.'),
      otherwise: () => string().notRequired(),
    }),
    claimStatus: string().when('billingStatus', {
      is: (billingStatus) => billingStatus === BILLING_CLAIMS_STATUSES.pending,
      then: () => string().oneOf(['pending'], 'Claim Status must be equal to Pending if Billing Status is Pending.'),
    }),
  });

  return (
    <>
      <PatientEditModal
        open={openPatientEditModal}
        patientUuid={claim.patientUUID}
        accountUuid={claim.accountUUID}
        handleClose={() => togglePatientEditModal(false)}
      />
      <Formik
        initialValues={claim}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={editClaim}
        validationSchema={ClaimsSchema}
      >
        {({ handleSubmit, errors }) => (
          <ContentWrapper
            titleText={t('titles:editClaim')}
            className={classes.paper}
            additionalNode={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Grid
                  container
                  direction='column'
                  className={classes.patientInfoContainer}
                >
                  <Typography
                    noWrap
                    className={cx(
                      classes.titleItem,
                      classes.titleText,
                      classes.additionInfo,
                    )}
                  >
                    {
                      loadingClaim || !Object.keys(claim)
                        ? <Skeleton variant='text' width={180} />
                        : claim.entityType === 'program'
                        ? <Box component='span' fontWeight='fontWeightMedium'>Program</Box>
                        : <>
                          <Box component='span' fontWeight='fontWeightMedium'>Assessment
                            name: </Box>
                          {claim.entityName}
                        </>
                    }
                  </Typography>
                  <Typography
                    noWrap
                    style={{ paddingBottom: 10 }}
                    className={cx(
                      classes.titleItem,
                      classes.titleText,
                      classes.additionInfo,
                    )}
                  >
                    {
                      loadingClaim || !Object.keys(claim)
                        ? <Skeleton variant='text' width={150} />
                        : <>
                          <Box component='span' fontWeight='fontWeightMedium'>MRN: </Box>
                          {patientInfo && patientInfo.mrnNumber || claim.patientMrnNumber || '-'}
                        </>
                    }
                  </Typography>
                  <Typography
                    noWrap
                    className={cx(
                      classes.titleItem,
                      classes.titleText,
                      classes.patientName,
                    )}
                  >
                    {patientFullName}
                  </Typography>
                  <Grid container>
                    <PatientInfoBlock
                      isReportPage
                      isForInitLoading
                      patientUUID={claim && claim.patientUUID}
                    />
                  </Grid>
                </Grid>
                <RoundedButton
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{
                    height: 'fit-content',
                  }}
                  onClick={() => togglePatientEditModal(true)}
                  disabled={!claim || !claim.accountUUID || !claim.patientUUID}
                  isLoading={false}
                  isSuccess={false}
                  isFailed={false}
                >
                  {t('btn:modifyDemographics')}
                </RoundedButton>
              </div>
            }
            actions={
              <>
                <BackButton />
                <RoundedButton
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={loading || !canEditClaim}
                  onClick={handleSubmit}
                  isLoading={loading}
                  isSuccess={isSuccess}
                  isFailed={isFailed}
                >
                  {t('btn:save')}
                </RoundedButton>
              </>
            }
          >
            {
              <Box p={4}>
                <ClaimForm
                  isAllDataNotLoaded={isAllDataNotLoaded}
                  claim={claim}
                  setClaim={setClaim}
                  errors={errors}
                  initActivities={initActivities}
                  backendErrors={backendErrors}
                  setBackendErrors={setBackendErrors}
                  cptCodes={cptCodes}
                  modifiers={modifiers}
                  insuranceProviders={insuranceProviders}
                  appointmentProviders={appointmentProviders}
                  isDistributionSponsor={isDistributionSponsor}
                />
              </Box>
            }
          </ContentWrapper>
        )}
      </Formik>
    </>
  );
};

EditClaim.propTypes = {};

export default EditClaim;
