import { useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

const deepCompareEquals = (a, b) => isEqual(a, b);

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

const useDeepCompareEffect = (callback, dependencies) => {
  useEffect(callback, useDeepCompareMemoize(dependencies));
};

export default useDeepCompareEffect;
