import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation } from 'react-fetching-library';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import Modal from 'components/Shared/Modal';
import getDigitPhone from 'helpers/phone';
import showServerError from 'helpers/showError';
import PatientForm from 'components/Patients/PatientForm';
import { EMPTY_ADDRESS } from 'components/Shared/constants';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { checkPermission } from 'helpers/checkPermissions';
import permissions from 'config/permissions';
import getFullName from 'helpers/user';
import { useDispatch, useSelector } from 'react-redux';
import PatientEditModal from './../PatientEditModal';
import { getUtcDate } from '../../../helpers/localize';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import { PATIENT_LANGUAGE } from '../constants';
import PatientAddedModal from './PatientAddedModal/PatientAddedModal';

const useStyles = makeStyles((theme) => ({
  goToListLink: {
    color: theme.colors.lightBlue,
    fontWeight: 400,
    fontSize: 15,
    textAlign: 'center',
    cursor: 'pointer',
  },
  modalBtn: {
    height: 128,
    width: 300,
    borderColor: theme.colors.lightBlue,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 2,
    width: 'max-content',
    color: theme.colors.blue2,
    textTransform: 'uppercase',
    paddingTop: theme.spacing(2),
  },
  modal: {
    width: '100%',
    maxWidth: 1000,
  },
  createButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
  },
}));

const PatientCreateModal = ({ open, handleClose, accountUuid, reloadData }) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const [backendErrors, setBackendErrors] = useState({});
  const closeModal = () => {
    handleClose();
    setBackendErrors({});
  };
  const [openPatientEditModal, togglePatientEditModal] = useState(false);
  const [openModal, toggleModal] = useState(false);
  const [patient, setPatient] = useState({}); // trigger useOrderMetadata
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const {
    user,
    metadata: {
      currentAccountPermissions,
      childAccountPermissions,
      currentAccount,
    },
  } = useSelector((state) => state.user);
  const hasPermissionForOrder = checkPermission(
    currentAccount.uuid === accountUuid
      ? currentAccountPermissions
      : childAccountPermissions,
    permissions.ORDER_CREATE,
  );
  const hasPermissionForSurvey = checkPermission(
    currentAccount.uuid === accountUuid
      ? currentAccountPermissions
      : childAccountPermissions,
    permissions.ORDER_SURVEY_CREATE,
  );

  const successProvidersCallback = (patientInfo) => {
    dispatch(
      enqueueSnackbar({
        message: t('notifications:patientCreated', {
          name: getFullName(patientInfo),
        }),
        options: {
          variant: SNACKBAR_VARIANTS.success,
        },
      }),
    );
  };

  const PatientCreateAction = (values) => ({
    method: 'PUT',
    endpoint: `/accounts/${accountUuid}/patients`,
    body: values,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [
    PatientCreateAction,
  ]);

  const createPatient = async (values, { setSubmitting }) => {
    const formattedValues = {
      ...values,
      phone: { phone: getDigitPhone(values.phone) },
      dateOfBirth: getUtcDate(values.dateOfBirth),
    };
    setBackendErrors({});

    const { payload, error, status } = await mutate(formattedValues);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      setSubmitting(false);
      switch (status) {
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      setPatient(payload);
      successProvidersCallback(payload);
      closeModal();
      reloadData();
      toggleModal(hasPermissionForOrder || hasPermissionForSurvey);
    }
  };

  return (
    <>
      <Modal
        open={open}
        classes={{ paper: classes.modal }}
        title={t('titles:addNewPatient')}
        handleClose={closeModal}
      >
        <Formik
          initialValues={{
            mrnNumber: '',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            dateOfBirth: null,
            genderAtBirth: '',
            hasDrugAllergies: null,
            smsOptIn: false,
            drugAllergies: '',
            address: {
              ...EMPTY_ADDRESS,
            },
            patientLanguage: PATIENT_LANGUAGE.UNKNOWN,
          }}
          validateOnBlur={false}
          onSubmit={createPatient}
        >
          {({ isValid, handleSubmit, dirty, isSubmitting, values }) => (
            <Box p={4}>
              <PatientForm
                values={values}
                backendErrors={backendErrors}
                actions={
                  <Box py={2} display='flex' justifyContent='space-between'>
                    <RoundedButton
                      variant='outlined'
                      color='primary'
                      size='small'
                      onClick={closeModal}
                      className={classes.createButton}
                    >
                      {t('btn:cancel')}
                    </RoundedButton>
                    <RoundedButton
                      variant='contained'
                      color='primary'
                      size='small'
                      disabled={!isValid || isSubmitting || !dirty}
                      onClick={handleSubmit}
                      isLoading={loading}
                      isSuccess={isSuccess}
                      isFailed={isFailed}
                      className={classes.createButton}
                    >
                      {t('btn:create')}
                    </RoundedButton>
                  </Box>
                }
              />
            </Box>
          )}
        </Formik>
      </Modal>
      <PatientAddedModal
        openModal={openModal}
        toggleModal={toggleModal}
        accountUuid={accountUuid}
        patient={patient}
        togglePatientEditModal={togglePatientEditModal}
        hasPermissionForSurvey={hasPermissionForSurvey}
        hasPermissionForOrder={hasPermissionForOrder}
      />
      {patient.uuid && (
        <PatientEditModal
          open={openPatientEditModal}
          patientUuid={patient.uuid}
          accountUuid={accountUuid}
          handleClose={() => togglePatientEditModal(false)}
        />
      )}
    </>
  );
};

PatientCreateModal.propTypes = {
  accountUuid: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};

export default PatientCreateModal;
