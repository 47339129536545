import React, { useEffect, useContext } from 'react';
import { useQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { AppRouteContext } from 'contexts';
import { routes } from 'config/routes';

import { Button, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import {
  closeSnackbar,
  enqueueSnackbar, SNACKBAR_VARIANTS,
} from '../redux_store/reducer/reducers/notificationsReducer';
import {
  updateIsMaintenanceMode,
} from '../redux_store/reducer/reducers/userReducer/userReducer';
import { checkIsMaintenanceMode } from '../helpers/downForMaintenance';

const useStyles = makeStyles(() => ({
  warningAction: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  warningCloseAction: {
    position: 'absolute',
    right: 16,
    top: 'calc(50% - 14px)',
  },
}));

const useOrderMetadata = (
  patientUuid,
  accountUuid,
  errorCallback,
  {
    callback: warningCallback,
    actionCallback: warningActionCallback,
    actionTitle: warningActionTitle,
    reorderUuid = 'newOrder',
  },
  successCallback,
) => {
  const { t } = useTranslation(['titles']);
  const { setAppRoute } = useContext(AppRouteContext);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { query, loading } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/patients/${patientUuid}/create-order-metadata`,
    },
    false,
  ]);

  const onClickDismiss = (key) => () => {
    dispatch(closeSnackbar(key));
  };

  const warningAction = (key) => (
    <div className={classes.warningAction}>
      <Button
        variant='outlined'
        color='primary'
        onClick={warningActionCallback}
      >
        {warningActionTitle}
      </Button>
      <IconButton
        onClick={onClickDismiss(key)}
        size='small'
        className={classes.warningCloseAction}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
    </div>
  );

  useEffect(() => {
    if (patientUuid) {
      query().then(({ payload: metadata, error: metadataError }) => {
        if (!metadataError && metadata) {
          const { providers = [] } = metadata;
          if (
            metadata &&
            metadata.patientInfo &&
            (metadata.patientInfo.hasDrugAllergies === null ||
              // eslint-disable-next-line no-prototype-builtins
              !metadata.patientInfo.hasOwnProperty('hasDrugAllergies') ||
              metadata.patientInfo.hasDrugAllergies === '')
          ) {
            if (warningCallback) {
              warningCallback();
            }
            dispatch(
              enqueueSnackbar({
                message: t('notifications:hasDrugAllergies'),
                options: {
                  variant: SNACKBAR_VARIANTS.warning,
                  action: warningAction,
                },
              }),
            );
            return;
          }
          if (providers.length) {
            setAppRoute({
              path: routes.patientOrderCreate.path,
              params: {
                patientUuid,
                accountUuid,
                reorderUuid,
              },
              title: t('titles:createOrder'),
            });
            if (successCallback) {
              successCallback();
            }
          } else if (errorCallback) {
            errorCallback();
          }
        } else if (metadataError) {
          checkMaintenanceMode();
        }
      });
    }
  }, [patientUuid]);

  const checkMaintenanceMode = async () => {
    const response = await checkIsMaintenanceMode();
    if (response.status === 200) {
      dispatch(updateIsMaintenanceMode(true));
    }
  }

  return {
    loading,
  };
};

export default useOrderMetadata;
