import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import ContentWrapper from 'components/Wrappers/ContentWrapper';
import { ReactComponent as Logo } from 'assets/icons/qlab-logo.svg';

const useStyles = makeStyles((theme) => ({
  main: {
    width: 360,
  },
  paper: {
    overflow: 'visible',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 3, 1, 3),
  },
  logo: {
    flex: '1 1 auto',
    fill: theme.palette.common.white,
  },
  title: {
    width: 120,
    position: 'relative',
    top: theme.spacing(-2),
    padding: theme.spacing(2),
  },
}));

const AuthFormWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <ContentWrapper
        title={<Logo className={classes.logo} />}
        className={classes.paper}
        titleClassName={classes.title}
      >
        {children}
      </ContentWrapper>
    </div>
  );
};

AuthFormWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthFormWrapper;
