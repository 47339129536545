import { SURVEYS_QUESTION_TYPES } from '../Shared/constants';

export const getCount = (SurveyItem) => {
  let colors = { success: 0, warning: 0, error: 0, info: 0 };

  SurveyItem.map(item => {
    if (item.itemType === SURVEYS_QUESTION_TYPES.GridSelect) {
      item.subItems.map(item => {
        if (item.value) {
          item.value.valueUIClass ? (colors[item.value.valueUIClass] += 1) : (colors.info += 1);
        }
      });
    }

    if (item.value) {
      if (item.value) {
        item.value.valueUIClass ? (colors[item.value.valueUIClass] += 1) : (colors.info += 1);
      }
    }

    if (item.values) {
      item.values.map(item => {
        item.valueUIClass ? (colors[item.valueUIClass] += 1) : (colors.info += 1);
      });
    }
  });

  return colors;
};
