import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import FailedIcon from '@material-ui/icons/ErrorOutline';
import Loader from 'components/Shared/Loader';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: 'all .6s',
  },
  progressContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    transition: 'all .6s',
  },
  hidden: {
    visibility: 'hidden',
  },
  isSuccessContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    transition: 'all .6s',
  },
  icon: {
    width: 30,
    height: 30,
  },
  whiteIcon: {
    color: 'white',
  },
}));

const LOADER_SIZES = {
  small: 22,
  medium: 24,
  large: 40,
};

const LoadingWrapper = ({
                          children,
                          isLoading,
                          isSuccess,
                          isFailed,
                          size,
                          loaderColor,
                          LoaderProps = {},
                          whiteIconStatusColor,
                        }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={cx({
          [classes.hidden]: isLoading || isSuccess || isFailed,
        })}
      >
        {children}
      </div>
      <div
        style={{ opacity: isLoading ? 1 : 0 }}
        className={classes.progressContainer}
      >
        <Loader
          size={LOADER_SIZES[size]}
          loaderColor={loaderColor}
          className={classes.progressContainer}
          {...LoaderProps}
        />
      </div>
      <div
        style={{ opacity: isSuccess ? 1 : 0 }}
        className={cx(classes.progressContainer, classes.isSuccessContainer)}>
        <DoneIcon className={cx(classes.icon, {
          [classes.whiteIcon]: whiteIconStatusColor,
        })}  />
      </div>
      <div
        style={{ opacity: isFailed ? 1 : 0 }}
        className={cx(classes.progressContainer, classes.isSuccessContainer)}>
        <FailedIcon className={cx(classes.icon, {
          [classes.whiteIcon]: whiteIconStatusColor,
        })} />
      </div>
    </div>
  );
};

LoadingWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isFailed: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  size: PropTypes.string,
  loaderColor: PropTypes.string,
  LoaderProps: PropTypes.shape({
    disableShrink: PropTypes.bool,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }),
};

LoadingWrapper.defaultProps = {
  isFailed: false,
  isSuccess: false,
  isLoading: false,
  size: 'medium',
  LoaderProps: undefined,
  loaderColor: 'white',
};

export default LoadingWrapper;
