import React, { useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import './app.css';
import { makeStyles, Typography } from '@material-ui/core';
import { useMutation } from 'react-fetching-library';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import RoundedButton from '../RoundedButton';
import HorizontalLinearStepper from './components/stepper';
import { stepsConstants, stepsNameConstants } from './constants';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import PropTypes from 'prop-types';
import { getFormattedDate } from '../../../helpers/localize';

const useStyles = makeStyles((theme) => ({
  errorStyle: {
    opacity: 0.5,
    backgroundColor: `${theme.palette.error.main} !important`,
  },
  successStyle: {
    opacity: 0.8,
    backgroundColor: `${theme.palette.success.main} !important`,
  },
  button: {
    height: 50,
    width: 260,
  },
  hint: {
    marginTop: theme.spacing(1),
    fontSize: '14px !important',
  },
  whiteLoader: {
    color: theme.colors.white,
  },
}));

export default function FilesDragAndDrop({
                                           name,
                                           hasValidation,
                                           steps,
                                         }) {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const { accountUuid } = useParams();
  const { t } = useTranslation(['fileUploading', 'errors', 'notifications']);
  const classes = useStyles();
  const [step, setStep] = useState(stepsNameConstants.selectFile);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();
  const [urlValidateErrorsFile, setUrlValidateErrorsFile] = useState();

  const uploadAndValidateFile = (values) => ({
    method: 'POST',
    endpoint: `/accounts/${accountUuid}/${name}-upload?validate-only=true`,
    body: values,
    responseType: 'blob',
  });
  const { mutate } = useMutation(uploadAndValidateFile);

  const importFile = (values) => ({
    method: 'POST',
    endpoint: `/accounts/${accountUuid}/${name}-upload`,
    body: values,
    responseType: 'blob',
  });
  const { mutate: importMutate } = useMutation(importFile);

  const resetAllData = () => {
    setStep(stepsNameConstants.selectFile);
    setFile();
    setUrlValidateErrorsFile();
  };

  const downloadErrorsFile = () => {
    const link = document.createElement('a');
    link.href = urlValidateErrorsFile;
    link.setAttribute('download', `Validation errors ${getFormattedDate(new Date(Date.now()))}.xlsx`);
    document.body.appendChild(link);
    link.click();
    resetAllData();
  };

  const sendFile = async (isOnlyValidate) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    setIsLoading(true);

    const { payload, error } = isOnlyValidate
      ? await mutate(formData)
      : await importMutate(formData);

    if (error) {
      const res = JSON.parse(await payload.text());
      dispatch(
        enqueueSnackbar({
          message: (res && res.exception && res.exception.message) ? res.exception.message : 'Unexpected error',
          options: {
            variant: SNACKBAR_VARIANTS.error,
          },
        }),
      );
      resetAllData();
    } else if (payload && payload.size > 0) {
      if (hasValidation) {
        setStep(stepsNameConstants.validate);
        const url = window.URL.createObjectURL(new Blob([payload]));
        setUrlValidateErrorsFile(url);
      } else {
        setStep(stepsNameConstants.finish);
      }
    } else {
      setStep(
        isOnlyValidate ? stepsNameConstants.import : stepsNameConstants.finish,
      );
    }

    setIsLoading(false);
  };

  const fileHandler = (files) => {
    if (files.length > 1) {
      dispatch(
        enqueueSnackbar({
          message: t('fileUploading:uploadOneFile'),
          options: {
            variant: SNACKBAR_VARIANTS.error,
          },
        }),
      );
      return;
    }
    const parsedFileName = files[0].name.split('.');
    const extension = parsedFileName[parsedFileName.length - 1];
    if (extension !== undefined && extension.toUpperCase() === 'XLSX') {
      setFile(files[0]);
      setStep(stepsNameConstants.upload);
    } else {
      dispatch(
        enqueueSnackbar({
          message: t('fileUploading:fileTypeNotSupported'),
          options: {
            variant: SNACKBAR_VARIANTS.error,
          },
        }),
      );
    }
  };

  const filePicker = () => {
    inputRef.current.click();
  };

  const onButtonClick = () => {
    switch (step) {
      case stepsNameConstants.selectFile: {
        filePicker();
        break;
      }
      case stepsNameConstants.upload: {
        sendFile(hasValidation);
        break;
      }
      case stepsNameConstants.validate: {
        downloadErrorsFile();
        break;
      }
      case stepsNameConstants.import: {
        sendFile(false);
        break;
      }
      case stepsNameConstants.finish: {
        resetAllData();
        break;
      }
      default: {
        break;
      }
    }
  };

  const setNewStep = (newStep) => {
    switch (newStep) {
      case stepsNameConstants.selectFile: {
        resetAllData();
        break;
      }
      case stepsNameConstants.validate: {
        setStep(stepsNameConstants.validate);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div className='container'>
      <HorizontalLinearStepper
        steps={steps}
        stepName={step}
        setStep={setNewStep}
        hasValidationError={urlValidateErrorsFile}
        hasValidation={hasValidation}
      />
      <FileDrop
        onTargetClick={null}
        onDrop={
          step !== stepsNameConstants.selectFile ? null : (f) => fileHandler(f)
        }
      >
        <Typography className={cx('title')}>
          {stepsConstants[step].message}
        </Typography>
        <RoundedButton
          variant='contained'
          color='primary'
          size='small'
          isLoading={isLoading}
          disabled={isLoading}
          loaderColor={
            stepsConstants[step].errorStyle || stepsConstants[step].successStyle
              ? 'error'
              : null
          }
          className={cx(classes.button, {
            [classes.errorStyle]: stepsConstants[step].errorStyle,
            [classes.successStyle]: stepsConstants[step].successStyle,
          })}
          onClick={onButtonClick}
          LoaderProps={{
            iconClassName: classes.whiteLoader,
          }}
        >
          {stepsConstants[step].buttonTitle}
        </RoundedButton>
        {step === stepsNameConstants.selectFile && (
          <>
            <Typography className={cx('title', classes.hint)}>
              {t('fileUploading:availableFormats')}
            </Typography>
            <input
              accept='.XLSX'
              value=''
              style={{ visibility: 'hidden', opacity: 0 }}
              ref={inputRef}
              multiple={false}
              type='file'
              onChange={(e) => fileHandler(e.target.files)}
            />
          </>
        )}
      </FileDrop>
    </div>
  );
}

FilesDragAndDrop.propTypes = {
  hasValidation: PropTypes.bool,
  name: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

FilesDragAndDrop.defaultProps = {
  hasValidation: false,
};
