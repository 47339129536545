import { Box, makeStyles } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-fetching-library';
import { conformToMask } from 'react-text-mask';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import Modal from 'components/Shared/Modal';
import getDigitPhone from 'helpers/phone';
import showServerError from 'helpers/showError';
import { MASK } from 'components/Shared/FormattedPhone';
import AssessmentForm from 'components/Assessments/Item/AssessmentForm';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { getUtcDate } from '../../../../helpers/localize';
import Loader from '../../../Shared/Loader';
import { ActivePatientContext } from '../../../../contexts/ActivePatientProvider';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../../redux_store/reducer/reducers/notificationsReducer';

const useStyles = makeStyles((theme) => ({
  modalBtn: {
    height: 128,
    width: 300,
    borderColor: theme.colors.lightBlue,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 2,
    width: 'max-content',
    color: theme.colors.blue2,
    textTransform: 'uppercase',
    paddingTop: theme.spacing(2),
  },
  modal: {
    width: '100%',
    maxWidth: 1000,
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },
  createButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
    maxHeight: 40,
  },
  indicator: {
    display: 'none',
  },
  tabs: {
    zIndex: 1,
  },
  tab: {
    minWidth: 'unset',
    minHeight: 'unset',
    opacity: 1,
    borderRadius: 17,
    border: `0.5px solid ${theme.colors.lightBlue}`,
    fontWeight: 400,
    fontSize: 15,
    backgroundColor: theme.palette.common.white,
    color: theme.colors.lightBlue,
  },
  selected: {
    fontWeight: 700,
    backgroundColor: theme.colors.lightBlue,
    color: theme.palette.common.white,
  },
  leftTab: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  rightTab: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  divider: {
    zIndex: 0,
    top: '42%',
    width: '100%',
    position: 'absolute',
  },
  secondaryTitle: {
    fontSize: 15,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.colors.blue2,
    opacity: 0.5,
    padding: theme.spacing(6, 20, 0, 20),
    [theme.breakpoints.down(1000)]: {
      padding: theme.spacing(0),
    },
  },
  title: {
    paddingTop: theme.spacing(2),
    color: theme.colors.lightBlue,
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
  checkIcon: {
    width: 50,
    height: 35,
    color: theme.palette.common.white,
  },
  iconBox: {
    width: 73,
    height: 73,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.lightBlue,
  },
  linkBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.colors.gray1,
    marginLeft: 84,
  },
  link: {
    cursor: 'default',
    textAlign: 'center',
    display: 'flex',
    fontSize: 25,
    fontWeight: 500,
    padding: theme.spacing(2, 6),
    msUserSelect: 'none',
    userSelect: 'none',
  },
  copyIcon: {
    width: 69,
    marginLeft: 15,
  },
  modalSecondTitle: {
    padding: theme.spacing(0, 16),
    [theme.breakpoints.down(1000)]: {
      padding: theme.spacing(0),
    },
  },
}));

const ConfirmResendingInvitation = ({
  handleClose,
  reloadData,
  resendInvitationData,
  source,
  subtitle,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['btn', 'errors', 'notifications', 'titles']);
  const [backendErrors, setBackendErrors] = useState({});
  const closeModal = () => {
    handleClose();
    setBackendErrors({});
  };
  const { user } = useSelector((state) => state.user);
  const [account, setAccount] = useState();
  const { patientInfo, setPatientInfo } = useContext(ActivePatientContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const changeContactInfoAction = (values) => ({
    method: 'POST',
    endpoint: `/accounts/${resendInvitationData.accountUUID}/${source}s/${resendInvitationData[source]}/invitations`,
    body: values,
  });

  const { loading, mutate } = useMetadataUpdate(useMutation, [
    changeContactInfoAction,
  ]);

  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${resendInvitationData.accountUUID}`,
    },
    false,
  ]);

  useEffect(() => {
    if (resendInvitationData) {
      query().then(({ error, payload }) => {
        if (!error) {
          setAccount(payload);
        }
      });
    }
  }, [resendInvitationData]);

  const handleResendInvitation = async (values, { setSubmitting }) => {
    if (
      values.sendingOption !== 'sendNow' &&
      (!values.scheduledDate ||
        (!values.scheduleDeliverTime && values.scheduleDeliverTime !== 0) ||
        values.scheduleDeliverTime === 'invalid_value')
    ) {
      if (!values.scheduledDate) {
        setBackendErrors({ sendingDataError: 'The date is required to send' });
      } else {
        setBackendErrors({ sendingDataError: 'The time is required to send' });
      }
      return;
    }

    setBackendErrors({});

    const initPhone = conformToMask(
      resendInvitationData.sendInviteToPatientPhone,
      MASK,
    ).conformedValue;
    const initEmail = resendInvitationData.sendInviteToPatientEmail;

    const patches = {
      email: values.email,
      phone: getDigitPhone(values.phone),
      updatePatientRecordPhone:
        values.updatePatientRecordPhone &&
        initPhone !== values.phone &&
        !!values.phone,
      updatePatientRecordEmail:
        values.updatePatientRecordEmail &&
        initEmail !== values.email &&
        !!values.email,
      sendingOption: values.sendingOption,
      scheduledDate:
        values.sendingOption === 'sendNow'
          ? null
          : getUtcDate(values.scheduledDate),
      scheduleDeliverTime:
        values.sendingOption === 'sendNow' ? null : values.scheduleDeliverTime,
    };
    const { payload, error, status } = await mutate(patches);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      setSubmitting(false);
      switch (status) {
        case 400: {
          if (payload) {
            const { errorCode } = payload;
            if (errorCode === 1) {
              showServerError(
                dispatch,
                t('errors:invitationMessageWasJustSent'),
              );
              break;
            } else if (errorCode === 2) {
              showServerError(
                dispatch,
                t('errors:invitationsWereNotOriginallySent'),
              );
              break;
            }
          }
          showServerError(dispatch, t('errors:unknownError'));
          break;
        }
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
        reloadData();
        handleClose();

        const updatePatientRecordEmail =
          values.updatePatientRecordEmail &&
          initEmail !== values.email &&
          !!values.email;
        const updatePatientRecordPhone =
          values.updatePatientRecordPhone &&
          initPhone !== values.phone &&
          !!values.phone;

        const newPatientInfo = {
          ...patientInfo,
        };

        if (updatePatientRecordEmail) {
          newPatientInfo.email = values.email;
        }
        if (updatePatientRecordPhone) {
          newPatientInfo.phone = values.phone;
        }
        if (updatePatientRecordEmail || updatePatientRecordPhone) {
          setPatientInfo(newPatientInfo);
        }
      }, 2000);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:invitationResent'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    }
  };

  const initialValues = {
    phone: conformToMask(resendInvitationData.sendInviteToPatientPhone, MASK)
      .conformedValue,
    email: resendInvitationData.sendInviteToPatientEmail,
    updatePatientRecordPhone: false,
    updatePatientRecordEmail: false,
    sendingOption: 'sendNow',
    scheduledDate: null,
    scheduleDeliverTime: account?.scheduleDeliverTime ?? null,
  };
  return (
    <Modal
      open={!!resendInvitationData[source]}
      handleClose={closeModal}
      classes={{ paper: classes.modal, container: classes.modalContainer }}
      title={t('titles:verifyInvitationDetails')}
      secondTitleStyle={classes.modalSecondTitle}
    >
      {!resendInvitationData || !account ? (
        <Box pt={19} pb={19}>
          <Loader fullHeight />
        </Box>
      ) : (
        <Formik
          initialValues={{
            phone: conformToMask(
              resendInvitationData.sendInviteToPatientPhone,
              MASK,
            ).conformedValue,
            email: resendInvitationData.sendInviteToPatientEmail,
            updatePatientRecordPhone: false,
            updatePatientRecordEmail: false,
            sendingOption: 'sendNow',
            scheduledDate: null,
            scheduleDeliverTime: account?.scheduleDeliverTime ?? null,
          }}
          validateOnBlur={false}
          onSubmit={handleResendInvitation}
        >
          {({ isValid, handleSubmit, isSubmitting, values }) => (
            <Box pt={5} p={4}>
              <Box pt={2}>
                <AssessmentForm
                  subtitle={subtitle ?? t('forms:invitationWillBeResent')}
                  values={values}
                  initialValues={initialValues}
                  backendErrors={backendErrors}
                  hideSendingOptions={true}
                  actions={
                    <Box
                      py={1}
                      pb={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <RoundedButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={closeModal}
                        className={classes.createButton}
                      >
                        {t('btn:cancel')}
                      </RoundedButton>
                      <div style={{ display: 'flex' }}>
                        <RoundedButton
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled={!isValid || isSubmitting}
                          onClick={handleSubmit}
                          isLoading={loading}
                          isSuccess={isSuccess}
                          isFailed={isFailed}
                          className={classes.createButton}
                        >
                          {t('btn:send')}
                        </RoundedButton>
                      </div>
                    </Box>
                  }
                />
              </Box>
            </Box>
          )}
        </Formik>
      )}
    </Modal>
  );
};

ConfirmResendingInvitation.propTypes = {
  resendInvitationData: PropTypes.shape({
    patientUUID: PropTypes.string.isRequired,
    survey: PropTypes.string.isRequired,
    accountUUID: PropTypes.string.isRequired,
    sendInviteToPatientEmail: PropTypes.string.isRequired,
    sendInviteToPatientPhone: PropTypes.string.isRequired,
  }).isRequired,
  source: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
};

ConfirmResendingInvitation.defaultProps = {
  subtitle: null,
};

export default ConfirmResendingInvitation;
