import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import i18next from 'i18next';

import FormikTextField from 'components/FormikFields/FormikTextField';
import FormControlButtons from 'components/Shared/FormControlButtons';

const ConfirmPasswordForm = ({
  cancelButtonRoute,
  backendErrors,
  submitText,
  onSubmit,
  loading,
  isSuccess,
  isFailed,
}) => {
  const { t } = useTranslation(['btn', 'forms']);
  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, dirty }) => (
        <Form>
          <FormikTextField
            type="password"
            name="password"
            label={t('forms:password')}
            backendError={backendErrors.password}
            margin="normal"
            required
          />
          <FormikTextField
            type="password"
            name="confirmPassword"
            label={t('forms:confirmPassword')}
            backendError={backendErrors.confirmPassword}
            margin="normal"
            required
          />

          <FormControlButtons
            loading={loading}
            isValid={isValid}
            submitText={submitText}
            isSubmitting={isSubmitting}
            cancelButtonRoute={cancelButtonRoute}
            dirty={dirty}
            isSuccess={isSuccess}
            isFailed={isFailed}
          />
        </Form>
      )}
    </Formik>
  );
};

ConfirmPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  loading: PropTypes.bool,
  submitText: PropTypes.string,
  backendErrors: PropTypes.shape({
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
  cancelButtonRoute: PropTypes.PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

ConfirmPasswordForm.defaultProps = {
  backendErrors: { password: '', confirmPassword: '' },
  loading: false,
  isSuccess: false,
  isFailed: false,
  submitText: i18next.t('btn:submit'),
};

export default ConfirmPasswordForm;
