import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  contentCentred: {
    textAlign: 'center',
  },
  img: {
    width: 150,
  },
  title: {
    color: '#888888',
    fontWeight: 600,
    fontSize: 20,
  },
  secondaryTitle: {
    color: '#B3B3B3',
    fontSize: 14,
    whiteSpace: 'pre',
  },
}));

const TablePlaceholder = ({ image, itemsNotFoundText, message, customImageWidget }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.contentCentred}>
        {image && <img alt='' src={image} className={classes.img} />}
        {customImageWidget}
        <div className={classes.title}>{itemsNotFoundText}</div>
        <div className={classes.secondaryTitle}>{message}</div>
      </div>
    </div>
  );
};

TablePlaceholder.propTypes = {
  image: PropTypes.string.isRequired,
  itemsNotFoundText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default TablePlaceholder;
