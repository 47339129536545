import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Divider,
  makeStyles,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'formik';
import cx from 'classnames';
import { FormattedPhone } from 'components/Shared/FormattedPhone';
import FormikTextField from 'components/FormikFields/FormikTextField';
import genderItems, {
  PATIENT_LANGUAGE,
  PATIENT_LANGUAGE_SELECTOR_OPTIONS,
} from 'components/Patients/constants';
import HelperText from 'components/Shared/HelperText';
import Radio from 'components/Shared/Radio';
import { getFormattedDate } from '../../helpers/localize';
import CalendarPopover from '../FormikFields/Calendar/CalendarPopover';
import Select from '../FormikFields/ComplexSelect/Select';
import AddressForm from '../Accounts/Item/AddressForm';

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    padding: '5px 12px',
    borderRadius: 8,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    minWidth: 229,
    minHeight: 40,
  },
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  checkbox: {
    marginLeft: -theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dividerSpacing: {
    marginTop: 0,
  },
  radio: {
    color: theme.colors.grey3,
  },
  checkedRadio: {
    color: theme.colors.lightBlue,
  },
  roleLabel: {
    margin: theme.spacing(0, 0, 1.875, 0),
    width: 125,
    color: theme.colors.blue2,
  },
  roleLabelCheckbox: {
    margin: theme.spacing(1.875, 0, 1.175, 0),
    color: theme.colors.blue2,
  },
  radioContainer: {
    width: 210,
  },
  mb1: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  m2: {
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2.5),
    },
  },
}));

const PatientForm = ({ values, backendErrors, actions }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms', 'errors', 'notifications']);

  return (
    <Form className={classes.form}>
      <Grid
        container
        justify="space-between"
        spacing={3}
        className={classes.mb1}
      >
        <Grid item xs={12} sm={4}>
          <FormikTextField
            type="text"
            name="mrnNumber"
            label={t('forms:mrnNumber')}
            backendError={backendErrors.mrnNumber}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormikTextField
            type="text"
            name="firstName"
            label={t('forms:firstName')}
            backendError={backendErrors.firstName}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormikTextField
            type="text"
            name="lastName"
            label={t('forms:lastName')}
            backendError={backendErrors.lastName}
            required
          />
        </Grid>
      </Grid>
      <Grid container justify="space-between" spacing={3}>
        <Grid item xs={12} sm={4}>
          <FormikTextField
            type="tel"
            name="phone"
            label={t('forms:phone')}
            backendError={backendErrors.phone}
            InputProps={{
              inputComponent: FormattedPhone,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormikTextField
            type="text"
            name="email"
            label={t('forms:email')}
            backendError={backendErrors.email}
          />
        </Grid>
      </Grid>
      <Grid container justify="space-between">
        <div className={classes.m2}>
          <Field name="dateOfBirth">
            {({ field, form }) => {
              let date = null;
              if (field.value) {
                // add offset in minutes to work with date like in UTC
                const newDate = new Date(field.value);
                if (field.value.includes('T00:00Z')) {
                  const userTimezoneOffset =
                    newDate.getTimezoneOffset() * 60000;
                  date = new Date(newDate.getTime() + userTimezoneOffset);
                } else {
                  date = newDate;
                }
              }
              return (
                <FormControl
                  {...{
                    required: true,
                    error: !!backendErrors.dateOfBirth,
                  }}
                >
                  <FormLabel
                    {...{
                      className: classes.roleLabel,
                    }}
                  >
                    {t('forms:dateOfBirth')}
                  </FormLabel>
                  <div className={classes.datepickerWrapper}>
                    <CalendarPopover
                      onChange={(newDate) => {
                        if (!newDate) {
                          // clear filter
                          form.setFieldValue(field.name, '');
                        } else {
                          // valid date
                          form.setFieldValue(
                            field.name,
                            getFormattedDate(newDate),
                          );
                        }
                      }}
                      date={date}
                      maxDate={new Date()}
                    />
                  </div>
                  <HelperText>{backendErrors.dateOfBirth}</HelperText>
                </FormControl>
              );
            }}
          </Field>
        </div>
        <div className={classes.m2}>
          <Field name="genderAtBirth" type="checkbox" className={classes.m2}>
            {({ field, form }) => (
              <FormControl required error={!!backendErrors.genderAtBirth}>
                <FormLabel className={classes.roleLabel}>
                  {t('forms:genderAtBirth')}
                </FormLabel>
                <RadioGroup
                  row
                  name={field.name}
                  value={field.value}
                  onChange={(e) => {
                    form.setFieldValue(field.name, e.target.value);
                  }}
                >
                  <Grid
                    container
                    spacing={3}
                    className={classes.radioContainer}
                  >
                    {genderItems.map(({ label, value }) => (
                      <Grid item xs={6} key={value}>
                        <FormControlLabel
                          value={value}
                          control={<Radio />}
                          label={label}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
                <HelperText>{backendErrors.genderAtBirth}</HelperText>
              </FormControl>
            )}
          </Field>
        </div>

        <div className={classes.mb1}>
          <Field name="hasDrugAllergies" type="checkbox">
            {({ field, form }) => {
              let { value } = field;
              if (field.value === undefined) {
                value = null;
              }
              return (
                <FormControl required>
                  <FormLabel className={classes.roleLabel}>
                    {t('forms:drugAllergies')}
                  </FormLabel>
                  <RadioGroup
                    row
                    name={field.name}
                    value={value}
                    onChange={(e) => {
                      form.setFieldValue(
                        field.name,
                        JSON.parse(e.target.value || 'null'),
                      );
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      className={classes.radioContainer}
                    >
                      <Grid item xs={6}>
                        <FormControlLabel
                          value
                          control={<Radio />}
                          label={t('forms:yes')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t('forms:no')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          value={null}
                          control={<Radio />}
                          label={t('forms:unknown')}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              );
            }}
          </Field>
        </div>
        {values.hasDrugAllergies && (
          <div className={classes.mb1} style={{marginTop:30}}>
            <FormikTextField
              type="text"
              name="drugAllergies"
              label={t('forms:drugList')}
              multiline
              rows={4}
              maxRows={4}
              backendError={backendErrors.drugAllergies}
            />
          </div>
        )}
      </Grid>
      <Grid container direction="column" justify="space-between" spacing={3}  style={{ marginBottom: 0 }}>
        <Grid item xs={12} sm={4}>
          <Field name="patientLanguage" type="checkbox">
            {({ field, form }) => {
              return (
                <Select
                  dataKey="patientLanguage"
                  filterValue={field.value ?? PATIENT_LANGUAGE.UNKNOWN}
                  columnData={{
                    options: PATIENT_LANGUAGE_SELECTOR_OPTIONS,
                  }}
                  onChange={(name, value) => {
                    form.setFieldValue(name, value);
                  }}
                  label={`${t('forms:patientLanguage')}`}
                  labelWidth={78}
                  error={backendErrors.patientLanguage}
                  className={cx(classes.margin0)}
                />
              );
            }}
          </Field>
        </Grid>
      </Grid>

      <Divider
        className={cx(
          classes.divider,
          classes.dividerSpacing,
        )}
      />

      <Grid container direction="column" justify="space-between">
        <AddressForm
          fullWidth
          prefix="address"
          backendErrors={backendErrors}
          hasMobileLayout
        />
      </Grid>

      <Divider
        className={cx(
          classes.divider,
          classes.dividerSpacing,
        )}
      />

      <Grid container direction="column" justify="space-between">
        <Field name="smsOptIn" type="checkbox">
          {({ field, form }) => {
            return (
              <Grid container direction="column">
                <FormLabel className={classes.roleLabelCheckbox}>
                  {t('forms:smsOptIn')}
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      color="primary"
                      onChange={(e, checked) => {
                        form.setFieldValue(field.name, checked);
                      }}
                      disabled={false}
                    />
                  }
                  className={classes.checkbox}
                  label={t('forms:smsOptInDescription')}
                />
              </Grid>
            );
          }}
        </Field>
      </Grid>
      <Grid container direction="column" justify="space-between">
        <Field name="hasEmailSubscription" type="checkbox">
          {({ field, form }) => {
            return (
              <Grid container direction="column">
                <FormLabel className={classes.roleLabelCheckbox} >
                  {t('forms:hasEmailSubscription')}
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      color="primary"
                      onChange={(e, checked) => {
                        form.setFieldValue(field.name, checked);
                      }}
                      disabled={false}
                    />
                  }
                  className={classes.checkbox}
                  label={t('forms:hasEmailSubscriptionDescription')}
                />
              </Grid>
            );
          }}
        </Field>
      </Grid>

      {actions}
    </Form>
  );
};

PatientForm.propTypes = {
  actions: PropTypes.node,
  values: PropTypes.shape({
    hasDrugAllergies: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf([null]),
    ]),
  }).isRequired,
  backendErrors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mrnNumber: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    genderAtBirth: PropTypes.string,
    dateOfBirth: PropTypes.string,
    drugAllergies: PropTypes.string,
  }),
};

PatientForm.defaultProps = {
  actions: undefined,
  backendErrors: undefined,
};

export default PatientForm;
