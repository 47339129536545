import React  from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import client from '../config/fetch';
import { ClientContextProvider } from 'react-fetching-library';

function ClientProvider({ children }) {
  const dispatch = useDispatch();

  return (
    <ClientContextProvider client={client(dispatch)}>
      {children}
    </ClientContextProvider>
  );
}

ClientProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ClientProvider;
