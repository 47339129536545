import React from 'react';
import PropTypes from 'prop-types';
import OldMarkdownParser from './OldMarkdownParser/OldMarkdownParser';
import NewMarkdownParser from './MarkdownParser';

const checkMarkdownOldVersion = (content) => {
  return (
    content.includes('*{spacing}*') ||
    content.includes('|++|{') ||
    content.includes('%%%')
  );
};

const MarkdownParser = ({ content }) => {
  const isMarkdownOldVersion = checkMarkdownOldVersion(content);
  return isMarkdownOldVersion ? (
    <OldMarkdownParser text={content} />
  ) : (
    <NewMarkdownParser content={content} />
  );
};

MarkdownParser.propTypes = {
  content: PropTypes.string.isRequired,
};

export default MarkdownParser;
