import { Box, makeStyles } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation, useQuery } from 'react-fetching-library';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import Modal from 'components/Shared/Modal';
import showServerError from 'helpers/showError';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { Formik } from 'formik';
import { conformToMask } from 'react-text-mask';
import { useDispatch, useSelector } from 'react-redux';
import AssessmentForm from '../Assessments/Item/AssessmentForm';
import { MASK } from '../Shared/FormattedPhone';
import getDigitPhone from '../../helpers/phone';
import InvitationSent from '../Shared/CreateNewItemModal/InvitationSent';
import Breadcrumbs from '../Shared/CreateNewItemModal/Breadcrumbs';
import FilteredItemList from '../Shared/CreateNewItemModal/FilteredItemList';
import { RESOURCE_ICON_LIST } from './constants';
import { getUtcDate } from '../../helpers/localize';
import Loader from '../Shared/Loader';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import ConfirmationsPopup from '../Shared/ConfirmationsPopup';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    maxWidth: 1000,
    minWidth: 1000,
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },
  createButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
    maxHeight: 40,
  },
  backButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
    margin: '0 20px',
    maxHeight: 40,
  },
  title: {
    paddingTop: theme.spacing(2),
    color: theme.colors.lightBlue,
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
  firstTitleStyle: {
    textAlign: 'start',
    padding: theme.spacing(4),
    [theme.breakpoints.down(1000)]: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  modalSecondTitle: {
    width: '100%',
  },
  titleChildrenStyle: {
    width: 'calc(100% - 380px)',
    maxWidth: 'calc(100% - 380px)',
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

const ResourceCreate = ({
  open,
  handleClose,
  accountUuid,
  patient,
  reloadData,
  scheduleDeliverTime,
}) => {
  const classes = useStyles();
  const { t } = useTranslation([
    'btn',
    'errors',
    'notifications',
    'titles',
    'dialogs',
  ]);
  const [activeResource, setActiveResource] = useState({});
  const [step, setStep] = useState(0);
  const [resourceList, setResourceList] = useState([]);
  const [filteredResourceList, setFilteredResourceList] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const patientUuid = get(patient, 'uuid', '');
  const [backendErrors, setBackendErrors] = useState({});
  const { patientInfo, setPatientInfo } = useContext(ActivePatientContext);
  const [confirmResendingInfo, setConfirmResendingInfo] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [
    confirmResendingAdditionalInfo,
    setConfirmResendingAdditionalInfo,
  ] = useState(null);
  const { query, loading: loadingResourceList } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/resources-data`,
    },
    false,
  ]);

  const closeModal = () => {
    handleClose();
    setBackendErrors({});
  };

  const closeConfirmationsPopup = () => {
    setConfirmResendingInfo(null);
    setConfirmResendingAdditionalInfo(null);
  };

  useEffect(() => {
    if (accountUuid) {
      query().then(({ error, payload }) => {
        if (!error) {
          setResourceList(payload.result);
          setFilteredResourceList(payload.result);
        }
      });
    }
  }, [accountUuid]);

  const ResourceCreateAction = (values) => ({
    method: 'PUT',
    endpoint: `/accounts/${accountUuid}/patients/${patientUuid}/resources`,
    body: values,
  });

  const { loading, mutate } = useMetadataUpdate(useMutation, [
    ResourceCreateAction,
  ]);

  const chooseResource = async (resourceKey) => {
    setStep(1);
    setActiveResource(resourceKey);
  };
  const handleSuccessfulCreating = (payload, additionalInfo) => {
    const { values, initEmail, initPhone } =
      additionalInfo || confirmResendingAdditionalInfo;
    reloadData(true);
    dispatch(
      enqueueSnackbar({
        message: t('notifications:resourceCreated', {
          name: `${patientInfo.firstName} ${patientInfo.lastName}`,
        }),
        options: {
          variant: SNACKBAR_VARIANTS.success,
        },
      }),
    );
    setStep(2);

    const updatePatientRecordEmail =
      values.updatePatientRecordEmail &&
      initEmail !== values.email &&
      !!values.email;
    const updatePatientRecordPhone =
      values.updatePatientRecordPhone &&
      initPhone !== values.phone &&
      !!values.phone;

    const newPatientInfo = {
      ...patientInfo,
    };

    if (updatePatientRecordEmail) {
      newPatientInfo.email = values.email;
    }
    if (updatePatientRecordPhone) {
      newPatientInfo.phone = values.phone;
    }
    if (updatePatientRecordEmail || updatePatientRecordPhone) {
      setPatientInfo(newPatientInfo);
    }
    closeConfirmationsPopup();
  };

  const createResource = async (values, { setSubmitting }) => {
    if (
      values.sendingOption !== 'sendNow' &&
      (!values.scheduledDate ||
        (!values.scheduleDeliverTime && values.scheduleDeliverTime !== 0) ||
        values.scheduleDeliverTime === 'invalid_value')
    ) {
      if (!values.scheduledDate) {
        setBackendErrors({ sendingDataError: 'The date is required to send' });
      } else {
        setBackendErrors({ sendingDataError: 'The time is required to send' });
      }
      return;
    }

    const initPhone =
      patient && conformToMask(patient.phone ?? '', MASK).conformedValue;
    const initEmail = patient && get(patient, 'email', '');

    const formattedValues = {
      email: values.email,
      phone: getDigitPhone(values.phone),
      resourceKey: activeResource.resourceKey,
      updatePatientRecordPhone:
        values.updatePatientRecordPhone &&
        initPhone !== values.phone &&
        !!values.phone,
      updatePatientRecordEmail:
        values.updatePatientRecordEmail &&
        initEmail !== values.email &&
        !!values.email,
      scheduledDate:
        values.sendingOption === 'sendNow'
          ? null
          : getUtcDate(values.scheduledDate),
      scheduleDeliverTime:
        values.sendingOption === 'sendNow' ? null : values.scheduleDeliverTime,
    };
    setBackendErrors({});

    const { payload, error, status } = await mutate({
      ...formattedValues,
    });
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      setSubmitting(false);
      switch (status) {
        case 422: {
          if (
            payload.requiredConfirmations &&
            payload.requiredConfirmations.length > 0
          ) {
            setConfirmResendingAdditionalInfo({
              formattedValues,
              values,
              initEmail,
              initPhone,
            });
            setConfirmResendingInfo(payload.requiredConfirmations);
            break;
          } else if (payload.validationErrors.resourceKey) {
            showServerError(dispatch, payload.validationErrors.resourceKey);
          } else {
            setBackendErrors(payload.validationErrors);
            showServerError(dispatch, t('errors:validationError'));
          }
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      const additionalInfo = {
        formattedValues,
        values,
        initEmail,
        initPhone,
      };
      handleSuccessfulCreating(payload, additionalInfo);
    }
  };

  useEffect(() => {
    if (open) {
      setStep(0);
    }
    return setStep(0);
  }, [open]);

  useEffect(() => {
    setActiveResource('');
  }, []);

  const modalTitle =
    // eslint-disable-next-line no-nested-ternary
    step === 0
      ? t('titles:createNewResource')
      : step === 1
      ? activeResource.title
      : t('titles:resourceCreated');

  const initialValues = {
    phone: patient && conformToMask(patient.phone ?? '', MASK).conformedValue,
    email: patient && get(patient, 'email', ''),
    sendingOption: 'sendNow',
    scheduledDate: null,
    updatePatientRecordPhone: false,
    updatePatientRecordEmail: false,
    scheduleDeliverTime,
  };
  return (
    <Modal
      open={open}
      handleClose={closeModal}
      classes={{ paper: classes.modal, container: classes.modalContainer }}
      title={modalTitle}
      firstTitleStyle={classes.firstTitleStyle}
      secondTitleStyle={classes.modalSecondTitle}
      titleChildren={<Breadcrumbs activeStep={step} steps={[1, 2]} />}
      titleChildrenStyle={classes.titleChildrenStyle}
    >
      <ConfirmationsPopup
        closePopup={closeConfirmationsPopup}
        confirmResendingInfo={confirmResendingInfo}
        handleSuccessfulCreating={handleSuccessfulCreating}
        confirm={mutate}
        confirmBody={
          confirmResendingAdditionalInfo &&
          confirmResendingAdditionalInfo.formattedValues
        }
        isLoading={loading}
      />
      {!scheduleDeliverTime || !patient || !filteredResourceList ? (
        <Loader fullHeight />
      ) : (
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          onSubmit={createResource}
        >
          {({ isValid, handleSubmit, isSubmitting, values }) => (
            <Box pt={0} p={4}>
              {step === 0 && (
                <FilteredItemList
                  items={filteredResourceList}
                  handleClose={closeModal}
                  selectItem={chooseResource}
                  activeItem={activeResource.title}
                  loading={loadingResourceList}
                  iconList={RESOURCE_ICON_LIST}
                  dataList={resourceList}
                  notFoundTitle={t('titles:resources')}
                  setFilteredDataList={setFilteredResourceList}
                />
              )}
              {step === 1 && (
                <Box pt={2}>
                  <AssessmentForm
                    subtitle={t('forms:invitationWillBeSent')}
                    values={values}
                    initialValues={initialValues}
                    backendErrors={backendErrors}
                    actions={
                      <Box
                        py={1}
                        pb={2}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <div style={{ display: 'flex' }}>
                          <RoundedButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={closeModal}
                            className={classes.createButton}
                          >
                            {t('btn:cancel')}
                          </RoundedButton>

                          <RoundedButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => setStep(0)}
                            className={classes.backButton}
                          >
                            {t('btn:back')}
                          </RoundedButton>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <RoundedButton
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={!isValid || isSubmitting}
                            onClick={handleSubmit}
                            isLoading={loading}
                            className={classes.createButton}
                            isSuccess={isSuccess}
                            isFailed={isFailed}
                          >
                            {t('btn:send')}
                          </RoundedButton>
                        </div>
                      </Box>
                    }
                  />
                </Box>
              )}
              {step === 2 && <InvitationSent handleClose={closeModal} />}
            </Box>
          )}
        </Formik>
      )}
    </Modal>
  );
};

ResourceCreate.propTypes = {
  patient: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
  accountUuid: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  scheduleDeliverTime: PropTypes.string,
};

ResourceCreate.defaultProps = {
  scheduleDeliverTime: null,
};

export default ResourceCreate;
