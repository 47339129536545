import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import cx from 'classnames';

import FormikTextField from 'components/FormikFields/FormikTextField';
import FormikSelectField from 'components/FormikFields/FormikSelectField';
import RoundedButton from 'components/Shared/RoundedButton';
import { STATES } from 'components/Accounts/constants';

const useStyles = makeStyles((theme) => ({
  addressContainer: {
    maxWidth: 800,
  },
  removeBtn: {
    margin: theme.spacing(1.5, 1.5, 3, 0),
  },
  fullWidth: {
    width: '100%',
  },
  statesSelect: {
    marginTop: theme.spacing(1),
  },
  ml2: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
}));

const AddressForm = ({
  prefix,
  backendErrors,
  disabled,
  onRemove,
  fullWidth,
  hasMobileLayout,
}) => {
  const { t } = useTranslation(['forms']);
  const classes = useStyles();

  const name = prefix ? `${prefix}.name` : 'name';
  const streetAddress = prefix ? `${prefix}.streetAddress` : 'streetAddress';
  const suite = prefix ? `${prefix}.suite` : 'suite';
  const city = prefix ? `${prefix}.city` : 'city';
  const state = prefix ? `${prefix}.state` : 'state';
  const zipCode = prefix ? `${prefix}.zipCode` : 'zipCode';

  return (
    <>
      {onRemove && (
        <Grid
          container
          spacing={3}
          className={fullWidth ? undefined : classes.addressContainer}
        >
          <Grid item xs={9}>
            <FormikTextField
              type="text"
              name={name}
              label={t('forms:name')}
              backendError={backendErrors[name]}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={3}
        className={cx({
          [classes.ml2]: hasMobileLayout,
          [classes.addressContainer]: !fullWidth,
        })}
      >
        <Grid item xs={hasMobileLayout ? 12 : 9} sm={9}>
          <FormikTextField
            type="text"
            name={streetAddress}
            label={t('forms:streetAddress')}
            backendError={backendErrors[streetAddress]}
            fullWidth={fullWidth}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={hasMobileLayout ? 12 : 3} sm={3}>
          <FormikTextField
            type="text"
            name={suite}
            label={t('forms:suite')}
            backendError={backendErrors[suite]}
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <Grid
        container
        wrap="wrap"
        justify="space-between"
        alignItems="flex-start"
        className={classes.fullWidth}
      >
        <Grid
          container
          spacing={3}
          className={fullWidth ? undefined : classes.addressContainer}
        >
          <Grid item xs={hasMobileLayout ? 12 : 6} sm={6}>
            <FormikTextField
              type="text"
              name={city}
              label={t('forms:city')}
              backendError={backendErrors[city]}
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={hasMobileLayout ? 12 : 3} sm={3}>
            <FormikSelectField
              name={state}
              label={t('forms:state')}
              type="text"
              menuItems={STATES}
              className={cx(classes.statesSelect, classes.fullWidth)}
              backendError={backendErrors[state]}
              disabled={disabled}
              required
            />
          </Grid>
          <Grid item xs={hasMobileLayout ? 12 : 3} sm={3}>
            <FormikTextField
              type="text"
              name={zipCode}
              label={t('forms:zipCode')}
              backendError={backendErrors[zipCode]}
              disabled={disabled}
              required
            />
          </Grid>
        </Grid>

        {onRemove && (
          <RoundedButton
            variant="contained"
            color="secondary"
            size="small"
            onClick={onRemove}
            className={classes.removeBtn}
          >
            {t('forms:removeAddress')}
          </RoundedButton>
        )}
      </Grid>
    </>
  );
};

AddressForm.propTypes = {
  prefix: PropTypes.string,
  backendErrors: PropTypes.shape({
    name: PropTypes.string,
    streetAddress: PropTypes.string,
    suite: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  hasMobileLayout: PropTypes.bool,
  onRemove: PropTypes.func,
};

AddressForm.defaultProps = {
  prefix: '',
  fullWidth: false,
  backendErrors: {},
  disabled: false,
  onRemove: undefined,
  hasMobileLayout: false,
};

export default AddressForm;
