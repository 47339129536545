import React, { useState } from 'react';
import { makeStyles, LinearProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation } from 'react-fetching-library';
import showServerError from 'helpers/showError';
import { routes } from 'config/routes';
import ResetPasswordTextField from 'components/Shared/ResetPasswordTextField';
import AuthFormWrapper from 'components/Wrappers/AuthFormWrapper';
import ConfirmPasswordForm from 'components/Shared/ConfirmPasswordForm';
import getFullName from 'helpers/user';
import { useDispatch } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { setHipaaCheckParams as setHipaaCheckParamsAction } from '../../redux_store/reducer/reducers/userReducer/userReducer';

const useStyles = makeStyles((theme) => ({
  text: {
    margin: theme.spacing(2, 0, 2, 0),
    textAlign: 'left',
  },
}));

const PasswordReset = () => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'forms', 'notifications']);
  const [step, setStep] = useState(1);
  const [secret, setSecret] = useState(null);
  const [user, setUser] = useState(null);
  const [authMetadata, setAuthMetadata] = useState(null);
  const dispatch = useDispatch();
  const [backendErrors, setBackendErrors] = useState({});
  const completed = (step - 1) * 33.33;
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const increaseStep = () => {
    setStep((oldStep) => oldStep + 1);
  };

  const { loading: sendEmailLoading, mutate: sendEmailMutate } = useMutation(
    (values) => ({
      method: 'POST',
      endpoint: '/user/reset-password/email',
      body: values,
    }),
  );

  const sendEmail = async (values, { setSubmitting }) => {
    setBackendErrors({});
    const { payload, error, status } = await sendEmailMutate(values);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      setSubmitting(false);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
      };

      switch (status) {
        case 404: {
          showServerError(dispatch, t('errors:userProfileNotFound'));
          break;
        }
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsFailed(false);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:verifyCodeHasBeenSent'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      setSecret(payload);
      increaseStep();
    }
  };

  const { loading: sendCodeLoading, mutate: sendCodeMutate } = useMutation(
    (values) => ({
      method: 'POST',
      endpoint: '/user/reset-password/verify-email',
      body: values,
    }),
  );

  const sendCode = async (values, { setSubmitting }) => {
    setBackendErrors({});
    const { payload, error, status } = await sendCodeMutate({
      ...values,
      secret,
    });
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      setSubmitting(false);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
      };

      switch (status) {
        case 404: {
          showServerError(dispatch, t('errors:verificationCodeInvalid'));
          break;
        }
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsFailed(false);
      setUser(payload.user);
      setAuthMetadata(payload.authMetadata);
      increaseStep();
    }
  };

  const {
    loading: setNewPasswordLoading,
    mutate: setNewPasswordMutate,
  } = useMutation((values) => ({
    method: 'POST',
    endpoint: '/user/password',
    body: values,
  }));

  const setNewPassword = async (values, { setSubmitting }) => {
    setBackendErrors({});
    const { payload, error, status } = await setNewPasswordMutate(values);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      setSubmitting(false);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
      };

      switch (status) {
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsFailed(false);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      dispatch(
        setHipaaCheckParamsAction({
          newUser: user,
          newMetadata: authMetadata,
          newRoute: {
            path: routes.root.path,
          },
        }),
      );
      dispatch(
        enqueueSnackbar({
          message: t('notifications:passwordHasBeenChanged'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    }
  };

  return (
    <AuthFormWrapper>
      <LinearProgress variant="determinate" value={completed} />
      <Typography className={classes.text}>
        {t(`forms:resetPasswordStep${step}`, {
          name: user ? getFullName(user) : '',
        })}
      </Typography>

      {step === 1 && (
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={sendEmail}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <ResetPasswordTextField
              name="email"
              type="email"
              cancelButtonRoute={routes.login}
              submitText={t('btn:sendCode')}
              label={t('forms:email')}
              loading={sendEmailLoading}
              isValid={isValid}
              isSubmitting={isSubmitting}
              dirty={dirty}
              autoComplete="email"
              autoFocus
              margin="normal"
              required
              isFailed={isFailed}
            />
          )}
        </Formik>
      )}

      {step === 2 && (
        <Formik
          initialValues={{
            code: '',
          }}
          onSubmit={sendCode}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <ResetPasswordTextField
              name="code"
              type="text"
              cancelButtonRoute={routes.login}
              submitText={t('btn:proceed')}
              label={t('forms:confirmCode')}
              loading={sendCodeLoading}
              isValid={isValid}
              isSubmitting={isSubmitting}
              dirty={dirty}
              autoFocus
              margin="normal"
              required
              isFailed={isFailed}
            />
          )}
        </Formik>
      )}

      {step >= 3 && (
        <ConfirmPasswordForm
          backendErrors={backendErrors}
          onSubmit={setNewPassword}
          loading={setNewPasswordLoading}
          cancelButtonRoute={routes.login}
          isSuccess={isSuccess}
          isFailed={isFailed}
          submitText={t('btn:setPasswordLogIn')}
        />
      )}
    </AuthFormWrapper>
  );
};

export default PasswordReset;
