import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  InputAdornment,
  IconButton,
  TextField,
} from '@material-ui/core';
import cx from 'classnames';
import RemoveIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import smoothscroll from 'smoothscroll-polyfill';
import HelperText from '../../../Shared/HelperText';
import Select from '../../../FormikFields/ComplexSelect/Select';
import DataInput from '../../../FormikFields/DataInput';
import {
  CPT_STATUS_SELECTOR_OPTIONS,
  PR_TYPE_CLAIMS_SELECTOR_OPTIONS,
} from '../../constants';
import { useSelector } from 'react-redux';
import { addDays, getFormattedDate } from '../../../../helpers/localize';
import ICDSelector from './ICDSelector';
import CurrencyFormat from 'react-currency-format';
import PropTypes from 'prop-types';

// --------- adding smooth scrolling for click nav link ---------
smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    width: 30,
    height: 30,
    padding: 6,
    margin: '5px 0',
  },
  activitiesError: {
    position: 'absolute',
    color: theme.palette.error.main,
    bottom: 0,
  },
  activityContainer: {
    position: 'relative',
    boxShadow: 'none',
    transition: 'boxShadow 0.5s ease-in',
    padding: 8,
    paddingBottom: 0,
    marginBottom: 28,
  },
  activityContainerWithError: {
    boxShadow: '1px 4px 15px -5px rgba(240, 95, 95, 0.97)',
    transition: 'boxShadow 0.5s ease-in',
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    gap: 12,
  },
  tableWideItem: {
    flex: 10,
  },
  tableItem: {
    flex: 7,
  },
  tableInput: {
    marginTop: 0,
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& .MuiOutlinedInput-root': {
      height: 40,
      paddingLeft: 8,
    },
  },
}));

const CptInfoRow = ({
                      activities,
                      cptCodes,
                      modifiers,
                      backendErrors,
                      onDismiss,
                      updateCPTErrorsIfNeeded,
                      updateClaimValue,
                      disabled,
                      appointmentDate,
                      cpt,
                      index,
                    }) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);
  const [navWidth, setNavWidth] = useState(0);

  useEffect(() => {
    updateNavWidth().then(() => {
    });
  }, [isSideMenuPinned]);

  useEffect(() => {
    window.addEventListener('resize', updateNavWidth);
  }, []);

  async function updateNavWidth() {
    for (let i = 5; i < 300; i += 5) {
      await new Promise(resolve => {
        setTimeout(() => {
          resolve('');
        }, i);
      });
      setNavWidth((window?.innerWidth ?? 1000) - (document.getElementById('navBar')?.getBoundingClientRect().width ?? 0) - 176);
    }
  }

  const getActivitiesWithUpdate = (id, key, value, activities) => {
    return activities && activities.map((c) => {
      return id !== c.id
        ? c
        : {
          ...c,
          [key]: value,
        };
    });
  };

  const updateClaimCPTInfo = (id, key, value) => {
    updateCPTErrorsIfNeeded(id, key);
    const newActivities = getActivitiesWithUpdate(id, key, value, activities);
    updateClaimValue('activities', newActivities);
  };

  const roundClaimCPTInfoValue = (id, key, value) => {
    let newValue = Number(value);

    let newActivities = getActivitiesWithUpdate(id, key, newValue, activities) ?? [];

    if (key === 'paid' || key === 'pr') {
      const currentActivityOldValue = newActivities.find((activity) => id === activity.id);

      let value = '';
      if (key === 'paid') {
        if (newValue > 0) {
          value = 'paid';
        } else if (currentActivityOldValue && currentActivityOldValue['pr'] > 0) {
          value = 'patientResponsibility';
        }
      }
      if (key === 'pr') {
        if (newValue > 0
          && currentActivityOldValue
          && (currentActivityOldValue['paid'] === 0 || currentActivityOldValue['paid'] === '0.00')) {
          value = 'patientResponsibility';
        } else if (currentActivityOldValue && currentActivityOldValue['paid'] > 0) {
          value = 'paid';
        }
      }

      newActivities = getActivitiesWithUpdate(
        id,
        'cptStatus',
        value,
        newActivities,
      ) ?? [];
    }

    updateClaimValue('activities', newActivities);
  };

  const multiUpdateClaimCPTInfo = (id, values, newActivities) => {
    let updatedActivities = activities;
    values.forEach(({ key }) => {
      updateCPTErrorsIfNeeded(id, key);
    });
    updatedActivities = updatedActivities && updatedActivities.map((c) => {
      return id !== c.id
        ? c
        : {
          ...c,
          ...values.reduce((a, v) => ({ ...a, [v.key]: v.value }), {}),
        };
    });

    if (newActivities?.length) {
      updatedActivities = [
        ...updatedActivities,
        ...newActivities,
      ];
    }
    updateClaimValue('activities', updatedActivities);
  };

  const updateCPTCode = async (id, dataKey, value) => {
    if (value === '96136') {
      const has96137CptCode = (activities ?? []).some(item => item.code === '96137');

      await multiUpdateClaimCPTInfo(id, [
        {
          key: 'modifier',
          value: '59',
        },
        {
          key: 'code',
          value: '96136',
        },
        {
          key: 'dos',
          value: getFormattedDate(appointmentDate ? new Date(appointmentDate) : new Date()),
        },
      ], !has96137CptCode ? [{
        code: '96137',
        dos: getFormattedDate(appointmentDate ? new Date(appointmentDate) : new Date()),
      }] : null);
    } else if (value === '96137') {
      multiUpdateClaimCPTInfo(id, [
        {
          key: 'code',
          value: '96137',
        },
        {
          key: 'dos',
          value: getFormattedDate(appointmentDate ? new Date(appointmentDate) : new Date()),
        },
      ]);
    } else if (value === '99358' && appointmentDate) {
      multiUpdateClaimCPTInfo(id, [
        {
          key: 'code',
          value: '99358',
        },
        {
          key: 'dos',
          value: getFormattedDate(addDays(new Date(appointmentDate), 3)),
        },
      ]);
    } else {
      updateClaimCode(id, dataKey, value);
    }
  };

  const updateClaimCode = (id, key, value) => {
    updateClaimCPTInfo(id, key, value);
  };


  const pr = parseFloat(cpt.pr);
  const paid = parseFloat(cpt.paid);
  const allowed = (
    (!isNaN(pr) ? pr : 0) + (!isNaN(paid) ? paid : 0)
  ).toFixed(2);

  let maxDosDate = addDays((appointmentDate ? new Date(appointmentDate) : new Date()), 30);
  let minDosDate = addDays((appointmentDate ? new Date(appointmentDate) : new Date()), -30);

  return <div
    className={cx(classes.activityContainer, {
      [classes.activityContainerWithError]: backendErrors[cpt.id],
    })}
  >
    <div key={cpt.id} className={classes.rowContainer}
         style={{
           maxWidth: navWidth,
           width: navWidth,
         }}>
      <div className={classes.tableItem} style={{ minWidth: 140 }}>
        <Select
          required={true}
          disabled={disabled}
          dataKey='code'
          filterValue={cpt.code}
          columnData={{
            isFilterable: true,
            options: cptCodes,
          }}
          onChange={(dataKey, value) => updateCPTCode(cpt.id, dataKey, value)}
          label={`${t('forms:cptCode')}`}
          labelWidth={78}
          error={
            (backendErrors[cpt.id] &&
              backendErrors[cpt.id].cptNumber) ||
            (backendErrors[index] && backendErrors[index].code)
          }
          className={cx(classes.margin0, classes.fullWidth)}
        />
      </div>
      <div
        className={classes.tableWideItem}
        style={{ minWidth: 260 }}
      >
        <DataInput
          required={true}
          disabled={disabled}
          name='dos'
          customValue={cpt.dos}
          backendErrors={{
            'dos': (backendErrors[cpt.id] &&
              backendErrors[cpt.id].dos) ||
              (backendErrors[index] && backendErrors[index].dos),
          }}
          updateValue={(dataKey, value) =>
            updateClaimCode(cpt.id, dataKey, value)
          }
          maxDate={maxDosDate}
          minDate={minDosDate}
          datepickerWrapperClassName={
            classes.datepickerWrapperClassName
          }
        />
      </div>
      <div className={classes.tableItem} style={{ minWidth: 140 }}>
        <Select
          dataKey='modifier'
          disabled={disabled}
          required={false}
          filterValue={cpt.modifier}
          columnData={{
            isFilterable: true,
            options: modifiers,
          }}
          onChange={(dataKey, value) =>
            updateClaimCode(cpt.id, dataKey, value)
          }
          label={`${t('forms:modifier')}`}
          labelWidth={78}
          error={
            (backendErrors[cpt.id] &&
              backendErrors[cpt.id].modifier) ||
            (backendErrors[index] && backendErrors[index].modifier)
          }
          className={cx(classes.margin0, classes.fullWidth)}
        />
      </div>
      <div className={classes.tableItem} style={{ minWidth: 230 }}>
        <ICDSelector
          cpt={cpt}
          disabled={disabled}
          backendErrors={(backendErrors[cpt.id] &&
            backendErrors[cpt.id].icd) ||
          (backendErrors[index] && backendErrors[index].icd)}
          updateClaimCPTInfo={updateClaimCPTInfo}
        />
      </div>
      <div className={classes.tableItem} style={{ minWidth: 120 }}>
        <CurrencyTextFilter
          required={true}
          disabled={true}
          type='text'
          name='allowed'
          label={`${t('forms:allowed')}`}
          error={
            (backendErrors[cpt.id] &&
              backendErrors[cpt.id].allowed) ||
            (backendErrors[index] && backendErrors[index].allowed)
          }
          value={allowed}
          className={classes.tableInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position='end'>
                $&emsp;
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.tableItem} style={{ minWidth: 120 }}>
        <CurrencyTextFilter
          disabled={disabled}
          type='text'
          label={`${t('forms:paid')}`}
          name='paid'
          error={
            (backendErrors[cpt.id] && backendErrors[cpt.id].paid) ||
            (backendErrors[index] && backendErrors[index].paid)
          }
          value={cpt.paid}
          onChange={(value) =>
            roundClaimCPTInfoValue(cpt.id, 'paid', value)
          }
          className={classes.tableInput}
        />
      </div>
      <div className={classes.tableItem} style={{ minWidth: 120 }}>
        <CurrencyTextFilter
          disabled={disabled}
          type='text'
          name='pr'
          label={`${t('forms:pr')}`}
          error={
            (backendErrors[cpt.id] && backendErrors[cpt.id].pr) ||
            (backendErrors[index] && backendErrors[index].pr)
          }
          value={cpt.pr}
          onChange={(value) =>
            roundClaimCPTInfoValue(cpt.id, 'pr', value)
          }
          className={classes.tableInput}
        />
      </div>
      <div
        className={classes.tableItem}
        style={{ display: 'flex', wrap: 'nowrap', minWidth: 140 }}
      >
        <div style={{ width: '100%', flex: 1 }}>
          <Select
            disabled={disabled}
            required={cpt.pr > 0}
            dataKey='prType'
            filterValue={cpt.prType}
            columnData={{
              isFilterable: true,
              options: PR_TYPE_CLAIMS_SELECTOR_OPTIONS,
            }}
            onChange={(dataKey, value) =>
              updateClaimCode(cpt.id, dataKey, value)
            }
            label={`${t('forms:prType')}`}
            labelWidth={78}
            error={
              (backendErrors[cpt.id] && backendErrors[cpt.id].prType) ||
              (backendErrors[index] && backendErrors[index].prType)
            }
            className={cx(classes.margin0, classes.fullWidth)}
          />
        </div>
      </div>
      <div
        className={classes.tableItem}
        style={{ display: 'flex', wrap: 'nowrap', minWidth: 160 }}
      >
        <div style={{ width: '100%', flex: 1 }}>
          <Select
            disabled={disabled}
            dataKey='cptStatus'
            filterValue={cpt.cptStatus}
            columnData={{
              isFilterable: true,
              options: CPT_STATUS_SELECTOR_OPTIONS,
            }}
            onChange={(dataKey, value) =>
              updateClaimCode(cpt.id, dataKey, value)
            }
            label={`${t('forms:cptStatus')}`}
            labelWidth={78}
            disabledItems={
              paid > 0
                ? CPT_STATUS_SELECTOR_OPTIONS.filter(item => item.value !== 'paid').map(item => item.value)
                : paid === 0 && pr > 0
                ? CPT_STATUS_SELECTOR_OPTIONS.filter(item => item.value !== 'patientResponsibility').map(item => item.value)
                : []
            }
            error={
              (backendErrors[cpt.id] && backendErrors[cpt.id].cptStatus) ||
              (backendErrors[index] && backendErrors[index].cptStatus)
            }
            className={cx(classes.margin0, classes.fullWidth)}
          />
        </div>
      </div>
      <div className={classes.tableItem} style={{ minWidth: 40 }}>
        <IconButton
          disabled={disabled}
          aria-label='delete'
          className={cx(classes.deleteButton)}
          size='small'
          onClick={() => onDismiss(cpt.id)}
        >
          <RemoveIcon fontSize='small' />
        </IconButton>
      </div>
    </div>
    <HelperText className={cx(classes.activitiesError)}>
      {typeof backendErrors[cpt.id] === 'string' &&
      backendErrors[cpt.id]}
    </HelperText>
  </div>;
};

export default CptInfoRow;


const CurrencyTextFilter = ({ value, onChange, label, disabled, error,required}) => {
  const [valueAsString, setValueAsString] = useState(value);

  useEffect(() => {
    if (value === '') {
      setValueAsString('')
    } else if (valueAsString === '' && value !== null) {
      setValueAsString(value);
    }

    if (valueAsString !== value) {
      setValueAsString(value.toString());
    }
  }, [value]);

  const handleInputChange = (e) => {
    if (onChange) {
      const value = e.target.value.replace('$ ', '').replace(',', '');
      onChange(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleInputChange(e);
    }
  };

  return <CurrencyFormat
    prefix={'$ '}
    value={valueAsString}
    customInput={(pr) => <TextField
      size={'small'}
      variant={'outlined'}
      label={label}
      disabled={disabled}
      required={required}
      error={error}
      helperText={error}
      {...pr}
    />}
    allowNegative={false}
    fixedDecimalScale={true}
    onKeyDown={handleKeyDown}
    decimalScale={2}
    onBlur={handleInputChange}
    thousandSeparator={','}
  />;
};

CurrencyTextFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

CurrencyTextFilter.defaultProps = {
  value: '',
  onChange: undefined,
};


