import { createSlice } from '@reduxjs/toolkit';
import { getLocalizedAddress } from '../../../../helpers/localize';
import { REQUEST_STATUS } from '../../../constants';
import { fetchAccounts } from './api';

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    accounts: null,
    totalCount: [],
    status: REQUEST_STATUS.idle,
    error: null,
  },
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAccounts.pending, (state) => {
        state.status = REQUEST_STATUS.loading;
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.status = REQUEST_STATUS.succeeded;
          state.accounts =
            action.payload.data.data.items.map((account) => {
              return { ...account,
              customIdentifier: account.customIdentifier
                ? account.customIdentifier
                : 'N/A',
              address: getLocalizedAddress(account.address),
              moreTitle: account.businessName,
            }});
        } else {
          state.status = REQUEST_STATUS.failed;
          state.error = action?.payload?.error?.message;
        }
      });
  },
});

export const {
  setAccounts,
} = accountsSlice.actions;

export default accountsSlice.reducer;
