import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from '../../../Shared/Modal';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    maxWidth: 1000,
    padding: '0 40px 40px 40px',
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },
  modalSecondTitle: {
    paddingBottom: theme.spacing(2),
    padding: theme.spacing(0, 16),
    [theme.breakpoints.down(1000)]: {
      padding: theme.spacing(0),
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 600,
  },
  blueText: {
    color: '#172b4d',
    fontSize: '0.875rem',
    lineHeight: 1.43,
    marginTop: 20,
  },
  blackText: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    marginTop: 20,
  },
  text: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    marginTop: 20,
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
  },
  listItem: {
    margin: 5,
  },
  list: {
    margin: 0,
  },
  decimalList: {
    listStyleType: 'decimal',
  },
}));

const AsthmaControlAssessmentReport = ({ showHelpPopup, setShowHelpPopup }) => {
  const classes = useStyles();
  const { t } = useTranslation(['asthmaControlAssessmentReport']);

  return (
    <Modal
      open={showHelpPopup}
      handleClose={() => {
        setShowHelpPopup(false);
      }}
      classes={{ paper: classes.modal, container: classes.modalContainer }}
      title={t('asthmaControlAssessmentReport:title')}
      secondTitleStyle={classes.modalSecondTitle}
    >
      <div className={cx(classes.blackText, classes.bold)}>
        {t('asthmaControlAssessmentReport:firstPart')}
      </div>
      <div className={cx(classes.blueText, classes.bold)}>
        {t('asthmaControlAssessmentReport:asthmaControlTestTitle')}
      </div>
      <div>{t('asthmaControlAssessmentReport:asthmaControlTestText')}</div>
      <div className={cx(classes.text)}>
        {t('asthmaControlAssessmentReport:asthmaControlTestText2')}
      </div>
      <div className={cx(classes.text, classes.bold)}>
        {t('asthmaControlAssessmentReport:referencesTitle')}
      </div>
      <ul className={cx(classes.decimalList, classes.list)}>
        <li>{t('asthmaControlAssessmentReport:referencesFirst')}</li>
        <li>{t('asthmaControlAssessmentReport:referencesSecond')}</li>
      </ul>
    </Modal>
  );
};

AsthmaControlAssessmentReport.propTypes = {
  showHelpPopup: PropTypes.bool.isRequired,
  setShowHelpPopup: PropTypes.func.isRequired,
};

export default AsthmaControlAssessmentReport;
