import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import cx from 'classnames';
import RemoveIcon from '@material-ui/icons/Close';
import { getAllIndexes } from '../../../../../../../helpers';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  statesSelect: {
    marginTop: theme.spacing(1),
  },
  services: {
    transition: 'opacity 0.6s linear',
    padding: '12px 8px',
    opacity: 1,
    borderRadius: 10,
    height: '100%',
    width: '100%',
  },
  activitiesError: {
    position: 'absolute',
    color: theme.palette.error.main,
    bottom: 0,
    marginLeft: 15,
  },
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },
  icon: {
    color: alpha(theme.colors.blue3, 0.25),
    height: 16,
  },
  lightColorBorder: {
    borderColor: alpha('#818FA0', 0.25),
    transition: 'borderColor 0.5s ease-in',

    '&:hover': {
      borderColor: alpha(theme.colors.development, .5),
      transition: 'borderColor 0.5s ease-in',
    },
  },
}));

const CptEndCheckbox = ({
                          form,
                          backendErrors,
                          cpts,
                          setCpts,
                          setErrors,
                          validateField,
                          cellProps,
                        }) => {
  const classes = useStyles();


  const _updateErrors = async (index) => {
    const serviceErrors = backendErrors.ispConfiguration
      && backendErrors.ispConfiguration.services;

    const newErrors = {
      ...backendErrors,
      ispConfiguration: {
        ...backendErrors.ispConfiguration,
        services: [
          ...(serviceErrors ? serviceErrors.slice(0, index) : []),
          ...(serviceErrors ? serviceErrors.slice(index + 1) : []),
        ],
      },
    };

    await setErrors(newErrors);
  };

  const _updateDuplicatedErrors = async (newCpt, prewValue) => {
    const indexesOfSameCptCode = getAllIndexes(newCpt.map(i => i.cptCode), prewValue);
    if (indexesOfSameCptCode.length === 1) {
      await validateField(`ispConfiguration.services[${indexesOfSameCptCode[0]}].cptCode`);
    }
  };

  const _updateCpts = (index) => {
    setCpts((cpts.length > 1 ? [
      ...cpts.slice(0, index),
      ...cpts.slice(index + 1),
    ] : []).map((item, i) => {
      return {
        ...item,
        index: i,
      };
    }));
  };

  const onDismiss = async (index, form) => {
    if (form.values.ispConfiguration) {
      const services = form.values.ispConfiguration.services;
      const prewValue = services[index].cptCode;
      const currentErrorObj = backendErrors?.ispConfiguration?.services[index]?.cptCode;
      const hasDuplicatesError = currentErrorObj === 'CPT Code should be unique';
      const newCpt = form.values.ispConfiguration && services ? [
        ...services.slice(0, index),
        ...services.slice(index + 1),
      ] : [];

      await form.setFieldValue('ispConfiguration.services', newCpt);
      _updateCpts(index);

      await _updateErrors(index);
      if (hasDuplicatesError) {
        await _updateDuplicatedErrors(newCpt, prewValue);
      }
    }
  };

  return <div
    style={{
      width: '100%',
      paddingTop: 23,
    }}
  >
    <div className={classes.tableItem} style={{ minWidth: 40, maxWidth: 40 }}>
      <IconButton
        aria-label='delete'
        className={cx(classes.deleteButton)}
        size='small'
        onClick={() => onDismiss(cellProps.rowData.index, form)}
      >
        <RemoveIcon fontSize='small' />
      </IconButton>
    </div>
  </div>;
};

export default CptEndCheckbox;
