import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ActivePatientContext = createContext({});

function ActivePatientProvider({ children }) {
  const [patientUuid, setPatientUuid] = useState();
  const [patientInfo, setPatientInfo] = useState();

  const value = {
    patientUuid,
    patientInfo,
    setPatientUuid,
    setPatientInfo,
  };

  return (
    <ActivePatientContext.Provider value={value}>
      {children}
    </ActivePatientContext.Provider>
  );
}

ActivePatientProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ActivePatientProvider;
