import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
window.process = { cwd: () => '' };

const styles = (theme) => ({
  root: {
    width: '100%',
    fontWeight: 1.5,
  },
  listItem: {
    marginBottom: theme.spacing(0.5),
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
    margin: 0,
  },
  typography: {
    fontSize: '0.875rem',
    lineHeight: 1.4,
    whiteSpace: 'break-spaces',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    '& span': {
      marginBottom: theme.spacing(0.4),
      marginTop: theme.spacing(0.4),
      '& br': {
        content: '"A" !important',
        display: 'block !important',
        marginBottom: '7px !important',
      },
    },
  },
  tableContainer: {
    boxShadow: 'none',
    border: 'none',
    borderRadius: 10,
    overflow: 'hidden',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
  },
  tableBody: {
    fontSize: '0.875rem',
  },
  tableSell: {
    '&:last-child': {
      paddingRight: 0,
      paddingLeft: 0,
    },
    paddingRight: theme.spacing(2),
    border: 'none',
    padding: 0,
    marginBottom: theme.spacing(0.5),
  },
  tableRow: {
    verticalAlign: 'top',
  },
  tableHead: {
    display: 'none',
    border: 'none',
  },
  linkBiding: {
    position: 'absolute',
    top: -120,
  },
  error: {
    color: '#DC125B',
  },
  success: {
    color: '#41C377',
  },
  warning: {
    color: '#EA7502',
  },
  info: {
    color: '#8E8E8E',
  },
  blockItalic: {
    fontStyle: 'normal',
  },
});

const clearColorMarker = (content) =>
  content
    .replace('!{success}', '')
    .replace('!{warning}', '')
    .replace('!{error}', '')
    .replace('!{info}', '')
    .replace('{success}', '')
    .replace('{warning}', '')
    .replace('{error}', '')
    .replace('{info}', '');

const MarkdownText = withStyles(styles)(({ classes, ...props }) => {
  let content = props.children;
  const isTextSuccess = content.includes('{success}');
  const isTextWarning = content.includes('{warning}');
  const isTextError = content.includes('{error}');
  const isTextInfo = content.includes('{info}');

  // Use the italics marker to separate colored text
  const shouldRemoveItalic = !content.startsWith('!{');

  if (isTextSuccess || isTextWarning || isTextError || isTextInfo) {
    content = clearColorMarker(content);
  }

  return (
    <span
      className={cx(classes.typography, {
        [classes.success]: isTextSuccess,
        [classes.warning]: isTextWarning,
        [classes.error]: isTextError,
        [classes.info]: isTextInfo,
        [classes.blockItalic]: shouldRemoveItalic,
      })}
      dangerouslySetInnerHTML={{
        __html: content.replaceAll(
          `
`,
          `<br />`,
        ),
      }}
    />
  );
});

const MarkdownParagraph = withStyles(styles)(({ classes, ...props }) => {
  let content = [...props.children];

  const currentItem = content[0].props.children;
  let isTextSuccess = false;
  let isTextWarning = false;
  let isTextError = false;
  let isTextInfo = false;

  if (typeof currentItem === 'string') {
    isTextSuccess = currentItem.includes('{success}');
    isTextWarning = currentItem.includes('{warning}');
    isTextError = currentItem.includes('{error}');
    isTextInfo = currentItem.includes('{info}');
  }

  if (isTextSuccess || isTextWarning || isTextError || isTextInfo) {
    const cutTextAlignNote = {
      ...content[0],
      props: {
        ...content[0].props,
        value: clearColorMarker(currentItem),
        children: clearColorMarker(currentItem),
        node: {
          ...content[0].props.node,
          value: clearColorMarker(currentItem),
        },
      },
    };
    content.splice(0, 1);
    content = [cutTextAlignNote, ...content];
  }

  return (
    <Typography
      className={cx(classes.typography, {
        [classes.success]: isTextSuccess,
        [classes.warning]: isTextWarning,
        [classes.error]: isTextError,
        [classes.info]: isTextInfo,
      })}
    >
      {content}
    </Typography>
  );
});

const MarkdownList = withStyles(styles)(({ classes, ...props }) => {
  return <ul className={classes.list}>{props.children}</ul>;
});

const MarkdownListItem = withStyles(styles)(({ classes, ...props }) => {
  return (
    <li className={classes.listItem}>
      <Typography component="span" className={classes.listItemText}>
        {props.children}
      </Typography>
    </li>
  );
});

const MarkdownTable = withStyles(styles)(({ classes, ...props }) => {
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table size="small" aria-label="a dense table" className={classes.table}>
        {props.children}
      </Table>
    </TableContainer>
  );
});

const MarkdownTableCell = withStyles(styles)(({ classes, ...props }) => {
  return (
    <TableCell className={classes.tableSell}>
      <Typography>{props.children}</Typography>
    </TableCell>
  );
});

const MarkdownTableRow = withStyles(styles)(({ classes, ...props }) => {
  return <TableRow className={classes.tableRow}>{props.children}</TableRow>;
});

const MarkdownTableBody = withStyles(styles)(({ classes, ...props }) => {
  return <TableBody className={classes.tableBody}>{props.children}</TableBody>;
});

const MarkdownTableHead = withStyles(styles)(({ classes, ...props }) => {
  return <TableHead className={classes.tableHead}>{props.children}</TableHead>;
});

export const renderers = {
  text: MarkdownText,
  paragraph: MarkdownParagraph,
  list: MarkdownList,
  listItem: MarkdownListItem,
  table: MarkdownTable,
  tableHead: MarkdownTableHead,
  tableBody: MarkdownTableBody,
  tableRow: MarkdownTableRow,
  tableCell: MarkdownTableCell,
};

const NewMarkdownParser = withStyles(styles)(
  ({ classes, className, content, ...rest }) => {
    return (
      <ReactMarkdown
        className={cx(classes.root, className)}
        plugins={[[remarkGfm]]}
        escapeHtml={false}
        renderers={renderers}
        {...rest}
      >
        {content}
      </ReactMarkdown>
    );
  },
);

NewMarkdownParser.propTypes = {
  className: PropTypes.string,
};

export default NewMarkdownParser;
