import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';
import cx from 'classnames';
import LoadingWrapper from 'components/Shared/LoadingWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    height: '100%',
    transition: 'all .6s',
    fontSize: 14,
  },
  textTransform: {
    textTransform: 'uppercase',
    letterSpacing: 2,
    fontSize: 13,
    fontWeight: 'bold',
  },
  isSuccess: {
    backgroundColor: `${theme.colors.green} !important`,
    transition: 'all .6s',
  },
  isFailed: {
    backgroundColor: `${theme.palette.error.main} !important`,
    transition: 'all .6s',
  },
}));

const RoundedButton = ({
                         children,
                         className,
                         isLoading,
                         isFailed,
                         isSuccess,
                         disabled,
                         size,
                         loaderColor,
                         LoadingWrapperProps = {},
                         whiteIconStatusColor,
                         disableTextUppercase = false,
                         ...rest
                       }) => {
  const classes = useStyles();
  const { LoaderProps, ...restLoadingWrapperProps } = LoadingWrapperProps;

  return (
    <Button
      color='secondary'
      className={cx(classes.root, className, {
        [classes.isSuccess]: isSuccess,
        [classes.isFailed]: isFailed,
        [classes.textTransform]: !disableTextUppercase,
      })}
      size={size}
      disabled={disabled || isSuccess || isLoading || isFailed}
      {...rest}
    >
      <LoadingWrapper
        size={size}
        loaderColor={loaderColor}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isFailed={isFailed}
        whiteIconStatusColor={whiteIconStatusColor}
        LoaderProps={{
          ...LoaderProps,
        }}
        {...restLoadingWrapperProps}
      >
        {children}
      </LoadingWrapper>
    </Button>
  );
};

RoundedButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  isFailed: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  loaderColor: PropTypes.string,
  LoadingWrapperProps: PropTypes.shape({
    className: PropTypes.string,
    LoaderProps: PropTypes.object,
  }),
};

RoundedButton.defaultProps = {
  disabled: false,
  isFailed: false,
  isSuccess: false,
  isLoading: false,
  className: undefined,
  size: 'medium',
  loaderColor: 'white',
  LoadingWrapperProps: undefined,
};

export default RoundedButton;
