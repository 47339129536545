import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useMutation, useQuery } from 'react-fetching-library';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import showServerError from 'helpers/showError';
import { routes } from 'config/routes';
import OrderForm from 'components/Orders/OrderForm';
import { AppRouteContext } from 'contexts';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import CreateOrderDialog from 'components/Dialogs/CreateOrderDialog';
import BackButton from 'components/Shared/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import EmptyPageLoader from '../Shared/EmptyPageLoader';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';

const OrderCreate = () => {
  const { accountUuid, patientUuid, reorderUuid = 'newOrder' } = useParams();
  const [initialValues, setInitialValues] = useState();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const [backendErrors, setBackendErrors] = useState({});
  const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const { setAppRoute } = useContext(AppRouteContext);
  const { user } = useSelector((state) => state.user);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const { payload: metadata, error: metadataError } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'GET',
        endpoint: `/accounts/${accountUuid}/patients/${patientUuid}/create-order-metadata`,
      },
    ],
  );

  const { query, loading: loadingInitialData } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/orders/${reorderUuid}`,
    },
    false,
  ]);

  const orderCreateAction = (values) => ({
    method: 'PUT',
    endpoint: `/accounts/${accountUuid}/patients/${patientUuid}/orders`,
    body: values,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [
    orderCreateAction,
  ]);

  // set selected items when all items have been loaded
  useEffect(() => {
    if (reorderUuid !== 'newOrder') {
      query().then(({ error, payload }) => {
        if (!error) {
          const newInitData = {
            ...payload,
            status: 'pending',
          };

          if (payload.category === 'slit') {
            newInitData.slitMaintenance = true;
            newInitData.slitMaintenanceQuantity = 3;
          }

          if (payload.category === 'scit') {
            newInitData.scitType = 'twelveDosePerUnitSCITMaintenance';
          }

          setInitialValues(newInitData);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (metadata && (reorderUuid === 'newOrder')) {
      const { providers, addresses } = metadata;
      setInitialValues({
        providerUserUUID: providers.length ? providers[0].uuid : '',
        category: '',
        scitType: '',
        scitAllergens: [],
        slitLowDose: false,
        slitLowDoseQuantity: 0,
        slitMaintenance: false,
        slitMaintenanceQuantity: 0,
        address: addresses.length ? addresses[0] : {},
        comments: '',
      });
    }
  }, [metadata]);

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const createOrder = async (values, { setSubmitting, setFieldValue }) => {
    handleCloseConfirmDialog();
    setBackendErrors({});
    const {
      slitLowDose,
      slitLowDoseQuantity,
      slitMaintenance,
      slitMaintenanceQuantity,
    } = values;

    const formattedValues = {
      ...values,
      slitLowDoseQuantity:
        slitLowDose && slitLowDoseQuantity ? slitLowDoseQuantity : 0,
      slitMaintenanceQuantity:
        slitMaintenance && slitMaintenanceQuantity
          ? slitMaintenanceQuantity
          : 0,
    };
    delete formattedValues.slitLowDose; // internal usage
    delete formattedValues.slitMaintenance; // internal usage

    const { payload, error, status } = await mutate(formattedValues);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      if (!slitLowDose) {
        setFieldValue('slitLowDoseQuantity', 0);
      }
      if (!slitMaintenance) {
        setFieldValue('slitMaintenanceQuantity', 0);
      }

      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      setSubmitting(false);
      switch (status) {
        case 422: {
          if (payload.validationErrors.status === "The specified condition was not met for 'status'.") {
            showServerError(dispatch, "The specified condition was not met for 'status'.");
            break;
          }

          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:orderCreated', {
            name: metadata.patientInfo.name,
          }),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      const {
        path,
        additional: { title },
      } = routes.ordersList;
      setAppRoute(
        {
          path,
          title,
          clearBreadcrumbs: true,
        },
        { addDelay: true },
      );
    }
  };

  if (!metadata || metadataError || loadingInitialData || !initialValues) {
    return <EmptyPageLoader />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={createOrder}
    >
      {({ isValid, handleSubmit, dirty, isSubmitting, values }) => (
        <ContentWrapper
          titleText={t('titles:createOrder')}
          actions={
            <>
              <BackButton />
              <RoundedButton
                variant="contained"
                color="primary"
                size="small"
                disabled={!isValid || isSubmitting || (reorderUuid === 'newOrder' && !dirty)}
                onClick={handleOpenConfirmDialog}
                isLoading={loading}
                isSuccess={isSuccess}
                isFailed={isFailed}
              >
                {t('btn:save')}
              </RoundedButton>
            </>
          }
        >
          <OrderForm
            values={values}
            backendErrors={backendErrors}
            metadata={metadata}
          />

          <CreateOrderDialog
            open={isOpenConfirmDialog}
            onClose={handleCloseConfirmDialog}
            onSubmit={handleSubmit}
          />
        </ContentWrapper>
      )}
    </Formik>
  );
};

export default OrderCreate;
