import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import TimePicker from './TimePicker';
import DatePicker from './DatePicker';
import ProvidersSection from './ProvidersSection';

const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  appointmentContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    marginBottom: 20,
  },
  gap: {
    marginBottom: 10,
  },
  hideDataPicker: {
    maxHeight: 0,
    transition: 'max-height 0.3s ease-out, opacity 0.3s ease-out',
    opacity: 0,
    minWidth: 450,
    marginTop: 16,
  },
  showDataPicker: {
    maxHeight: 500,
    opacity: 1,
    transition: 'max-height 0.5s ease-in, opacity 0.5s ease-in',
  },
  secondaryTitle: {
    fontSize: '1.2rem',
    color: '#00A8FF',
  },
}));

const SendingOptions = ({ backendErrors, setBackendErrors, initialValues, patientUUID, accountUuid }) => {
  const classes = useStyles();
  const { en: { forms } } = useSelector((state) => state.i18n);

  const sendingOptions = {
    sendNow: forms.sendNow,
    scheduleForLater: forms.scheduleForLater,
  };

  return (
    <>
      <div className={classes.appointmentContainer}>
        <Grid item>
          <Typography className={classes.secondaryTitle}>
            {forms.appointmentTimestamp}
          </Typography>
        </Grid>
        <Grid
          container
          direction='row'
          className={cx(classes.hideDataPicker, {
            [classes.showDataPicker]: true,
          })}
        >
          <DatePicker
            name={'appointmentDate'}
            backendErrors={backendErrors}
            setBackendErrors={setBackendErrors}
          />
          <TimePicker
            name={'appointmentTime'}
            backendErrors={backendErrors}
            setBackendErrors={setBackendErrors}
          />
        </Grid>
        <FormHelperText error className={classes.formError}>
          {backendErrors && backendErrors.sendingDataError}
        </FormHelperText>
      </div>
      <ProvidersSection patientUUID={patientUUID} accountUuid={accountUuid} />
      <Field type='text' name='sendingOption'>
        {({ field }) => {
          return (
            <FormControl required className={classes.center}>
              <Grid item>
                <Typography className={classes.secondaryTitle}>
                  {forms.scheduleDeliverData}
                </Typography>
              </Grid>
              <RadioGroup
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                className={cx(classes.center)}
                color='primary'
              >
                <FormControlLabel
                  color='primary'
                  value='sendNow'
                  control={<Radio />}
                  label={sendingOptions.sendNow}
                />
                <FormControlLabel
                  color='primary'
                  value='scheduleForLater'
                  control={<Radio />}
                  label={sendingOptions.scheduleForLater}
                  className={cx(classes.gap)}
                />
                <Grid
                  container
                  direction='row'
                  className={cx(classes.hideDataPicker, {
                    [classes.showDataPicker]: field.value === 'scheduleForLater',
                  })}
                >
                  <DatePicker
                    name={'scheduledDate'}
                    required
                    backendErrors={backendErrors}
                    setBackendErrors={setBackendErrors}
                  />
                  <TimePicker
                    name={'scheduleDeliverTime'}
                    initValue={initialValues.scheduleDeliverTime}
                    required
                    backendErrors={backendErrors}
                    setBackendErrors={setBackendErrors}
                  />
                </Grid>
              </RadioGroup>
            </FormControl>
          );
        }}
      </Field>
    </>
  );
};

SendingOptions.propTypes = {
  sendingDataError: PropTypes.string,
  initialValues: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    scheduleDeliverTime: PropTypes.string,
  }).isRequired,
};

SendingOptions.defaultProps = {
  sendingDataError: null,
};

export default SendingOptions;
