/* eslint-disable */
import React from 'react';

const ReportsLogo = ({ fill, className }) => (
  <svg className={className} style={{ width: 20, height: 15 }} viewBox="0 0 20 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>Reports@3x</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard" transform="translate(-35.000000, -422.000000)" fill={fill || "#FFFFFF"} fillRule="nonzero">
        <g id="Side" transform="translate(18.000000, 30.000000)">
          <g id="Tabs" transform="translate(0.000000, 83.000000)">
            <path d='M32.2941176,322.846154 L32.2941176,315.346154 C32.2941176,315.027528 32.5574797,314.769231 32.8823529,314.769231 L35.2352941,314.769231 C35.5601674,314.769231 35.8235294,315.027528 35.8235294,315.346154 L35.8235294,322.846154 L36.4117647,322.846154 C36.7366379,322.846154 37,323.104451 37,323.423077 C37,323.741703 36.7366379,324 36.4117647,324 L17.5882353,324 C17.2633621,324 17,323.741703 17,323.423077 C17,323.104451 17.2633621,322.846154 17.5882353,322.846154 L18.1764706,322.846154 L18.1764706,314.192308 C18.1764706,313.873682 18.4398326,313.615385 18.7647059,313.615385 L21.1176471,313.615385 C21.4425203,313.615385 21.7058824,313.873682 21.7058824,314.192308 L21.7058824,322.846154 L22.8823529,322.846154 L22.8823529,309.576923 C22.8823529,309.258297 23.145715,309 23.4705882,309 L25.8235294,309 C26.1484026,309 26.4117647,309.258297 26.4117647,309.576923 L26.4117647,322.846154 L27.5882353,322.846154 L27.5882353,312.461538 C27.5882353,312.142913 27.8515974,311.884615 28.1764706,311.884615 L30.5294118,311.884615 C30.854285,311.884615 31.1176471,312.142913 31.1176471,312.461538 L31.1176471,322.846154 L32.2941176,322.846154 Z' id='Reports'/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ReportsLogo;
