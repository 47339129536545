import React from 'react';
import PropTypes from 'prop-types';
import AllergyBaselineAssessmentReport from './AllergyBaselineAssessmentReport';
import PediatricAllergyBaselineAssessmentReport from './PediatricAllergyBaselineAssessmentReport';
import SNOT22Report from './SNOT22Report';
import AsthmaControlAssessmentReport from './AsthmaControlAssessmentReport';
import QScaleReport from './QScaleReport';

const HelpModals = ({ isLoading, localizedTitle, showHelpPopup, setShowHelpPopup }) => {
  return <>
    {
      !isLoading && localizedTitle === 'Allergy Baseline Assessment' &&
      <AllergyBaselineAssessmentReport
        showHelpPopup={showHelpPopup}
        setShowHelpPopup={setShowHelpPopup}
      />
    }
    {
      !isLoading && localizedTitle === 'Pediatric Allergy Baseline Assessment' &&
      <PediatricAllergyBaselineAssessmentReport
        showHelpPopup={showHelpPopup}
        setShowHelpPopup={setShowHelpPopup}
      />
    }
    {
      !isLoading && localizedTitle === 'SNOT-22 Assessment' &&
      <SNOT22Report
        showHelpPopup={showHelpPopup}
        setShowHelpPopup={setShowHelpPopup}
      />
    }
    {
      !isLoading && localizedTitle === 'Asthma Control Assessment' &&
      <AsthmaControlAssessmentReport
        showHelpPopup={showHelpPopup}
        setShowHelpPopup={setShowHelpPopup}
      />
    }
    {
      !isLoading && localizedTitle === 'Q-Scale' &&
      <QScaleReport
        showHelpPopup={showHelpPopup}
        setShowHelpPopup={setShowHelpPopup}
      />
    }
  </>;
};

HelpModals.propTypes = {
  showHelpPopup: PropTypes.bool.isRequired,
  setShowHelpPopup: PropTypes.func.isRequired,
};

export default HelpModals;
