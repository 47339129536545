import React from 'react';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import useStatusChange from 'hooks/useStatusChange';
import { MAX_ORDERS } from 'components/Orders/constants';
import { useSelector } from 'react-redux';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';

const OrdersConfirmationDialog = ({
                                    isOpenStatusDialog,
                                    setOpenStatusDialog,
                                    statusChanging,
                                    setStatusChanging,
                                    reloadData,
                                    items,
                                  }) => {
  const { en: { dialogs: tDialogs } } = useSelector((state) => state.i18n);

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
    setStatusChanging({});
  };

  const handleChangeCurrentStatus = () => {
    const { orderUuid, accountUuid } = statusChanging;
    setStatusChanging({
      ...statusChanging,
      preparedList: [{ accountUuid, target: [orderUuid] }],
      pressedSelectedButton: false,
    });
  };

  const handleChangeSelectedStatus = () => {
    const { selected } = statusChanging;

    // transform all the selected items to { [accountUuid]: [orderUuid, ...] }
    const selectedItems = {};
    selected.forEach((orderUuid) => {
      const { uuid, accountUUID } = items.find(
        (item) => item.uuid === orderUuid,
      );
      if (selectedItems[accountUUID]) {
        selectedItems[accountUUID].push(uuid);
      } else {
        selectedItems[accountUUID] = [uuid];
      }
    });

    // split by MAX param => [{ accountUuid, orders: [orderUuid, ...] }]
    const preparedList = [];
    Object.entries(selectedItems).forEach(([key, value]) => {
      if (value.length < MAX_ORDERS) {
        preparedList.push({ accountUuid: key, target: value });
      } else {
        const iterates = Math.floor(value.length / MAX_ORDERS);
        const rest = value.length % MAX_ORDERS;
        for (let i = 0; i < iterates; i += 1) {
          preparedList.push({
            accountUuid: key,
            target: value.slice(MAX_ORDERS * i, MAX_ORDERS * (i + 1)),
          });
        }
        if (rest) {
          preparedList.push({
            accountUuid: key,
            target: value.slice(value.length - rest),
          });
        }
      }
    });
    setStatusChanging({
      ...statusChanging,
      preparedList,
      pressedSelectedButton: true,
    });
  };

  const handleStatusSuccess = () => {
    reloadData();
    handleCloseStatusDialog();
  };

  const { isLoading: isStatusLoading } = useStatusChange({
    name: 'order',
    statusChanging,
    onSuccess: handleStatusSuccess,
    onError: handleCloseStatusDialog,
  });

  return <ConfirmDialog
    open={isOpenStatusDialog}
    onClose={handleCloseStatusDialog}
    title={tDialogs.confirmStatusChange}
    description={getTransitionWithVariable(tDialogs.statusDescription, {
      status: statusChanging.statusItem
        ? statusChanging.statusItem.label
        : '',
    })}
    SelectedBtnProps={{
      onClick: handleChangeSelectedStatus,
      isLoading: statusChanging.pressedSelectedButton && isStatusLoading,
      disabled: isStatusLoading,
    }}
    CurrentBtnProps={{
      onClick: handleChangeCurrentStatus,
      isLoading: !statusChanging.pressedSelectedButton && isStatusLoading,
      disabled: isStatusLoading,
    }}
  />;
};

export default OrdersConfirmationDialog;
