import React from 'react';
import { withStyles, Radio as MaterialRadio } from '@material-ui/core';

const Radio = withStyles((theme) => ({
  root: {
    color: theme.colors.grey3,
    '&$checked': {
      color: theme.colors.lightBlue,
    },
  },
  checked: {},
}))((props) => <MaterialRadio color="default" {...props} />);

export default Radio;
