import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  makeStyles,
  FormControlLabel,
  Checkbox,
  TextField,
  IconButton,
  FormControl,
} from '@material-ui/core';
import { Field, getIn } from 'formik';
import cx from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import HelperText from 'components/Shared/HelperText';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 52,
    flexDirection: 'row',
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
    width: 150,
  },
  textFieldContainer: {
    position: 'relative',
  },
  textField: {
    maxWidth: 150,
  },
  arrowsContainer: {
    position: 'absolute',
    right: 4,
    bottom: 11,
  },
  btnContainer: {
    height: 16,
  },
  arrowBtn: {
    fontSize: 18,
    padding: 0,
  },
}));

const FormikCheckBoxTextField = ({
  initialValue,
  checkBoxLabel,
  name,
  type,
  checkBoxName,
  className,
  backendError,
  disabled,
  hiddenOnUnchecked,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Field type={type} name={name}>
      {({ field, form }) => {
        const frontendError = getIn(form.errors, field.name);
        const touched = getIn(form.touched, field.name);
        const isChecked = form.values[checkBoxName];
        const error = (touched && !!frontendError) || !!backendError;

        const increaseValue = () => {
          form.setFieldValue(field.name, field.value ? field.value + 1 : 1);
        };
        const decreaseValue = () => {
          form.setFieldValue(field.name, field.value ? field.value - 1 : 0);
        };

        const textField = (
          <div className={classes.textFieldContainer}>
            <TextField
              name={field.name}
              value={field.value}
              type={type}
              error={error}
              className={cx(classes.textField, className)}
              fullWidth
              margin="dense"
              variant="outlined"
              onChange={(e) => {
                const { value } = e.target;
                if (!value || value.match(/^\d+$/)) {
                  field.onChange(e);
                }
              }}
              onBlur={field.onBlur}
              autoComplete="off"
              disabled={!isChecked || disabled}
              onKeyDown={(e) => {
                if (e.keyCode === 38) {
                  increaseValue();
                }
                if (e.keyCode === 40) {
                  decreaseValue();
                }
              }}
              {...field}
              {...rest}
            />
            {type === 'number' && (
              <div className={classes.arrowsContainer}>
                <div className={classes.btnContainer}>
                  <IconButton
                    className={classes.arrowBtn}
                    onClick={increaseValue}
                    disabled={!isChecked || disabled}
                  >
                    <ArrowDropUpIcon fontSize="inherit" />
                  </IconButton>
                </div>
                <div className={classes.btnContainer}>
                  <IconButton
                    className={classes.arrowBtn}
                    onClick={decreaseValue}
                    disabled={!isChecked || disabled}
                  >
                    <ArrowDropDownIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        );

        return (
          <FormControl
            component={Grid}
            container
            alignItems="center"
            className={classes.container}
            error={error}
            disabled={disabled}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  color="primary"
                  onChange={(e, checked) => {
                    form.setFieldValue(checkBoxName, checked);
                  }}
                />
              }
              label={checkBoxLabel}
              className={classes.checkbox}
            />
            {hiddenOnUnchecked ? (
              <>{isChecked && textField}</>
            ) : (
              <>{textField}</>
            )}
            <HelperText>{frontendError || backendError}</HelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

FormikCheckBoxTextField.propTypes = {
  checkBoxLabel: PropTypes.string.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checkBoxName: PropTypes.string.isRequired,
  backendError: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hiddenOnUnchecked: PropTypes.bool,
};

FormikCheckBoxTextField.defaultProps = {
  hiddenOnUnchecked: false,
  backendError: undefined,
  className: undefined,
  disabled: false,
};

export default FormikCheckBoxTextField;
