import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox, fade,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import i18next from 'i18next';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: '100%',

    '& .MuiSelect-icon': {
      top: 'initial',
    },
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
    whiteSpace: 'nowrap',

    '&:focus': {
      backgroundColor: 'transparent !important',
    },
  },
  buttonContainer: {
    cursor: 'default',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 2,
    paddingBottom: 12,

    '&:hover': {
      backgroundColor: 'white',
    },
  },
  childItem: {
    paddingLeft: 46,
    color: theme.colors.grey2,
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  parentItemText: {
    '& span': {
      fontWeight: 500,
    },
  },
  bottomDrawer: {
    borderBottom: `1px solid ${fade('#000000', 0.1)}`,
  },
  childItemText: {
    color: alpha(theme.colors.grey2, .8),
  },
  button: {
    width: '100%',
    marginTop: 6,
  },
  paper: {
    maxHeight: 500,

    '& .MuiMenu-list': {
      paddingTop: 0,
    },
  },
  select: {
    maxHeight: 300,
  },
  searchInput: {
    width: '100%',
  },
  searchContainerMenuItem: {
    padding: 20,
    paddingTop: 12,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white !important',
    zIndex: 2,
  },
}));

let timeoutId = null;

const SelectFilter = ({
                        filterValue,
                        hideMultiple,
                        dataKey,
                        columnData: { isFilterable, options = [], hasSearch },
                        onChange,
                      }) => {
  const classes = useStyles();
  const inputElement = React.useRef();
  const [value, setValue] = React.useState([]);
  const [selectorOptions, setSelectorOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [_searchValue, _setSearchValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const searchInput = React.useRef(null);
  const { t } = useTranslation(['btn']);
  const debounce = (callback, wait) => {
    return async (...args) => {
      await clearTimeout(timeoutId);
      timeoutId = await setTimeout(() => {
        callback(...args);
      }, wait);
    };
  };

  const updateOptions = () => {
    if (hideMultiple) {
      if (filterValue === '') {
        setValue('All');
      } else {
        setValue(filterValue);
      }
      setSelectorOptions([
        { label: i18next.t('tables:all'), value: 'All' },
        ...options,
      ]);
    } else {
      if (filterValue === '') {
        setValue(['']);
      } else {
        setValue(filterValue || []);
      }
      setSelectorOptions([
        { label: i18next.t('tables:all'), value: '' },
        ...options,
      ]);
    }
  };

  useEffect(() => {
    updateOptions();
  }, []);

  useEffect(() => {
    updateOptions();
  }, [options]);

  useEffect(() => {
    if (hideMultiple) {
      if (filterValue === '') {
        setValue('All');
      } else {
        setValue(filterValue);
      }
    } else {
      if (filterValue === '') {
        setValue(['']);
      } else {
        setValue(
          Array.isArray(filterValue)
            ? filterValue
            : (filterValue
            ? filterValue.split(',')
            : ''),
        );
      }
    }
  }, [filterValue]);

  const handleSelectClose = () => {
    if (!hideMultiple) {
      onChange && onChange(dataKey, value);
      handleClose();
    }
  };

  const handleSelectChange = (event, data) => {
    const {
      target: { value: newValue },
    } = event;

    if (event.currentTarget.id === 'search') {
      return;
    }

    if (!hideMultiple) {
      if (newValue.includes('button')) {
        return;
      }

      if (newValue.length === 0 || newValue.filter(filterValue => filterValue !== '').length === options.length) {
        setValue(['']);
        return;
      }

      const isItParentItem = data.props.value.includes('parent_item');

      if (!data.props.value) {
        setValue(selectorOptions.map((i) => i.value));
      }

      if (isItParentItem) {
        const newValue = data.props.value.replace('parent_item', '').split('@@@');
        const isSelected = newValue.every(r => value.includes(r));
        let newValues = [];
        let prevValues = [...value].filter((i) => !!i);

        if (isSelected) {
          newValues = [...prevValues.filter(filterValue => !newValue.includes(filterValue))];
        } else {
          newValues = [...prevValues, ...newValue];
        }

        setValue(newValues.length === 0
          || newValues.length === selectorOptions.filter((i) => !i.child && !!i.value).length
          ? ['']
          : newValues,
        );
      } else {
        let diff;
        if (newValue.length > value.length) {
          diff = newValue.filter((x) => !value.includes(x));
        } else {
          diff = value.filter((x) => !newValue.includes(x));
        }

        if (diff[0] === '') {
          setValue(['']);
        } else {
          setValue(
            typeof newValue === 'string'
              ? newValue.split(',').filter((ele) => {
                return ele !== '';
              })
              : newValue.filter((ele) => {
                return ele !== '';
              }),
          );
        }
      }
    } else {
      onChange(dataKey, newValue === 'All' ? '' : newValue);
      setValue(newValue);
      handleClose();
    }
  };

  const getLabelByKey = (selected) => {
    if (hideMultiple) {
      const option = selectorOptions.find((key) => key.value === selected);
      return option ? option.label : '';
    } else {
      let renderedValue = '';

      selected.forEach((val) => {
        const option = selectorOptions.find((key) => key.value === val);
        const label = option ? option.label : '';
        renderedValue += `${label}, `;
      });

      return renderedValue.slice(0, -2);
    }
  };

  const applyFilter = () => {
    onChange(dataKey, value);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    _setSearchValue('');
    setSearchValue('');
  };

  const handleOpen = async () => {
    setOpen(true);

    if (hasSearch) {
      await new Promise(resolve => {
        setTimeout(() => {
          resolve('');
        }, 400);
      });
      hasSearch && searchInput.current.focus();
    }
  };

  const filteredBySearchOptions = useMemo(
    () => selectorOptions.filter(item => {
      if (hasSearch && searchValue !== '') {
        return item.label.toLowerCase().includes(searchValue.toLowerCase());
      } else {
        return true;
      }
    }),
    [searchValue, selectorOptions, open],
  );

  const updateFilteredOptionsWithDelay = debounce((v) => setSearchValue(v), 1500);

  return (
    <Select
      id={`select-${dataKey}`}
      ref={inputElement}
      value={value}
      open={open}
      onOpen={handleOpen}
      multiple={!hideMultiple}
      onChange={handleSelectChange}
      onClose={handleSelectClose}
      SelectProps={{
        classes: {
          select: classes.select,
          paper: classes.paper,
        },
        MenuProps: {
          getContentAnchorEl: null,
        },
      }}
      MenuProps={{
        classes: { paper: classes.paper },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}

      input={
        <TextField
          id={`select-${dataKey}`}
          select
          value={filterValue}
          classes={{
            select: classes.select,
            root: classes.inputRoot,
            paper: classes.paper,
          }}
          InputProps={{
            classes: {
              input: classes.input,
              select: classes.select,
              paper: classes.paper,
            },
            disableUnderline: true,
            disabled: !isFilterable,
          }}
        >
          {(options || []).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      }
      renderValue={(selected) => getLabelByKey(selected)}
    >
      {
        hasSearch && <MenuItem key={`search`} value={'search'} id={'search'}
                               onKeyDown={(e) => e.stopPropagation()}
                               onChange={(e) => e.stopPropagation()}
                               className={classes.searchContainerMenuItem}
        >
          <TextField
            className={classes.searchInput}
            label={'Search'}
            inputRef={searchInput}
            value={_searchValue}
            autoFocus={true}
            onChange={async (e) => {
              _setSearchValue(e.target.value);
              await updateFilteredOptionsWithDelay(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchValue(e.target.value);
              }
            }}
            onBlur={() => {
              hasSearch && open && searchInput.current.focus();
            }}
          />
        </MenuItem>
      }
      {filteredBySearchOptions.map((option) => {
        let isSelected = value.indexOf(option.value) > -1;

        if (!!option.child) {
          isSelected = option.child.every(r => value.includes(r));
        }

        return (
          <MenuItem key={option.value + (!!option.child ? 'parent' : '')}
                    value={!!option.child ? option.child.join('@@@') + 'parent_item' : option.value}
                    className={cx({
                      [classes.childItem]: option.isChild,
                      [classes.selected]: isSelected,
                      [classes.bottomDrawer]: option.value === '',
                    })}
          >
            {!hideMultiple && <Checkbox checked={isSelected} />}
            <ListItemText primary={option.label} className={cx({
              [classes.childItemText]: option.isChild,
              [classes.parentItemText]: !!option.child,
            })} />
          </MenuItem>
        );
      })}
      {
        hasSearch && filteredBySearchOptions.length === 0 &&
        <div
          style={{
            height: 300,
            padding: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >

          <Typography className={classes.title}>
            {t('titles:nothingFound')}
          </Typography>
        </div>
      }
      {
        !hideMultiple && <MenuItem disableRipple
                                   key={'button'}
                                   value={'button'}
                                   className={classes.buttonContainer}
        >
          <Button
            color='primary'
            disabled={false}
            variant={'contained'}
            className={cx(classes.button)}
            onClick={applyFilter}
          >
            {t('btn:applyFilter')}
          </Button>
        </MenuItem>
      }
    </Select>
  );
};

SelectFilter.propTypes = {
  filterValue: PropTypes.arrayOf(PropTypes.string),
  dataKey: PropTypes.string.isRequired,
  columnData: PropTypes.shape({
    isFilterable: PropTypes.bool,
    options: PropTypes.array,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectFilter.defaultProps = {
  filterValue: [],
};

export default SelectFilter;
