import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  DialogContent,
  DialogTitle, Link,
  makeStyles, TableBody, TableCell, TableHead, TableRow,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import RoundedButton from 'components/Shared/RoundedButton';
import remarkGfm from 'remark-gfm';
import { withStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import smoothscroll from 'smoothscroll-polyfill';

// --------- adding smooth scrolling for click nav link ---------
smoothscroll.polyfill();

const AGREEMENT = `
**Privacy Policy**

We recognize that your privacy is very important and take it seriously. This Privacy Policy describes the policies and procedures of '**QHSLab**' regarding the collection, use, and disclosure of your information when you use our websites, software, platforms, and services (collectively referred to as the '**Services**'). It also informs you about your privacy rights and how the law protects you.

By using our Services, you consent to the collection, use, and disclosure of your information in accordance with this Privacy Policy. We will not use or share your personal information with anyone except as described in this Privacy Policy. Capitalized terms that are not defined in this Privacy Policy have the meanings given to them in our Service Agreement and Terms of Service.
This Privacy Policy does not apply to the Personal Information we may collect from our customers' patients and clients ('Clients') in the course of providing the Services. However, the handling of Client Personal Information is governed by our agreements with our customers, including our Service Agreement and HIPAA Business Associate Agreement (collectively referred to as our 'Agreement'). In the event of any conflict between the provisions of this Privacy Policy and our Agreement, the provisions of our Agreement will take precedence.

We do not establish direct relationships with our customers' clients.
If we receive any inquiries or requests from Clients regarding their Personal Information, we will redirect those inquiries or requests to the relevant customer.
If you are a client of one of our customers, we may retain your Personal Information on behalf of that customer. If you have any questions about how we handle your Personal Information, we encourage you to contact the appropriate customer. Any inquiries directly received from you concerning the use of your Personal Information will be forwarded to that customer.

With that in mind, this Privacy Policy is designed to describe:

- [Who we are and how to contact us?](#who-we-are-and-how-to-contact-us?)
- [Whose personal data do we collect?](#whose-personal-data-do-we-collect?)
- [When we may process your personal data?](#when-we-may-process-your-personal-data?)
- [What Personal Data we collect](#what-personal-data-we-collect)
- [How we use cookies and other tracking or profiling technologies](#how-we-use-cookies-and-other-tracking-or-profiling-technologies)
- [How we use your Personal Data and why](#how-we-use-your-personal-data-and-why)
- [Who we share your personal Data with?](#who-we-share-your-personal-data-with?)
- [How long we store your Personal Data](#how-long-we-store-your-personal-data)
- [Where we store your Personal Data](#where-we-store-your-personal-data)
- [How we protect your Personal Data](#how-we-protect-your-personal-data)
- [Links to Other Websites](#links-to-other-websites)
- [Changes to our Privacy Policy](#changes-to-our-privacy-policy)

This Privacy Policy is intended to meet our duties of transparency under the "Health Insurance Portability and Accountability Act "("HIPAA").

We will post any modifications or changes to this Privacy Policy on this page.

**Who We Are and How to Contact Us?**

**Who we are:** **QHSLab**, Inc., a company organized and existing under the laws of the state of Florida (referred to as either **'QHSLab**,' 'we', 'us', or 'our' in this Privacy Policy).

Our address is 901 Northpoint Parkway, Suite 302, West Palm Beach, FL 33407, USA.

**How to contact us:** If you have any questions about our practices or this Privacy Policy, please reach out to us at **[hello@QHSLab.com](mailto:hello@qhslab.com)**.

**Whose personal data do we collect?**

We may process your personal data, if:

- You are our potential or current client;
- You are an entity (or you are providing services to an entity), that we want to sell our services to;
- You are providing services / work for our client or to an entity, that uses our services or platform developed by us (you act on behalf of them e.g. as their employee, associate, representative);
- You are interested in actions that we undertake (e.g. as part of contact with media).

**When we may process your personal data?**

We may process your personal data received directly from You or from other sources. Therefore, your personal data may be processed:

- If you have provided us with your personal data via various means of communication (e.g. by sending us an inquiry, via email, via our Platform);
- In the case of conclusion or performance of a contract, including in case when your personal data has been provided to us as contact data for the purpose of proper performance of the above contract;
- If we have received your personal data from another source (e.g. from an entity that is our contractor / client, during events or from publicly available sources / websites).

**What Personal Data we collect**

**QHSLab** uses Personal Data we collect to provide the Services, maintain security, monitor aggregate metrics such as total number of visitors, traffic, and demographic patterns, and track user content and users as necessary to comply with the applicable laws.

We collect information in **three ways**: if and when you provide information to us, automatically through operating our services, and from outside sources.

**Information You Provide to Us.** The amount and type of information depends on the context and how we use the information. Here are some examples:

- Registration Information: We ask for basic information from you in order to set up your account - for example, your name, phone number, address and email address, and unique identifiers such as your usernames and passwords.
- Professional and employment-related information.
- Transaction and Billing Information: If you buy something from us, you will provide additional personal and payment information that is required to process the transaction and your payment, such as your name, credit card information, and contact information. We process this data, but it is stored with a compliant 3rd party.
- Content Information: In connection with using the Services, you may generate content that provides us with additional information about you.
- Communications with Us: You may also provide us information when you respond to surveys or communicate with us about a support question.

**Information We Collect Automatically.** We also collect some information automatically:

- Log Information: we collect information that web browsers, mobile devices, and servers typically make available, such as the IP address, the date and time of access. We collect log information when you use our Services.
- Usage Information: We collect information about your usage of our Services. For example, we collect information about the actions that site administrators and users perform on a site–in other words, who did what, when and to what thing on a site (e.g., \\[username\\] deleted “\\[content\\]” at \\[time/date\\]). We also collect information about what happens when you use our Services along with information about your device. We use this information to, for example, provide our Services to you, as well as get insights on how people use our Services, so we can make our Services better.
- Location Information: We may determine the approximate location of your device based on your IP address. We collect and use this information, for example, to allow our customers to properly configure date and time-based scheduling.

**Aggregated Data.** We may also collect, use and share "Aggregated Data" for any purpose. Aggregated Data may be derived from your Personal Data, but once in aggregated form it will not constitute Personal Data as this data does not directly or indirectly reveal your identity. However, if we combine or connect Aggregated Data with your Personal Data so that it can directly or indirectly identify you, we treat the combined data as Personal Data which will be used in accordance with this Privacy Policy.

**Children's Data.** Our Services are not directed to, and we do not knowingly collect information from, children under the age of 13. If we learn that we have received any Personal Information directly from a child under the age of 13 without first receiving the child’s parent’s verified consent, we will use that Personal Information only to respond directly to that child (or the parent or legal guardian) to inform the child that he or she cannot use the Services. We will then subsequently delete that child’s Personal Information. If you believe that a child under 13 may have provided us with Personal Information, please contact us.

**Public Data.** Information that you choose to make public can be disclosed publicly. That means, of course, that information like any content that you make public on your website is all available to others. Public information may also be indexed by search engines or used by third parties. Please keep all of this in mind when deciding what you would like to share.

**How we use cookies and other tracking or profiling technologies**

**QHSLab** may collect non-personally identifiable information regarding your usage of our Websites, including the pages you viewed, time spent using the website, demographic data such as server locations, connection speed, and other information that does not identify you. Like most online services, we also use cookies and other technologies that may collect Personal Data.

**Cookies:**

**What are cookies?** When you visit the Services, we may send one or more "cookies" – small data files – to your computer to uniquely identify your browser and let **QHSLab** help you enhance your navigation through the Site. A cookie may convey anonymous information about how you browse the Services to us so we can provide you with a more personalized experience, but does not collect personal information about you. A persistent cookie remains on your computer after you close your browser so that it can be used by your browser on subsequent visits to the Service. Persistent cookies can be removed by following your web browser's directions. A session cookie is temporary and disappears after you close your browser.

**What types of cookies do we use?** Our Site use the following types of cookies for the purposes set out below:

|**Type of cookie**|**Purpose**|
| :- | :- |
|Essential Cookies|These cookies are essential to provide you with services available through our Websites and to enable you to use some of its features. For example, they allow you to log in to secure areas of our Websites and help the content of the pages you request to load quickly. Without these cookies, the Services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.|
|Security Cookies|These cookies enable and support our security features, and to help us detect malicious activity.|
|Functionality Cookies|These cookies allow our Websites to remember choices you make when you use our Websites, such as remembering your login details and remembering the changes you make to other parts of our Websites which you can customize. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you visit our Websites.|


**How can I disable cookies?** You can typically reset your web browser to refuse all cookies or to notify you when a cookie is being sent. In order to do this, follow the instructions provided by your browser (usually located within the "settings", "help" "tools" or "edit" facility). Many browsers are set to accept cookies until you change your settings.
If you do not accept our cookies, you may experience some inconvenience in your use of our Services and some features of the Service may not function properly.\t

Further information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit [www.allaboutcookies.org](http://www.allaboutcookies.org/).

**Log Files:** Log file information is automatically reported by your browser each time you access a web page. When you use the Websites, our servers automatically record certain information your web browser sends whenever you visit any website. These server logs may include information such as your web request, Internet Protocol address, browser type, referring / exit pages and URLs, location, domain names, pages viewed, and other such information.

**How We Use Your Personal Data and Why**

We generally use Personal Data for various purposes, including delivering and improving our Services, managing our platforms, and providing customer and technical support. Additionally, we utilize this data for conducting research and analysis about our platforms, verifying user identity, and preventing fraud or other unauthorized or illegal activities. Our use of Personal Data also extends to enforcing or exercising any rights outlined in our Terms of Service.

We have set out below, in a table format, more detailed examples of relevant purposes for which we may use your Personal Data.

|**Purpose**|**Why do we do this**|
| :- | :- |
|Providing, updating, and maintaining our Services, and business|To deliver the Services you have requested.|
|Research and development|To enable us to improve the Services and better understand our users and the markets in which we operate. For example, we may conduct or facilitate research and use learnings about how people use our Services and feedback provided directly to us to troubleshoot and to identify trends, usage, activity patterns, areas for additional features and improvement of the Services and other insights. We also test and analyze certain new features with some users before introducing the feature to all users.|
|Communicating with users about the Services|To send communications via email, including, for example, responding to your comments, questions and requests, providing customer support, and sending you technical notices, service updates, security alerts. We may also provide tailored communications based on your activity and interactions with us. |
|Providing customer and technical support|To respond to your requests for assistance, comments and questions, to analyze crash information, to repair and improve the Services and provide other customer support.|
|Enhancing security|To keep our Services and associated systems operational and secure, including, for example, verifying accounts and activity, monitoring and investigating suspicious or fraudulent activity and to identify violations of our terms and policies.|
|To comply with applicable law, legal process and regulations and protect legitimate business interests|As we believe is reasonably necessary to comply with a law, regulation, order, subpoena, rule of a self-regulatory organization or audit or to protect the safety of any person, to address fraud, security issues, or to protect our legal rights, interests and the interests of others.|


**What happens when you do not provide necessary Personal Data?**
Where we need to process your Personal Data either to comply with law, or to perform the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with the functionalities of the Services). In this case, we may have to stop you from using our Services.

**Who We Share Your Personal Data With?**

We may share your Personal with third parties in the ways that are described in the table below. We consider this information to be a vital part of our relationship with you.

|**Recipients**|**Why we share it**|
| :- | :- |
|Service Providers|Our service providers provide us support for our Services, including, for example, development tools, hosting, maintenance, backup, storage, virtual infrastructure, analysis, compliance reviews, banking services, and other services for us, which may require them to access or use Personal Data about you.|
|Professional Advisers|Our lawyers, accountants and consultants may need to review your personal data to provide consultancy, compliance, banking, legal, insurance, accounting and similar services.|
|Legal and Taxing Authorities, Regulators and Participants in Judicial Proceedings|We may disclose your Personal Data if we believe it is reasonably necessary to comply with a law, regulation, order, subpoena, rule of a self-regulatory organization or audit or to protect the safety of any person, to address fraud, security or technical issues, or to protect our legal rights, interests and the interests of others, such as, for example, in connection with the acquisition, merger or sale of securities or a business (e.g., due diligence).|

**Business Transfers.** In connection with any merger, sale of company assets, or acquisition of all or a portion of our business by another company, or in the unlikely event that **QHSLab** goes out of business or enters bankruptcy, customer information would likely be one of the assets that is transferred or acquired by a third party. If any of these events were to happen, this Privacy Policy would continue to apply to your information and the party receiving your information may continue to use your information, but only consistent with this Privacy Policy.

**With Your Consent.** We may share and disclose information with your consent or at your direction. For example, we may share your information with third parties with which you authorize us to do so.

**How long we store your Personal Data**

We will retain your information for as long as it is reasonably needed for the purposes outlined in 'How We Use Your Personal Data and Why'. We will only keep your Personal Data for as long as reasonably necessary for these purposes, unless a longer retention period is required by law (for example, for regulatory purposes). This may involve maintaining your Personal Data beyond the termination of your contract, for the duration necessary to pursue legitimate business interests, conduct audits, comply with (and demonstrate compliance with) legal obligations, resolve disputes, and enforce our agreements.

**Where We Store Your Personal Data**

The Services are maintained in the United States of America. Personal Data that you provide to us may be stored, processed, and accessed by us, our staff, subcontractors, and third parties with whom we share Personal Data, either in the USA or elsewhere, for the purposes described in this policy. Additionally, we may store Personal Data in locations outside the direct control of **QHSLab**, such as on servers or databases co-located with hosting providers.

**How We Protect Your Personal Data**

**QHSLab** employs industry-standard physical, managerial, and technical safeguards to preserve the integrity and security of your personal information. Access to your Personal Data is restricted to employees and other staff with a business need for such access, all of whom are bound by a contractual duty of confidentiality.
We conduct periodic reviews of our policies and procedures to assess their effectiveness and ensure they remain up-to-date. Additionally, we have established procedures to address any actual or suspected Personal Data breaches.
It's important to note that **QHSLab** cannot guarantee the protection, control, or management of your Personal Data as outlined in this Privacy Policy if you share your login and password information with any third party, including those operating websites or providing other services.

**Links to Other Websites**

Our services may include links to other websites not operated or controlled by **QHSLab**. We are not responsible for the content, accuracy, or opinions expressed on such websites, and they are not investigated, monitored, or checked for accuracy or completeness by us. Please remember that when you use a link to navigate from our Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those linked on our site, are subject to that website's own rules and policies. Third parties may use their own cookies or other methods to collect information about you.

**Changes to Our Privacy Policy**

We reserve the right, in our sole discretion, to change, modify, add, or remove portions of this Privacy Policy at any time. Any changes or updates will be effective immediately upon posting to this page. It is advisable to review this Privacy Policy regularly for changes. You can determine if changes have been made by checking the Effective Date below. Your continued use of our services following the posting of any changes to this Privacy Policy indicates your consent to such changes.
`;

const styles = (theme) => ({
  root: {
    width: '100%',
    fontWeight: 1.5,
  },
  typography: {
    fontSize: '0.875rem',
    lineHeight: 1.4,
    whiteSpace: 'break-spaces',
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    '& span': {
      marginBottom: theme.spacing(0.4),
      marginTop: theme.spacing(0.4),
      '& br': {
        content: '"A" !important',
        display: 'block !important',
        marginBottom: '7px !important',
      },
    },
  },
  linkBiding: {
    position: 'absolute',
    top: -120,
  },
  tableContainer: {
    boxShadow: 'none',
    borderRadius: 10,
    overflow: 'hidden',
    border: '1px solid #d6d6d6',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
  },
  tableBody: {
    '& p': {
      fontSize: '0.875rem !important',
    }
  },
  tableSell: {
    '&:last-child': {
      paddingRight: theme.spacing(.5),
      paddingLeft: theme.spacing(.5),
      borderRight: 'none',
    },
    border: 'none',
    padding: theme.spacing(.5),
    borderRight: '1px solid #d6d6d6',
    backgroundColor: 'transparent',
  },
  tableRow: {
    border: 'none',
    verticalAlign: 'top',
    '&:last-child': {
      borderBottom: 'none',
    },
    borderBottom: '1px solid #d6d6d6',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 4fr',
    backgroundColor: 'transparent',
},
  tableHead: {
    border: 'none',
    borderBottom: '1px solid #d6d6d6',
    backgroundColor: '#dfeef5',

    '& p': {
      fontSize: '0.875rem !important',
    }
  },
});

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
  },
  content: {
    backgroundColor: theme.palette.grey[50],
  },
}));

const PrivacyDialog = ({ onClose, accountName }) => {
  const { t } = useTranslation(['btn', 'dialogs']);
  const classes = useStyles();

  const handleAnchorClick = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    const element = document.getElementById(name);
    element.scrollIntoView({
      block: 'start',
      inline: 'start',
      behavior: 'smooth',
    });
  };

  const UpdatedLink = withStyles(styles)(({ classes, ...props }) => {
    if (props.href.startsWith('#')) {
      return (
        <Link href='' className={classes.link}
              onClick={(e) => handleAnchorClick(e, props.href)}>
          {props.children}
        </Link>
      );
    }

    return (
      <Link className={classes.link} {...props} />
    );
  });

  const Strong = withStyles(styles)(({ classes, ...props }) => {
    let id = '#';
    if (props && props.children && props.children[0] && props.children[0].props && props.children[0].props.value) {
      id += props.children[0].props.value.toLowerCase().replaceAll(' ', '-');
    }

    return (
      <strong style={{position: 'relative',}} {...props} >
        {props.children}
        <div id={id} style={{position: 'absolute', bottom: 30, color:'red'}}/>
      </strong>
    );
  });

  const MarkdownTable = withStyles(styles)(({ classes, ...props }) => {
    return (
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size="small" aria-label="a dense table" className={classes.table}>
          {props.children}
        </Table>
      </TableContainer>
    );
  });

  const MarkdownTableCell = withStyles(styles)(({ classes, ...props }) => {
    return (
      <TableCell className={classes.tableSell}>
        <Typography>{props.children}</Typography>
      </TableCell>
    );
  });

  const MarkdownTableRow = withStyles(styles)(({ classes, ...props }) => {
    return <TableRow className={classes.tableRow}>{props.children}</TableRow>;
  });

  const MarkdownTableBody = withStyles(styles)(({ classes, ...props }) => {
    return <TableBody className={classes.tableBody}>{props.children}</TableBody>;
  });

  const MarkdownTableHead = withStyles(styles)(({ classes, ...props }) => {
    return <TableHead className={classes.tableHead}>{props.children}</TableHead>;
  });


  const renderers = {
    link: UpdatedLink, // we need a button
    strong: Strong,
    table: MarkdownTable,
    tableHead: MarkdownTableHead,
    tableBody: MarkdownTableBody,
    tableRow: MarkdownTableRow,
    tableCell: MarkdownTableCell,
  };

  return (
    <>
      <DialogTitle id='invite-title' disableTypography>
        <Typography variant='h6'>
          {t('dialogs:privacyFor', { accountName })}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ReactMarkdown
          source={AGREEMENT}
          remarkPlugins={[remarkGfm]}
          renderers={renderers}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <RoundedButton
          onClick={onClose}
          variant='contained'
          color='secondary'
          size='small'
        >
          {t('btn:close')}
        </RoundedButton>
      </DialogActions>
    </>
  );
};

PrivacyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  accountName: PropTypes.string.isRequired,
};

export default PrivacyDialog;
