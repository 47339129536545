import { createSlice } from '@reduxjs/toolkit';
import React from 'react';
import initialState from '../../../config/translations';

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState: initialState,
  reducers: {},
});

export const {} = i18nSlice.actions;

export default i18nSlice.reducer;

export const getTransitionWithVariable = (text, variables) => {
  let formattedText = text;
  Object.keys(variables).forEach(key => {
    formattedText = formattedText.replace(`{{${key}}}`, variables[key])
  })
  return formattedText;
}
