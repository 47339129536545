import React from 'react';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';

const ExportCSVButton = ({
                           fetchCSVData,
                           handleClose,
                           setExporting,
                           classes,
                         }) => {
  const { en: { btn: tBtn } } = useSelector((state) => state.i18n);

  const downloadedCSVFile = async () => {
    handleClose();
    fetchCSVData();
    setExporting(true);
  };

  return <Button
    className={classes.selectorMenu}
    onClick={downloadedCSVFile}
  >
    {tBtn.downloadCSV}
  </Button>;
};

export default ExportCSVButton;
