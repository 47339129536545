import {
  Box,
  Button,
  fade,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AppRouteContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import cx from 'classnames';
import RefreshTableButton from '../Shared/RefreshTableButton';
import { routes } from '../../config/routes';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import { checkPermission } from '../../helpers/checkPermissions';
import permissions from '../../config/permissions';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  historyBox: {
    padding: theme.spacing(4),
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: theme.spacing(4),
    borderTop: `1px solid ${fade(theme.colors.grey2, 0.13)}`,
  },
  greyText: {
    fontSize: 15,
    fontWeight: 400,
    fontStyle: 'italic',
    color: fade(theme.colors.blue2, 0.5),
  },
  flex: {
    display: 'flex',
  },
  blueText: {
    fontSize: 22,
    fontWeight: 400,
    color: theme.colors.lightBlue,
    paddingRight: theme.spacing(4),
  },
  assessmentButton: {
    boxShadow: 'none',
    width: 145,
    fontSize: 15,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 17,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 17,
    color: theme.colors.lightBlue,
    borderColor: theme.colors.lightBlue,
    backgroundColor: theme.palette.common.white,
  },
  resourceButton: {
    width: 125,
    fontSize: 15,
    boxShadow: 'none',
    borderRadius: 0,
    color: theme.colors.lightBlue,
    borderColor: theme.colors.lightBlue,
    backgroundColor: theme.palette.common.white,
    borderRightWidth: 1,
    borderLeftWidth: 0,
    '&:hover': {
      borderRightWidth: 1,
      borderLeftWidth: 0,
    },
  },
  orderButton: {
    width: 95,
    fontSize: 15,
    boxShadow: 'none',
    borderRadius: 0,
    color: theme.colors.lightBlue,
    borderColor: theme.colors.lightBlue,
    backgroundColor: theme.palette.common.white,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    '&:hover': {
      borderRightWidth: 0,
      borderLeftWidth: 0,
    },
  },
  billingButton: {
    width: 145,
    fontSize: 15,
    boxShadow: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 17,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 17,
    color: theme.colors.lightBlue,
    borderColor: theme.colors.lightBlue,
    backgroundColor: theme.palette.common.white,
  },
  claimButton: {
    width: 105,
    fontSize: 15,
    boxShadow: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 17,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 17,
    color: theme.colors.lightBlue,
    borderColor: theme.colors.lightBlue,
    backgroundColor: theme.palette.common.white,
    borderLeftWidth: 0,
    '&:hover': {
      borderLeftWidth: 0,
    },
  },
  removeRightBorders: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  activeButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.colors.lightBlue,
  },
}));

const PatientAdditionalNode = ({
  historyTitle,
  modifiedData,
  totalCount,
  activeItemNumber,
  reloadData,
  dataLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const { setAppRoute } = useContext(AppRouteContext);
  const { patientInfo } = useContext(ActivePatientContext);
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const {
    metadata: { currentAccountPermissions, childAccountPermissions },
  } = useSelector((state) => state.user);
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );
  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');
  const isClaimsAvailable = checkPermission(
    isDistributionSponsor ? childAccountPermissions : currentAccountPermissions,
    permissions.CLAIMS_GET,
  );
  const isClaimReportAvailable = checkPermission(
    currentAccountPermissions,
    permissions.CLAIM_REPORTS_GET,
  ) && false // remove if claim Report List enabled to patient page;
  const isResourcesAvailable = checkPermission(
    currentAccountPermissions,
    permissions.GET_RESOURCES,
  );

  return (
    <Grid container justify="space-between" className={classes.historyBox}>
      <Grid item>
        <Box pb={{ xs: 2, md: 0 }} className={classes.flex} alignItems="center">
          <Typography className={classes.blueText}>{historyTitle}</Typography>
          <Typography className={classes.greyText}>
            {modifiedData ? t('tables:items', { totalCount }) : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item className={classes.flex}>
        <RefreshTableButton
          reloadData={reloadData}
          hasRightPadding
          isLoading={dataLoading}
        />
        <Box className={classes.flex} justifyContent="flex-end">
          <Button
            color="primary"
            variant={activeItemNumber === 0 ? 'contained' : 'outlined'}
            className={cx(classes.assessmentButton, {
              [classes.activeButton]: activeItemNumber === 0,
            })}
            onClick={() =>
              setAppRoute({
                path: routes.patientAssessmentsList.path,
                title: `${t(
                  'titles:patientDashboard',
                )}: ${patientFirstName} ${patientLastName}`,
                params: {
                  accountUuid: paramAccountUuid,
                  patientUuid: paramsPatientUuid,
                },
              })
            }
          >
            {t('btn:assessments')}
          </Button>
          {isResourcesAvailable && (
            <Button
              color="primary"
              variant={activeItemNumber === 1 ? 'contained' : 'outlined'}
              className={cx(classes.resourceButton, {
                [classes.activeButton]: activeItemNumber === 1,
              })}
              onClick={() =>
                setAppRoute({
                  path: routes.patientResourcesList.path,
                  params: {
                    accountUuid: paramAccountUuid,
                    patientUuid: paramsPatientUuid,
                  },
                  title: `${t(
                    'titles:patientDashboard',
                  )}: ${patientFirstName} ${patientLastName}`,
                })
              }
            >
              {t('btn:resource')}
            </Button>
          )}
          <Button
            color="primary"
            variant={
              activeItemNumber === 1 + (isResourcesAvailable ? 1 : 0)
                ? 'contained'
                : 'outlined'
            }
            className={cx(classes.orderButton, {
              [classes.activeButton]:
                activeItemNumber === 1 + (isResourcesAvailable ? 1 : 0),
            })}
            onClick={() =>
              setAppRoute({
                path: routes.patientOrdersList.path,
                params: {
                  accountUuid: paramAccountUuid,
                  patientUuid: paramsPatientUuid,
                },
                title: `${t(
                  'titles:patientDashboard',
                )}: ${patientFirstName} ${patientLastName}`,
              })
            }
          >
            {t('btn:orders')}
          </Button>
          <Button
            color="primary"
            variant={
              activeItemNumber === 2 + (isResourcesAvailable ? 1 : 0)
                ? 'contained'
                : 'outlined'
            }
            className={cx(classes.billingButton, {
              [classes.activeButton]:
                activeItemNumber === 2 + (isResourcesAvailable ? 1 : 0),
              [classes.removeRightBorders]:
                isClaimsAvailable || isClaimReportAvailable,
            })}
            onClick={() =>
              setAppRoute({
                path: routes.patientBillingList.path,
                title: `${t(
                  'titles:patientDashboard',
                )}: ${patientFirstName} ${patientLastName}`,
                params: {
                  accountUuid: paramAccountUuid,
                  patientUuid: paramsPatientUuid,
                },
              })
            }
          >
            {t('btn:billingReports')}
          </Button>
          {isClaimsAvailable && (
            <Button
              color="primary"
              variant={
                activeItemNumber === 3 + (isResourcesAvailable ? 1 : 0)
                  ? 'contained'
                  : 'outlined'
              }
              className={cx(classes.claimButton, {
                [classes.activeButton]:
                  activeItemNumber === 3 + (isResourcesAvailable ? 1 : 0),
                [classes.removeRightBorders]: isClaimReportAvailable,
              })}
              onClick={() =>
                setAppRoute({
                  path: routes.patientClaimsReportingList.path,
                  title: `${t(
                    'titles:patientDashboard',
                  )}: ${patientFirstName} ${patientLastName}`,
                  params: {
                    accountUuid: paramAccountUuid,
                    patientUuid: paramsPatientUuid,
                  },
                })
              }
            >
              {t('btn:claimsReporting')}
            </Button>
          )}
          {isClaimReportAvailable && (
            <Button
              color="primary"
              variant={
                activeItemNumber === 4 + (isResourcesAvailable ? 1 : 0)
                  ? 'contained'
                  : 'outlined'
              }
              className={cx(classes.claimButton, {
                [classes.activeButton]:
                  activeItemNumber === 4 + (isResourcesAvailable ? 1 : 0),
              })}
              onClick={() =>
                setAppRoute({
                  path: routes.patientClaimReportList.path,
                  title: `${t(
                    'titles:patientDashboard',
                  )}: ${patientFirstName} ${patientLastName}`,
                  params: {
                    accountUuid: paramAccountUuid,
                    patientUuid: paramsPatientUuid,
                  },
                })
              }
            >
              {t('btn:claimReport')}
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

PatientAdditionalNode.propTypes = {
  modifiedData: PropTypes.any.isRequired,
  totalCount: PropTypes.number.isRequired,
  activeItemNumber: PropTypes.number.isRequired,
  historyTitle: PropTypes.string.isRequired,
  reloadData: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool.isRequired,
};

export default PatientAdditionalNode;
