import React, { createContext } from 'react';

export const UserContext = createContext({});
export const withUserData = (Component) =>
  function WrapperComponent(props) {
    return (
      <UserContext.Consumer>
        {(contextProps) => <Component {...props} {...contextProps} />}
      </UserContext.Consumer>
    );
  };

export const AppRouteContext = createContext({});
export const withAppRoute = (Component) =>
  function WrapperComponent(props) {
    return (
      <AppRouteContext.Consumer>
        {(contextProps) => {
          return <Component {...props} {...contextProps} />;
        }}
      </AppRouteContext.Consumer>
    );
  };
