import React from 'react';
// import PropTypes from 'prop-types'

const Privacy = () => {
  return <div>Privacy</div>;
};

Privacy.propTypes = {
  //
};

export default Privacy;
