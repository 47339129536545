import React from 'react';
// import PropTypes from 'prop-types'

const Terms = () => {
  return <div>terms</div>;
};

Terms.propTypes = {
  //
};

export default Terms;
