import getDigitPhone from '../../helpers/phone';
import { ACCOUNT_TYPES } from './constants';
import { addMethod, array, object, string } from 'yup';

export const removeUnnecessaryProps = (values) => {
  delete values.isShippingTheSame;
  delete values.isDistributionSponsor;
  delete values.disableAllergiendResources;
  delete values.disableOrdersAutomations;
  delete values.disableResourcesAll;
  delete values.disableAssessments;
  delete values.disableOrders;
  delete values.enableClaimsManagement;

  delete values.emailSurveyInvitationEnglish;
  delete values.emailSurveyInvitationSpanish;
  delete values.emailResourceInvitationEnglish;
  delete values.emailResourceInvitationSpanish;
  delete values.emailSurveyReminderEnglish;
  delete values.emailSurveyReminderSpanish;
  delete values.emailResourceReminderEnglish;
  delete values.emailResourceReminderSpanish;
  delete values.emailSurveyCompletionEnglish;
  delete values.emailSurveyCompletionSpanish;

  delete values.smsSurveyInvitationEnglish;
  delete values.smsSurveyInvitationSpanish;
  delete values.smsResourceInvitationEnglish;
  delete values.smsResourceInvitationSpanish;
  delete values.smsSurveyReminderEnglish;
  delete values.smsSurveyReminderSpanish;
  delete values.smsResourceReminderEnglish;
  delete values.smsResourceReminderSpanish;
  delete values.smsSurveyCompletionEnglish;
  delete values.smsSurveyCompletionSpanish;

  delete values.emailSurveyInvitationIsDefault;
  delete values.emailResourceInvitationIsDefault;
  delete values.smsSurveyInvitationIsDefault;
  delete values.smsResourceInvitationIsDefault;
  delete values.emailSurveyReminderIsDefault;
  delete values.emailResourceReminderIsDefault;
  delete values.smsSurveyReminderIsDefault;
  delete values.smsResourceReminderIsDefault;
  delete values.emailSurveyCompletionIsDefault;
  delete values.smsSurveyCompletionIsDefault;
  delete values.loading;

  return values;
};

export const formatSmsOrEmailValues = (typeKey, initValues, values) => {
  let formattedValues = JSON.parse(JSON.stringify(values));

  Object.keys(formattedValues[typeKey]).forEach(function(
    key,
  ) {
    const value = initValues && initValues[typeKey] && initValues[typeKey][key];
    const newValue = formattedValues[typeKey][key];

    const isNewDefault = newValue && newValue[0]?.isDefault;

    if (value && value.length > 0) {
      if (isNewDefault) {
        formattedValues[typeKey][key][0].text = value[0].text;
        formattedValues[typeKey][key][1].text = value[1].text;
      }
    } else if (value && value.length === 0 || !value) {
      if (isNewDefault) {
        delete formattedValues[typeKey][key][0].text;
        delete formattedValues[typeKey][key][1].text;
      }
    }
  });
  return formattedValues;
};

export const formattedAccountsValues = (values, checkAccountType, initValues) => {
  let formattedValues = {
    ...values,
    phone: getDigitPhone(values.phone),
    fax: getDigitPhone(values.fax),
    emailConfiguration: {
      surveyInvitation: [
        {
          local: 'english',
          text: values.emailSurveyInvitationEnglish,
          isDefault: values.emailSurveyInvitationIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailSurveyInvitationSpanish,
          isDefault: values.emailSurveyInvitationIsDefault,
        },
      ],
      surveyReminder: [
        {
          local: 'english',
          text: values.emailSurveyReminderEnglish,
          isDefault: values.emailSurveyReminderIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailSurveyReminderSpanish,
          isDefault: values.emailSurveyReminderIsDefault,
        },
      ],
      surveyCompletion: [
        {
          local: 'english',
          text: values.emailSurveyCompletionEnglish,
          isDefault: values.emailSurveyCompletionIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailSurveyCompletionSpanish,
          isDefault: values.emailSurveyCompletionIsDefault,
        },
      ],
      resourceInvitation: [
        {
          local: 'english',
          text: values.emailResourceInvitationEnglish,
          isDefault: values.emailResourceInvitationIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailResourceInvitationSpanish,
          isDefault: values.emailResourceInvitationIsDefault,
        },
      ],
      resourceReminder: [
        {
          local: 'english',
          text: values.emailResourceReminderEnglish,
          isDefault: values.emailResourceReminderIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailResourceReminderSpanish,
          isDefault: values.emailResourceReminderIsDefault,
        },
      ],
    },
    smsConfiguration: {
      surveyInvitation: [
        {
          local: 'english',
          text: values.smsSurveyInvitationEnglish,
          isDefault: values.smsSurveyInvitationIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsSurveyInvitationSpanish,
          isDefault: values.smsSurveyInvitationIsDefault,
        },
      ],
      surveyReminder: [
        {
          local: 'english',
          text: values.smsSurveyReminderEnglish,
          isDefault: values.smsSurveyReminderIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsSurveyReminderSpanish,
          isDefault: values.smsSurveyReminderIsDefault,
        },
      ],
      surveyCompletion: [
        {
          local: 'english',
          text: values.smsSurveyCompletionEnglish,
          isDefault: values.smsSurveyCompletionIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsSurveyCompletionSpanish,
          isDefault: values.smsSurveyCompletionIsDefault,
        },
      ],
      resourceInvitation: [
        {
          local: 'english',
          text: values.smsResourceInvitationEnglish,
          isDefault: values.smsResourceInvitationIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsResourceInvitationSpanish,
          isDefault: values.smsResourceInvitationIsDefault,
        },
      ],
      resourceReminder: [
        {
          local: 'english',
          text: values.smsResourceReminderEnglish,
          isDefault: values.smsResourceReminderIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsResourceReminderSpanish,
          isDefault: values.smsResourceReminderIsDefault,
        },
      ],
    },
  };

  if (checkAccountType) {
    formattedValues.accountType = values.isDistributionSponsor
      ? ACCOUNT_TYPES.DISTRIBUTION_SPONSOR
      : ACCOUNT_TYPES.STANDARD;
  }

  if (values.isShippingTheSame) {
    formattedValues.shippingAddress = {
      ...formattedValues.organizationAddress,
    };
  }

  formattedValues.accountPermissions = {
    disableAllergiendResources: formattedValues.disableAllergiendResources,
    disableOrdersAutomations: formattedValues.disableOrdersAutomations,
    disableResourcesAll: formattedValues.disableResourcesAll,
    disableAssessments: formattedValues.disableAssessments,
    disableOrders: formattedValues.disableOrders,
    enableClaimsManagement: formattedValues.enableClaimsManagement,
  };

  formattedValues = removeUnnecessaryProps(formattedValues);
  formattedValues = formatSmsOrEmailValues('emailConfiguration', initValues, formattedValues);
  formattedValues = formatSmsOrEmailValues('smsConfiguration', initValues, formattedValues);

  return formattedValues;
};


addMethod(array, 'unique', function(message, path) {
  return this.test('unique', message, function(list) {
    const mapper = x => x[path];
    const onlyMappedProps = list.map(mapper);
    const set = [...new Set(onlyMappedProps)];
    const isUnique = list.length === set.length;

    if (isUnique) {
      return true;
    }
    const duplicates = onlyMappedProps.filter((item, index) => item && onlyMappedProps.indexOf(item) !== index);
    let errors = [];

    onlyMappedProps.forEach((item, index) => {
      if (duplicates.includes(item)) {
        errors = [...errors, this.createError({ path: `${this.path}[${index}].${path}`, message })];
      }
    });

    return this.createError({
      message: () => errors,
    });
  });
});

addMethod(object, 'checkService', function(errorMessage) {
  return this.test(`services`, errorMessage, function(value) {
    const { path, createError } = this;
    if (value.claimReportType) {
      if (!value.services || !value.services.length) {
        return createError({
          path,
          message: `CPTs and prices are required when Claim Report Type is set`,
        });
      }
    }
    return true;
  });
});

const ServicesSchema = object({
  cptCode: string().required('CPT Code is a required field.'),
  price: string().required('Price is a required field.'),
});

const IspConfigurationSchema = {
  services: array().when({
    is: (exists) => !!exists,
    then: () => {
      return array(ServicesSchema).unique('CPT Code should be unique', 'cptCode');
    },
    otherwise: () => {},
  }),
};

export const AccountSchema = object({
  ispConfiguration: object().when({
    is: (exists) => !!exists,
    then: () => {
      return object(IspConfigurationSchema).checkService();
    },
    otherwise: () => {},
  }),
});

export const getAllIndexes = (arr, val) => {
  let indexes = [];
  let i = -1;
  while ((i = arr.indexOf(val, i + 1)) !== -1) {
    indexes.push(i);
  }
  return indexes;
};
