import React, { useContext, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as MaintenanceIcon } from '../../assets/icons/maintenance.svg';
import { ReactComponent as Logo } from '../../assets/icons/qlab-logo.svg';
import { useTranslation } from 'react-i18next';
import { routes } from '../../config/routes';
import { AppRouteContext } from '../../contexts';
import { checkIsMaintenanceMode } from '../../helpers/downForMaintenance';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, #2E48D3 0%, #00A8FF 100%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    maxWidth: 100,
    fill: theme.palette.common.white,
    margin: 30,
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 26,
    textAlign: 'center',
    paddingBottom: theme.spacing(.5),
    marginTop: -20,
  },
  subTitle: {
    color: theme.palette.common.white,
    opacity: .8,
    fontSize: 12,
    textAlign: 'center',
    textWrap: 'wrap',
  },
}));

const DownForMaintenance = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setAppRoute } = useContext(AppRouteContext);
  const { t } = useTranslation(['titles']);

  const checkMaintenance = async () => {
    const response = await checkIsMaintenanceMode();
    if (response.status === 204) {
      setAppRoute({
        path: routes.root.path,
      });
      navigate(routes.root.path)
    }
  };

  useEffect(() => {
    checkMaintenance();
    try {
      setInterval(async () => {
        checkMaintenance();
      }, 1000 * 60 * 10);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className={classes.container}>
      <Logo className={classes.logo} />
      <div className={classes.contentContainer}>
        <MaintenanceIcon />
        <Typography className={classes.title}>
          {t('titles:downForMaintenance')}
        </Typography>
        <Typography className={classes.subTitle}>
          {t('titles:downForMaintenanceSubtitleFirst')}
        </Typography>
        <Typography className={classes.subTitle}>
          {t('titles:downForMaintenanceSubtitleSecond')}
        </Typography>
      </div>
      <div style={{ height: 78 }}/>
    </div>
  );
};

export default DownForMaintenance;
