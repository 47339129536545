const _getPreFilterKeys = (en) => {
  const { scit, slit, formulas, tables } = en;
  return {
    // orders => order type
    'fiftyDosePerUnitBuildUp': scit.buildUp50,
    'tenDosePerUnitStageOne': scit.stage1,
    'tenDosePerUnitStageTwo': scit.stage2,
    'tenDosePerUnitStageThree': scit.stage3,
    'tenDosePerUnitStageFour': scit.stage4,
    'twelveDosePerUnitSCITMaintenance': scit.scitMaintenance,

    'oneMonthLowDose': slit.oneMonthLowDose,
    'oneMonthSLITMaintenance': slit.oneMonthSLITMaintenance,

    // orders => order formula
    'outdoor': formulas.outdoor,
    'indoorAllergenMixtureTwo': formulas.indoor2,
    'indoorAllergenMixtureThree': formulas.indoor3,
    'indoorAllergenMixtureFour': formulas.indoor4,
    'mold': formulas.mold,
    'environmentMixture': formulas.environment,

    // orders => order status
    'sending': tables.sending,
    'pending': tables.pending,
    'processing': tables.processing,
    'shipped': tables.shipped,
    'onHold': tables.onHold,
    'delayed': tables.delayed,
    'canceled': tables.canceled,
    'compounding': tables.compounding,

    // assessment => created by
    'manuallyCreated': tables.manuallyCreated,
    'integration': tables.integration,
    'automated': tables.automated,
  };
};

export const getTitle = (options, en) => {
  const keys = _getPreFilterKeys(en);
  let resultTitle = '';

  options.forEach(item => {
    if (keys.hasOwnProperty(item)) {
      resultTitle += keys[item] + ', ';
    } else {
      if (item.includes(',')) {
        const splitedItem = item.split(',');
        splitedItem.forEach(item => {
          if (keys.hasOwnProperty(item)) {
            resultTitle += keys[item] + ', ';
          } else {
            resultTitle += item + ',';
          }
        });
        resultTitle += ' '
      } else {
        resultTitle += item + ', ';
      }
    }
  });

  resultTitle = resultTitle.slice(0, -2);
  return resultTitle;
};
