import { makeStyles } from '@material-ui/core';
import React from 'react';
import Loader from 'components/Shared/Loader';

export const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    height: '100%',
    minHeight: 350,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const StyledLoader = () => {
  const classes = useStyles();
  return <div className={classes.loaderContainer}>
    <Loader size={35} />
  </div>;
};

export default StyledLoader;
