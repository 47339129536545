import { createTheme } from '@material-ui/core/styles';

const defaultTheme = {
  palette: {
    primary: {
      main: '#00A8FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#8E8E8E',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#DC125B',
      light: '#d79f9a',
    },
    error: {
      main: '#dc1212',
      light: '#d79f9a',
    },
    success: {
      main: '#41C377',
      light: '#93c4a8',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 8,
  },
  colors: {
    navBg: '#3E3E44',
    navBgHover: '#4A494F',
    gray1: '#D9D9D9',
    gray2: '#E4E4E4',
    gray3: '#e7e7e7',
    gray4: '#818FA0',
    gray5: '#BDC5D0',
    grey: '#8E8E8E',
    grey2: '#000000',
    grey3: '#EBEBEB',
    grey4: '#DEDEDE',
    blue: '#2E48D3',
    blue2: '#031F42',
    blue3: '#001899',
    blue4: '#1B71E6',
    blue5: '#1C35BC',
    green: '#41C377',
    darkGreen: '#0d9146',
    orange: '#EA7502',
    lightOrange: '#f3b16c',
    lightBlue: '#00A8FF',
    lightBlue2: '#6C7FDF',
    lightBlue3: '#4DC2FF',
    errorPink: '#e3d7d5',
    errorPinkLight: '#f2eceb',
    testing: '#4aa7f8',
    development: '#8a0101',
    darkError: '#dc1212',
  },
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: 8,
      },
    },
    MuiButton: {
      contained: {
        color: 'white !important',
      },
    },
  },
};
const theme = createTheme(defaultTheme);

export default theme;
