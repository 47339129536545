import { Box, fade, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { get } from 'lodash';
import theme from 'config/theme';
import { useDispatch, useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import { CHART_TYPES } from './constants';
import ChartButtons from './ChartButtons';
import { generateLineTypeChartData } from './helpers';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  getCharts,
  updateCharts,
} from '../../redux_store/reducer/reducers/chartsReducer';

const useStyles = makeStyles(() => ({
  chartBox: {
    width: '100%',
    height: 336,
    border: `1px solid ${fade(theme.palette.common.black, 0.12)}`,
    borderRadius: theme.shape.borderRadius,
  },
  shimmer: {
    margin: theme.spacing(4, 0),
    width: '100%',
    height: 1000,
  },
}));

const Charts = ({ chartData }) => {
  const { en } = useSelector((state) => state.i18n);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { charts } = useSelector((state) => state.charts);

  useEffect(() => {
    dispatch(getCharts());
  }, []);

  return (
    <Grid container spacing={4}>
      {!chartData && <Skeleton variant="rect" className={classes.shimmer} />}
      {chartData &&
        charts.map(({ key, chartType }) => {
          const data = generateLineTypeChartData(
            en.charts[key],
            get(chartData, key, []).map(({ name, value }) => ({
              value,
              name: en.charts[name],
            })),
            chartType,
          );
          return (
            <Grid
              style={{
                position: 'relative',
              }}
              item
              md={12}
              lg={6}
              xs={12}
              sm={12}
            >
              <ChartButtons
                chartType={chartType}
                setCharts={(newCharts) => dispatch(updateCharts(newCharts))}
                charts={charts}
                dataKey={key}
              />
              {chartType === CHART_TYPES.PIE && (
                <Box className={classes.chartBox}>
                  <ReactECharts style={{ height: 400 }} option={data} />
                </Box>
              )}
              {(chartType === CHART_TYPES.BAR ||
                chartType === CHART_TYPES.LINE) && (
                <Box className={classes.chartBox}>
                  <ReactECharts style={{ height: 330 }} option={data} />
                </Box>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};

export default Charts;
