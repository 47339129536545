import React  from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  container:{
    height: '100%',
    width: 'calc(100% - 2px)',
    position: 'relative',
    transition: '.3s filter linear',
    paddingTop: 0,
  },
  blurDashboard: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  hideBlur: {
    height: 0,
  }
}));

const BlurScreen = ({children, containerStyle, isBlurShown}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      style={{
      filter: isBlurShown ? 'blur(3px)' : null,
      ...containerStyle
    }}>
      <div
        className={cx({
          [classes.blurDashboard]: true,
          [classes.hideBlur]: !isBlurShown
        })}
      />
      {children}
    </div>
  );
};

export default BlurScreen;
