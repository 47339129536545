import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import HelperText from '../../Shared/HelperText';
import PaginationMenuSelect from './PaginationMenuSelect';
import SimpleSelect from './SimpleSelect';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: '100%',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  activitiesError: {
    color: theme.palette.error.main,
  },
  fullWidth: {
    width: '100%',
    margin: '0 !important',
  },
  statesSelect: {
    marginTop: theme.spacing(1),
    height: 44,
  },
}));

const Select = (props) => {
  const classes = useStyles();
  const { error, hasMenuPagination } = props;
  return (
    <>
      {hasMenuPagination ? (
        <PaginationMenuSelect {...props} />
      ) : (
        <SimpleSelect {...props} />
      )}
      <HelperText style={{...(props?.activitiesErrorStyle || [])}} className={cx(classes.activitiesError)}>{error}</HelperText>
    </>
  );
};

Select.propTypes = {
  filterValue: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  dataKey: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool,
  columnData: PropTypes.shape({
    isFilterable: PropTypes.bool,
    options: PropTypes.array,
    filteredOptions: PropTypes.array,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  hasMenuPagination: PropTypes.bool,
};

Select.defaultProps = {
  error: '',
  filterValue: '',
  label: '',
  required: false,
  multiSelect: false,
  hasMenuPagination: false,
};

export default Select;
