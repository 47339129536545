import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { makeStyles, Grid, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';

import { getLocalizedAddress, renderLocalizedData } from 'helpers/localize';
import AddressForm from '../Accounts/Item/AddressForm';

const useStyles = makeStyles((theme) => ({
  addressContainer: {
    width: '100%',
    maxWidth: 700,
  },
  addressItem: {
    display: 'block',
  },
  selector: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const AddressSelectField = ({ type, name, addresses, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms']);

  // json is used as a value in address selector.
  // an address object doesn't have an uniq identifier,
  // so we need to remove possible duplicates
  const uniqAddresses = uniqBy(
    addresses.map((address) => ({
      ...address,
      json: JSON.stringify(address),
    })),
    'json',
  );

  return (
    <Field type={type} name={name}>
      {({ field, form }) => {
        const handleItemClick = (e) => {
          const { value } = e.target;
          if (value) {
            const address = JSON.parse(value);
            form.setFieldValue(field.name, address);
          }
        };

        return (
          <div className={classes.addressContainer}>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <TextField
                  select
                  name={field.name}
                  value={JSON.stringify(field.value)}
                  type={type}
                  margin="dense"
                  variant="outlined"
                  label={t('forms:shippingAddress')}
                  className={classes.selector}
                  disabled={disabled}
                  onChange={handleItemClick}
                  onBlur={field.onBlur}
                  SelectProps={{
                    renderValue: (value) => JSON.parse(value).name,
                    MenuProps: {
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  {uniqAddresses.map((address) => {
                    const { json } = address;
                    return (
                      <MenuItem
                        key={json}
                        value={json}
                        className={classes.addressItem}
                      >
                        {renderLocalizedData(getLocalizedAddress(address))}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
            <AddressForm prefix="address" disabled />
          </div>
        );
      }}
    </Field>
  );
};

AddressSelectField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
};

AddressSelectField.defaultProps = {
  disabled: false,
};

export default AddressSelectField;
