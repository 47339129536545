import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PatientActions from '../../../Patients/PatientActions';
import RefreshTableButton from '../../../Shared/RefreshTableButton';
import RoundedButton from '../../../Shared/RoundedButton';
import showServerError from '../../../../helpers/showError';
import { ReactComponent as ExportIcon } from '../../../../assets/icons/export-icon.svg';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-fetching-library';
import { getEndpoint } from '../../../../helpers/endpoint';
import { GET_ASSESSMENTS_COLUMNS } from '../../constants';
import { checkPermission } from '../../../../helpers/checkPermissions';
import permissions from '../../../../config/permissions';
import { TABLE_KEYS } from '../../../../redux_store/reducer/reducers/tableColumnsReducer';
import { getFormattedDate } from '../../../../helpers/localize';

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    borderRadius: theme.shape.borderRadius,
    minHeight: 0,
    minWidth: 0,
    padding: '0 8px',
    height: 49,
    marginLeft: 18,
  },
  icon: {
    width: '1em',
    height: '1em',
    fontSize: 14,
    marginRight: 12,
    transform: 'rotate( -180deg )',
  },
}));

const Actions = ({
                   reloadList,
                   dataLoading,
                   baseEndpoint,
                   tableSort,
                   tableFilter,
                   totalAccountsCount,
                                 }) => {
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const {
    user,
    metadata: { currentAccountPermissions },
  } = useSelector((state) => state.user);
  const isPatientPage = Boolean(paramsPatientUuid);
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const { listOfHiddenColumns } = useSelector((state) => state.tableColumns);
  const tableKey = paramAccountUuid ? TABLE_KEYS.assessmentsDS : TABLE_KEYS.assessments;
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );

  const { t } = useTranslation(['titles']);

  const getAssessmentCSV = (fields) => ({
    method: 'GET',
    endpoint: getEndpoint(`${baseEndpoint}/export-surveys.csv`, { fields }, tableSort, tableFilter),
    responseType: 'blob',
    headers: {
      'Content-Type': 'text/csv',
    },
  });

  const { mutate: getCSV } = useMutation(getAssessmentCSV);

  const FIELDS_FOR_CSV = GET_ASSESSMENTS_COLUMNS(
    isPatientPage,
    paramAccountUuid,
    totalAccountsCount,
    isDistributionSponsor,
  ).filter(
    ({ permission, hidden }) =>
      checkPermission(currentAccountPermissions, permission) && !hidden,
  ).map((columnData) => columnData.dataKey)
    .filter(value => !(listOfHiddenColumns[tableKey] ?? []).includes(value));

  const hasLoadCSVPermission = checkPermission(
    currentAccountPermissions,
    permissions.ADMIN_PATIENT_IMPORT,
  );


  return (
    <>
      {isPatientPage ? (
        <PatientActions reloadList={reloadList} />
      ) : (
        <>
          <RefreshTableButton
            reloadData={reloadList}
            isLoading={dataLoading}
          />
          {
            hasLoadCSVPermission &&
            <RoundedButton
              variant={'outlined'}
              color='primary'
              size='small'
              isLoading={isCSVLoading}
              onClick={async () => {
                setIsCSVLoading(true);
                const { payload, error, status } = await getCSV(FIELDS_FOR_CSV.join());
                if (error) {
                  const options = payload && {
                    correlationUUID: payload.correlationUUID,
                    userUUID: user.uuid,
                  };
                  switch (status) {
                    case 401: {
                      showServerError(dispatch, t('notifications:notPermissionForEditingClaim'));
                      break;
                    }
                    default: {
                      showServerError(dispatch, '', options);
                      break;
                    }
                  }
                } else if (payload && payload.size > 0) {
                  const url = window.URL.createObjectURL(new Blob([payload]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `Assessments ${getFormattedDate(new Date(Date.now()))}.csv`);
                  document.body.appendChild(link);
                  link.click();
                }
                setIsCSVLoading(false);
              }}
              className={classes.iconBtn}
              LoadingWrapperProps={{
                LoaderProps: {
                  disableShrink: true,
                },
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 4,
                }}
              >
                <ExportIcon className={classes.icon} />
                <div style={{
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  lineHeight: 1.75,
                  letterSpacing: '0.02857em',
                  marginRight: 8,
                }}>
                  {t('btn:Download')}
                </div>
              </div>
            </RoundedButton>
          }
        </>
      )}
    </>
  );
};

export default Actions;
