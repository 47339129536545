import { useRef, useMemo } from 'react';
import isEqual from 'lodash/isEqual';

const deepCompareEquals = (a, b) => isEqual(a, b);

const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

const useDeepCompareMemo = (callback, dependencies) => {
  return useMemo(callback, useDeepCompareMemoize(dependencies));
};

export default useDeepCompareMemo;
