import React, { useContext } from 'react';
import { makeStyles, Typography, Grid, Box } from '@material-ui/core';
import cx from 'classnames';

import { AppRouteContext } from 'contexts';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 32,
    paddingLeft: 28,
    padding: theme.spacing(3, 0),
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  text: {
    fontSize: 14,
    color: theme.colors.gray4,
  },
  linkContainer: {
    position: 'relative',
  },
  link: {
    cursor: 'pointer',
    marginRight: theme.spacing(2.5),
  },
  currentTitle: {
    fontSize: 14,
    fontWeight: '500',
    color: theme.colors.lightBlue,
  },
  point: {
    top: 9,
    right: 8,
    width: 4,
    height: 4,
    borderRadius: '50%',
    position: 'absolute',
    backgroundColor: theme.colors.gray5,
  },
}));

const Breadcrumbs = () => {
  const classes = useStyles();
  const { setAppRoute, breadcrumbs } = useContext(AppRouteContext);
  const updatedBreadcrumbs =
    breadcrumbs.length > 1
      ? breadcrumbs.map((item, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return isLast
            ? item
            : item.title === breadcrumbs[index + 1].title
            ? null
            : item;
        })
      : breadcrumbs;

  const handleLinkClick = (item) => {
    setAppRoute({
      ...item,
    });
  };

  return (
    <Box pr={3}>
      <Grid container alignItems="center" className={classes.root}>
        {updatedBreadcrumbs.map((item, index) => {
          const isLast = index === updatedBreadcrumbs.length - 1;
          if (item) {
            return isLast ? (
              <Typography
                key={item.path}
                className={cx(classes.text, classes.currentTitle)}
              >
                {item.title}
              </Typography>
            ) : (
              <div key={item.path} className={classes.linkContainer}>
                <Typography
                  onClick={() => handleLinkClick(item)}
                  className={cx(classes.text, classes.link)}
                >
                  {item.title}
                </Typography>
                <span className={classes.point} />
              </div>
            );
          }
        })}
      </Grid>
    </Box>
  );
};

export default Breadcrumbs;
