import React, { useContext, useState } from 'react';
import { makeStyles, Button, Menu, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { UserContext, AppRouteContext } from 'contexts';
import { routes } from 'config/routes';
import MenuDivider from 'components/Shared/MenuDivider';
import CustomMenuItem from 'components/Shared/CustomMenuItem';
import { ReactComponent as LogoutIcon } from 'assets/icons/logOut.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/password.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux_store/reducer/reducers/userReducer/api';

const useStyles = makeStyles((theme) => ({
  dropIcon: {
    position: 'absolute',
    right: 7,
    color: theme.palette.text.secondary,
  },
  blueBackground: {
    backgroundColor: theme.colors.lightBlue,
  },
  userAvatar: {
    width: 40,
    height: 40,
  },
  menuIcon: {
    width: 16,
    height: 16,
  },
}));

const UserMenu = ({ ...restProps }) => {
  const classes = useStyles();
  const { t } = useTranslation(['nav', 'titles']);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector((state) => state.user);
  const { setAppRoute } = useContext(AppRouteContext);
  const dispatch = useDispatch();

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleCloseMenu();
    dispatch(logout());
  };

  const handleEditProfile = () => {
    handleCloseMenu();
    setAppRoute({
      path: routes.editProfile.path,
      title: t('titles:editProfile'),
      clearBreadcrumbs: true,
      removeQueryString: true,
    });
  };

  const handleChangePassword = () => {
    handleCloseMenu();
    setAppRoute({
      path: routes.changePassword.path,
      title: t('titles:changePassword'),
      clearBreadcrumbs: true,
      removeQueryString: true,
    });
  };
  const { firstName, lastName, photo } = user;

  return (
    <div {...restProps}>
      <Button
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <Avatar
          src={photo}
          className={cx(
            (firstName || lastName) && classes.blueBackground,
            classes.userAvatar,
          )}
        >
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </Avatar>
      </Button>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <CustomMenuItem
          onClick={handleEditProfile}
          icon={ProfileIcon}
          iconClassName={classes.menuIcon}
        >
          {t('titles:editProfile')}
        </CustomMenuItem>
        <CustomMenuItem
          onClick={handleChangePassword}
          icon={PasswordIcon}
          iconClassName={classes.menuIcon}
        >
          {t('titles:changePassword')}
        </CustomMenuItem>
        <MenuDivider />
        <CustomMenuItem
          onClick={handleLogout}
          icon={LogoutIcon}
          iconClassName={classes.menuIcon}
        >
          {t('nav:logOut')}
        </CustomMenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
