import { Box, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import Modal from 'components/Shared/Modal';
import showServerError from 'helpers/showError';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginBottom: theme.spacing(4),
  },
  modal: {
    width: '100%',
    maxWidth: 1000,
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },
  createButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
    maxHeight: 40,
  },
}));

const ConfirmationsPopup = ({
  confirmResendingInfo,
  confirm,
  confirmBody,
  closePopup,
  handleSuccessfulCreating,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications', 'titles']);
  const dispatch = useDispatch();
  const [confirmations, setConfirmations] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const getIsButtonDisabled = () => {
    return confirmResendingInfo.length !== Object.keys(confirmations).length;
  };

  return (
    <Modal
      open={!!confirmResendingInfo}
      handleClose={closePopup}
      classes={{ paper: classes.modal, container: classes.modalContainer }}
    >
      {!confirmResendingInfo ? (
        <Box pt={19} pb={19}>
          <Loader fullHeight />
        </Box>
      ) : (
        <Formik
          initialValues={{}}
          validateOnBlur={false}
          onSubmit={async () => {
            const { payload, error } = await confirm({
              ...confirmBody,
              confirmations,
            });
            if (!error) {
              setIsSuccess(true);
              setTimeout(() => {
                setIsSuccess(false);
              }, 2000);
              handleSuccessfulCreating(payload);
            } else {
              setIsFailed(true);
              setTimeout(() => {
                setIsFailed(false);
              }, 2000);
              const options = payload && {
                correlationUUID: payload.correlationUUID,
                userUUID: user.uuid,
              };
              showServerError(dispatch, '', options);
            }
          }}
        >
          {({ handleSubmit }) => (
            <Box pt={5} p={4}>
              <Form>
                {confirmResendingInfo.map((item) => {
                  return (
                    <Field key={'confirmationPopup'} name={item.key} type="checkbox">
                      {() => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={item.key}
                              checked={confirmations.includes(item.key)}
                              color="primary"
                              onChange={() => {
                                if (!confirmations.includes(item.key)) {
                                  setConfirmations([
                                    ...confirmations,
                                    item.key,
                                  ]);
                                } else {
                                  const newConfirmations = {
                                    ...confirmations,
                                  }.filter(
                                    (confirmation) => confirmation !== item.key,
                                  );
                                  setConfirmations(newConfirmations);
                                }
                              }}
                            />
                          }
                          className={classes.checkbox}
                          label={item.localizedMessage}
                        />
                      )}
                    </Field>
                  );
                })}

                <Box py={1} display="flex" justifyContent="space-between">
                  <RoundedButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={closePopup}
                    className={classes.createButton}
                  >
                    {t('btn:cancel')}
                  </RoundedButton>
                  <div style={{ display: 'flex' }}>
                    <RoundedButton
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={getIsButtonDisabled() || isLoading}
                      onClick={handleSubmit}
                      isLoading={isLoading}
                      isSuccess={isSuccess}
                      isFailed={isFailed}
                      className={classes.createButton}
                    >
                      {t('btn:confirmAndContinue')}
                    </RoundedButton>
                  </div>
                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      )}
    </Modal>
  );
};

ConfirmationsPopup.propTypes = {
  confirmResendingInfo: PropTypes.arrayOf(PropTypes.any).isRequired,
  confirm: PropTypes.func.isRequired,
  handleSuccessfulCreating: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  confirmBody: PropTypes.any.isRequired,
};

export default ConfirmationsPopup;
