import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { Box, makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import { ReactComponent as SuccessIcon } from 'assets/icons/check.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/caution.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { SNACKBAR_VARIANTS } from '../redux_store/reducer/reducers/notificationsReducer';

const useStyles = makeStyles((theme) => ({
  snackbarRoot: {
    margin: 'auto',
    width: 'fit-content',
  },
  snackbarType: {
    fontSize: 15,
    fontWeight: '400',
    padding: '5px 16px 5px 45px',
    display: 'flex',
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    '& .MuiSnackbarContent-message': {
      width: 'calc(100% - 35px)',
    },
    '& .MuiSnackbarContent-action': {
      marginRight: 'auto',
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  snackbarError: {
    color: theme.palette.error.main,
  },
  snackbarSuccess: {
    color: theme.colors.green,
  },
  snackbarWarning: {
    color: theme.colors.orange,
  },
  snackbarInfo: {
    color: theme.colors.blue4,
  },
  snackbarContainer: {
    top: 70,
  },
  snackbarContent: {
    margin: theme.spacing(0, 3),
  },
  collapseWrapper: {
    margin: 0,
  },
  closeIcon: {
    width: 20,
    height: 20,
  },
  snackbarSuccessIconBox: {
    backgroundColor: theme.colors.green,
    top: 0,
  },
  snackbarErrorIconBox: {
    backgroundColor: theme.palette.error.main,
    top: 0,
  },
  snackbarWarningIconBox: {
    backgroundColor: theme.colors.orange,
    top: 0,
  },
  snackbarInfoIconBox: {
    backgroundColor: theme.colors.blue4,
    top: 0,
  },
  iconBox: {
    left: 0,
    width: '35px',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
}));

const SUCCESS_DURATION = 5000;
export const INFO_DURATION = 5000;
export const ERROR_DURATION = 10000;
export const WARNING_DURATION = 10000;

const SNACKBAR_OPTIONS = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  autoHideDuration: SUCCESS_DURATION,
};

function NotificationsProvider({ children }) {
  const classes = useStyles();
  const providerRef = useRef();

  const onClickDismiss = (key) => () => {
    providerRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={providerRef}
      {...SNACKBAR_OPTIONS}
      classes={{
        variantSuccess: cx(classes.snackbarType, classes.snackbarSuccess),
        variantError: cx(classes.snackbarType, classes.snackbarError),
        variantWarning: cx(classes.snackbarType, classes.snackbarWarning),
        variantInfo: cx(classes.snackbarType, classes.snackbarInfo),
        root: classes.snackbarRoot,
        anchorOriginTopCenter: classes.snackbarContent,
        containerAnchorOriginTopRight: classes.snackbarContainer,
        collapseWrapper: classes.collapseWrapper,
        wrapperInner: classes.wrapperInner,
      }}
      iconVariant={{
        [SNACKBAR_VARIANTS.success]: (
          <Box className={cx(classes.iconBox, classes.snackbarSuccessIconBox)}>
            <SuccessIcon />
          </Box>
        ),
        [SNACKBAR_VARIANTS.error]: (
          <Box className={cx(classes.iconBox, classes.snackbarErrorIconBox)}>
            <ErrorIcon />
          </Box>
        ),
        [SNACKBAR_VARIANTS.warning]: (
          <Box className={cx(classes.iconBox, classes.snackbarWarningIconBox)}>
            <WarningIcon />
          </Box>
        ),
        [SNACKBAR_VARIANTS.info]: (
          <Box className={cx(classes.iconBox, classes.snackbarInfoIconBox)}>
            <InfoIcon />
          </Box>
        ),
      }}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)} size="small">
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}

NotificationsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NotificationsProvider;
