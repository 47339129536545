import { CHART_TYPES } from './constants';

export const generateLineTypeChartData = (title, data, type) => {
  let xAxisData = [];
  let seriesData = [];
  const rootContainer = document.getElementById('dashboardInner');
  const root = rootContainer ? rootContainer.offsetWidth : 1024;

  if (Math.round(root / 125))
    data.forEach((item) => {
      let newName = '';
      const test = item.name.split(' ');
      let index = 0;
      test.forEach((item, i) => {
        if (i > 0) {
          const a = [...test].slice(index, i + 1).join(' ');
          if (a.length > Math.round(root / 125) || i === test.length - 1) {
            newName = newName + a + '\n';
            index = i + 1;
          }
        }
      });
      xAxisData = [...xAxisData, newName || item.name];
      seriesData = [...seriesData, item.value];
    });
  const sortedData = data.sort(function (a, b) {
    return a.value - b.value;
  });
  return {
    title: {
      text: title,
      left: 20,
      top: 12,
      textStyle: {
        color: '#031F42',
      },
    },
    gradientColor:
      type !== CHART_TYPES.PIE ? ['#5894e7', '#1B71E6', '#031F42'] : null,
    visualMap: [
      {
        show: false,
        type: 'continuous',
        seriesIndex: type !== CHART_TYPES.PIE ? 0 : null,
        max: type !== CHART_TYPES.BAR ? sortedData[0].value : null,
        min:
          type !== CHART_TYPES.BAR
            ? sortedData[sortedData.length - 1].value
            : null,
        inRange:
          type === CHART_TYPES.PIE
            ? {
                colorLightness: [0.9, 0.4],
              }
            : null,
      },
    ],
    tooltip: {
      trigger: type === CHART_TYPES.PIE ? 'item' : 'axis',
    },
    yAxis: type !== CHART_TYPES.PIE ? { type: 'value' } : null,
    xAxis:
      type !== CHART_TYPES.PIE
        ? {
            type: 'category',
            data: xAxisData,
            axisLabel: {
              interval: 0,
              rotate: 0,
              color: '#031F42',
            },
          }
        : null,
    series: [
      type === CHART_TYPES.PIE
        ? {
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            data: sortedData,
            roseType: 'radius',
            label: {
              color: '#031F42',
            },
            labelLine: {
              lineStyle: {
                color: '#c4c4c4',
              },
              smooth: 0.2,
              length: 10,
              length2: 20,
            },
            itemStyle: {
              color: '#1B71E6',
              shadowBlur: 20,
              shadowOffsetX: 3,
              shadowOffsetY: 3,
              shadowColor: 'rgba(3,31,66,0.4)',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 3,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0,0,0, 0.5)',
              },
            },
          }
        : {
            label:
              type !== CHART_TYPES.PIE
                ? {
                    color: '#031F42',
                  }
                : null,
            type: type,
            showSymbol: false,
            data: seriesData,
            backgroundStyle: { color: 'rgba(180, 180, 180, 0.2)' },
            areaStyle: { color: '#bbd5ff' },
            itemStyle: {
              color: '#1B71E6',
              shadowBlur: 20,
              shadowOffsetX: 10,
              shadowOffsetY: 10,
              shadowColor: 'rgba(3,31,66,0.4)',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0,0,0, 0.5)',
              },
            },
          },
    ],
  };
};
