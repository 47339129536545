import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, InputBase } from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: '100%',
    height: ' 100%',
    paddingRight: 2,
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
  },
}));

const CurrencyTextFilter = ({
                              filterValue,
                              dataKey,
                              columnData: { isFilterable },
                              onChange,
                            }) => {
  const textInput = React.useRef(null);

  useEffect(() => {
    if (filterValue === '') {
      textInput.current.value = '';
    } else if (textInput.current.value === '' && filterValue !== null) {
      textInput.current.value = filterValue;
    }
  }, [filterValue]);

  const handleInputChange = (e) => {
    if (onChange) {
      const value = e.target.value.replace('$ ', '');
      onChange(dataKey, value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleInputChange(e);
    }
  };

  return <CurrencyFormat
    inputRef={textInput}
    prefix={'$ '}
    value={filterValue}
    customInput={_CustomInput}
    allowNegative={false}
    fixedDecimalScale={true}
    onKeyDown={handleKeyDown}
    decimalScale={2}
    onBlur={handleInputChange}
    onChange={handleInputChange}
    thousandSeparator={','}
  />;
};

const _CustomInput = (props) => {
  const { t } = useTranslation(['tables']);
  const classes = useStyles();
  const {
    // textInput,
    // isFilterable,
  } = props;
  return <InputBase
    classes={{
      root: classes.inputRoot,
      input: classes.input,
    }}
    placeholder={t('tables:filter')}
    autoComplete='off'
    type='search'
    {...props}
  />;
};


CurrencyTextFilter.propTypes = {
  filterValue: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  columnData: PropTypes.shape({
    isFilterable: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
};

CurrencyTextFilter.defaultProps = {
  filterValue: '',
  onChange: undefined,
};

export default CurrencyTextFilter;
