import i18next from 'i18next';
import permissions from '../../config/permissions';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

export const FIELDS = [
  'accountCustomTags',
  'accountBusinessName',
  'patientMrnNumber',
  'patientFirstName',
  'patientLastName',
  'procedureName',
  'completedTimestamp',
  'providerFirstName',
  'providerLastName',
  'reviewedTimestamp',
];

export const BILLINGS_FOR_PATIENT_FIELDS = [
  'procedureName',
  'patientMrnNumber',
  'completedTimestamp',
  'providerFirstName',
  'providerLastName',
  'reviewedTimestamp',
];

export const GET_BILLINGS_COLUMNS = (
  isPatientPage,
  paramAccountUuid,
  totalAccountsCount,
) => [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'accountCustomTags',
    hasBubble: true,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      totalAccountsCount,
      // filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 1,
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'patientMrnNumber',
    columnData: {
      isFilterable: true,
    },
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'patientFirstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: 200,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'patientLastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: isPatientPage ? 250 : 220,
    label: i18next.t('tables:procedureName'),
    dataKey: 'procedureName',
    flexGrow: 1,
    // columnData: {
    //   isFilterable: true,
    //   filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    // },
  },
  {
    width: 180,
    label: i18next.t('tables:completedTimestamp'),
    dataKey: 'completedTimestamp',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: isPatientPage ? 200 : 170,
    label: i18next.t('tables:providerFirstName'),
    dataKey: 'providerFirstName',
    flexGrow: 1,
  },
  {
    width: isPatientPage ? 200 : 170,
    label: i18next.t('tables:providerLastName'),
    dataKey: 'providerLastName',
    flexGrow: 1,
  },
  {
    width: 160,
    label: i18next.t('tables:reviewedTimestamp'),
    dataKey: 'reviewedTimestamp',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
];

export const FAKE_BILLING = {
  accountCustomTags: [],
  uuid: '',
  accountUUID: '',
  accountBusinessName: '',
  procedureName: '',
  patientUUID: '',
  patientFirstName: '',
  accountCustomIdentifier: '',
  patientLastName: '',
  providerFirstName: '',
  providerLastName: '',
  completedTimestamp: '',
  reviewedTimestamp: '',
  moreTitle: '',
};
