import { useState, useEffect } from 'react';
import { useMutation } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import useMetadataUpdate from 'hooks/useMetadataUpdate';
import showServerError from 'helpers/showError';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../redux_store/reducer/reducers/notificationsReducer';

const useStatusChange = ({
  name,
  statusChanging: { statusItem, preparedList },
  onSuccess,
  onError,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['errors', 'notifications']);
  const { user } = useSelector((state) => state.user);
  const [params, setParams] = useState({});
  const { iteration, isValidation, isLoading } = params;

  useEffect(() => {
    if (preparedList) {
      setParams({
        iteration: 1,
        isValidation: true,
        isLoading: true,
      });
    }
  }, [preparedList]);

  const statusChangeAction = (
    values,
    { accountUuid, isValidateOnly = false },
  ) => {
    return {
      method: 'POST',
      endpoint: `/accounts/${accountUuid}/${name}s/actions${
        isValidateOnly ? '?validate-only' : ''
      }`,
      body: values,
    };
  };
  const { mutate } = useMetadataUpdate(useMutation, [statusChangeAction]);

  useEffect(() => {
    /*
      check validation for all the list items
      and only then change status
    */
    const changeStatus = async () => {
      const { accountUuid, target } = preparedList[iteration - 1];
      const uuids = `${name}UUIDs`;

      const { status, error, payload } = await mutate(
        {
          statusAction: statusItem.value,
          [uuids]: target,
        },
        { isValidateOnly: isValidation, accountUuid },
      );

      const notificationParam = name.charAt(0).toUpperCase() + name.slice(1);

      if (error) {
        const isSingleOrder =
          preparedList.length === 1 && preparedList[0].target.length === 1;
        const options = payload && {
          correlationUUID: payload.correlationUUID,
          userUUID: user.uuid,
        };
        switch (status) {
          case 404: {
            showServerError(
              dispatch,
              t('errors:orderCouldNotFind', {
                name: notificationParam,
              }),
            );
            break;
          }
          case 422: {
            showServerError(
              dispatch,
              t(
                isSingleOrder
                  ? 'errors:orderStatusSingleValidation'
                  : 'errors:orderStatusMultiplyValidation',
                {
                  name: notificationParam,
                },
              ),
            );
            break;
          }
          default: {
            showServerError(dispatch, '', options);
            break;
          }
        }
        setParams({
          iteration: 0, // finish iterating
          isValidation: true,
          isLoading: false,
        });
        onError();
      } else {
        const isLast = iteration === preparedList.length;
        if (isValidation) {
          setParams({
            iteration: isLast ? 1 : iteration + 1,
            isValidation: !isLast,
            isLoading: true,
          });
        } else {
          setParams({
            iteration: isLast ? 0 : iteration + 1,
            isValidation: isLast,
            isLoading: !isLast,
          });
          if (isLast) {
            dispatch(
              enqueueSnackbar({
                message: t('notifications:orderStatusChanged', {
                  name: notificationParam,
                }),
                options: {
                  variant: SNACKBAR_VARIANTS.success,
                },
              }),
            );
            onSuccess();
          }
        }
      }
    };

    if (iteration) {
      changeStatus();
    }
  }, [iteration, isValidation]);

  return {
    isLoading,
  };
};

export default useStatusChange;
