import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, makeStyles } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
    // stroke: theme.palette.common.black,
  },
}));

const CustomMenuItem = forwardRef(
  ({ icon: Icon, children, iconClassName, ...restProps }, ref) => {
    const classes = useStyles();
    return (
      <MenuItem ref={ref} {...restProps}>
        {Icon && <Icon className={cx(classes.icon, iconClassName)} />}
        <span className={classes.text}>{children}</span>
      </MenuItem>
    );
  },
);

CustomMenuItem.propTypes = {
  icon: PropTypes.elementType,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  iconClassName: PropTypes.string,
};

CustomMenuItem.defaultProps = {
  icon: undefined,
  iconClassName: undefined,
};

export default CustomMenuItem;
