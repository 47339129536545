import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetchList from 'hooks/useFetchList';
import PropTypes from 'prop-types';
import Loader from '../../../../Shared/Loader';
import Select from '../../../../FormikFields/ComplexSelect/Select';

const AccountsSelect = ({
  currentAccountUuidForPdf,
  setCurrentAccountUuidForPdf,
}) => {
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);
  const [accountList, setAccountList] = useState('');

  const { items: accounts, loading: accountsLoading } = useFetchList({
    baseEndpoint: `/accounts`,
    baseParams: { fields: 'businessName' },
  });

  useEffect(() => {
    if (accounts) {
      const parsedAccounts =
        accounts &&
        accounts.map((account) => {
          return {
            label: account.businessName,
            value: account.uuid,
          };
        });
      setCurrentAccountUuidForPdf(accounts[0].uuid);
      setAccountList(parsedAccounts);
    }
  }, [accounts]);

  return (
    <>
      {accountsLoading || !accountList ? (
        <div style={{ height: 44, display: 'flex' }}>
          <Loader fullHeight />
        </div>
      ) : (
        <Select
          dataKey="chooseAnAccount"
          filterValue={currentAccountUuidForPdf}
          columnData={{
            isFilterable: false,
            options: accountList,
          }}
          onChange={(dataKey, val) => setCurrentAccountUuidForPdf(val)}
          label={`${t('forms:chooseAnAccount')}`}
          labelWidth={78}
          hasMenuPagination
        />
      )}
    </>
  );
};

AccountsSelect.propTypes = {
  currentAccountUuidForPdf: PropTypes.string.isRequired,
  setCurrentAccountUuidForPdf: PropTypes.func.isRequired,
};

export default AccountsSelect;
