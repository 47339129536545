/* eslint-disable */
import React from 'react';

const AssessmentsLogo = ({ fill, className }) => (
  <svg className={className} style={{ width: 20, height: 20 }} viewBox="0 0 20 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>Assessment@3x</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill={fill || "#FFFFFF"} fillRule="evenodd">
      <g id="Dashboard" transform="translate(-36.000000, -367.000000)" fill={fill || "#FFFFFF"} fillRule="nonzero">
        <g id="Side" fill={fill || "#FFFFFF"} transform="translate(18.000000, 30.000000)">
          <g id="Tabs" fill={fill || "#FFFFFF"} transform="translate(0.000000, 83.000000)">
            <g id="Assessment" fill={fill || "#FFFFFF"} transform="translate(18.000000, 254.000000)">
              <rect id='Rectangle' fill={fill || '#FFFFFF'} x='11.3772152' y='1.97468354' width='8.44050633' height='2.53164557'/>
              <polygon id='Path' fill={fill || '#FFFFFF'} points='7.87848101 1.65063291 5.95696203 4.49710592e-16 3.29873418 3.09113924 1.66582278 1.66329114 0 3.5721519 3.55443038 6.67341772'/>
              <rect id='Rectangle' fill={fill || '#FFFFFF'} x='11.1620253' y='12.1012658' width='8.44050633' height='2.53164557'/>
              <polygon id='Path' fill={fill || '#FFFFFF'} points='1.99240506 16.8759494 3.71139241 15.156962 5.4278481 16.8759494 7.21772152 15.0835443 5.50126582 13.3670886 7.21772152 11.6506329 5.4278481 9.86075949 3.71139241 11.5772152 1.99240506 9.86075949 0.202531646 11.6506329 1.92151899 13.3670886 0.202531646 15.0835443'/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AssessmentsLogo;
