// use proper hook and check the response for metadata updates
const useMetadataUpdate = (hook, params) => {
  const { status, ...rest } = hook(...params);
  // empty values for table
  const sort = {};
  const filter = {};
  const onSort = () => {};
  return { status, ...rest, sort, filter, onSort };
};

export default useMetadataUpdate;
