import PropTypes from 'prop-types';
import React from 'react';
import 'intersection-observer';
import './styles.css';
import { ANSWER_COLORS_LIST } from '../constants';
import MarkdownParser from '../../Reports/widgets/MarkdownParser';

const SingleSelect = ({ survey }) => {
  const { title, value, reportContent } = survey;

  return (
    <>
      <tr className="list-break-marker">
        <td>{title}</td>
        <td>
          {value.valueUIClass === ANSWER_COLORS_LIST.success ? value.title : ''}
        </td>
        <td>
          {value.valueUIClass === ANSWER_COLORS_LIST.warning ? value.title : ''}
        </td>
        <td>
          {value.valueUIClass === ANSWER_COLORS_LIST.error ? value.title : ''}
        </td>
        <td>
          {value.valueUIClass === ANSWER_COLORS_LIST.usual ||
          value.valueUIClass === ANSWER_COLORS_LIST.info ||
          !value.valueUIClass
            ? value.title
            : ''}
        </td>
      </tr>
      {reportContent && (
        <tr className="list-break-marker">
          <td>
            <MarkdownParser content={reportContent} />
          </td>
        </tr>
      )}
    </>
  );
};

SingleSelect.propTypes = {
  survey: PropTypes.object,
};

SingleSelect.defaultProps = {
  survey: {},
};

export default SingleSelect;
