export const getDateFromMinuteOffset = (minuteOffset) => {
  const hour = Math.floor(minuteOffset / 60);
  const minutes = minuteOffset % 60;

  const date = new Date();
  date.setHours(hour);
  date.setMinutes(minutes);

  return date;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
