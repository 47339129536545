import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import 'intersection-observer';
import './styles.css';
import { ANSWER_COLORS_LIST } from '../constants';
import MarkdownParser from '../../Reports/widgets/MarkdownParser';
import { makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    maxWidth: 1000,
    padding: '0 40px 40px 40px',
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },

  date: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  resultText: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.colors.blue2,
  },

  sectionTitle: {
    fontWeight: 500,
    color: theme.colors.blue2,
    fontSize: '1.2rem',
    margin: 4,
  },

  icon: {
    color: theme.colors.lightBlue,
    fontSize: 32,
    margin: 4,
  },
  open: {
    transform: 'rotate( 180deg )',
    transition: 'transform 150ms ease',
  },
  close: {
    transform: 'rotate( 360deg )',
    transition: 'transform 150ms ease',
  },
}));

const COLORS = {
  'success': 'rgba(65, 195, 119, .15)',
  'error': 'rgba(220, 18, 91, .15)',
  'warning': 'rgba(234, 117, 2, .15)',
};

const TEXT_COLORS = {
  'success': 'rgba(65, 195, 119)',
  'error': 'rgba(220, 18, 91)',
  'warning': 'rgba(234, 117, 2)',
};

const RESULT_ICONS = {
  'Disimproved': <TrendingDownIcon />,
  'Improved': <TrendingUpIcon />,
  'None': <TrendingFlatIcon />,
};

const TableView = ({ survey }) => {
  const classes = useStyles();

  const { title, values, reportContent } = survey;

  return (
    <>
      <tr className='list-break-marker'>
        <td>{title}</td>
        {
          values && values.map(({ value, colorCode }, index) => {
            const isResultColumn = index === values.length - 1;
            return <td
              style={{
                backgroundColor: isResultColumn ? COLORS[colorCode] : null,
              }}
            >
              <div style={{
                color: !isResultColumn ? TEXT_COLORS[colorCode] : 'black',
                flex: 1,
                flexShrink: 1,
                padding: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontWeight: isResultColumn ? 500 : 400,
              }}
                   className={cx({
                     [classes.resultText]: isResultColumn,
                   })}
              >
                {value}
                {
                  isResultColumn ? RESULT_ICONS[value] : ''
                }
              </div>
            </td>;
          })
        }
      </tr>
      {reportContent && (
        <tr className='list-break-marker'>
          <td>
            <MarkdownParser content={reportContent} />
          </td>
        </tr>
      )}
    </>
  );
};

TableView.propTypes = {
  survey: PropTypes.object,
};

TableView.defaultProps = {
  survey: {},
};

export default TableView;
