import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RoundedButton from 'components/Shared/RoundedButton';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3, 2, 3),
  },
  btn: {
    marginLeft: theme.spacing(2),
  },
}));

const CreateOrderDialog = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation(['btn', 'dialogs']);
  const classes = useStyles();
  const [isChecked, setChecked] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="create-title">
        {t('dialogs:prescriberDeclaration')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('dialogs:bySavingThisPrescription')}
        </DialogContentText>
        <DialogContentText>{t('dialogs:requirementA')}</DialogContentText>
        <DialogContentText>{t('dialogs:requirementB')}</DialogContentText>
        <DialogContentText>{t('dialogs:requirementC')}</DialogContentText>
        <DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                color="primary"
                onChange={(e, checked) => {
                  setChecked(checked);
                }}
              />
            }
            label={t('dialogs:prescribingPhysician')}
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions classes={{ root: classes.actions }}>
        <RoundedButton
          onClick={onClose}
          variant="contained"
          color="secondary"
          size="small"
        >
          {t('btn:cancel')}
        </RoundedButton>
        <RoundedButton
          onClick={onSubmit}
          variant="contained"
          color="primary"
          size="small"
          className={classes.btn}
          disabled={!isChecked}
        >
          {t('btn:save')}
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

CreateOrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateOrderDialog;
