import { createSlice } from '@reduxjs/toolkit';

const getTables = () => {
  let tables = localStorage.getItem('tables');
  return JSON.parse(tables);
};

const setTables = (tables) => {
  localStorage.setItem('tables', JSON.stringify(tables));
};

export const tableColumnsSlice = createSlice({
  name: 'tableColumns',
  initialState: {
    listOfHiddenColumns: {},
  },
  reducers: {
    getTablesFromLocalStorage: (state) => {
      state.listOfHiddenColumns = getTables() ?? {};
    },
    setTablesHiddenColumnByKey: (state, action) => {
      const {tableKey, columnKeys} = action.payload;
      const newList = {...state.listOfHiddenColumns};
      newList[tableKey] = columnKeys;
      state.listOfHiddenColumns = newList;
      setTables(newList);
    },
  },
});

export const {
  getTablesFromLocalStorage,
  setTablesHiddenColumnByKey,
} = tableColumnsSlice.actions;

export default tableColumnsSlice.reducer;

export const TABLE_KEYS = {
  assessments: 'assessments',
  assessmentsDS: 'assessmentsDS',
  patients: 'patients',
  patientsDS: 'patientsDS',
  resources: 'resources',
  resourcesDS: 'resourcesDS',
  billing: 'billing',
  billingDS: 'billingDS',
  claims: 'claims',
  claimsDS: 'claimsDS',
  orders: 'orders',
  ordersDS: 'ordersDS',
  accounts: 'accounts',
  alerts: 'alerts',
  alertsDS: 'alertsDS',
  users: 'users',
  claimReports: 'claimReports',
  claimReportsDS: 'claimReportsDS',
  reportable: 'reportable',
  reportableDS: 'reportableDS',
  allowedAccounts: 'allowedAccounts',
}

export const TABLE_NAMES = {
  [TABLE_KEYS.assessments]: 'surveys',
  [TABLE_KEYS.assessmentsDS]: 'surveys',
  [TABLE_KEYS.patients]: 'patients',
  [TABLE_KEYS.patientsDS]: 'patients',
  [TABLE_KEYS.resources]: 'resources',
  [TABLE_KEYS.resourcesDS]: 'resources',
  [TABLE_KEYS.billing]: 'billing',
  [TABLE_KEYS.billingDS]: 'billing',
  [TABLE_KEYS.orders]: 'orders',
  [TABLE_KEYS.ordersDS]: 'orders',
  [TABLE_KEYS.claims]: 'claims',
  [TABLE_KEYS.claimsDS]: 'claims',
  [TABLE_KEYS.claimReports]: 'claimReports',
  [TABLE_KEYS.claimReportsDS]: 'claimReports',
  [TABLE_KEYS.reportable]: 'reportables',
  [TABLE_KEYS.reportableDS]: 'reportables',
  [TABLE_KEYS.accounts]: 'accounts',
  [TABLE_KEYS.allowedAccounts]: 'accounts',
  [TABLE_KEYS.alerts]: 'alerts',
  [TABLE_KEYS.alertsDS]: 'alerts',
}
