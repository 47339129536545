import { configureStore } from '@reduxjs/toolkit';
import appSettingsReducer from '../reducer/reducers/appSettingsReducer';
import notificationsReducer from '../reducer/reducers/notificationsReducer';
import userReducer from '../reducer/reducers/userReducer/userReducer';
import i18nReducer from '../reducer/reducers/i18nReducer';
import chartsReducer from '../reducer/reducers/chartsReducer';
import accountsReducer from '../reducer/reducers/accountsReducer/accountsReducer';
import tableColumnsReducer from '../reducer/reducers/tableColumnsReducer';
import alertsReducer from '../reducer/reducers/alertsReducer/alertsReducer';
import signalRMiddleware from '../reducer/middleware/alertsMiddleware';

export default configureStore({
  reducer: {
    appSettings: appSettingsReducer,
    notifications: notificationsReducer,
    user: userReducer,
    i18n: i18nReducer,
    charts: chartsReducer,
    accounts: accountsReducer,
    tableColumns: tableColumnsReducer,
    alerts: alertsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(signalRMiddleware),
});
