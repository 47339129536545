import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0.5, 0),
  },
}));

const MenuDivider = (props) => {
  const classes = useStyles();
  return <Divider className={classes.divider} {...props} />;
};

export default MenuDivider;
