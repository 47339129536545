import { Checkbox, makeStyles, MenuItem, Tooltip } from '@material-ui/core';
import React, { useMemo, useRef } from 'react';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import { getTitle } from '../helpers';
import { useSelector } from 'react-redux';

export const useStyles = makeStyles((theme) => ({
  menuItem: {
    height: 40,
    minHeight: 40,
    overflow: 'initial',
    width: '100%',
    padding: 0,
    fontSize: 14,

    '&:hover $hiddenButton': {
      opacity: 1,
      transition: 'opacity 0.3s',
    },
  },
  hiddenButton: {
    opacity: 0,
    transition: 'opacity 0.3s ease-in, color 1s ease-in',
    color: theme.colors.grey,
    padding: '6px 16px 6px 12px',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      color: theme.colors.blue4,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  checkboxContainer: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
}));

const MenuRow = ({
                   option,
                   selected,
                   onItemClick,
                   applyCurrentFilter,
                 }) => {
  const classes = useStyles();
  const { en } = useSelector((state) => state.i18n);
  const titleRef = useRef();

  const onCheckboxClick = () => onItemClick(option, !selected.includes(option));

  const onQuickActionClick = (e) => {
    e.cancelBubble = true;
    e.stopPropagation();
    applyCurrentFilter(e);
  };

  const title = useMemo(
    () => {
      const names = option.split(',');
      return getTitle(names, en);
    },
    [option],
  );

  const showTooltip = useMemo(
    () => {
      return titleRef.current?.offsetWidth < titleRef.current?.scrollWidth;
    },
    [titleRef],
  );

  return <MenuItem key={option}
                   value={option}
                   classes={{ root: classes.menuItem }}
                   onClick={onCheckboxClick}
  >
    <div className={classes.content}>
      <div className={classes.checkboxContainer}>
        <Checkbox
          color='primary'
          checked={selected.includes(option)}
          onChange={onCheckboxClick}
        />
        <Tooltip
          disableFocusListener={!showTooltip}
          disableHoverListener={!showTooltip}
          disableTouchListener={!showTooltip}
          title={title}
          style={{
            zIndex: 100,
          }}
        >
          <div className={classes.text} ref={titleRef}>
            {title}
          </div>
        </Tooltip>
      </div>
      <div className={classes.hiddenButton}
           onClick={onQuickActionClick}
      >
        <OfflineBoltOutlinedIcon />
      </div>
    </div>
  </MenuItem>;
};

export default MenuRow;
