import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from '../../../Shared/Modal';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    maxWidth: 1000,
    padding: '0 40px 40px 40px',
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },
  modalSecondTitle: {
    paddingBottom: theme.spacing(2),
    padding: theme.spacing(0, 16),
    [theme.breakpoints.down(1000)]: {
      padding: theme.spacing(0),
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 600,
  },
  blueText: {
    color: '#172b4d',
    fontSize: '0.875rem',
    lineHeight: 1.43,
    marginTop: 20,
  },
  blackText: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    marginTop: 20,
  },
  text: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    marginTop: 20,
  },
  row: {
    display: 'flex',
    alignItems: 'baseline',
  },
  listItem: {
    margin: 5,
  },
  list: {
    margin: 0,
  },
  decimalList: {
    listStyleType: 'decimal',
  },
}));

const PediatricAllergyBaselineAssessmentReport = ({
  showHelpPopup,
  setShowHelpPopup,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['pediatricAllergyBaselineAssessmentReport']);

  return (
    <Modal
      open={showHelpPopup}
      handleClose={() => {
        setShowHelpPopup(false);
      }}
      classes={{ paper: classes.modal, container: classes.modalContainer }}
      title={t('pediatricAllergyBaselineAssessmentReport:title')}
      secondTitleStyle={classes.modalSecondTitle}
    >
      <div className={cx(classes.blackText, classes.bold)}>
        {t('pediatricAllergyBaselineAssessmentReport:firstPart')}
      </div>
      <div className={cx(classes.blackText)}>
        {t('pediatricAllergyBaselineAssessmentReport:requirementsFirst')}
      </div>
      <div className={cx(classes.blackText)}>
        {t('pediatricAllergyBaselineAssessmentReport:requirementsSecond')}
      </div>
      <div className={cx(classes.blackText, classes.italic)}>
        {t('pediatricAllergyBaselineAssessmentReport:requirementsThird')}
      </div>
      <div className={cx(classes.blackText, classes.italic)}>
        {t('pediatricAllergyBaselineAssessmentReport:requirementsForth')}
      </div>
      <div className={cx(classes.blueText, classes.bold)}>
        {t(
          'pediatricAllergyBaselineAssessmentReport:QHSLabAllergyAssessmentTitle',
        )}
      </div>
      <div>
        {t(
          'pediatricAllergyBaselineAssessmentReport:QHSLabAllergyAssessmentText',
        )}
      </div>

      <div className={cx(classes.blueText, classes.bold)}>
        {t(
          'pediatricAllergyBaselineAssessmentReport:respiratoryAllergyPredictionTestTitle',
        )}
      </div>
      <div>
        {t(
          'pediatricAllergyBaselineAssessmentReport:respiratoryAllergyPredictionTestText',
        )}
      </div>
      <div className={cx(classes.blueText, classes.bold)}>
        {t(
          'pediatricAllergyBaselineAssessmentReport:generalAllergyHistoryTitle',
        )}
      </div>
      <div>
        {t(
          'pediatricAllergyBaselineAssessmentReport:generalAllergyHistoryText',
        )}
      </div>
      <div className={cx(classes.blueText, classes.bold)}>
        {t(
          'pediatricAllergyBaselineAssessmentReport:patientReportedAllergySymptomsTitle',
        )}
      </div>
      <div>
        {t(
          'pediatricAllergyBaselineAssessmentReport:patientReportedAllergySymptomsText',
        )}
      </div>
      <div className={cx(classes.blueText, classes.bold)}>
        {t(
          'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestTitle',
        )}
      </div>
      <div>
        {t(
          'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestText1',
        )}
      </div>
      <div className={cx(classes.text)}>
        {t(
          'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestText2',
        )}
      </div>
      <ul className={classes.list}>
        <li>
          <div className={classes.row}>
            <p className={cx(classes.blueText, classes.bold, classes.listItem)}>
              {t(
                'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestMildTitle',
              )}
              :
            </p>
            <p className={cx(classes.listItem)}>
              {t(
                'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestMildText',
              )}
            </p>
          </div>
        </li>
        <li>
          <div className={classes.row}>
            <p className={cx(classes.blueText, classes.bold, classes.listItem)}>
              {t(
                'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestModerateTitle',
              )}
              :
            </p>
            <p className={cx(classes.listItem)}>
              {t(
                'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestModerateText',
              )}
            </p>
          </div>
        </li>
        <li>
          <div className={classes.row}>
            <p className={cx(classes.blueText, classes.bold, classes.listItem)}>
              {t(
                'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestSevereTitle',
              )}
              :
            </p>
            <p className={cx(classes.listItem)}>
              {t(
                'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestSevereText',
              )}
            </p>
          </div>
        </li>
      </ul>
      <div className={cx(classes.text)}>
        {t(
          'pediatricAllergyBaselineAssessmentReport:sinoNasalOutcomeTestText3',
        )}
      </div>

      <div className={cx(classes.blueText, classes.bold)}>
        {t('pediatricAllergyBaselineAssessmentReport:asthmaControlTestTitle')}
      </div>
      <div>
        {t('pediatricAllergyBaselineAssessmentReport:asthmaControlTestText')}
      </div>
      <div className={cx(classes.blueText, classes.bold)}>
        {t(
          'pediatricAllergyBaselineAssessmentReport:allergyMedicationUseTitle',
        )}
      </div>
      <div>
        {t('pediatricAllergyBaselineAssessmentReport:allergyMedicationUseText')}
      </div>
      <div className={cx(classes.text, classes.bold)}>
        {t('pediatricAllergyBaselineAssessmentReport:referencesTitle')}
      </div>
      <ul className={cx(classes.decimalList, classes.list)}>
        <li>{t('pediatricAllergyBaselineAssessmentReport:referencesFirst')}</li>
        <li>
          {t('pediatricAllergyBaselineAssessmentReport:referencesSecond')}
        </li>
        <li>{t('pediatricAllergyBaselineAssessmentReport:referencesThird')}</li>
        <li>
          {t('pediatricAllergyBaselineAssessmentReport:referencesFourth')}
        </li>
        <li>{t('pediatricAllergyBaselineAssessmentReport:referencesFifth')}</li>
        <li>{t('pediatricAllergyBaselineAssessmentReport:referencesSixth')}</li>
      </ul>
    </Modal>
  );
};

PediatricAllergyBaselineAssessmentReport.propTypes = {
  showHelpPopup: PropTypes.bool.isRequired,
  setShowHelpPopup: PropTypes.func.isRequired,
};

export default PediatricAllergyBaselineAssessmentReport;
