/* eslint-disable */
import React from 'react';

const OrdersLogo = ({ fill, className }) => (
  <svg className={className} style={{ width: 20, height: 25}} viewBox="0 0 17 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>Order@3x</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard" transform="translate(-36.000000, -306.000000)" fill={fill || "#FFFFFF"} fillRule="nonzero" stroke="#2E48D3" strokeWidth="0.25">
        <g id="Side" transform="translate(18.000000, 30.000000)">
          <g id="Tabs" transform="translate(0.000000, 83.000000)">
            <g id="Order" transform="translate(19.000000, 194.000000)">
              <path d='M0.0448803828,0.865358852 L0.0448803828,19.1345933 C0.0448803828,19.4766029 0.24722488,19.7862201 0.560526316,19.923445 C0.873779904,20.0606699 1.23856459,19.9995215 1.49,19.7677033 L3.15464115,18.2325837 C3.19100478,18.1989474 3.24631579,18.1985646 3.28325359,18.2315311 L4.37449761,19.2054067 C5.06837321,19.8247847 6.11239234,19.8219617 6.80311005,19.1988995 L7.87851675,18.2287081 C7.91516746,18.1957416 7.97023923,18.1957416 8.00679426,18.2287081 L9.0822488,19.1989474 C9.77287081,19.8220096 10.8169378,19.8247847 11.5108134,19.2055024 L12.6021531,18.2315789 C12.6389952,18.1986603 12.6942584,18.1990909 12.7307177,18.2326316 L14.3953589,19.767799 C14.5576555,19.9174163 14.7670813,19.9959809 14.9794258,19.9959809 C15.0961244,19.9959809 15.2137321,19.9722488 15.3248325,19.9235885 C15.638134,19.7863158 15.8404785,19.4767464 15.8404785,19.1347368 L15.8404785,0.865358852 C15.8404785,0.389712919 15.4548804,0.00411483254 14.9792344,0.00411483254 L0.906124402,0.00411483254 C0.430430622,0.00411483254 0.0448803828,0.389760766 0.0448803828,0.865358852 Z M1.76732057,1.72660287 L14.1178469,1.72660287 L14.1178469,17.168756 L13.8983254,16.9663158 C13.2069378,16.328756 12.156555,16.3202871 11.4550718,16.9463636 L10.3637321,17.9202871 C10.3272249,17.9528708 10.2722967,17.9527273 10.2359809,17.9199043 L9.16047847,16.9496651 C8.81330144,16.6365072 8.37799043,16.4799043 7.94258373,16.4799043 C7.50717703,16.4799043 7.07181818,16.6365072 6.72464115,16.9497129 L5.6491866,17.9199522 C5.61287081,17.9527751 5.55789474,17.9528708 5.52138756,17.9202871 L4.43014354,16.9464115 C3.72856459,16.3201914 2.67813397,16.328756 1.98684211,16.9663158 L1.76732057,17.168756 L1.76732057,1.72660287 L1.76732057,1.72660287 Z' id='Shape'/>
              <path d='M4.74416268,8.22325359 L10.8851196,8.22325359 C11.3607656,8.22325359 11.7463636,7.8376555 11.7463636,7.36200957 C11.7463636,6.88636364 11.3607656,6.50076555 10.8851196,6.50076555 L4.74416268,6.50076555 C4.26851675,6.50076555 3.88291866,6.88636364 3.88291866,7.36200957 C3.88291866,7.8376555 4.26851675,8.22325359 4.74416268,8.22325359 Z' id='Path'/>
              <path d='M4.74416268,11.5495694 L10.8851196,11.5495694 C11.3607656,11.5495694 11.7463636,11.1639713 11.7463636,10.6883254 C11.7463636,10.2126794 11.3607656,9.82708134 10.8851196,9.82708134 L4.74416268,9.82708134 C4.26851675,9.82708134 3.88291866,10.2126794 3.88291866,10.6883254 C3.88291866,11.1639713 4.26851675,11.5495694 4.74416268,11.5495694 Z' id='Path'/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default OrdersLogo;
