import React from 'react';
import './parseMarkdownStyle.css';
import PropTypes from 'prop-types';

const OldMarkdownParser = ({ text }) => {
  return (
    <div className="parse-markdown-container">
      {text.split('%%%').map((paragraph) => {
        return (
          <p
            key={paragraph}
            className="row-container"
            dangerouslySetInnerHTML={{
              __html: paragraph
                .replaceAll('*{spacing}*', `<div class='empty-row' />`)
                .replaceAll(
                  '**|++|{success}',
                  `&zwnj;<span class="bold-text success">`,
                )
                .replaceAll(
                  '**|++|{warning}',
                  `&zwnj;<span class="bold-text warning">`,
                )
                .replaceAll(
                  '**|++|{error}',
                  `&zwnj;<span class="bold-text error">`,
                )
                .replaceAll(
                  '**|++|{info}',
                  `&zwnj;<span class="bold-text info">`,
                )
                .replaceAll('++**', `</span>&zwnj;`)
                .replaceAll('**|', `&zwnj;<span class="bold-text">`)
                .replaceAll('**', `</span>&zwnj;`)
                .replaceAll('++|{success}', `&zwnj;<span class="success">`)
                .replaceAll('++|{warning}', `&zwnj;<span class="warning">`)
                .replaceAll('++|{error}', `&zwnj;<span class="error">`)
                .replaceAll('++|{info}', `&zwnj;<span class="info">`)
                .replaceAll('++', `</span>&zwnj;`),
            }}
          />
        );
      })}
    </div>
  );
};

OldMarkdownParser.propTypes = {
  text: PropTypes.string.isRequired,
};

export default OldMarkdownParser;
