import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { clearFilters } from '../../../../config/sessionStorage';
import { routes } from 'config/routes';
import { userInitialState } from './initialState';
import {
  login,
  logout,
  getUser,
  getMetadata,
  updateUser,
  checkIsLogin,
  setIsLogin,
} from './api';
import isEqual from 'lodash/isEqual';
import { REQUEST_STATUS } from '../../../constants';

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    updateIsMaintenanceMode: (state, action) => {
      state.isMaintenanceMode = action.payload;
    },
    getIsLogin: (state) => {
      if (checkIsLogin() === 'true') {
        state.isLogin = true;
      }
    },
    setIsLogin: (state, action) => {
      setIsLogin(action.payload);
      state.isLogin = action.payload;
    },
    setIsLoginStatus: (state, action) => {
      state.isLoginStatus = action.payload;
    },
    setBlockedAccounts: (state, action) => {
      state.blockedAccounts = action.payload;
    },
    setUser: (state, action) => {
      if (!isEqual(state.user, action.payload)) {
        state.user = action.payload;
      }
    },
    setHipaaCheckParams: (state, action) => {
      if (!isEqual(state.hipaaCheckParams, action.payload)) {
        state.hipaaCheckParams = action.payload;
      }
    },
    setMetadata: (state, action) => {
      state.metadata = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.isLoginStatus = REQUEST_STATUS.loading;
        state.isLoginErrors = {};
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.ok) {
          if (!action.payload.data.data.authMetadata) {
            state.isLoginStatus = REQUEST_STATUS.idle;
            return;
          }
          clearFilters();
          setIsLogin(true);
          state.isLogin = true;
          state.hipaaCheckParams = {
            newUser: action.payload.data.data.user,
            newMetadata: action.payload.data.data.authMetadata,
            newRoute: {
              path: routes.root.path,
            },
          };
        } else {
          state.isLoginStatus = REQUEST_STATUS.failed;
          if ((action.payload.status === 422)) {
            state.isLoginErrors = action.payload.data.validationErrors;
          }
        }
      })

      .addCase(logout.pending, (state) => {
        state.isLogoutStatus = REQUEST_STATUS.loading;
        state.isLoginErrors = {};
      })
      .addCase(logout.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.isLogin = false;
          state.isLogoutStatus = REQUEST_STATUS.succeeded;
          state.user = null;
          state.metadata = null;
          state.hipaaCheckParams = null;
          state.blockedAccounts = [];
          clearFilters();
          setIsLogin(false);
        } else {
          state.isLogoutStatus = REQUEST_STATUS.failed;
        }
      })

      .addCase(getUser.pending, (state) => {
        if (state.status === REQUEST_STATUS.idle) {
          state.status = 'idle_loading';
        } else {
          state.status = REQUEST_STATUS.loading;
        }
      })
      .addCase(getUser.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.user = action.payload.data.data;
          state.status = REQUEST_STATUS.succeeded;
        } else {
          state.status = REQUEST_STATUS.failed;
          if (action.error) {
            state.error = action.error.message;
          }
        }
      })
      .addCase(updateUser.pending, (state) => {
        state.status = REQUEST_STATUS.loading;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.status = REQUEST_STATUS.succeeded;
        } else {
          state.status = REQUEST_STATUS.failed;
          state.error = action.error.message;
        }
      })

      .addCase(getMetadata.pending, (state) => {
        state.metadataStatus = REQUEST_STATUS.loading;
      })
      .addCase(getMetadata.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.metadataStatus = REQUEST_STATUS.succeeded;
          state.metadata = action.payload.data.data;
        } else {
          state.metadataStatus = REQUEST_STATUS.failed;
          state.metadata = null;
        }
      });
  },
});

export const {
  updateIsMaintenanceMode,
  setUser,
  setHipaaCheckParams,
  setMetadata,
  getIsLogin,
  setIsLoginStatus,
  setBlockedAccounts,
} = userSlice.actions;

export default userSlice.reducer;
