import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import cx from 'classnames';
import Select from '../../../../FormikFields/ComplexSelect/Select';
import useMetadataUpdate from '../../../../../hooks/useMetadataUpdate';
import { useQuery } from 'react-fetching-library';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  selectAppointmentProvider: {
    padding: '8px 0',
  },
  secondaryTitle: {
    fontSize: '1.2rem',
    color: '#00A8FF',
  },
}));

const ProvidersSection = ({ patientUUID, accountUuid }) => {
  const classes = useStyles();
  const { en: { forms } } = useSelector((state) => state.i18n);
  const [appointmentProviders, setAppointmentProviders] = useState(null);
  const [insuranceProviders, setInsuranceProviders] = useState(null);

  const { query: queryInfo, loading: loadingClaimInfo } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'GET',
        endpoint: `/accounts/${accountUuid}/patients/${patientUUID}/claims/lookup-info`,
      },
      false,
    ],
  );

  useEffect(() => {
    if (queryInfo && accountUuid && patientUUID) {
      queryInfo().then(({ error, payload }) => {
        if (!error) {
          if (payload.insuranceProviders) {
            const newInsuranceProviders = payload?.insuranceProviders?.filter((insurance) => !!insurance).map(
              (name) => {
                return {
                  label: name,
                  value: name,
                };
              },
            );
            setInsuranceProviders(newInsuranceProviders);
          }
          if (payload.appointmentProviders) {
            const newProviders = payload.appointmentProviders.filter((provider) => !!provider).map(
              (provider) => {
                return {
                  label: provider,
                  value: provider,
                };
              },
            );
            setAppointmentProviders(newProviders);
          }
        }
      });
    }
  }, [queryInfo, accountUuid, patientUUID]);

  return <>
    {
      accountUuid && patientUUID && <Grid item>
        <Typography className={classes.secondaryTitle}>
          {forms.selectAppointmentProvider}
        </Typography>
        <div className={classes.selectAppointmentProvider}>
          <Field type='data' name='appointmentProvider'>
            {({ field, form }) => {
              return (
                <Select
                  filterValue={field.value}
                  initDataLoading={loadingClaimInfo}
                  disabled={!appointmentProviders || loadingClaimInfo}
                  required={false}
                  dataKey='appointmentProvider'
                  columnData={{
                    isFilterable: false,
                    options: appointmentProviders,
                  }}
                  onChange={(key, value) => {
                    form.setFieldValue(
                      key,
                      value,
                    );
                  }}
                  label={forms.appointmentProvider}
                  labelWidth={78}
                  className={cx(classes.margin0, classes.fullWidth)}
                />
              );
            }}
          </Field>
        </div>
      </Grid>
    }
    {
      accountUuid && patientUUID && <Grid item>
        <Typography className={classes.secondaryTitle}>
          {forms.selectInsuranceProvider}
        </Typography>
        <div className={classes.selectAppointmentProvider}>
          <Field type='data' name='insurance'>
            {({ field, form }) => {
              return (
                <Select
                  filterValue={field.value}
                  initDataLoading={loadingClaimInfo}
                  disabled={!insuranceProviders || loadingClaimInfo}
                  required={false}
                  dataKey='insurance'
                  columnData={{
                    isFilterable: false,
                    options: insuranceProviders,
                  }}
                  onChange={(key, value) => {
                    form.setFieldValue(
                      key,
                      value,
                    );
                  }}
                  label={forms.insurance}
                  labelWidth={78}
                  className={cx(classes.margin0, classes.fullWidth)}
                />
              );
            }}
          </Field>
        </div>
      </Grid>
    }
  </>;
};

ProvidersSection.propTypes = {
  sendingDataError: PropTypes.string,
  initialValues: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    scheduleDeliverTime: PropTypes.string,
  }).isRequired,
};

ProvidersSection.defaultProps = {
  sendingDataError: null,
};

export default ProvidersSection;
