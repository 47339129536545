import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RoundedButton from 'components/Shared/RoundedButton';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3, 2, 3),
  },
  btn: {
    marginLeft: theme.spacing(2),
  },
}));

const ConfirmDialog = ({
                         open,
                         onClose,
                         title,
                         description,
                         SelectedBtnProps,
                         CurrentBtnProps,
                       }) => {
  const { t } = useTranslation(['btn']);
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='remove-title'>
      <DialogTitle id='remove-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <RoundedButton
          onClick={onClose}
          variant='contained'
          color='secondary'
          size='small'
        >
          {t('btn:cancel')}
        </RoundedButton>

        <div>
          {
            SelectedBtnProps && <RoundedButton
              variant='contained'
              color='primary'
              size='small'
              className={classes.btn}
              LoadingWrapperProps={{
                LoaderProps: {
                  disableShrink: true,
                },
              }}
              {...SelectedBtnProps}
            >
              {t('btn:selected')}
            </RoundedButton>
          }
          <RoundedButton
            variant='contained'
            color='primary'
            size='small'
            className={classes.btn}
            LoadingWrapperProps={{
              LoaderProps: {
                disableShrink: true,
              },
            }}
            {...CurrentBtnProps}
          >
            {CurrentBtnProps.customText ?? t('btn:current')}
          </RoundedButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  SelectedBtnProps: PropTypes.shape({
    onClick: PropTypes.func,
  }),
  CurrentBtnProps: PropTypes.shape({
    onClick: PropTypes.func,
  }),
};

ConfirmDialog.defaultProps = {
  description: undefined,
  SelectedBtnProps: undefined,
  CurrentBtnProps: undefined,
};

export default ConfirmDialog;
