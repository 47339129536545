/* eslint-disable */
import React from 'react';

const DashboardLogo = ({ fill, className }) => (
  <svg className={className} style={{ width: 15, height: 15, marginLeft: 2 }} viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Dash@3x</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard" transform="translate(-37.000000, -134.000000)"  fill={fill || "#F4F5F9"} fillRule="nonzero">
        <g id="Side" transform="translate(18.000000, 30.000000)">
          <g id="Tabs" transform="translate(0.000000, 83.000000)">
            <path d="M19,21 L23.5,21 L23.5,25.5 L19,25.5 L19,21 Z M25.76,21 L30.26,21 L30.26,25.5 L25.76,25.5 L25.76,21 Z M32.5,21 L37,21 L37,25.5 L32.5,25.5 L32.5,21 Z M19,27.76 L23.5,27.76 L23.5,32.26 L19,32.26 L19,27.76 Z M25.76,27.76 L30.26,27.76 L30.26,32.26 L25.76,32.26 L25.76,27.76 Z M32.5,27.76 L37,27.76 L37,32.26 L32.5,32.26 L32.5,27.76 Z M19,34.5 L23.5,34.5 L23.5,39 L19,39 L19,34.5 Z M25.76,34.5 L30.26,34.5 L30.26,39 L25.76,39 L25.76,34.5 Z M32.5,34.5 L37,34.5 L37,39 L32.5,39 L32.5,34.5 Z" id="Dash"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DashboardLogo;
