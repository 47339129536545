import { defaultRoutes, routes } from 'config/routes';

export const checkPermission = (accountPermissions, permission) => {
  if (!permission) {
    return true;
  }
  const biAccountPermissions = window.BigInt(accountPermissions);
  const biPermission = window.BigInt(permission);
  return (biAccountPermissions & biPermission) === biPermission;
};

const getAllowedRoute = (route, accountPermissions, disabledFeatures) => {
  const { permission } = route.additional;
  const isAllowed =
    checkPermission(accountPermissions, permission) &&
    !disabledFeatures.includes(route.additional.disabledId) && !route.parentRoute;
  return isAllowed ? route : null;
};

export const findAllowedRoute = (
  breadcrumb,
  accountPermissions,
  disabledFeatures,
) => {
  let currentRoute;
  const route = Object.values(routes).find(
    (item) => item.path === breadcrumb.path,
  );
  if (route) {
    currentRoute = getAllowedRoute(route, accountPermissions, disabledFeatures);
  }
  return currentRoute || (route && route.parentRoute) ||
    defaultRoutes.find((defaultRoute) =>
      getAllowedRoute(defaultRoute, accountPermissions, disabledFeatures));
};
