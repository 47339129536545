import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import RoundedButton from 'components/Shared/RoundedButton';
import { AppRouteContext } from 'contexts';
import { routes } from 'config/routes';

const useStyles = makeStyles((theme) => ({
  marginRight16: {
    marginRight: theme.spacing(2),
  },
}));

// set route: either previous bc if exist or root path
const BackButton = ({ className, rightMargin, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn']);
  const { breadcrumbs, setAppRoute } = useContext(AppRouteContext);
  const lastBc = breadcrumbs.length > 1 && breadcrumbs[breadcrumbs.length - 2];

  const handleClick = () => {
    setAppRoute(lastBc || { path: routes.root.path });
  };

  return (
    <RoundedButton
      color="primary"
      variant="outlined"
      size="small"
      className={cx(className, {
        [classes.marginRight16]: rightMargin,
      })}
      onClick={handleClick}
      {...props}
    >
      {t('btn:cancel')}
    </RoundedButton>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  rightMargin: PropTypes.bool,
};

BackButton.defaultProps = {
  className: undefined,
  rightMargin: true,
};

export default BackButton;
