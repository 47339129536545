import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    width: '100%',
    height: 14,
    backgroundColor: theme.colors.gray2,
  },
}));

const CellPlaceholder = () => {
  const classes = useStyles();

  return <div className={classes.placeholder} />;
};

export default CellPlaceholder;
