import i18next from 'i18next';
import React from 'react';
import FILTER_TYPES from '../../../../../../../Filters/constants';
import PriceComponent from './widgets/PriceComponent';
import CptCodeComponent from './widgets/CptCodeComponent';
import {
  FILTERING_RULES,
  SORTING_TYPES,
} from '../../../../../../../Table/helpers';

export const cptsColumns = (args) => [
  {
    width: 100,
    minWidth: 100,
    label: i18next.t('tables:cptCode'),
    dataKey: 'cptCode',
    flexGrow: 3,
    flexShrink: 3,
    columnData: {
      isFilterable: true,
      removeHidding: true,
      customContent: (props) => <CptCodeComponent {...props} {...args}/>,
      options: args.cptCodes,
      filterType: FILTER_TYPES.SELECT,
      hasSearch: true,
    },
  },
  {
    width: 100,
    label: i18next.t('tables:price'),
    dataKey: 'price',
    flexGrow: 3,
    flexShrink: 3,
    columnData: {
      isFilterable: true,
      removeHidding: true,
      filterType: FILTER_TYPES.INPUT_CURRENCY,
      customContent: (props) => <PriceComponent {...props} {...args}/>,
    },
  },
];

export const filterOptions = {
  price: {
    type: FILTERING_RULES.exact,
    postfix: '.00',
  },
  cptCode: {
    type: FILTERING_RULES.exact
  }
}

export const sortOptions = {
  price: {
    type: SORTING_TYPES.number,
  },
  cptCode: {
    type: SORTING_TYPES.string
  }
}
