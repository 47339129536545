import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import MenuRow from './../MenuRow';
import StyledLoader from '../Loader';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import useMetadataUpdate from '../../../../../hooks/useMetadataUpdate';
import { useMutation } from 'react-fetching-library';
import NoOptions from '../NoOptions';
import debounce from 'lodash/debounce';
import { getEndpoint } from '../../../../../helpers/endpoint';
import { SortDirection } from 'react-virtualized';

export const useStyles = makeStyles((theme) => ({
  resultsContainer: {
    overflow: 'auto',
    height: 448,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const AllOptions = ({
                      selectedOptions = [],
                      setSelectedOptions,
                      dataKey,
                      handleClose,
                      open,
                      tableName,
                      searchValue,
                      showOnlyAvailableOptions,
                      tableFilter,
                    }) => {
  const classes = useStyles();
  const { en: { titles } } = useSelector((state) => state.i18n);
  const { accountUuid: paramAccountUuid } = useParams();
  const { metadata: { currentAccount } } = useSelector((state) => state.user);
  const [optionsFromApi, setOptionsFromApi] = useState([]);
  const [optionsPreFilterFromApi, setOptionsPreFilterFromApi] = useState([]);
  const [shouldUpdateDataFlag, setShouldUpdateDataFlag] = useState({
    available: true,
    all: true,
  });
  const othersFieldsFilters = Object.keys(tableFilter)
    .filter(key => key !== dataKey)
    .reduce((obj, key) => {
      obj[key] = tableFilter[key];
      return obj;
    }, {});


  const getOptionsBySearch = ({ search, showOnlyAvailableOptions }) => ({
    method: 'GET',
    endpoint: getEndpoint(
      `/accounts/${paramAccountUuid || currentAccount.uuid}/lookups/${tableName}`,
      { fields: [dataKey] },
      {
        sortBy: dataKey,
        sortDirection: SortDirection.ASC,
      },
      { ...(search ? { [dataKey]: search } : {}), ...(showOnlyAvailableOptions ? othersFieldsFilters : {}) },
    ),
  });

  const { mutate, loading: dataLoading } = useMetadataUpdate(useMutation, [
    getOptionsBySearch,
  ]);

  const requestSearchResult = async (prop) => {
    if (prop) {
      const res = await mutate(prop);
      if (res) {
        const { payload, error } = res;
        if (!error && payload) {
          if (prop.showOnlyAvailableOptions) {
            setOptionsPreFilterFromApi(payload.values);
            setShouldUpdateDataFlag({
              ...shouldUpdateDataFlag,
              available: false,
            });
          } else {
            setOptionsFromApi(payload.values);
            setShouldUpdateDataFlag({
              ...shouldUpdateDataFlag,
              all: false,
            });
          }
        }
      }
    }
  };

  const requestSearchResultWithDelay = useCallback(
    debounce(requestSearchResult, 500),
    [],
  );

  useEffect(() => {
    if (shouldUpdateDataFlag[showOnlyAvailableOptions ? 'available' : 'all']) {
      requestSearchResult({
        search: searchValue,
        showOnlyAvailableOptions,
      });
    }
  }, [showOnlyAvailableOptions]);

  useEffect(() => {
    requestSearchResultWithDelay({
      search: searchValue,
      showOnlyAvailableOptions,
    });

    setShouldUpdateDataFlag({
      all: true,
      available: true,
    });
  }, [searchValue]);

  const onOptionCheckboxClick = (option, checked) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    }
  };

  useEffect(() => {
    if (!open) {
      setOptionsFromApi([]);
    } else {
      requestSearchResult({ search: '', showOnlyAvailableOptions: true });
    }
  }, [open]);


  const hasOptionsFromApi = showOnlyAvailableOptions
    ? !!(optionsPreFilterFromApi && optionsPreFilterFromApi?.length)
    : !!(optionsFromApi && optionsFromApi?.length);

  return <div className={classes.resultsContainer}>
    {!dataLoading && hasOptionsFromApi && <div className={classes.listContainer}>
      {
        (showOnlyAvailableOptions ? optionsPreFilterFromApi : optionsFromApi).map((option) => (
          <MenuRow
            option={option}
            selected={selectedOptions}
            onItemClick={onOptionCheckboxClick}
            applyCurrentFilter={(e) => handleClose(e, option)}
          />
        ))
      }
    </div>
    }
    {dataLoading && <StyledLoader />}
    {!dataLoading && !hasOptionsFromApi && <NoOptions title={titles.nothingFound} />}
  </div>;
};

export default AllOptions;
