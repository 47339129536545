import React from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import {
  makeStyles,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  FormHelperText,
  Checkbox,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    width: '100%',
  },
  menuItem: {
    paddingLeft: theme.spacing(1),
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionsLabel: {
    paddingLeft: theme.spacing(2),
    backgroundColor: 'white',
  },
  option: {
    display: 'flex',
    paddingLeft: theme.spacing(4),
  },
  menu: {
    maxHeight: 600,
  },
  label: {
    minWidth: 150,
    maxWidth: 200,
    whiteSpace: 'normal',
  },
}));

const FormikMultiSelectField = ({
  name,
  type,
  items,
  label,
  labelWidth,
  backendError,
  required,
  className,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Field type={type} name={name}>
      {({ field, form }) => {
        const frontendError = getIn(form.errors, field.name);
        const touched = getIn(form.touched, field.name);
        const helperText = frontendError || backendError || ' ';

        return (
          <FormControl
            variant="outlined"
            className={cx(classes.formControl, className)}
            error={(touched && !!frontendError) || !!backendError}
            required={required}
            disabled={disabled}
          >
            <InputLabel id="multiple-select-label" margin="dense">
              {label}
            </InputLabel>
            <Select
              value={field.value}
              labelId="multiple-select-label"
              id="multiple-select"
              multiple
              autoWidth
              margin="dense"
              input={<OutlinedInput labelWidth={labelWidth} />}
              onChange={(e) => {
                if (e.target.value[e.target.value.length - 1] !== undefined) {
                  form.setFieldValue(field.name, e.target.value);
                }
              }}
              renderValue={(selected) =>
                selected
                  .map((value) => {
                    let activeItem = {};
                    items.forEach((item) => {
                      if (item.options) {
                        item.options.forEach((option) => {
                          if (option.value === value) {
                            activeItem = option;
                          }
                        });
                      } else if (item.value === value) {
                        activeItem = item;
                      }
                    });

                    return activeItem.label;
                  })
                  .join(', ')
              }
              MenuProps={{
                getContentAnchorEl: null,
                className: classes.menu,
              }}
              {...rest}
            >
              {items.map((item) => {
                return !item.options ? (
                  <MenuItem
                    value={item.value}
                    key={item.value}
                    className={classes.menuItem}
                  >
                    <Checkbox checked={field.value.includes(item.value)} />
                    <ListItemText
                      primary={item.label}
                      classes={{
                        root: classes.label,
                      }}
                    />
                  </MenuItem>
                ) : (
                  item.options.map((option, index) => {
                    return index === 0 ? (
                      <ListSubheader
                        className={classes.optionsLabel}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {option.label}
                      </ListSubheader>
                    ) : (
                      <MenuItem
                        value={option.value}
                        key={option.value}
                        className={cx(classes.menuItem, classes.option)}
                      >
                        <Checkbox
                          checked={field.value.includes(option.value)}
                        />
                        <ListItemText
                          primary={option.label}
                          classes={{
                            root: classes.label,
                          }}
                        />
                      </MenuItem>
                    );
                  })
                );
              })}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

FormikMultiSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  labelWidth: PropTypes.number.isRequired,
  backendError: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

FormikMultiSelectField.defaultProps = {
  backendError: undefined,
  required: false,
  disabled: false,
  className: undefined,
};

export default FormikMultiSelectField;
