import React from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SupportButton from 'components/Shared/SupportButton';
import UnknownError from 'components/Shared/UnknownError';
import { ERROR_DURATION } from 'contexts/NotificationsProvider';
import {
  enqueueSnackbar,
  closeSnackbar, SNACKBAR_VARIANTS,
} from '../redux_store/reducer/reducers/notificationsReducer';

function showServerError(dispatch, message, options, duration) {
  return dispatch(
    enqueueSnackbar({
      message: message || <UnknownError options={options} />,
      options: {
        variant: SNACKBAR_VARIANTS.error,
        autoHideDuration: duration || ERROR_DURATION,
        action: options
          ? (key) => (
              <>
                <SupportButton options={options} />
                <IconButton
                  onClick={() => {
                    dispatch(closeSnackbar(key));
                  }}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </>
            )
          : undefined,
      },
    }),
  );
}

export default showServerError;
