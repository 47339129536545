import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import CptEndCheckbox from './widgets/CptEndCheckbox';
import CptsTablePlaceholder from './widgets/CptsTablePlaceholder';
import NoCptWidget from './widgets/NoCptWidget';
import { cptsColumns, filterOptions, sortOptions } from './constants';
import VirtualizedTable from '../../../../../../../Table/VirtualizedTable';
import { getFilteredData, getSortedData } from '../../../../../../../Table/helpers';

const Cpts = ({
                validateField,
                setErrors,
                backendErrors,
                cpts,
                setCpts,
                loadingCptCodes,
                cptCodes,
                resetFiltersTrigger,
                setResetFiltersTrigger,
                updateFieldValue,
              }) => {
  const { metadata: { currentAccountPermissions } } = useSelector((state) => state.user);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const fetchProps = {
    status,
    sort: sort,
    filter: filter,
    onSort: (sortObj) => {
      setSort(sortObj);
      setFilteredData(getSortedAndFilteredData(filter, sortObj));
    },
    onFilter: (key, value) => {
      const newFilters = { ...filter, [key]: value };
      setFilter(newFilters);
      setFilteredData(getSortedAndFilteredData(newFilters, sort));
    },
  };

  const getSortedAndFilteredData = (newFilters, sort) => {
    return getSortedData(getFilteredData(cpts, newFilters, filterOptions), sort, sortOptions);
  };

  useEffect(() => {
    setFilteredData(getSortedAndFilteredData(filter, sort));
  }, [cpts]);

  useEffect(() => {
    if (resetFiltersTrigger) {
      setTimeout(() => {
        setFilteredData(cpts);
        setFilter({});
        setResetFiltersTrigger(false);
      }, 300);
    }
  }, [resetFiltersTrigger]);

  const columns = useMemo(
    () => cptsColumns({
      cptCodes: cptCodes,
      updateFieldValue: updateFieldValue,
      validateField: validateField,
      backendErrors: backendErrors
        && backendErrors.ispConfiguration
        && backendErrors.ispConfiguration.services || {},
    }),
    [cptCodes, cpts, backendErrors],
  );

  return (
    !cpts?.length
      ? <NoCptWidget />
      : <Field type={'text'} name={'ispConfiguration.services'}>
        {({ form }) => {
          return <VirtualizedTable
            tableId={'cpts_table_id'}
            fakeRows={[]}
            rowCount={filteredData.length || 0}
            rows={filteredData || []}
            columns={columns}
            FetchProps={fetchProps}
            isEditableMode={true}
            hideContextMenu={true}
            onDataLoading={loadingCptCodes}
            currentAccountPermissions={currentAccountPermissions}
            placeholder={<CptsTablePlaceholder />}
            endCheckboxWidth={60}
            endCheckboxFlex={0}
            endCheckboxRenderer={(cellProps) => {
              return <CptEndCheckbox
                form={form}
                backendErrors={backendErrors}
                cpts={cpts}
                setCpts={setCpts}
                setErrors={setErrors}
                validateField={validateField}
                cellProps={cellProps}
              />;
            }}
          />;
        }}
      </Field>
  );
};


export default Cpts;
