import { ButtonGroup, fade, IconButton, makeStyles } from '@material-ui/core';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import React from 'react';
import { CHART_TYPES } from './constants';
import theme from '../../config/theme';

const useStyles = makeStyles(() => ({
  chartBoxButtons: {
    position: 'absolute',
    right: 28,
    height: 32,
    top: 28,
    zIndex: 1,
    border: `1px solid ${fade(theme.palette.common.black, 0.12)}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

const ChartButtons = ({ chartType, setCharts, charts, dataKey }) => {
  const classes = useStyles();

  const updateCharts = (chartType) => {
    let newCharts = charts.map((chart) => {
      if (chart.key === dataKey) {
        return {
          key: dataKey,
          chartType: chartType,
        };
      } else {
        return chart;
      }
    });
    setCharts(newCharts);
  };

  return (
    <ButtonGroup
      className={classes.chartBoxButtons}
      color="primary"
      aria-label="outlined primary button group"
    >
      {Object.keys(CHART_TYPES).map((_chartType) => {
        const type = _chartType.toLowerCase();
        let icon;
        switch (type) {
          case CHART_TYPES.BAR:
            icon = <BarChartIcon fontSize="inherit" />;
            break;
          case CHART_TYPES.PIE:
            icon = <PieChartIcon fontSize="inherit" />;
            break;
          case CHART_TYPES.LINE:
            icon = <ShowChartIcon fontSize="inherit" />;
            break;
        }

        return (
          <IconButton
            aria-label="delete"
            className={classes.margin}
            size="small"
            color={chartType === type ? 'primary' : 'secondary'}
            onClick={() => updateCharts(type)}
          >
            {icon}
          </IconButton>
        );
      })}
    </ButtonGroup>
  );
};

export default ChartButtons;
