import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { SortDirection } from 'react-virtualized';
import { get, isEmpty } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import useFetchList from 'hooks/useFetchList';
import { AppRouteContext } from 'contexts';
import showServerError from 'helpers/showError';
import {
  getLocalizedDateIgnoreTime,
  getLocalizedLabel,
} from 'helpers/localize';
import claimsPlaceholderImage from 'assets/placeholders/orders.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ActivePatientContext } from '../../../contexts/ActivePatientProvider';
import { NULL_QUERY_DELAY } from '../../Shared/constants';
import RefreshTableButton from '../../Shared/RefreshTableButton';
import PatientActions from '../../Patients/PatientActions';
import PatientAdditionalNode from '../../Patients/PatientAdditionalNode';
import PatientInfoBlock from '../../Patients/PatientInfoBlock';
import {
  CLAIMS_FOR_PATIENT_FIELDS,
  GET_CLAIMS_COLUMNS,
  FIELDS,
  ENCOUNTER_CLAIMS_STATUSES_OPTIONS,
  BILLING_CLAIMS_STATUSES_OPTIONS,
  FAKE_CLAIM,
  CLAIM_STATUS_SELECTOR_OPTIONS, CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS,
} from '../constants';
import { routes } from '../../../config/routes';
import OutstandingClaimReportPdfModal
  from './widgets/OutstandingClaimReportPdfModal/OutstandingClaimReportPdfModal';
import { checkPermission } from '../../../helpers/checkPermissions';
import permissions from '../../../config/permissions';
import getFakeDataList, { FAKE_TITLE_DATA } from '../../Shared/FakeDataGenerator';
import DownloadButton from './widgets/DownloadButton';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import { CUSTOM_TAGS_OPTIONS } from '../../Shared/CustomTags';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/file_download_black.svg';
import { useMutation } from 'react-fetching-library';
import { downloadFile } from '../../Billing/helpers';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import TableChips from '../../Table/TableChip';

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'hidden',
  },
  printBtn: {
    width: 49,
    borderColor: theme.colors.lightBlue,
    marginRight: theme.spacing(2),
  },
  printBtnLabel: {
    width: 24,
    height: 24,
  },
}));

/*
  could be rendered in 2 cases:
  - all account claims
  - claims for a special user (patientUuid in URL params)
*/

const ClaimsReportingList = () => {
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);
  const { setAppRoute } = useContext(AppRouteContext);
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const {
    metadata: {
      currentAccount,
      currentAccountPermissions,
      childAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const [modifiedClaims, setModifiedClaims] = useState();
  const [filterStyle, setFilterStyle] = useState(false);
  const { patientUuid, patientInfo } = useContext(ActivePatientContext);
  const [openDownloadPdfModal, toggleDownloadPdfModal] = useState(false);
  const [tableFilter, setTableFilter] = useState();
  const [tableSort, setTableSort] = useState({
    sortBy: 'claimGeneratedTimestamp',
    sortDirection: SortDirection.DESC,
  });
  const hasLoadFilesPermission = checkPermission(
    currentAccountPermissions,
    permissions.SURVEYS_GET,
  );

  const baseEndpoint = `/accounts/${
    paramAccountUuid || currentAccount.uuid
  }/claims`;
  const {
    items,
    totalAccountsCount,
    totalCount,
    completionRate,
    aggregations,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint,
    baseParams: {
      fields: (isPatientPage ? CLAIMS_FOR_PATIENT_FIELDS : FIELDS).join(),
      q: isPatientPage ? `patientUUID:${paramsPatientUuid}` : undefined,
    },
    baseSort: {
      sortBy: 'claimGeneratedTimestamp',
      sortDirection: SortDirection.DESC,
    },
  });

  const { filter, sort } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);

  useEffect(() => {
    if (sort) {
      setTableSort(sort);
    }
    if (filter) {
      setTableFilter(filter);
    }
  }, [sort, filter]);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  useEffect(() => {
    if (items) {
      setModifiedClaims(
        items.map((claim) => ({
          ...claim,
          cptCount: claim.cptCount ? claim.cptCount.toString() : '-',
          cptPaid: claim.cptPaid ? claim.cptPaid.toString() : '-',
          dos: claim.dos
            ? getLocalizedDateIgnoreTime(claim.dos, 'UTC')
            : '-',
          patientDateOfBirth: claim.patientDateOfBirth
            ? getLocalizedDateIgnoreTime(claim.patientDateOfBirth, 'UTC')
            : '-',
          appointmentTimestamp: claim.appointmentTimestamp
            ? getLocalizedDateIgnoreTime(claim.appointmentTimestamp, 'UTC')
            : '-',
          accountCustomIdentifier: claim.accountCustomIdentifier
            ? claim.accountCustomIdentifier
            : 'N/A',
          entityOrderTimestamp: claim.entityOrderTimestamp
            ? getLocalizedDateIgnoreTime(claim.entityOrderTimestamp, 'UTC')
            : '-',
          paymentDate: claim.paymentDate
            ? getLocalizedDateIgnoreTime(claim.paymentDate, 'UTC')
            : '-',
          claimGeneratedTimestamp: claim.claimGeneratedTimestamp ?
            getLocalizedDateIgnoreTime(claim.claimGeneratedTimestamp, 'UTC')
            : '-',
          lastModifiedTimestamp: claim.lastModifiedTimestamp
            ? getLocalizedDateIgnoreTime(claim.lastModifiedTimestamp, 'UTC')
            : '-',
          entityCompletedTimestamp: claim.entityCompletedTimestamp
            ? getLocalizedDateIgnoreTime(
              claim.entityCompletedTimestamp, 'UTC',
            ) : '-',
          patientMrnNumber: !claim.patientMrnNumber ? '-' : claim.patientMrnNumber,
          moreTitle: isPatientPage
            ? `${claim.surveyName}`
            : `${claim.patientFirstName} ${claim.patientLastName}`,
          resendInvitationAction: !claim.invitationResendAllowed,
          encounterStatus: claim.encounterStatus ? getLocalizedLabel(
            ENCOUNTER_CLAIMS_STATUSES_OPTIONS,
            claim.encounterStatus,
          ) : '-',
          billingStatus: claim.billingStatus ? getLocalizedLabel(
            BILLING_CLAIMS_STATUSES_OPTIONS,
            claim.billingStatus,
          ) : '-',
          claimStatus: claim?.claimStatus ? getLocalizedLabel(
            CLAIM_STATUS_SELECTOR_OPTIONS,
            claim.claimStatus,
          ) : '-',
          entityType: claim?.entityType ? getLocalizedLabel(
            CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS,
            claim.entityType,
          ) : '-',
          accountCustomTags: claim.accountCustomTags ? claim.accountCustomTags.map(item => getLocalizedLabel(
            CUSTOM_TAGS_OPTIONS,
            item,
          )) : [],
        })),
      );
    }
  }, [items]);

  const handleRowClick = (claim) => {
    const accountUuid = claim.accountUUID;
    const claimUuid = claim.uuid;

    setAppRoute({
      path: routes.editClaim.path,
      title: `${t('titles:editClaim')}`,
      params: {
        accountUuid,
        claimUuid,
      },
    });
  };

  const reloadClaimsReportingList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');
  const titleText = isPatientPage
    ? (patientInfo && `${patientLastName}, ${patientFirstName}`) ||
    FAKE_TITLE_DATA
    : t('titles:claimsReporting');

  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );

  const canGetClaim = checkPermission(
    isDistributionSponsor ? childAccountPermissions : currentAccountPermissions,
    isDistributionSponsor
      ? permissions.CLAIM_GET
      : permissions.CLAIM_GET_LIMITED,
  );

  const isTableDataLoading = dataLoading || !modifiedClaims;
  const FAKE_CLAIM_LIST = getFakeDataList(FAKE_CLAIM);

  const importFile = (claim) => ({
    method: 'GET',
    endpoint: `/accounts/${claim.accountUUID}/surveys/${claim.entityUUID}/program-report/pdf`,
    responseType: 'blob',
  });

  const { mutate: importMutate } = useMutation(importFile);

  const downloadProgramPDF = async (e, claim) => {
    const { payload, error, status } = await importMutate(claim);
    if (!error) {
      downloadFile(
        new Blob([payload]),
        `${claim.surveyName} - ${claim.patientLastName} ${claim.patientFirstName} ${claim.entityOrderTimestamp}.pdf`,
      );
      dispatch(
        enqueueSnackbar({
          message: t('notifications:pdfSucceededSaved'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    } else {
      switch (status) {
        case 422: {
          const obj = JSON.parse(await payload.text());
          Object.keys(obj.validationErrors).forEach(key =>
            showServerError(dispatch, obj.validationErrors[key]));
          break;
        }
        default: {
          showServerError(dispatch, t('errors:unknownError'));
          break;
        }
      }
    }
  };

  const checkIsDownloadProgramPDFDisabled = (claimUuid) => {
    if (dataLoading || !modifiedClaims || !hasLoadFilesPermission) {
      return true;
    }
    let isDisabled = true;

    modifiedClaims.forEach((claim) => {
      if (claim.uuid === claimUuid && claim.entityType === 'Program') {
        isDisabled = false;
      }
    });

    return isDisabled;
  };

  return (
    <>
      <OutstandingClaimReportPdfModal
        openDownloadPdfModal={openDownloadPdfModal}
        toggleDownloadPdfModal={toggleDownloadPdfModal}
      />
      <ContentWrapper
        titleText={titleText}
        className={classes.paper}
        hasTopPaddingForSmallScreen={isPatientPage}
        underTitleText={
          isPatientPage ? (
            <PatientInfoBlock
              isForInitLoading={
                (!items && patientUuid !== paramsPatientUuid) || !patientInfo
              }
            />
          ) : (
            ''
          )
        }
        count={isPatientPage ? null : totalCount || FAKE_TITLE_DATA}
        additionChip={
          ((completionRate || completionRate === 0) && (completionRate * 100).toFixed(1)) ||
          FAKE_TITLE_DATA
        }
        countFiltered={filterStyle}
        actions={
          <>
            {isPatientPage ? (
              <PatientActions reloadList={reloadClaimsReportingList} />
            ) : (
              <>
                <RefreshTableButton
                  reloadData={reloadClaimsReportingList}
                  isLoading={dataLoading}
                />
                <DownloadButton
                  toggleDownloadPdfModal={() => toggleDownloadPdfModal(true)}
                  tableFilter={tableFilter}
                  tableSort={tableSort}
                  selectorTypeButton={false}
                />
              </>
            )}
          </>
        }
        additionalNode={
          <div style={{ display: 'flex', flexdirection: 'column' }}>
            {
              isPatientPage
                ? <PatientAdditionalNode
                  activeItemNumber={4}
                  modifiedData={modifiedClaims}
                  totalCount={totalCount}
                  reloadData={reloadClaimsReportingList}
                  dataLoading={dataLoading}
                  historyTitle={t('tables:claimsHistory')}
                />
                : <TableChips aggregations={aggregations} />
            }
          </div>
        }
      >
        <VirtualizedTable
          tableKey={paramAccountUuid ? TABLE_KEYS.claimsDS : TABLE_KEYS.claims}
          hasColumnsSelect={true}
          fakeRows={FAKE_CLAIM_LIST}
          rowCount={totalCount}
          rows={modifiedClaims}
          columns={GET_CLAIMS_COLUMNS(
            isPatientPage,
            paramAccountUuid,
            totalAccountsCount,
          )}
          onDataLoading={isTableDataLoading}
          onRowClick={canGetClaim ? handleRowClick : null}
          FetchProps={{ ...restFetchProps }}
          currentAccountPermissions={currentAccountPermissions}
          actionProps={{
            actions: [
              {
                key: 'downloadProgram',
                icon: DownloadIcon,
                onActionClick: downloadProgramPDF,
                children: t('titles:downloadProgram'),
                isDisabled: checkIsDownloadProgramPDFDisabled,
              },
            ],
          }}
          placeholder={
            <TablePlaceholder
              image={claimsPlaceholderImage}
              itemsNotFoundText={t('placeholders:noItemsFound', {
                item: t('titles:claims'),
              })}
              message={t('placeholders:reviseFiltersOrCreateYourFirstItem', {
                item: t('titles:claims'),
              })}
            />
          }
        />
      </ContentWrapper>
    </>
  );
};

export default ClaimsReportingList;
