import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import FormikTextField from 'components/FormikFields/FormikTextField';
import { FormattedPhone } from 'components/Shared/FormattedPhone';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  dataContainer: {
    maxWidth: 800,
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
  },
}));

const BasicInfoSection = ({
                            tradeName,
                            businessName,
                            backendErrors,
                            isEditMode,
                            isCurrentAccountEditing,
                            isDistributionSponsor,
                          }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms']);
  const [tradeNameCheckBox, setTradeNameCheckBox] = useState(false);

  useEffect(() => {
    setTradeNameCheckBox(tradeName === businessName);
  }, []);

  return (
    <>
      <Typography className={classes.title}>
        {t('forms:basicInfo')}:
      </Typography>

      <Field name='isDistributionSponsor' type='checkbox'>
        {({ field, form }) => (
          <FormControlLabel
            control={
              <Checkbox
                name={field.name}
                checked={field.value}
                color='primary'
                onChange={(e, checked) => {
                  form.setFieldValue(field.name, checked);
                }}
                disabled={isEditMode}
              />
            }
            className={classes.checkbox}
            label={t('forms:distributionSponsor')}
          />
        )}
      </Field>

      <Grid container spacing={3} className={classes.dataContainer}>
        <Grid item xs={9}>
          <FormikTextField
            type='text'
            name='businessName'
            label={t('forms:businessName')}
            backendError={backendErrors.businessName}
            required
            duplicateValueToFieldName={tradeNameCheckBox ? 'tradeName' : null}
            disabled={!isDistributionSponsor}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.dataContainer}>
        <Grid
          item
          xs={12}
          container
          direction='row'
          justify='space-between'
          spacing={1}
        >
          <Grid item xs={9}>
            <FormikTextField
              type='text'
              name='tradeName'
              label={t('forms:tradeName')}
              backendError={backendErrors.tradeName}
              required
              disabled={tradeNameCheckBox}
            />
          </Grid>
          <Grid item xs={3}>
            <Field name='sameAsBusinessName' type='checkbox'>
              {({ form }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tradeNameCheckBox}
                      color='primary'
                      onChange={(e, checked) => {
                        setTradeNameCheckBox(checked);
                        form.setFieldValue('tradeName', businessName);
                      }}
                    />
                  }
                  className={classes.checkbox}
                  label={t('forms:sameAsBusinessName')}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.dataContainer}>
        <Grid item xs={9}>
          <FormikTextField
            type='text'
            name='contactName'
            label={t('forms:contactName')}
            backendError={backendErrors.contactName}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.dataContainer}>
        <Grid item xs={3}>
          <FormikTextField
            type='tel'
            name='phone'
            label={t('forms:phone')}
            backendError={backendErrors.phone}
            InputProps={{
              inputComponent: FormattedPhone,
            }}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <FormikTextField
            type='tel'
            name='fax'
            label={t('forms:fax')}
            backendError={backendErrors.fax}
            InputProps={{
              inputComponent: FormattedPhone,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.dataContainer}>
        <Grid item xs={9}>
          <FormikTextField
            type='text'
            name='headProviderName'
            label={t('forms:headProviderName')}
            backendError={backendErrors.headProviderName}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.dataContainer}>
        <Grid item xs={9}>
          <FormikTextField
            type='text'
            name='customIdentifier'
            label={t('forms:customId')}
            backendError={backendErrors.customIdentifier}
            disabled={isCurrentAccountEditing}
          />
        </Grid>
      </Grid>
    </>
  );
};

BasicInfoSection.propTypes = {
  tradeName: PropTypes.string,
  businessName: PropTypes.string,
  isCurrentAccountEditing: PropTypes.bool,
  isDistributionSponsor: PropTypes.bool,
};

BasicInfoSection.defaultProps = {
  tradeName: '',
  businessName: '',
  isCurrentAccountEditing: false,
  isDistributionSponsor: false,
};

export default BasicInfoSection;
