import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import { TextField, makeStyles } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  textField: {
    maxWidth: 600,
  },
  zIndex: {
    zIndex: 0,
  },
}));

const FormikTextField = ({
                           type,
                           name,
                           backendError,
                           className,
                           defaultHelperText,
                           InputProps,
                           onFocus,
                           onBlur,
                           duplicateValueToFieldName,
                           fullWidth,
                           hideErrorAres,
                           hideErrorDuringEditing,
                           variant,
                           disableUnderline,
                           activitiesErrorStyle,
                           ...restProps
                         }) => {
  const classes = useStyles();
  const [readOnly, setReadOnly] = useState(true); // try to disable auto-filling
  const [value, setValue] = useState('');

  return (
    <Field type={type} name={name}>
      {({ field, form }) => {
        const frontendError = getIn(form.errors, field.name);
        const touched = hideErrorDuringEditing ? false : getIn(form.touched, field.name);
        setValue(field.value);

        return (
          <TextField
            name={field.name}
            value={value}
            type={type}
            error={(!hideErrorDuringEditing && touched && !!frontendError) || !!backendError}
            helperText={(!hideErrorDuringEditing && frontendError) || backendError || defaultHelperText}
            FormHelperTextProps={{
              style: {
                display: hideErrorAres && !((touched && !!frontendError) || !!backendError) ? 'none' : null,
                ...(activitiesErrorStyle || {}),
              },
            }}
            className={cx(
              !fullWidth && classes.textField,
              classes.zIndex,
              className,
            )}
            fullWidth
            classes={{ label: classes.label }}
            margin='dense'
            variant={variant || 'outlined'}
            onChange={(e) => {
              setValue(e.currentTarget.value);
              field.onChange(e);
              if (duplicateValueToFieldName) {
                form.setFieldValue(
                  duplicateValueToFieldName,
                  e.currentTarget.value,
                );
              }
            }}
            onBlur={onBlur || field.onBlur}
            onFocus={() => {
              setReadOnly(false);
              if (onFocus) {
                onFocus();
              }
            }}
            autoComplete='off'
            InputProps={{
              readOnly,
              ...InputProps,
            }}
            {...restProps}
          />
        );
      }}
    </Field>
  );
};

FormikTextField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  backendError: PropTypes.string,
  className: PropTypes.string,
  defaultHelperText: PropTypes.string,
  InputProps: PropTypes.shape({
    readOnly: PropTypes.bool,
  }),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  duplicateValueToFieldName: PropTypes.string,
};

FormikTextField.defaultProps = {
  fullWidth: true,
  backendError: undefined,
  className: undefined,
  defaultHelperText: ' ',
  InputProps: undefined,
  onFocus: undefined,
  onBlur: undefined,
  duplicateValueToFieldName: undefined,
};

export default FormikTextField;
