import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RoundedButton from 'components/Shared/RoundedButton';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3, 2, 3),
  },
}));

const RemoveDialog = ({
  open,
  onClose,
  onSubmit,
  title,
  description,
  isLoading,
  isSuccess,
  isFailed,
}) => {
  const { t } = useTranslation(['btn']);
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="remove-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="remove-title">{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions classes={{ root: classes.actions }}>
        <RoundedButton
          onClick={onClose}
          variant="contained"
          color="secondary"
          size="small"
        >
          {t('btn:cancel')}
        </RoundedButton>
        <RoundedButton
          onClick={onSubmit}
          variant="contained"
          color="primary"
          size="small"
          isLoading={isLoading}
          isSuccess={isSuccess}
          isFailed={isFailed}
          disabled={isLoading}
        >
          {t('btn:remove')}
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

RemoveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
};

RemoveDialog.defaultProps = {
  description: undefined,
  isLoading: false,
  isSuccess: false,
  isFailed: false,
};

export default RemoveDialog;
