import React  from 'react';
import assessmentsPlaceholderImage from 'assets/placeholders/orders.svg';
import TablePlaceholder from '../../../../../../../../Shared/TablePlaceholder';
import { useSelector } from 'react-redux';
import { getTransitionWithVariable } from '../../../../../../../../../redux_store/reducer/reducers/i18nReducer';

const CptsTablePlaceholder = () => {
  const { en: { titles, placeholders } } = useSelector((state) => state.i18n);
  return <TablePlaceholder
    image={assessmentsPlaceholderImage}
    itemsNotFoundText={getTransitionWithVariable(placeholders.noItemsFound, {
      item: titles.cpt,
    })}
    message={getTransitionWithVariable(placeholders.reviseFiltersOrCreateYourFirstItem, {
      item: titles.cpt,
    })}
  />;
};

export default CptsTablePlaceholder;
