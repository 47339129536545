import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../../client';

export const checkIsLogin = () => {
  return localStorage.getItem('isLogin');
};

export const setIsLogin = (val) => {
  localStorage.setItem('isLogin', val);
};

export const login = createAsyncThunk('user/login', (...args) =>
  client.post('/auth/tokens', ...args),
);

export const logout = createAsyncThunk('user/logout', (...args) =>
  client.delete('/auth/tokens', ...args),
);

export const getUser = createAsyncThunk('user/getUser', (...args) =>
  client.get('/user', ...args),
);

export const getMetadata = createAsyncThunk('user/getMetadata', (...args) =>
  client.get('/auth/token/metadata', ...args),
);

export const updateUser = createAsyncThunk('user/updateUser', (...args) =>
  client.post('/user', ...args),
);
