import i18next from 'i18next';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS, PATIENT_CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';
import permissions from '../../config/permissions';

const genderItems = [
  { label: i18next.t('forms:female'), value: 'female' },
  {
    label: i18next.t('forms:male'),
    value: 'male',
  },
];

export default genderItems;

export const FAKE_PATIENT = {
  accountCustomTags: [],
  uuid: '',
  accountUUID: '',
  accountBusinessName: '',
  accountCustomIdentifier: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  moreTitle: '',
};

export const PATIENT_LANGUAGE = {
  ENGLISH: 'english',
  SPANISH: 'spanish',
  UNKNOWN: 'unknown',
};

export const PATIENT_LANGUAGE_SELECTOR_OPTIONS = [
  {
    label: 'English',
    value: PATIENT_LANGUAGE.ENGLISH,
  },
  {
    label: 'Spanish',
    value: PATIENT_LANGUAGE.SPANISH,
  },
  {
    label: 'Unknown',
    value: PATIENT_LANGUAGE.UNKNOWN,
  },
];

export const GET_PATIENTS_COLUMNS = (
  accountUuid,
  isDistributionSponsor,
) => [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:accountCustomTags'),
    dataKey: 'accountCustomTags',
    hasBubble: true,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(accountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(accountUuid),
    flexGrow: 3,
    requiredColumn: true,
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'customTags',
    hasBubble: true,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: PATIENT_CUSTOM_TAGS_OPTIONS,
    },
    hidden: !isDistributionSponsor,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(accountUuid),
    requiredColumn: true,
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'mrnNumber',
    columnData: {
      isFilterable: true,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:firstName'),
    dataKey: 'firstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
  },
  {
    width: 200,
    label: i18next.t('tables:lastName'),
    dataKey: 'lastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
  },
  {
    width: 150,
    label: i18next.t('tables:dateOfBirth'),
    dataKey: 'dateOfBirth',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
]
