import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  makeStyles,
  Grid,
} from '@material-ui/core';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import FormikSelectField from '../../../../../FormikFields/FormikSelectField';
import { CLAIM_REPORT_TYPES } from '../../../../constants';
import CptsModal from './CptModal/CptsModal';
import HelperText from '../../../../../Shared/HelperText';
import RoundedButton from '../../../../../Shared/RoundedButton';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  fullWidth: {
    width: '100%',
    marginTop: 0,
  },
  statesSelect: {
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    padding: '0 0 0 12px !important',
  },
  contentContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: 12,
  },
  addNewButton: {
    boxShadow: '1px 4px 15px -5px rgba(0, 0, 0, 0.97)',
  },
  addNewButtonWithError: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      opacity: 0.8,
    },
  },
  addNewButtonIcon: {
    color: 'white',
  },
  activitiesError: {
    color: theme.palette.error.main,
    height: 20,
    marginLeft: 14,
    marginTop: 8,
  },
  services: {
    transition: 'display 0.6s linear',
    display: 'none',
  },
  showServices: {
    opacity: 1,
    transition: 'opacity 0.6s linear',
    display: 'contents',
  },
}));

const ClaimReportTypeSection = ({
                                  values,
                                  backendErrors,
                                  setErrors,
                                  validateField,
                                }) => {
  const classes = useStyles();
  const { en: { forms } } = useSelector((state) => state.i18n);
  const [openCptsModal, toggleCptsModal] = useState(false);

  const errors = useMemo(
    () => (backendErrors.ispConfiguration
      && backendErrors.ispConfiguration.services
      && Array.isArray(backendErrors.ispConfiguration.services)
      && backendErrors.ispConfiguration.services.filter(services => services && Object.keys(services).length).length
    ),
    [backendErrors],
  );

  return (
    <div style={{ width: '100%' }}>
      <Typography className={classes.title}>
        {forms.claimReportType}:
      </Typography>

      <Grid container spacing={3} className={classes.contentContainer}>
        <Grid item xs={4}>
          <FormikSelectField
            name={'ispConfiguration.claimReportType'}
            label={forms.claimReportType}
            type='text'
            hideErrorAreaIfNoErrors
            menuItems={CLAIM_REPORT_TYPES}
            className={cx(classes.statesSelect, classes.fullWidth)}
            backendError={backendErrors['ispConfiguration'] || backendErrors['ispConfiguration'] && backendErrors['ispConfiguration']['claimReportType']}
            disabled={false}
          />
        </Grid>
        <div
          className={cx(classes.services, {
            [classes.showServices]: values.ispConfiguration && values.ispConfiguration?.claimReportType,
          })}
        >
          <Grid item xs={4} className={classes.buttonContainer}>
            <RoundedButton
              variant='contained'
              color={errors || backendErrors['ispConfiguration'] ? 'error' : 'primary'}
              size='small'
              className={cx(classes.addNewButton, {
                [classes.addNewButtonWithError]: errors || backendErrors['ispConfiguration'],
              })}
              onClick={() => toggleCptsModal(true)}
            >
              {forms.manageCpts}
            </RoundedButton>
          </Grid>
          {
            errors && <HelperText className={cx(classes.activitiesError)}>
              {'Please check CPTs table. There are some errors.'}
            </HelperText>
          }
          {
            typeof backendErrors['ispConfiguration'] === 'string' &&
            <HelperText className={cx(classes.activitiesError)}>
              {backendErrors['ispConfiguration']}
            </HelperText>
          }

        </div>
      </Grid>

      <CptsModal
        values={values}
        toggleCptsModal={toggleCptsModal}
        openCptsModal={openCptsModal}
        backendErrors={backendErrors}
        validateField={validateField}
        setErrors={setErrors}
      />
    </div>
  );
};

ClaimReportTypeSection.propTypes = {
  values: PropTypes.shape({}),
  backendErrors: PropTypes.shape({}),
};

ClaimReportTypeSection.defaultProps = {
  values: {},
  backendErrors: {},
};

export default ClaimReportTypeSection;
