import { Box, ListItemIcon, makeStyles, Popover } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinesEllipsis from 'react-lines-ellipsis';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { ReactComponent as NoteIcon } from '../../../assets/icons/note.svg';
import { Badge, BADGES_COLOR_TYPES } from '../Badge';
import { ASSESSMENT_FILTER_LIST } from '../../Assessments/constants';
import assessmentsPlaceholderImage from '../../../assets/placeholders/orders.svg';
import TablePlaceholder from '../TablePlaceholder';
import Loader from '../Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    minHeight: 300,
    maxHeight: 300,
    marginBottom: 20,
  },
  popover: {
    width: 332,
  },
  popoverText: {
    padding: 20,
  },
  filterContainer: {
    paddingRight: 12,
  },
  filtersContainer: {
    display: 'flex',
    paddingBottom: 12,
  },
  paper: {
    width: '100%',
    maxHeight: 'fit-content',
    display: 'flex',
  },
  createButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
  },
  listItem: {
    paddingLeft: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  listItemIcon: {
    minWidth: 45,
  },
  listItemTitle: {
    color: '#00A8FF',
    fontWeight: '500 !important',
    width: 150,
    padding: 15,
  },
  listItemDescription: {
    padding: 15,
    width: '50%',
  },
  readMoreButton: {
    padding: 0,
    border: 'none',
    textTransform: 'lowercase',
    letterSpacing: 0,

    '&:hover': {
      border: 'none',
    },
  },
  badge: {
    padding: `0 ${theme.spacing(1.5)}px`,
    fontSize: '12px',
  },
  loaderStyle: {
    margin: '40px 0'
  }
}));

const FilteredItemList = ({
  items,
  handleClose,
  selectItem,
  activeItem,
  loading,
  iconList,
  dataList,
  setFilteredDataList,
  notFoundTitle,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'titles']);
  const [activeFilter, setActiveFilter] = useState('all');
  const { disabledFeatures } = useSelector((state) => state.appSettings);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverText, setPopoverText] = React.useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;

  const handleClick = (event, text) => {
    setAnchorEl(event.currentTarget);
    setPopoverText(text);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const onFilterClick = (id) => {
    const isActiveFilter = activeFilter === id;

    if (isActiveFilter) {
      setActiveFilter('all');
    } else {
      setActiveFilter(id);
    }
  };

  const filterData = () => {
    if (activeFilter === 'all') {
      setFilteredDataList(dataList);
      return;
    }
    const newFilteredResourceList = dataList.filter((value) => {
      let hasCommonKeys = false;

      value.categories.forEach((category) => {
        if (activeFilter === category) {
          hasCommonKeys = true;
        }
      });

      return hasCommonKeys;
    });

    setFilteredDataList(newFilteredResourceList);
  };

  useEffect(() => {
    filterData();
  }, [activeFilter]);

  return (
    <>
      <Popover
        classes={{
          root: classes.popover,
          paper: classes.paper,
        }}
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={cx(classes.popoverText)}>{popoverText}</div>
      </Popover>
      {
        !items.length && loading && <Loader
          fullHeight
          className={classes.loaderStyle}
        />
      }
      {
        !items.length && !loading && <div>
          <TablePlaceholder
            image={assessmentsPlaceholderImage}
            itemsNotFoundText={t('placeholders:noItemsFound', {
              item: notFoundTitle,
            })}
            message={t('placeholders:noFilteredItemsFound', {
              item: notFoundTitle,
            })}
          />
        </div>
      }
      {
        !!items.length && <>
          <div className={classes.filtersContainer}>
            {ASSESSMENT_FILTER_LIST.map(({ title, id }) => {
              const isActiveFilter = activeFilter === id;
              return <div className={classes.filterContainer}>
                <Badge
                  customStyle={classes.badge}
                  onClick={() => {
                    onFilterClick(id);
                  }}
                  label={`${isActiveFilter ? '✓ ' : ''}${title}`}
                  colorType={
                    isActiveFilter
                      ? BADGES_COLOR_TYPES.purple
                      : BADGES_COLOR_TYPES.lightGrey
                  }
                />
              </div>;
            })}
          </div>
          <List className={classes.root}>
            {items.map((itemProps) => {
              const { title, description, disabledId, type } = itemProps;

              const isCustomIcon =
                !!type && !!iconList && iconList.hasOwnProperty(type);

              const icon = isCustomIcon ? iconList[type] : <NoteIcon />;
              return (
                (disabledId == null || !disabledFeatures.includes(disabledId)) && (
                  <ListItem
                    key={title}
                    classes={{
                      root: classes.listItem,
                    }}
                  >
                    <ListItemIcon
                      classes={{
                        root: classes.listItemIcon,
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={title}
                      classes={{
                        root: classes.listItemTitle,
                      }}
                    />
                    <div className={classes.listItemDescription}>
                      <LinesEllipsis
                        text={description}
                        maxLine="2"
                        ellipsis={
                          <>
                            {'... '}
                            <RoundedButton
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={(event) => handleClick(event, description)}
                              className={classes.readMoreButton}
                            >
                              {`${t('btn:readMore')}`}
                            </RoundedButton>
                          </>
                        }
                        trimRight
                        basedOn="letters"
                      />
                    </div>
                    <RoundedButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => selectItem(itemProps)}
                      isLoading={activeItem === title && loading}
                      disabled={activeItem !== title && loading}
                      className={classes.createButton}
                    >
                      {t('btn:select')}
                    </RoundedButton>
                  </ListItem>
                )
              );
            })}
          </List>
        </>
      }
      <Box py={1} pb={2} display="flex" justifyContent="space-between">
        <RoundedButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleClose}
          className={classes.createButton}
        >
          {t('btn:cancel')}
        </RoundedButton>
      </Box>
    </>
  );
};

FilteredItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClose: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  activeItem: PropTypes.object.isRequired,
  iconList: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  dataList: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFilteredDataList: PropTypes.func.isRequired,
  notFoundTitle: PropTypes.func.isRequired,
};

FilteredItemList.defaultProps = {
  iconList: {},
};

export default FilteredItemList;
