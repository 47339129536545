import React from 'react';
import {
  makeStyles,
  IconButton,
} from '@material-ui/core';
import cx from 'classnames';
import { alpha } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { toggleIsSideMenuPinned } from '../../../../redux_store/reducer/reducers/appSettingsReducer';
import { ReactComponent as LeftPanelOpen } from '../../../../assets/icons/left_panel_open.svg';
import { ReactComponent as LeftPanelClose } from '../../../../assets/icons/left_panel_close.svg';

const useStyles = makeStyles((theme) => ({
  showIcon: {
    opacity: 1,
    transition: '0.3s',
    position: 'absolute',
    width: 25,
    height: 25,
    fill: '#ffffff',
  },
  hideIcon: {
    opacity: 0,
    transition: '0.3s',
  },
  iconButton: {
    position: 'relative',
    width: 40,
    height: 40,
    borderRadius: 10,
    opacity: .5,
    transition: 'opacity .1s ease-in-out',

    '&:hover': {
      backgroundColor: alpha('#ffffff', .2),
      opacity: 1,
      transition: 'opacity .1s ease-in-out',
    },
  },
  pinNavButtonContainer: {
    padding: 15,
  },
}));

const NavPinButton = () => {
  const classes = useStyles();
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);
  const dispatch = useDispatch();

  return <div className={classes.pinNavButtonContainer}>
    <IconButton
      aria-label='Search'
      onClick={() => dispatch(toggleIsSideMenuPinned())}
      className={classes.iconButton}
    >
      <LeftPanelOpen
        className={cx(classes.showIcon, {
          [classes.hideIcon]: isSideMenuPinned,
        })}
      />
      <LeftPanelClose
        className={cx(classes.showIcon, {
          [classes.hideIcon]: !isSideMenuPinned,
        })}
      />
    </IconButton>
  </div>;
};

export default NavPinButton;
