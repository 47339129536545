/* eslint-disable */
import React from 'react';

const PatientLogo = ({ fill, className }) => (
  <svg className={className} style={{ width: 20, height: 20}} viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>Patient@3x</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard" transform="translate(-34.000000, -247.000000)" fill={fill || "#FFFFFF"} fillRule="nonzero" stroke="#2E48D3" strokeWidth="0.5">
        <g id="Side" transform="translate(18.000000, 30.000000)">
          <g id="Tabs" transform="translate(0.000000, 83.000000)">
            <g id="Patient" transform="translate(17.000000, 135.000000)">
              <path d='M17,2 L13,2 L13,1 C13,0.44771525 12.5522847,0 12,0 L8,0 C7.44771525,0 7,0.44771525 7,1 L7,2 L3,2 C1.34314575,2 0,3.34314575 0,5 L0,17 C0,18.6568542 1.34314575,20 3,20 L17,20 C18.6568542,20 20,18.6568542 20,17 L20,5 C20,3.34314575 18.6568542,2 17,2 Z M9,2 L11,2 L11,4 L9,4 L9,2 Z M18,17 C18,17.5522847 17.5522847,18 17,18 L3,18 C2.44771525,18 2,17.5522847 2,17 L2,5 C2,4.44771525 2.44771525,4 3,4 L7,4 L7,5 C7,5.55228475 7.44771525,6 8,6 L12,6 C12.5522847,6 13,5.55228475 13,5 L13,4 L17,4 C17.5522847,4 18,4.44771525 18,5 L18,17 Z' id='Shape'/>
              <path d='M12.38,12.8 C12.7766132,12.2832274 12.9942424,11.6514007 13,11 C13,9.34314575 11.6568542,8 10,8 C8.34314575,8 7,9.34314575 7,11 C7.00575756,11.6514007 7.22338676,12.2832274 7.62,12.8 C6.60523555,13.5512189 6.00471045,14.7374413 6,16 L8,16 C8,14.8954305 8.8954305,14 10,14 C11.1045695,14 12,14.8954305 12,16 L14,16 C13.9952895,14.7374413 13.3947644,13.5512189 12.38,12.8 L12.38,12.8 Z M9,11 C9,10.4477153 9.44771525,10 10,10 C10.5522847,10 11,10.4477153 11,11 C11,11.5522847 10.5522847,12 10,12 C9.44771525,12 9,11.5522847 9,11 Z' id='Shape'/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PatientLogo;
