import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    border: `1px solid rgba(0, 168, 255, 0.5)`,
    borderRadius: 8,
    paddingLeft: 4,
    height: 49,
    overflow: 'hidden',
    transition: 'border-color .3s ease, background-color 1s ease',
    backgroundColor: '#ffffff',

    '&:hover': {
      border: `1px solid #00A8FF`,
      backgroundColor: `rgba(0, 168, 255, 0.05)`,

      '.contained':{
          borderRight: `1px solid #00A8FF`,
      }
    },
  },
  contained: {
    color: '#00A8FF !important',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderRadius: 0,
    borderRight: '1px solid rgba(0, 168, 255, 0.5)',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    transition: 'border-color .3s ease, background-color .3s ease, color .3s ease',

    '&:hover': {
      color: '#00A8FF !important',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderRadius: 0,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },

    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.5) !important',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderRadius: 0,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      cursor: 'inherit',
      borderRight: '1px solid rgba(0, 0, 0, 0.2)',

      '& .MuiButton-startIcon': {
        animation: `$rotation  1.5s linear infinite`,
      },
    },
  },
  selectMenu: {
    backgroundColor: 'transparent',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    border: 'none',
    overflow: 'hidden',
    transition: 'border-color .3s ease, background-color .3s ease, color .3s ease',

    '&::before': {
      borderBottom: '0 !important',
      backgroundColor: 'transparent',
    },
    '&::after': {
      borderBottom: '0 !important',
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiSelect-iconOpen': {
      transform: 'none',
      backgroundColor: 'transparent',
    },

    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'inherit',
      backgroundColor: 'transparent',
    },
  },
  select: {
    minWidth: 0,
    paddingRight: '18px !important',
    paddingLeft: 18,
    height: '74%',
    backgroundColor: 'transparent',
  },
  icon: {
    color: '#00A8FF',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
  },
  selectorMenu: {
    padding: 0,
    fontWeight: 400,
    width: 171,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    fontSize: '1rem',
    boxSizing: 'border-box',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    letterSpacing: '0.00938em',
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  selectMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 8,
  },
  selectMenuTitle: {
    height: 33,
    backgroundColor: '#000',
    color: '#fff',
    fontSize: 14,
    marginBottom: 8,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
}));

const RefreshTableButton = ({ reloadData, hasRightPadding, isLoading }) => {
  const { t } = useTranslation(['btn']);

  const classes = useStyles();
  return (
    <div
      style={{
        marginRight: hasRightPadding ? 16 : 0,
        borderColor: isLoading ? 'rgba(0, 0, 0, 0.2)' : null,
        backgroundColor: isLoading ? '#f5f5f5 !important' : null,
        transition: 'border-color .3s ease, background-color 1s ease, color .3s ease',
      }}
      className={classes.buttonContainer}
    >
      <Button
        className={classes.contained}
        startIcon={<Refresh />}
        onClick={() => reloadData(true)}
        disabled={isLoading}
      >
        {t('btn:refresh')}
      </Button>
      <Select
        classes={{ select: classes.select, icon: classes.icon }}
        disabled={isLoading}
        IconComponent={ExpandMoreIcon}
        className={classes.selectMenu}
        onChange={() => reloadData(false)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          MenuListProps: { disablePadding: true },
          getContentAnchorEl: null,
        }}
      >
        <div className={classes.selectMenuContainer}>
          <div className={classes.selectMenuTitle}>{t('btn:refresh')}</div>
          <Button
            className={classes.selectorMenu}
            onClick={() => reloadData(false)}
          >
            {t('btn:сlearAllFilters')}
          </Button>
        </div>
      </Select>
    </div>
  );
};

RefreshTableButton.propTypes = {
  reloadData: PropTypes.func.isRequired,
  hasRightPadding: PropTypes.bool,
  isLoading: PropTypes.bool,
};

RefreshTableButton.defaultProps = {
  hasRightPadding: false,
  isLoading: false,
};

export default RefreshTableButton;
