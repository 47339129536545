import PropTypes from 'prop-types';
import React from 'react';
import 'intersection-observer';
import './styles.css';
import { ANSWER_COLORS_LIST } from '../constants';
import MarkdownParser from '../../Reports/widgets/MarkdownParser';

const GridSelect = ({ survey }) => {
  const { title, subItems, reportContent } = survey;

  return (
    <>
      <tr className="list-break-marker">
        <td colSpan="5" className="grid-survey-title">
          {title}
        </td>
      </tr>
      {subItems &&
        subItems.map(({ title: subTitle, value }) => {

          let answerTitle = value ? value.title ?? '' : '';
          let valueUIClass = value ? value.valueUIClass ?? ANSWER_COLORS_LIST.usual : ANSWER_COLORS_LIST.usual;

          return (
            <tr className="list-break-marker">
              <td className="grid-survey-answer">{subTitle}</td>
              <td>
                {valueUIClass === ANSWER_COLORS_LIST.success ? answerTitle : ''}
              </td>
              <td>
                {valueUIClass === ANSWER_COLORS_LIST.warning ? answerTitle : ''}
              </td>
              <td>
                {valueUIClass === ANSWER_COLORS_LIST.error ? answerTitle : ''}
              </td>
              <td
                className={
                  valueUIClass === ANSWER_COLORS_LIST.info ? 'empty-value' : ''
                }
              >
                {valueUIClass === ANSWER_COLORS_LIST.usual ||
                valueUIClass === ANSWER_COLORS_LIST.info ||
                !valueUIClass
                  ? answerTitle
                  : ''}
              </td>
            </tr>
          );
        })}
      {reportContent && (
        <tr className="list-break-marker">
          <td>
            <MarkdownParser content={reportContent} />
          </td>
        </tr>
      )}
    </>
  );
};

GridSelect.propTypes = {
  survey: PropTypes.object,
};

GridSelect.defaultProps = {
  survey: {},
};

export default GridSelect;
