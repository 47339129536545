import * as React from 'react';
import {
  InputAdornment,
  makeStyles,
  Popover,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Calendar, DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import UsualInput from './UsualInput';
import RangeInput from './RangeInput';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { checkIsValidDate, parseDateToString, parseStringToDate } from './helpers';
import { useRef } from 'react';

const useStyles = makeStyles(() => ({
  popover: {
    width: 332,
    height: 332,
    maxHeight: 700,
  },
  paper: {
    maxWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 700,
  },
  inputRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  inputShouldRange: {
    display: 'flex',
    alignItems: 'center',
  },
  inputRightRoot: {
    width: 114,
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    fontSize: 13,
    fontWeight: 400,
  },
  dateRangeDelimiter: {
    margin: '0 10px',
  },
  popoverInputContainer: {
    display: 'flex',
    margin: 12,
    gap: 12,
  },
}));

function CalendarPopover({
                           date,
                           disabled,
                           onChange,
                           shouldRange,
                           width,
                           shouldReduceFontSize,
                           minDate,
                           maxDate,
                           disabledDates,
                           label,
                           shortView,
                           required,
                           error,
                         }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const inputRef = useRef(null);
  const rangeInputRef = useRef(null);

  const [value, setValue] = React.useState(date);

  const [state, setState] = React.useState({
    selection: {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (shortView) {
      if (shouldRange) {
        if (checkIsValidDate(inputRef.current?.value) && checkIsValidDate(rangeInputRef.current?.value)) {
          const firstData = parseStringToDate(inputRef.current?.value);
          const secondData = parseStringToDate(rangeInputRef.current?.value);

          if ((!date && (firstData || secondData)) || firstData !== date[0] || secondData !== date[1]) {
            updateDateValue();
          }
        }
      } else {
        if (checkIsValidDate(inputRef.current?.value)) {
          const firstData = parseStringToDate(inputRef.current?.value);
          if (firstData !== date) {
            updateDateValue();
          }
          onChange(parseStringToDate(inputRef.current?.value));
        }
      }
    }

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = useStyles();

  const handleDateChange = (newDate) => {
    if (shouldRange) {
      if (onChange !== null && onChange !== undefined && newDate) {
        if (shortView) {
          if (inputRef.current) {
            inputRef.current.value = newDate ? parseDateToString(newDate.selection.startDate) : null;
            setTimeout(() => {
              inputRef.current.value = newDate ? parseDateToString(newDate.selection.startDate) : null;
            }, 1);
          }
          if (rangeInputRef.current) {
            rangeInputRef.current.value = newDate ? parseDateToString(newDate.selection.endDate) : null;
            setTimeout(() => {
              rangeInputRef.current.value = newDate ? parseDateToString(newDate.selection.endDate) : null;
            }, 1);
          }
        } else {
          onChange([
            newDate ? newDate.selection.startDate : new Date(),
            newDate ? newDate.selection.endDate : null,
          ]);
        }

        if (
          newDate &&
          newDate.selection.startDate &&
          newDate.selection.endDate &&
          ((newDate.selection.startDate.toString() !==
            newDate.selection.endDate.toString()) ||
            (shortView && state && state.selection && state.selection.startDate
              && newDate.selection.startDate.toString() === state.selection.startDate.toString()))
        ) {
          handleClose(null);
        }
      }
      setState({ ...state, ...newDate });
    } else {
      if (onChange !== null && onChange !== undefined && newDate) {

        if (shortView) {
          if (inputRef.current) {
            inputRef.current.value = parseDateToString(newDate);
            setTimeout(() => {
              inputRef.current.value = parseDateToString(newDate);
            }, 1);
          }
        } else {
          onChange(newDate);
        }

        if (!shouldRange) {
          handleClose(null);
        }
      }

      if (!shouldRange) {
        setValue(newDate);
      }
    }
  };

  React.useEffect(() => {
    if (!shouldRange) {
      if (date) {
        setValue(date);
      } else {
        setValue('');
      }
    } else {
      if (date) {
        const newDate = {
          selection: {
            startDate: date[0],
            endDate: date[1],
            key: 'selection',
          },
        };
        setState({ ...state, ...newDate });
      } else {
        const newDate = {
          selection: {
            startDate: null,
            endDate: null,
            key: 'selection',
          },
        };
        setState({ ...state, ...newDate });
      }
    }
  }, [date]);

  const handleInputDateChange = (dateMoment, currentDate) => {
    if (dateMoment && dateMoment.isValid()) {
      if (shouldRange) {
        state.selection.startDate = parseStringToDate(currentDate);
        if (!(dateMoment && dateMoment.isValid() && checkIsValidDate(rangeInputRef.current?.value))) {
          setTimeout(() => {
            rangeInputRef.current?.focus();
          }, 1);
        }
      }
    }
  };

  const handleRangeInputDateChange = (dateMoment, currentDate) => {
    if (dateMoment && dateMoment.isValid()) {
      state.selection.endDate = parseStringToDate(currentDate);
      if (!checkIsValidDate(inputRef.current?.value)) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 1);
      }
    }
  };

  const updateDateValue = () => {
    if (shouldRange) {
      const firstData = parseStringToDate(inputRef.current?.value);
      const secondData = parseStringToDate(rangeInputRef.current?.value);
      if (firstData > secondData) {
        onChange([secondData, firstData]);
      } else {
        onChange([firstData, secondData]);
      }
    } else {
      onChange(parseStringToDate(inputRef.current?.value));
    }
  };

  const zoomLevel = (window.outerWidth - 10) / window.innerWidth;

  return (
    <>
      {shouldRange && (
        <RangeInput
          error={error}
          disabled={disabled}
          required={required}
          shortView={shortView}
          date={date}
          shouldReduceFontSize={shouldReduceFontSize}
          label={label}
          onChange={onChange}
          handleClick={handleClick}
          handleClose={handleClose}
          width={width}
        />
      )}
      {!shouldRange && (
        <UsualInput
          error={error}
          disabled={disabled}
          required={required}
          date={date}
          shortView={shortView}
          label={label}
          shouldReduceFontSize={shouldReduceFontSize}
          onChange={onChange}
          handleClick={handleClick}
          handleClose={handleClose}
        />
      )}
      <Popover
        classes={{
          root: classes.popover,
          paper: classes.paper,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {shortView && <div className={classes.popoverInputContainer}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoFocus
              inputRef={inputRef}
              format='MM/DD/yyyy'
              placeholder='MM/DD/YYYY'
              fixedHeigh={true}
              id='date-picker-inline'
              inputVariant='outlined'
              value={shouldRange ? (state ? state.selection.endDate : null) : date}
              style={{ width: '100%' }}
              onChange={handleInputDateChange}
              InputProps={{
                startAdornment: shouldRange ? (
                  <InputAdornment position='start'>
                    {'from'}
                  </InputAdornment>
                ) : null,
              }}
              KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClose();
                }
              }}
            />
          </MuiPickersUtilsProvider>
          {shouldRange && (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                inputRef={rangeInputRef}
                disableToolbar
                format='MM/DD/yyyy'
                placeholder='MM/DD/YYYY'
                id='date-picker-inline-range'
                style={{ width: '100%' }}
                inputVariant='outlined'
                fixedHeigh={true}
                value={state ? state.selection.endDate : null}
                onChange={handleRangeInputDateChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {'to'}
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleClose();
                  }
                }}
                KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
              />
            </MuiPickersUtilsProvider>
          )}
        </div>}
        {shouldRange && (
          <DateRangePicker
            maxDate={maxDate ?? undefined}
            months={2}
            direction='vertical'
            fixedHeigh={true}
            scroll={{
              enabled: true,
              monthHeight: zoomLevel < 1 ? 320 : 230,
              longMonthHeight: zoomLevel < 1 ? 350 : 250,
            }}
            ranges={[state.selection]}
            onChange={(item) => handleDateChange(item)}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            disabledDates={disabledDates}
          />
        )}
        {!shouldRange && (
          <Calendar
            maxDate={maxDate ?? undefined}
            minDate={minDate ?? undefined}
            onChange={(item) => handleDateChange(item)}
            date={value}
            disabledDates={disabledDates}
          />
        )}
      </Popover>
    </>
  );
}

CalendarPopover.propTypes = {
  width: PropTypes.number,
  shouldReduceFontSize: PropTypes.bool,
  shortView: PropTypes.bool,
  disabled: PropTypes.bool,
  shouldRange: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabledDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};

CalendarPopover.defaultProps = {
  disabled: false,
  shouldRange: false,
  shortView: false,
  date: null,
  maxDate: null,
  minDate: null,
  onChange: undefined,
  width: undefined,
  disabledDates: undefined,
  label: undefined,
  shouldReduceFontSize: false,
};

export default CalendarPopover;
