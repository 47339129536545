import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-fetching-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useMetadataUpdate from 'hooks/useMetadataUpdate';
import showServerError from 'helpers/showError';
import { checkPermission } from 'helpers/checkPermissions';
import permissions from 'config/permissions';
import HipaaDialog from 'components/Dialogs/HipaaDialog';
import InfoDialog from 'components/Dialogs/InfoDialog';
import { useDispatch } from 'react-redux';
import { setIsLoginStatus } from '../../redux_store/reducer/reducers/userReducer/userReducer';
import { REQUEST_STATUS } from '../../redux_store/constants';

const HipaaCheck = ({ user, metadata, onSuccess, onFail, onSwitchAccount }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dialogs']);
  const [isAgreementDialogOpen, setAgreementDialogOpen] = useState(false);
  const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);
  const {
    currentAccountPermissions,
    currentAccount: { uuid, businessName },
  } = metadata;

  const { query: getQuery } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${uuid}/hipaa-agreement`,
    },
    false,
  ]);

  const { query: postQuery } = useMetadataUpdate(useQuery, [
    {
      method: 'POST',
      endpoint: `/accounts/${uuid}/hipaa-agreement`,
    },
    false,
  ]);

  useEffect(() => {
    if (uuid) {
      getQuery().then(({ payload, error }) => {
        if (error) {
          const options = payload && {
            correlationUUID: payload.correlationUUID,
            userUUID: user.uuid,
          };
          showServerError(dispatch, '', options);
          return;
        }
        /*
          policy accepted: redirect to the provided route
          policy not accepted: check permissions
            - true: show HIPAA Agreement
            - false: redirect to the next account / login page
        */
        if (payload) {
          dispatch(setIsLoginStatus(REQUEST_STATUS.succeeded));
          setTimeout(() => {
            onSuccess();
          }, 500);
        } else {
          const hasPermission = checkPermission(
            currentAccountPermissions,
            permissions.ACCOUNT_USERS_GET,
          );
          if (hasPermission) {
            setAgreementDialogOpen(true);
          } else {
            setInfoDialogOpen(true);
          }
        }
      });
    }
  }, []);

  const handleAgreementDialogClose = () => {
    setAgreementDialogOpen(false);
    onFail();
  };

  const handleAgreementDialogSubmit = () => {
    postQuery().then(({ payload, error, status }) => {
      if (error) {
        const options = {
          correlationUUID: payload.correlationUUID,
          userUUID: user.uuid,
        };
        switch (status) {
          case 422: {
            showServerError(dispatch, t('errors:accountOrUser'));
            break;
          }
          case 500: {
            switch (payload.errorCode) {
              case 1: {
                // Agreement already accepted.
                onSuccess();
                break;
              }
              default: {
                showServerError(dispatch, '', options);
                break;
              }
            }
            break;
          }
          default: {
            showServerError(dispatch, '', options);
            break;
          }
        }
      } else {
        onSuccess();
      }
    });
  };

  /*
    redirect user to the next account
    or to the login page
  */
  const handleInfoDialogClose = () => {
    onSwitchAccount();
  };

  return (
    <>
      <HipaaDialog
        open={isAgreementDialogOpen}
        onClose={handleAgreementDialogClose}
        onSubmit={handleAgreementDialogSubmit}
        accountName={businessName}
      />

      <InfoDialog
        open={isInfoDialogOpen}
        onClose={handleInfoDialogClose}
        title={t('dialogs:hipaaAgreement')}
        content={t('dialogs:administratorMustAccept')}
      />
    </>
  );
};

HipaaCheck.propTypes = {
  user: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  metadata: PropTypes.shape({
    currentAccount: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      businessName: PropTypes.string.isRequired,
    }).isRequired,
    currentAccountPermissions: PropTypes.number.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  onSwitchAccount: PropTypes.func.isRequired,
};

export default HipaaCheck;
