import i18next from 'i18next';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';
import permissions from '../../config/permissions';
import { CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS } from '../ClaimsReporting/constants';

export const ALERT_FIELDS = [
  'accountCustomTags',
  'accountBusinessName',
  'accountCustomIdentifier',
  'patientFirstName',
  'patientLastName',
  'patientDateOfBirth',
  'patientMrnNumber',
  'entityName',
  'alertTags',
  'title',
  'orderDate',
  'appointmentTimestamp',
  'invitedTimestamp',
  'viewedTimestamp',
  'entityType',
  'alertStatus',
  'viewedBy',
  'alertType',
  'acknowledgeStatus',
  'acknowledgedBy',
  'acknowledgedTimestamp',
  'alertCategory',
];

export const ALERTS_TYPE = {
  critical: 'Critical',
  nonCritical: 'Non-Critical',
};

export const ALERT_ACTIONS = {
  acknowledge: 'Acknowledge',
  oneMore: 'Some Action with modal',
};

export const ALERTS_TYPE_SELECTOR_OPTIONS = [
  {
    label: i18next.t('tables:critical'),
    value: 'critical',
  },
  {
    label: i18next.t('tables:nonCritical'),
    value: 'nonCritical',
  },
];

export const STATUS_OPTIONS = [
  {
    label: i18next.t('tables:pendingAcknowledgment'),
    value: 'pendingAcknowledgment',
  },
  {
    label: i18next.t('tables:acknowledged'),
    value: 'acknowledged',
  },
  {
    label: i18next.t('tables:pending'),
    value: 'pending',
  },
];

export const ALERT_CATEGORY_STATUSES = {
  'Imminent Risk': 'Imminent Risk',
  'Help Seeking Behavior': 'Help Seeking Behavior',
  'Risk Factors Identified': 'Risk Factors Identified',
};

export const ALERT_TAGS_STATUSES = {
  'Suicide Risk': 'Suicide Risk',
  'Allergy Test': 'Allergy Test',
  'Home Sleep Study': 'Home Sleep Study',
  'Mental Health': 'Mental Health',
  'Pain Management': 'Pain Management',
  'Sleep Quality': 'Sleep Quality',
  'Lifestyle Improvement': 'Lifestyle Improvement',
  'Risk Factors Identified': 'Risk Factors Identified',
};

export const ALERTS_TAGS_BY_CATEGORY = {
  [ALERT_CATEGORY_STATUSES['Imminent Risk']]: [ALERT_TAGS_STATUSES['Suicide Risk']],
  [ALERT_CATEGORY_STATUSES['Help Seeking Behavior']]: [
    ALERT_TAGS_STATUSES['Allergy Test'],
    ALERT_TAGS_STATUSES['Home Sleep Study'],
    ALERT_TAGS_STATUSES['Mental Health'],
    ALERT_TAGS_STATUSES['Pain Management'],
    ALERT_TAGS_STATUSES['Sleep Quality'],
    ALERT_TAGS_STATUSES['Lifestyle Improvement'],
  ],
  [ALERT_CATEGORY_STATUSES['Risk Factors Identified']]: [ALERT_TAGS_STATUSES['Risk Factors Identified']],
};

export const ALERT_TAGS_OPTIONS = [
  {
    label: i18next.t('tables:imminentRisk'),
    value: ALERT_CATEGORY_STATUSES['Imminent Risk'],
    child: ALERTS_TAGS_BY_CATEGORY['Imminent Risk'],
  },
  {
    label: i18next.t('tables:suicideRisk'),
    value: ALERT_TAGS_STATUSES['Suicide Risk'],
    isChild: true,
  },


  {
    label: i18next.t('tables:helpSeekingBehavior'),
    value: ALERT_CATEGORY_STATUSES['Help Seeking Behavior'],
    child: ALERTS_TAGS_BY_CATEGORY['Help Seeking Behavior'],
  },
  {
    label: i18next.t('tables:allergyTest'),
    value: ALERT_TAGS_STATUSES['Allergy Test'],
    isChild: true,
  },
  {
    label: i18next.t('tables:homeSleepStudy'),
    value: ALERT_TAGS_STATUSES['Home Sleep Study'],
    isChild: true,
  },
  {
    label: i18next.t('tables:mentalHealth'),
    value: ALERT_TAGS_STATUSES['Mental Health'],
    isChild: true,
  },
  {
    label: i18next.t('tables:painManagement'),
    value: ALERT_TAGS_STATUSES['Pain Management'],
    isChild: true,
  },
  {
    label: i18next.t('tables:sleepQuality'),
    value: ALERT_TAGS_STATUSES['Sleep Quality'],
    isChild: true,
  },
  {
    label: i18next.t('tables:lifestyleImprovement'),
    value: ALERT_TAGS_STATUSES['Lifestyle Improvement'],
    isChild: true,
  },


  {
    label: i18next.t('tables:riskFactorsIdentified'),
    value: ALERT_CATEGORY_STATUSES['Risk Factors Identified'],
    child: [ALERT_TAGS_STATUSES['Risk Factors Identified']],
  },
  {
    label: i18next.t('tables:riskFactorsIdentified'),
    value: ALERT_TAGS_STATUSES['Risk Factors Identified'],
    isChild: true,
  },
];


export const GET_ALERT_COLUMNS = (
  isPatientPage,
  paramAccountUuid,
  totalAccountsCount,
) => [
  {
    width: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'accountCustomTags',
    hasBubble: true,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      totalAccountsCount,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 1,
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 160,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 220,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'patientFirstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
    flexShrink: 3,
    hidden: isPatientPage,
  },
  {
    width: 220,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'patientLastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
    flexShrink: 3,
    hidden: isPatientPage,
  },
  {
    width: 200,
    label: i18next.t('tables:patientDOB'),
    dataKey: 'patientDateOfBirth',
    hidden: isPatientPage,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'patientMrnNumber',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 2,
  },
  {
    width: 250,
    label: i18next.t('tables:entityName'),
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    apiKey: 'alerts',
    dataKey: 'entityName',
    flexGrow: 1,
  },
  {
    width: 250,
    label: i18next.t('tables:alertTags'),
    dataKey: 'alertTags',
    hasBubble: true,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: ALERT_TAGS_OPTIONS,
    },
    flexGrow: 2,
  },
  // {
  //   minWidth: 200,
  //   width: 200,
  //   label: i18next.t('tables:alertName'),
  //   dataKey: 'title',
  //   columnData: {
  //     isFilterable: true,
  //   },
  //   flexGrow: 2,
  // },
  {
    width: 200,
    label: i18next.t('tables:orderDate'),
    dataKey: 'orderDate',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
    flexGrow: 2,
  },
  {
    width: 200,
    label: i18next.t('tables:appointmentTimestamp'),
    dataKey: 'appointmentTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
    flexGrow: 2,
  },
  {
    width: 200,
    label: i18next.t('tables:invitedTimestamp'),
    dataKey: 'invitedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
    flexGrow: 2,
  },
  {
    width: 200,
    label: i18next.t('tables:viewedTimestamp'),
    dataKey: 'viewedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
    flexGrow: 2,
  },
  {
    width: 180,
    label: i18next.t('tables:entityType'),
    dataKey: 'entityType',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS,
    },
    flexGrow: 2,
  },
  {
    minWidth: 200,
    width: 200,
    label: i18next.t('tables:viewedBy'),
    dataKey: 'viewedBy',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 2,
  },
  {
    minWidth: 200,
    width: 200,
    label: i18next.t('tables:alertType'),
    dataKey: 'alertType',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: ALERTS_TYPE_SELECTOR_OPTIONS,
    },
    flexGrow: 2,
  },
  // {
  //   minWidth: 200,
  //   width: 200,
  //   label: i18next.t('tables:acknowledgeStatus'),
  //   dataKey: 'acknowledgeStatus',
  //   columnData: {
  //     isFilterable: true,
  //     filterType: FILTER_TYPES.SELECT,
  //     options: STATUS_OPTIONS,
  //   },
  //   flexGrow: 2,
  // },
  // {
  //   minWidth: 200,
  //   width: 200,
  //   label: i18next.t('tables:acknowledgedBy'),
  //   dataKey: 'acknowledgedBy',
  //   columnData: {
  //     isFilterable: true,
  //   },
  //   flexGrow: 2,
  // },
  // {
  //   width: 250,
  //   label: i18next.t('tables:acknowledgedTimestamp'),
  //   dataKey: 'acknowledgedTimestamp',
  //   columnData: {
  //     shouldRange: true,
  //     isFilterable: true,
  //     filterType: FILTER_TYPES.DATE,
  //   },
  //   flexGrow: 2,
  // },
];

export const FAKE_ALERTS = {
  name: '',
  alertType: ALERTS_TYPE.nonCritical,
  criticalCategory: '',
};
