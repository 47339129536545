import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    marginLeft: 15,
  },
}));

const AllowedAccountsSection = ({
                                  values,
                                  backendErrors,
                                  toggleAllowedAccountsModal,
                                }) => {
  const { t } = useTranslation([
    'forms',
  ]);
  const classes = useStyles();
  const countOfSelectedAccounts = values?.allowedAccounts?.length ?? 0;

  return (
    <>
      <Divider className={classes.divider} />
      <Grid container direction='column' alignItems='flex-start'>
        <Typography className={classes.title}>
          {t('forms:accountAccess')}:
        </Typography>
        <Grid container direction='row' alignItems='center' justifyContent={'flex-start'}>
          <Typography>
            {t('forms:accountAccessNumber', {
              number: countOfSelectedAccounts === 0
                ? 'all'
                : countOfSelectedAccounts,
              end: countOfSelectedAccounts === 1
                ? ''
                : 's',
            })}
          </Typography>
          <Button
            color='primary'
            variant={'contained'}
            className={cx(classes.button)}
            onClick={() => {
              toggleAllowedAccountsModal(true);
            }}
          >
            {t('forms:modifyAccountAccessList')}
          </Button>
        </Grid>
        {(!!backendErrors['accountAccess'] || !!backendErrors['allowedAccounts']) && (
          <Typography className={cx(classes.error)}>
            {backendErrors['accountAccess'] || backendErrors['allowedAccounts']}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default AllowedAccountsSection;
