import {
  Typography,
  Box,
  makeStyles,
  Tab,
  Tabs,
  Divider,
} from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-fetching-library';
import { conformToMask } from 'react-text-mask';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import Modal from 'components/Shared/Modal';
import getDigitPhone from 'helpers/phone';
import showServerError from 'helpers/showError';
import { MASK } from 'components/Shared/FormattedPhone';
import AssessmentForm from 'components/Assessments/Item/AssessmentForm';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { useDispatch, useSelector } from 'react-redux';
import InvitationSent from '../../Shared/CreateNewItemModal/InvitationSent';
import Breadcrumbs from '../../Shared/CreateNewItemModal/Breadcrumbs';
import FilteredItemList from '../../Shared/CreateNewItemModal/FilteredItemList';
import { getUtcDate } from '../../../helpers/localize';
import Loader from '../../Shared/Loader';
import { ActivePatientContext } from '../../../contexts/ActivePatientProvider';
import ConfirmationsPopup from '../../Shared/ConfirmationsPopup';
import AssessmentUrl from './../SharedWidgets/AssessmentUrl';
import { routes } from '../../../config/routes';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { AppRouteContext } from '../../../contexts';
import { object, string } from 'yup';

const DELIVERY_METHOD = {
  inClinic: 'inClinic',
  sendInviteToPatient: 'sendInviteToPatient',
};

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    maxWidth: 1000,
    minWidth: 1000,
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },
  createButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
    maxHeight: 40,
  },
  backButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
    margin: '0 20px',
    maxHeight: 40,
  },
  indicator: {
    display: 'none',
  },
  tabs: {
    zIndex: 1,
  },
  tab: {
    minWidth: 'unset',
    minHeight: 'unset',
    opacity: 1,
    borderRadius: 17,
    border: `0.5px solid ${theme.colors.lightBlue}`,
    fontWeight: 400,
    fontSize: 15,
    backgroundColor: theme.palette.common.white,
    color: theme.colors.lightBlue,
  },
  selected: {
    fontWeight: 700,
    backgroundColor: theme.colors.lightBlue,
    color: theme.palette.common.white,
  },
  leftTab: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  rightTab: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  divider: {
    zIndex: 0,
    top: '42%',
    width: '100%',
    position: 'absolute',
  },
  secondaryTitle: {
    fontSize: 15,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.colors.blue2,
    opacity: 0.5,
    padding: theme.spacing(6, 20, 0, 20),
    [theme.breakpoints.down(1000)]: {
      padding: theme.spacing(0),
    },
  },
  title: {
    paddingTop: theme.spacing(2),
    color: theme.colors.lightBlue,
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
  linkBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.colors.gray1,
    marginLeft: 84,
  },
  link: {
    cursor: 'default',
    textAlign: 'center',
    display: 'flex',
    fontSize: 25,
    fontWeight: 500,
    padding: theme.spacing(2, 6),
    msUserSelect: 'none',
    userSelect: 'none',
  },
  copyIcon: {
    width: 69,
    marginLeft: 15,
  },
  firstTitleStyle: {
    textAlign: 'start',
    padding: theme.spacing(4),
    [theme.breakpoints.down(1000)]: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  modalSecondTitle: {
    width: '100%',
  },
  titleChildrenStyle: {
    width: 'calc(100% - 380px)',
    maxWidth: 'calc(100% - 380px)',
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

const AssessmentCreate = ({
                            open,
                            handleClose,
                            accountUuid,
                            patient,
                            reloadData,
                            scheduleDeliverTime,
                          }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation([
    'btn',
    'errors',
    'notifications',
    'titles',
    'dialogs',
  ]);
  const [backendErrors, setBackendErrors] = useState({});
  const [link, setLink] = useState(); // trigger useOrderMetadata
  const [activeAssessment, setActiveAssessment] = useState({});
  const [step, setStep] = useState(0);
  const patientUuid = get(patient, 'uuid', '');
  const [assessmentList, setAssessmentList] = useState([]);
  const [confirmResendingInfo, setConfirmResendingInfo] = useState(null);
  const [
    confirmResendingAdditionalInfo,
    setConfirmResendingAdditionalInfo,
  ] = useState(null);
  const [filteredAssessmentList, setFilteredAssessmentList] = useState([]);
  const { patientInfo, setPatientInfo } = useContext(ActivePatientContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const location = useLocation();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const { setAppRoute } = useContext(AppRouteContext);

  const { query, loading: loadingSurveys } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/surveys-data`,
    },
    false,
  ]);

  useEffect(() => {
    if (accountUuid) {
      query().then(({ error, payload }) => {
        if (!error) {
          setAssessmentList(payload.result);
          setFilteredAssessmentList(payload.result);
        }
      });
    }
  }, [accountUuid]);

  useEffect(() => {
    if (confirmResendingInfo === null) {
      setConfirmResendingAdditionalInfo(null);
    }
  }, [confirmResendingInfo]);

  const handleChange = (event, newValue) => {
    setStep(newValue + 1);
  };

  const closeModal = () => {
    handleClose();
    setBackendErrors({});

    if (location.pathname.includes('create-assessment')) {
      const patientFirstName = get(patientInfo, 'firstName', '');
      const patientLastName = get(patientInfo, 'lastName', '');
      if (location.pathname.includes('/assessments/')) {
        setAppRoute({
          path: routes.patientAssessmentsList.path,
          params: {
            accountUuid: paramAccountUuid,
            patientUuid: paramsPatientUuid,
          },
          title: `${t(
            'titles:patientDashboard',
          )}: ${patientFirstName} ${patientLastName}`,
        });
      } else if (location.pathname.includes('/orders/')) {
        setAppRoute({
          path: routes.patientOrdersList.path,
          params: {
            accountUuid: paramAccountUuid,
            patientUuid: paramsPatientUuid,
          },
          title: `${t(
            'titles:patientDashboard',
          )}: ${patientFirstName} ${patientLastName}`,
        });
      }
    }
  };

  const closeConfirmationsPopup = () => {
    setConfirmResendingInfo(null);
    setConfirmResendingAdditionalInfo(null);
  };

  const AssessmentCreateAction = (values) => ({
    method: 'PUT',
    endpoint: `/accounts/${accountUuid}/patients/${patientUuid}/surveys`,
    body: values,
  });

  const { loading, mutate } = useMetadataUpdate(useMutation, [
    AssessmentCreateAction,
  ]);

  const handleSuccessfulCreating = (payload, additionalInfo) => {
    const { values, isInClinicMethod, initEmail, initPhone } =
    additionalInfo || confirmResendingAdditionalInfo;
    reloadData();
    const { surveyUrl } = payload;
    if (isInClinicMethod) {
      setLink(surveyUrl);
      setStep(4);
    } else {
      setStep(3);

      const updatePatientRecordEmail =
        values.updatePatientRecordEmail &&
        initEmail !== values.email &&
        !!values.email;
      const updatePatientRecordPhone =
        values.updatePatientRecordPhone &&
        initPhone !== values.phone &&
        !!values.phone;

      const newPatientInfo = {
        ...patientInfo,
      };

      if (updatePatientRecordEmail) {
        newPatientInfo.email = values.email;
      }
      if (updatePatientRecordPhone) {
        newPatientInfo.phone = values.phone;
      }
      if (updatePatientRecordEmail || updatePatientRecordPhone) {
        setPatientInfo(newPatientInfo);
      }
    }
    closeConfirmationsPopup();
  };

  const createAssessment = async (values, { setSubmitting }) => {
    const isInClinicMethod = step === 2;
    const deliveryMethod = isInClinicMethod
      ? DELIVERY_METHOD.inClinic
      : DELIVERY_METHOD.sendInviteToPatient;

    const initPhone =
      patient && conformToMask(patient.phone ?? '', MASK).conformedValue;
    const initEmail = patient && get(patient, 'email', '');

    const formattedValues = isInClinicMethod
      ? {
        deliveryMethod,
        surveyType: activeAssessment.id,
      }
      : {
        ...values,
        deliveryMethod,
        phone: getDigitPhone(values.phone),
        surveyType: activeAssessment.id,
        updatePatientRecordPhone:
          values.updatePatientRecordPhone &&
          initPhone !== values.phone &&
          !!values.phone,
        updatePatientRecordEmail:
          values.updatePatientRecordEmail &&
          initEmail !== values.email &&
          !!values.email,
        scheduledDate:
          values.sendingOption === 'sendNow'
            ? null
            : getUtcDate(values.scheduledDate),
        scheduleDeliverTime:
          values.sendingOption === 'sendNow'
            ? null
            : values.scheduleDeliverTime,
      };
    setBackendErrors({});

    const { payload, error, status } = await mutate(formattedValues);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      setSubmitting(false);
      switch (status) {
        case 500: {
          if (payload.errorCode === 2) {
            showServerError(dispatch, t('errors:accountIsDisabled'));
            break;
          }
        }
        case 422: {
          if (
            payload.requiredConfirmations &&
            payload.requiredConfirmations.length > 0
          ) {
            setConfirmResendingAdditionalInfo({
              formattedValues,
              values,
              isInClinicMethod,
              initEmail,
              initPhone,
            });
            setConfirmResendingInfo(payload.requiredConfirmations);
            break;
          } else {
            showServerError(dispatch, t('errors:validationError'));
            setBackendErrors(payload.validationErrors);
            break;
          }
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      const additionalInfo = {
        formattedValues,
        values,
        isInClinicMethod,
        initEmail,
        initPhone,
      };
      handleSuccessfulCreating(payload, additionalInfo);
    }
  };

  useEffect(() => {
    if (open) {
      setStep(0);
    }
    return setStep(0);
  }, [open]);

  useEffect(() => {
    setActiveAssessment({});
    return setActiveAssessment({});
  }, []);

  useEffect(() => {
    if (step === 0) {
      setActiveAssessment({});
    }
  }, [step]);

  const handleActiveAssessment = (data) => {
    setActiveAssessment(data);
    setStep(1);
  };

  const initialValues = {
    phone: patient && conformToMask(patient.phone ?? '', MASK).conformedValue,
    email: patient && get(patient, 'email', ''),
    updatePatientRecordPhone: false,
    updatePatientRecordEmail: false,
    sendingOption: 'sendNow',
    scheduledDate: null,
    scheduleDeliverTime,
  };

  const AssessmentSchema = object({
    scheduleDeliverTime: object().when('sendingOption', {
      is: (sendingOption) => sendingOption !== 'sendNow',
      then: () => string().required('Time is a required field.'),
      otherwise: () => string().notRequired(),
    }),
    scheduledDate: object().when('sendingOption', {
      is: (sendingOption) => {
        console.log(sendingOption);
        return sendingOption !== 'sendNow'
      },
      then: () => string().required('Date is a required field.'),
      otherwise: () => string().notRequired(),
    }),
  });


  return (
    <Modal
      open={open}
      handleClose={closeModal}
      classes={{ paper: classes.modal, container: classes.modalContainer }}
      title={
        step > 0 && step < 3
          ? activeAssessment.title
          : t(
          step > 2
            ? 'titles:assessmentCreated'
            : 'titles:createNewAssessment',
          )
      }
      firstTitleStyle={classes.firstTitleStyle}
      secondTitleStyle={classes.modalSecondTitle}
      titleChildren={<Breadcrumbs activeStep={step} steps={[2, 3]} />}
      titleChildrenStyle={classes.titleChildrenStyle}
    >
      <ConfirmationsPopup
        closePopup={closeConfirmationsPopup}
        confirmResendingInfo={confirmResendingInfo}
        handleSuccessfulCreating={handleSuccessfulCreating}
        confirm={mutate}
        confirmBody={
          confirmResendingAdditionalInfo &&
          confirmResendingAdditionalInfo.formattedValues
        }
        isLoading={loading}
      />
      {!scheduleDeliverTime || !patient || !filteredAssessmentList ? (
        <Loader fullHeight />
      ) : (
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={createAssessment}
          validationSchema={AssessmentSchema}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Box pt={step < 3 ? 0 : 5} p={4}>
              {step === 0 && (
                <FilteredItemList
                  items={filteredAssessmentList}
                  handleClose={closeModal}
                  selectItem={handleActiveAssessment}
                  activeItem={activeAssessment.title}
                  loading={loadingSurveys}
                  dataList={assessmentList}
                  notFoundTitle={t('titles:assessments')}
                  setFilteredDataList={setFilteredAssessmentList}
                />
              )}

              {step < 3 && step > 0 && (
                <Box
                  display='flex'
                  justifyContent='center'
                  position='relative'
                  minWidth={250}
                >
                  <Tabs
                    value={step - 1}
                    onChange={handleChange}
                    classes={{
                      indicator: classes.indicator,
                      root: classes.tabs,
                    }}
                  >
                    <Tab
                      label={t('btn:sendAnInvite')}
                      classes={{
                        root: classes.tab,
                        selected: classes.selected,
                      }}
                      className={classes.leftTab}
                    />
                    <Tab
                      label={t('btn:takeInClinic')}
                      classes={{
                        root: classes.tab,
                        selected: classes.selected,
                      }}
                      className={classes.rightTab}
                    />
                  </Tabs>
                  <Divider className={classes.divider} />
                </Box>
              )}
              {step === 1 && (
                <Box pt={2}>
                  <AssessmentForm
                    subtitle={t('forms:invitationWillBeSent')}
                    values={values}
                    initialValues={initialValues}
                    backendErrors={backendErrors}
                    setBackendErrors={setBackendErrors}
                    patientUUID={patientUuid}
                    accountUuid={accountUuid}
                    actions={
                      <Box
                        py={1}
                        pb={2}
                        display='flex'
                        justifyContent='space-between'
                      >
                        <div style={{ display: 'flex' }}>
                          <RoundedButton
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={closeModal}
                            className={classes.createButton}
                          >
                            {t('btn:cancel')}
                          </RoundedButton>

                          <RoundedButton
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={() => setStep(0)}
                            className={classes.backButton}
                          >
                            {t('btn:back')}
                          </RoundedButton>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <RoundedButton
                            variant='contained'
                            color='primary'
                            size='small'
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                            isLoading={loading}
                            isSuccess={isSuccess}
                            isFailed={isFailed}
                            className={classes.createButton}
                          >
                            {t('btn:send')}
                          </RoundedButton>
                        </div>
                      </Box>
                    }
                  />
                </Box>
              )}
              {step === 2 && (
                <Box>
                  <Typography className={classes.secondaryTitle}>
                    {t('titles:openThisPage')}
                  </Typography>
                  <Typography className={classes.title}>
                    {t('titles:linkWillBeProvided')}
                  </Typography>
                  <Box
                    pt={4}
                    pb={2}
                    display='flex'
                    justifyContent='space-between'
                  >
                    <RoundedButton
                      variant='outlined'
                      color='primary'
                      size='small'
                      onClick={closeModal}
                      className={classes.createButton}
                    >
                      {t('btn:cancel')}
                    </RoundedButton>
                    <div>
                      <RoundedButton
                        variant='outlined'
                        color='primary'
                        size='small'
                        onClick={() => setStep(0)}
                        className={classes.backButton}
                      >
                        {t('btn:back')}
                      </RoundedButton>
                      <RoundedButton
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={handleSubmit}
                        isLoading={loading}
                        isSuccess={isSuccess}
                        isFailed={isFailed}
                        className={classes.createButton}
                      >
                        {t('btn:confirmBtn')}
                      </RoundedButton>
                    </div>
                  </Box>
                </Box>
              )}
              {step === 3 && <InvitationSent handleClose={closeModal} />}
              {step === 4 && (
                <Box pt={5}>
                  <AssessmentUrl
                    onCloseModal={closeModal}
                    link={link}
                  />
                </Box>
              )}
            </Box>
          )}
        </Formik>
      )}
    </Modal>
  );
};

AssessmentCreate.propTypes = {
  patient: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
  accountUuid: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  scheduleDeliverTime: PropTypes.string,
};

AssessmentCreate.defaultProps = {
  scheduleDeliverTime: null,
};

export default AssessmentCreate;
