import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

export const MASK = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const FormattedPhone = ({ value, inputRef, onChange, ...other }) => {
  const [formattedValue, setFormattedValue] = useState(value);

  useEffect(() => {
    setFormattedValue(value !== '' ? value : undefined);
  }, [value]);

  return (
    <MaskedInput
      {...other}
      value={formattedValue}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={MASK}
      onChange={(e) => {
        onChange(e);
        if (e.target.value === '' || e.target.value === '(___) ___-____') {
          setFormattedValue('');
        } else {
          setFormattedValue(e.target.value);
        }
      }}
      // placeholderChar={'\u2000'}
      // showMask
      // guide={false}
    />
  );
};

FormattedPhone.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

FormattedPhone.defaultProps = {
  value: undefined,
};
