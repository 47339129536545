import {
  Box,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'components/Shared/Modal';
import AssessmentUrl from './../../SharedWidgets/AssessmentUrl';

const useStyles = makeStyles((theme) => ({
  firstTitleStyle: {
    textAlign: 'start',
    padding: theme.spacing(4),
    [theme.breakpoints.down(1000)]: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },
  modal: {
    width: '100%',
    maxWidth: 1000,
    minWidth: 1000,
  },
}));

const ShowAssessmentLinkModal = ({
                                   open,
                                   handleClose,
                                   assessmentLink,
                                 }) => {
  const classes = useStyles();
  const { t } = useTranslation([
    'titles',
  ]);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      classes={{ paper: classes.modal, container: classes.modalContainer }}
      title={t('titles:viewAssessmentLink')}
      firstTitleStyle={classes.firstTitleStyle}
    >
      <Box pt={5} p={4}>
        <AssessmentUrl
          onCloseModal={handleClose}
          link={assessmentLink}
        />
      </Box>
    </Modal>
  );
};

ShowAssessmentLinkModal.propTypes = {
  assessmentLink: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ShowAssessmentLinkModal;
