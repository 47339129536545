import React, { useEffect, useMemo, useState } from 'react';
import {
  InputAdornment,
  InputBase,
  makeStyles, Popover,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SelectedSection from './widgets/SelectedSection';
import SearchSection from './widgets/SearchSection/SearchSection';
import ModalButton from './widgets/ModalButton';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import { getTitle } from './helpers';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  sectionsContainer: {
    display: 'flex',
    overflow: 'hidden',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 24px',
  },
  paper: {
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 24,
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  iconContainer: {
    right: 6,
  },
  popoverRoot: {
    cursor: 'not-allowed',
  },
  paperRoot: {
    cursor: 'default',
  },
}));

const SelectWithDynamicHintFilter = ({
                                       filterValue,
                                       dataKey,
                                       tableName,
                                       onChange,
                                       label,
                                       disabled = false,
                                       tableFilter,
                                     }) => {
  const classes = useStyles();
  const { en: { tables, btn }, en } = useSelector((state) => state.i18n);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [initTableFilter, setInitTableFilter] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const updateOptions = () => {
    if (filterValue) {
      setSelectedOptions(filterValue);
    } else {
      setSelectedOptions([]);
    }
  };

  useEffect(() => {
    if (open) {
      setInitTableFilter(filterValue);
    }
  }, [open]);

  useEffect(() => {
    updateOptions();
  }, []);

  useEffect(() => {
    if (!open) {
      updateOptions();
    }
  }, [filterValue]);


  const applyFilters = (e, newSelectedValue) => {
    let allSelectedOptions = [...selectedOptions];
    if (newSelectedValue) {
      allSelectedOptions = [...allSelectedOptions, newSelectedValue];
    }
    const newSelectedOptions = [...new Set(allSelectedOptions)];
    setSelectedOptions(newSelectedOptions);
    onChange(dataKey, newSelectedOptions);
    setAnchorEl(null);
  };

  const resetSelectedOptionsAndClose = () => {
    setAnchorEl(null);
    onChange(dataKey, initTableFilter);
    if (initTableFilter) {
      setSelectedOptions(initTableFilter);
    } else {
      setSelectedOptions([]);
    }
  };

  const endAdornment = <InputAdornment position='end' className={classes.iconContainer}>
    <ArrowDropDownIcon className={classes.icon} />
  </InputAdornment>;

  const title = useMemo(
    () => {
      if (filterValue) {
        return getTitle(filterValue, en)
      } else {
        return ''
      }
    },
    [filterValue],
  );

  const disableBackdropClick = useMemo(
    () => {
      return !isEqual(selectedOptions, (filterValue || []))
    },
    [selectedOptions, filterValue],
  );

  return (
    <>
      <InputBase
        value={title}
        disabled={disabled}
        label={label}
        id={id}
        placeholder={tables.filter}
        classes={{
          root: classes.inputRoot,
          input: classes.input,
        }}
        onFocus={e => e.target.blur()}
        endAdornment={endAdornment}
        onClick={handleClick}
      />
      <Popover
        disableBackdropClick={disableBackdropClick}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={'bottom-start'}
        onClose={resetSelectedOptionsAndClose}
        transitionDuration={{
          enter: 200,
          exit: 200,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -14,
        }}
        classes={{
          root: cx({ [classes.popoverRoot]: disableBackdropClick }),
          paper: classes.paperRoot,
        }}
      >
        <div className={classes.paper}>
          <div className={classes.sectionsContainer}>
            <SearchSection
              selectedOptions={selectedOptions}
              handleClose={applyFilters}
              setSelectedOptions={setSelectedOptions}
              dataKey={dataKey}
              open={open}
              tableName={tableName}
              tableFilter={tableFilter}
            />
            <SelectedSection
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              handleClose={applyFilters}
              clearAllFilter={() => setSelectedOptions([])}
            />
          </div>
          <div className={classes.buttonsContainer}>
            <ModalButton
              title={btn.cancel}
              variant={'outlined'}
              onClick={resetSelectedOptionsAndClose}
            />
            <ModalButton
              title={btn.applyFilter}
              onClick={applyFilters}
              disabled={false}
            />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default SelectWithDynamicHintFilter;
