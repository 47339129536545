import i18next from 'i18next';

export const stepsNameConstants = {
  selectFile: 'selectFile',
  upload: 'upload',
  validate: 'validate',
  import: 'import',
  finish: 'finish',
};

export const stepsConstants = {
  [stepsNameConstants.selectFile]: {
    message: i18next.t('fileUploading:dragFileHere'),
    buttonTitle: i18next.t('fileUploading:selectFile'),
  },
  [stepsNameConstants.upload]: {
    message: '',
    buttonTitle: i18next.t('fileUploading:uploadAndValidate'),
    successStyle: true,
  },
  [stepsNameConstants.validate]: {
    message: i18next.t('fileUploading:fileHasValidationErrors'),
    buttonTitle: i18next.t('fileUploading:downloadValidationErrors'),
    errorStyle: true,
  },
  [stepsNameConstants.import]: {
    message: i18next.t('fileUploading:fileSuccessfullyValidated'),
    buttonTitle: i18next.t('fileUploading:importPatientsNow'),
  },
  [stepsNameConstants.finish]: {
    message: i18next.t('fileUploading:importWasSuccessful'),
    buttonTitle: i18next.t('fileUploading:ok'),
    successStyle: true,
  },
};
