import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomEmailSectionRow from './CustomEmailSectionRow';
import { CUSTOM_EMAIL_SECTION_FIELDS } from './constants';
import useMetadataUpdate from '../../../../../../hooks/useMetadataUpdate';
import { useQuery } from 'react-fetching-library';
import { formattedAccountsValues } from '../../../../helpers';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  tableHeader: {
    marginLeft: theme.spacing(2),
  },
  dataContainerHeader: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    position: 'sticky',
    top: 0,
    zIndex: 100,
    background: 'white',
    boxShadow: '0 10px 20px -20px gray',
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
  },
}));

const CustomEmailSection = ({
                              form,
                              isDistributionSponsor,
                              backendErrors,
                              setAccount,
                            }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms']);
  const [defaultText, setDefaultText] = useState();

  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/default-text`,
    },
    false,
  ]);

  useEffect(() => {
    query().then(({ error, payload }) => {
      if (!error) {
        const findEmailOrSmsText = (firstKey, secondKey, local) => {
          return payload?.[firstKey]?.[secondKey]?.find(
            (e) => e.language === local,
          )?.text;
        };
        setDefaultText({
          emailSurveyInvitationEnglish: findEmailOrSmsText('emailConfiguration', 'surveyInvitation', 'english'),
          emailSurveyInvitationSpanish: findEmailOrSmsText('emailConfiguration', 'surveyInvitation', 'spanish'),
          emailResourceInvitationEnglish: findEmailOrSmsText('emailConfiguration', 'resourceInvitation', 'english'),
          emailResourceInvitationSpanish: findEmailOrSmsText('emailConfiguration', 'resourceInvitation', 'spanish'),
          emailSurveyReminderEnglish: findEmailOrSmsText('emailConfiguration', 'surveyReminder', 'english'),
          emailSurveyReminderSpanish: findEmailOrSmsText('emailConfiguration', 'surveyReminder', 'spanish'),
          emailResourceReminderEnglish: findEmailOrSmsText('emailConfiguration', 'resourceReminder', 'english'),
          emailResourceReminderSpanish: findEmailOrSmsText('emailConfiguration', 'resourceReminder', 'spanish'),
          emailSurveyCompletionEnglish: findEmailOrSmsText('emailConfiguration', 'surveyCompletion', 'english'),
          emailSurveyCompletionSpanish: findEmailOrSmsText('emailConfiguration', 'surveyCompletion', 'spanish'),

          smsSurveyInvitationEnglish: findEmailOrSmsText('smsConfiguration', 'surveyInvitation', 'english'),
          smsSurveyInvitationSpanish: findEmailOrSmsText('smsConfiguration', 'surveyInvitation', 'spanish'),
          smsResourceInvitationEnglish: findEmailOrSmsText('smsConfiguration', 'resourceInvitation', 'english'),
          smsResourceInvitationSpanish: findEmailOrSmsText('smsConfiguration', 'resourceInvitation', 'spanish'),
          smsSurveyReminderEnglish: findEmailOrSmsText('smsConfiguration', 'surveyReminder', 'english'),
          smsSurveyReminderSpanish: findEmailOrSmsText('smsConfiguration', 'surveyReminder', 'spanish'),
          smsResourceReminderEnglish: findEmailOrSmsText('smsConfiguration', 'resourceReminder', 'english'),
          smsResourceReminderSpanish: findEmailOrSmsText('smsConfiguration', 'resourceReminder', 'spanish'),
          smsSurveyCompletionEnglish: findEmailOrSmsText('smsConfiguration', 'surveyCompletion', 'english'),
          smsSurveyCompletionSpanish: findEmailOrSmsText('smsConfiguration', 'surveyCompletion', 'spanish'),
        });
      }
    });
  }, [query]);

  useEffect(() => {
    if (defaultText) {
      let newTextForFields = {};
      CUSTOM_EMAIL_SECTION_FIELDS.forEach(field => {
        if (
          !form.values[field.firstFieldName] ||
          form.values[field.idDefaultName]
        ) {
          newTextForFields[field.firstFieldName] = defaultText[field.firstFieldName];
        }
        if (
          !form.values[field.secondFieldName] ||
          form.values[field.idDefaultName]
        ) {
          newTextForFields[field.secondFieldName] = defaultText[field.secondFieldName];
        }
      });

      form.setValues({
        ...form.values,
        ...newTextForFields,
      });

      if (setAccount) {
        setAccount(formattedAccountsValues({
          ...form.initialValues,
          ...newTextForFields,
        }));
      }
    }
  }, [defaultText]);

  return (
    <>
      <Typography className={classes.title}>
        {t('forms:editAccountScreen')}:
      </Typography>

      <Grid container spacing={3} className={classes.dataContainerHeader}>
        <Grid item xs={2} md={2} />
        <Grid item xs={1} md={1}>
          <Typography className={classes.tableHeader}>
            {t('forms:overrideDefaultMessage')}:
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <Typography className={classes.tableHeader}>
            {t('forms:english')}:
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <Typography className={classes.tableHeader}>
            {t('forms:spanish')}:
          </Typography>
        </Grid>
      </Grid>

      {
        CUSTOM_EMAIL_SECTION_FIELDS.map(field => {
            return <CustomEmailSectionRow
              isDistributionSponsor={isDistributionSponsor}
              rowName={t(`forms:${field.rowNameFirst}`) + ' ' + t(`forms:${field.rowNameSecond}`)}
              firstFieldName={field.firstFieldName}
              secondFieldName={field.secondFieldName}
              idDefaultName={field.idDefaultName}
              backendErrors={backendErrors}
              defaultFirstFieldText={defaultText ? defaultText[field.firstFieldName] : null}
              defaultSecondFieldText={defaultText ? defaultText[field.secondFieldName] : null}
            />;
          },
        )
      }
    </>
  );
};

CustomEmailSection.propTypes = {
  tradeName: PropTypes.string,
  businessName: PropTypes.string,
  isCurrentAccountEditing: PropTypes.bool,
  isDistributionSponsor: PropTypes.bool,
};

CustomEmailSection.defaultProps = {
  tradeName: '',
  businessName: '',
  isCurrentAccountEditing: false,
  isDistributionSponsor: false,
};

export default CustomEmailSection;

