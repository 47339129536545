import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, FormLabel, FormControl } from '@material-ui/core';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { getFormattedDate } from '../../helpers/localize';
import HelperText from '../Shared/HelperText';
import CalendarPopover from './Calendar/CalendarPopover';

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    padding: '5px 12px',
    borderRadius: 8,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    minWidth: '100%',
    minHeight: 40,
    height: 40,
  },
  roleLabel: {
    margin: theme.spacing(0),
    color: 'rgba(0, 0, 0, 0.54)',
    position: 'absolute',
    backgroundColor: 'white',
    padding: '0 8px',
    fontSize: 12,
    top: -6,
    left: 8,
  },
  disabledRoleLabel: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  m2: {
    [theme.breakpoints.down('xs')]: {},
  },
  tableItem: {
    flex: 1,
  },
  tableInput: {
    marginTop: 0,
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& .MuiOutlinedInput-root': {
      height: 43,
      paddingLeft: 8,
    },
  },
  dateInput: {
    minWidth: '100%',
  },
  disabledField: {
    cursor: 'default',
  },
  errorText: {
    fontSize: '0.75rem',
    marginTop: 8,
  },
  datepickerWrapperWithError: {
    border: `1px solid #dc1212`,
  },
}));

const DataInput = ({
                     name,
                     backendErrors,
                     updateValue,
                     datepickerWrapperClassName,
                     customValue,
                     required,
                     ...props
                   }) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);

  return (
    <Form>
      <div className={classes.m2}>
        <Field required={required} name={name} className={cx(
          classes.dateInput, {
            [classes.disabledField]: props.disabled,
          })}>
          {({ field }) => {
            let date = null;
            const currentValue = customValue ?? field.value;
            if (currentValue) {
              // add offset in minutes to work with date like
              // in UTC
              const newDate = new Date(currentValue);
              if (currentValue.includes('T00:00Z')) {
                const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;
                date = new Date(newDate.getTime() + userTimezoneOffset);
              } else {
                date = newDate;
              }
            }
            return (
              <FormControl
                required={required}
                className={cx(classes.dateInput)}
                {...{
                  error: !!backendErrors[name],
                }}
              >
                <FormLabel
                  {...{
                    className: cx(classes.roleLabel, {
                      [classes.disabledRoleLabel]: props.disabled,
                    }),
                  }}
                >
                  {t(`forms:${name}`)}
                </FormLabel>
                <div
                  className={cx(
                    classes.datepickerWrapper,
                    datepickerWrapperClassName, {
                      [classes.datepickerWrapperWithError]: !!backendErrors[name],
                    },
                  )}
                >
                  <CalendarPopover
                    label={t(`forms:${name}`)}
                    required={required}
                    shortView={true}
                    error={backendErrors[name]}
                    onChange={(newDate) => {
                      if (!newDate) {
                        // clear filter
                        updateValue(name, '');
                      } else {
                        // valid date
                        updateValue(name, getFormattedDate(newDate));
                      }
                    }}
                    date={date}
                    {...props}
                  />
                </div>
                <HelperText className={cx(
                  classes.errorText,
                )}>{backendErrors[name]}</HelperText>
              </FormControl>
            );
          }}
        </Field>
      </div>
    </Form>
  );
};

DataInput.propTypes = {
  updateValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  backendErrors: PropTypes.shape().isRequired,
  datepickerWrapperClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

DataInput.defaultProps = {
  datepickerWrapperClassName: null,
  disabled: false,
};

export default DataInput;
