import React, { useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import showServerError from 'helpers/showError';
import { routes } from 'config/routes';
import { useMutation } from 'react-fetching-library';
import FormikTextField from 'components/FormikFields/FormikTextField';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { AppRouteContext } from 'contexts';
import BackButton from 'components/Shared/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';

const PasswordChange = () => {
  const { t } = useTranslation(['forms', 'notifications', 'btn']);
  const dispatch = useDispatch();
  const [backendErrors, setBackendErrors] = useState({});
  const { setAppRoute } = useContext(AppRouteContext);
  const { user } = useSelector((state) => state.user);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const passwordChangeAction = (values) => ({
    method: 'POST',
    endpoint: '/user/password',
    body: values,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [
    passwordChangeAction,
  ]);

  const changePassword = async (values, { setSubmitting }) => {
    setBackendErrors({});
    const { payload, error, status } = await mutate(values);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      setSubmitting(false);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };

      switch (status) {
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:passwordHasBeenChanged'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      setAppRoute({
        path: routes.root.path,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validateOnBlur={false}
      onSubmit={changePassword}
    >
      {({ isValid, isSubmitting, dirty, handleSubmit }) => (
        <ContentWrapper
          titleText={t('titles:changePassword')}
          actions={
            <>
              <BackButton />
              <RoundedButton
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                disabled={!isValid || isSubmitting || !dirty}
                isLoading={loading}
                isSuccess={isSuccess}
                isFailed={isFailed}
                onClick={handleSubmit}
              >
                {t('btn:updatePassword')}
              </RoundedButton>
            </>
          }
        >
          <Form>
            <Grid container direction="column">
              <FormikTextField
                type="password"
                name="password"
                label={t('forms:password')}
                backendError={backendErrors.password}
                autoComplete="new-password"
                required
              />
              <FormikTextField
                type="password"
                name="confirmPassword"
                label={t('forms:confirmPassword')}
                backendError={backendErrors.confirmPassword}
                autoComplete="new-password"
                required
              />
            </Grid>
          </Form>
        </ContentWrapper>
      )}
    </Formik>
  );
};

export default PasswordChange;
