import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../../client';
import qs from 'qs';

const FIELDS = ['businessName', 'customIdentifier', 'address'];

export const fetchAccounts = createAsyncThunk(
  'accounts/fetchAccounts',
  async () => {
    return await client.get(`/accounts${qs.stringify({ fields: FIELDS.join() }, {
      addQueryPrefix: true,
    })}`);
  },
);
