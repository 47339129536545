import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, FormHelperText, FormLabel, makeStyles } from '@material-ui/core';
import showServerError from 'helpers/showError';
import { getFormattedDate } from 'helpers/localize';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-fetching-library';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../../../redux_store/reducer/reducers/notificationsReducer';
import { downloadFile } from '../../../../Billing/helpers';
import Modal from '../../../../Shared/Modal';
import CalendarPopover from '../../../../FormikFields/Calendar/CalendarPopover';
import RoundedButton from '../../../../Shared/RoundedButton';
import AccountsSelect from './AccountsSelect';
import { checkPermission } from '../../../../../helpers/checkPermissions';
import permissions from '../../../../../config/permissions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
  },
  modalRow: {
    width: 'calc(100% - 24px)',
    margin: 12,
    padding: 0,
  },
  classesColumn: {
    width: '100%',
    margin: 'auto',
    maxWidth: 310,
  },
  datepickerWrapper: {
    padding: '5px 12px',
    borderRadius: 8,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    width: '100%',
    minHeight: 40,
  },
  datepickerWrapperWithError: {
    border: `1px solid #dc1212`,
  },
  roleLabel: {
    marginTop: 20,
    margin: theme.spacing(0.5),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  formError: {
    width: '100%',
    height: 20,
  },
}));

const OutstandingClaimReportPdfModal = ({
  toggleDownloadPdfModal,
  openDownloadPdfModal,
}) => {
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { accountUuid: paramAccountUuid } = useParams();
  const [pdfData, setPdfData] = useState('');
  const [currentAccountUuidForPdf, setCurrentAccountUuidForPdf] = useState();
  const [dataValidationError, setDataValidationError] = useState(false);
  const {
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  React.useEffect(() => {
    setCurrentAccountUuidForPdf(paramAccountUuid || currentAccount.uuid);
  }, []);

  const closePdfModal = () => {
    toggleDownloadPdfModal(false);
    setPdfData('');
    setDataValidationError(false);
    setCurrentAccountUuidForPdf(paramAccountUuid || currentAccount.uuid);
  };

  const importFile = (values) => ({
    method: 'POST',
    endpoint: `/accounts/${currentAccountUuidForPdf}/outstanding-claim-report.pdf`,
    body: values,
    responseType: 'blob',
  });

  const { mutate: importMutate, loading: pdfLoading } = useMutation(importFile);

  const downloadBillingPDF = async () => {
    const dataArr = pdfData.split(',').map((data) => {
      const dataParsedArr = data.split('/');
      return `${dataParsedArr[2]}-${dataParsedArr[0]}-${dataParsedArr[1]}`;
    });
    const config = {
      fromDate: dataArr[0],
      toDate: dataArr[1],
    };

    const { payload, error, status } = await importMutate(config);

    if (!error) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      downloadFile(new Blob([payload]), `Claims_${pdfData}.pdf`);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:pdfSucceededSaved'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      closePdfModal();
    } else {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      switch (status) {
        case 422:
        case 500: {
          showServerError(dispatch, t('errors:validationError'));
          setDataValidationError(true);
          break;
        }
        default: {
          showServerError(dispatch, t('errors:unknownError'));
          break;
        }
      }
    }
  };

  const handleDateChange = (newDate) => {
    setDataValidationError(false);
    if (newDate[0] == null) {
      setPdfData('');
    } else {
      const parsedVal = newDate.map((data) => getFormattedDate(data));
      setPdfData(parsedVal.join(','));
    }
  };


  let value;
  if (!pdfData) {
    value = null;
  } else {
    value = pdfData.split(',').map((data) => new Date(data));
  }

  return (
    <Modal
      open={openDownloadPdfModal}
      classes={{ paper: classes.modal }}
      title={t('titles:downloadClaim')}
      handleClose={() => toggleDownloadPdfModal(false)}
    >
      <Box pt={5} p={4} className={classes.modalRow}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          className={cx(classes.modalRow, classes.classesColumn)}
        >
          {isDistributionSponsor && (
            <AccountsSelect
              currentAccountUuidForPdf={currentAccountUuidForPdf}
              setCurrentAccountUuidForPdf={setCurrentAccountUuidForPdf}
            />
          )}
          <FormLabel
            {...{
              className: classes.roleLabel,
            }}
          >
            {t('titles:selectRangeDate')}
          </FormLabel>
          <div
            className={cx(classes.datepickerWrapper, {
              [classes.datepickerWrapperWithError]: dataValidationError,
            })}
          >
            <CalendarPopover
              width={130}
              shouldReduceFontSize={false}
              shouldRange
              onChange={handleDateChange}
              date={value}
            />
          </div>
          <FormHelperText error className={classes.formError}>
            {dataValidationError && 'Data is invalid'}
          </FormHelperText>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.modalRow}
        >
          <RoundedButton
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => toggleDownloadPdfModal(false)}
            className={classes.createButton}
          >
            {t('btn:cancel')}
          </RoundedButton>
          <RoundedButton
            variant="contained"
            color="primary"
            size="small"
            disabled={pdfLoading}
            onClick={downloadBillingPDF}
            isLoading={pdfLoading}
            isSuccess={isSuccess}
            isFailed={isFailed}
            className={classes.createButton}
          >
            {t('btn:downloadPDF')}
          </RoundedButton>
        </Box>
      </Box>
    </Modal>
  );
};

OutstandingClaimReportPdfModal.propTypes = {
  openDownloadPdfModal: PropTypes.string.isRequired,
  toggleDownloadPdfModal: PropTypes.func.isRequired,
};

export default OutstandingClaimReportPdfModal;
