import React  from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ReactComponent as LogoIcon } from 'assets/icons/qlab-logo.svg';
import cx from 'classnames';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    height: 84,
    minHeight: 84,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 24,
    marginLeft: theme.spacing(1),
    fill: theme.palette.common.white,
    minWidth: 135,
  },
  logoWrapper: {
    position: 'absolute',
    left: 12,
    width: '100%',
    overflow: 'hidden',
    opacity: 1,
    transition: 'opacity .3s',
    height: 24,
  },
}));

const NavLogo = () => {
  const classes = useStyles();
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);
  return <Grid className={classes.logoContainer}>
    <div className={cx(classes.logoWrapper)}
         style={{ opacity: isSideMenuPinned ? 1 : 0 }}
    >
      <LogoIcon className={classes.logo} />
    </div>
    <div className={cx(classes.logoWrapper)}
         style={{
           width: 48,
           opacity: isSideMenuPinned ? 0 : 1,
         }}
    >
      <LogoIcon className={classes.logo} />
    </div>
  </Grid>;
};

export default NavLogo;
