import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}));

const Loader = ({ fullHeight, size, className, loaderColor, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={cx(
        {
          [classes.fullHeight]: fullHeight,
        },
        className,
      )}
    >
      <CircularProgress
        color={loaderColor ?? 'primary'}
        size={size}
        {...rest}
      />
    </Grid>
  );
};

Loader.propTypes = {
  fullHeight: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
  loaderColor: PropTypes.string,
};

Loader.defaultProps = {
  fullHeight: false,
  size: 40, // MUI default value
  className: undefined,
  loaderColor: undefined,
};

export default Loader;
