import {
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import cx from 'classnames';
import Timer from '@material-ui/icons/Timer';
import CancelIcon from '@material-ui/icons/Cancel';
import { getDateFromMinuteOffset } from '../../../../../helpers/dateHelper';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  datepickerLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.8rem',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: -12,
    left: 9,
    padding: 4,
    zIndex: 1,
  },
  timePicker: {
    padding: 0,

    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 16,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 2,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(0px, 0px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0px, 0px) scale(1)',
    },
  },
  scheduledInputsTime: {
    maxWidth: 180,
  },
  relativeContainer: {
    position: 'relative',
  },
  icon: {
    width: 15,
    height: 15,
  },
  iconPadding: {
    padding: 8,
  },
  inputPadding: {
    padding: '10px 0 !important',
  },
}));

const TimePicker = ({ name, initValue, required = false, backendErrors, setBackendErrors }) => {
  const classes = useStyles();
  const { en: { forms } } = useSelector((state) => state.i18n);
  const [selectedTime, handleTimeChange] = useState(
    !!initValue
      ? getDateFromMinuteOffset(initValue)
      : null,
  );


  return <div className={classes.scheduledInputsTime}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid
        container
        justifyContent='space-around'
        className={classes.relativeContainer}
      >
        <Field name={name} type='time'>
          {({ field, form: { errors, setFieldValue, validateField } }) => {
            return <div
              style={{
                position: 'relative',
              }}
            >
              <KeyboardTimePicker
                label={forms[name]}
                inputVariant='outlined'
                className={cx(classes.timePicker, classes.fullWidth)}
                required={required}
                placeholder='__:__ _M'
                value={selectedTime}
                name={field.name}
                error={errors[name] || backendErrors[name]}
                helperText={errors[name] || backendErrors[name]}
                KeyboardButtonProps={{ 'aria-label': 'change time' }}
                onChange={(e) => {
                  handleTimeChange(e);
                  if (e._isValid) {
                    setFieldValue(
                      name,
                      e.hour() * 60 +
                      e.minutes(),
                    );

                    if (errors[name]) {
                      setTimeout(() => validateField(name));
                    }
                    if (backendErrors[name]) {
                      setTimeout(() => setBackendErrors({ ...backendErrors, [name]: null }),);
                    }
                  }
                }}
                onBlur={() => {
                  try {
                    if (selectedTime._isValid) {
                      setFieldValue(
                        name,
                        selectedTime.hour() * 60 +
                        selectedTime.minutes(),
                      );

                      if (errors[name]) {
                        setTimeout(() => validateField(name));
                      }
                      if (backendErrors[name]) {
                        setTimeout(() => setBackendErrors({ ...backendErrors, [name]: null }),);
                      }
                    } else {
                      throw new Error('Invalid data');
                    }
                  } catch (e) {
                    handleTimeChange(null);
                    setFieldValue(
                      name,
                      null,
                    );
                  }
                }}
                keyboardIcon={<Timer color='primary' className={classes.icon} />}
                InputLabelProps={{ classes: { root: classes.datepickerLabel } }}
                InputProps={{
                  classes: { input: classes.inputPadding },
                  endAdornment: (
                    <IconButton
                      className={classes.iconPadding}
                      onClick={() => {
                        setFieldValue(name, null);
                        handleTimeChange(null);
                      }}
                    >
                      <CancelIcon className={classes.icon} />
                    </IconButton>
                  ),
                }}
                InputAdornmentProps={{ position: 'start' }}
              />
            </div>;
          }}
        </Field>
      </Grid>
    </MuiPickersUtilsProvider>
  </div>;
};

TimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  initValue: PropTypes.string,
};

TimePicker.defaultProps = {
  required: false,
  initValue: null,
};

export default TimePicker;
