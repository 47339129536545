import React, { useMemo } from 'react';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import RoundedButton from '../../../../../Shared/RoundedButton';
import { getTransitionWithVariable } from '../../../../../../redux_store/reducer/reducers/i18nReducer';
import InfoIcon from '@material-ui/icons/Info';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    padding: 16,
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectAllContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    position: 'relative',
  },
  text: {
    marginLeft: 16,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  link: {
    marginLeft: 16,
    fontSize: 14,
    fontWeight: 500,
    color: theme.colors.orange,
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: '0 0 0 8px',
  },
  automationIcon: {
    color: '#0077b5',
    marginLeft: 8,
  },
  tooltip: {
    zIndex: 100,
    position: 'absolute',
    top: -5,
    right: -5,
    width: 18,
    height: 18,
  },
  tooltipBackground: {
    zIndex: 100,
    position: 'absolute',
    top: -4,
    right: -4,
    width: 14,
    height: 14,
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  undoContainer: {
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    transition: 'opacity 0.3s ease-in',
    opacity: 1,
  },
  hiddenUndoContainer: {
    transition: 'opacity 0.3s ease-in',
    opacity: 0,
  },
}));


const AllowedAccountsModalHeader = ({
                                      accounts,
                                      includedAccountsUUIDs,
                                      excludedAccountsUUIDs,
                                      prevCheckboxesState,
                                      updateUuids,
                                      setPrevCheckboxesState,
                                    }) => {
  const { en: { forms } } = useSelector((state) => state.i18n);
  const classes = useStyles();

  const onSelectAllFilteredClick = () => {
    setPrevCheckboxesState({
      includedAccountsUUIDs,
      excludedAccountsUUIDs,
    });

    const newIncludedAccounts = [...new Set([...includedAccountsUUIDs, ...accounts.map(item => item.uuid)])];
    const newExcludedAccounts = excludedAccountsUUIDs.filter(item => !newIncludedAccounts.includes(item));

    updateUuids({
      includedAccountsUUIDs: newIncludedAccounts,
      excludedAccountsUUIDs: newExcludedAccounts,
    })
  };

  const onUndoClick = () => {
    updateUuids({
      includedAccountsUUIDs: prevCheckboxesState.includedAccountsUUIDs,
      excludedAccountsUUIDs: prevCheckboxesState.excludedAccountsUUIDs,
    })
    setPrevCheckboxesState(null);
  };

  const allSelectedInTheList = useMemo(
    () => {
      return !accounts?.some(item => !includedAccountsUUIDs.includes(item.uuid));
    },
    [accounts, includedAccountsUUIDs, excludedAccountsUUIDs],
  );

  const hasPrevCheckboxesState = (
    prevCheckboxesState?.includedAccountsUUIDs?.length
    + prevCheckboxesState?.excludedAccountsUUIDs?.length) > 0;
  const countIncludedAccountsUUIDs = includedAccountsUUIDs.length;

  return <>
    <div className={classes.headerContainer}>
      <Typography style={{ width: '100%' }} className={classes.text}>
        {getTransitionWithVariable(forms.accountAccessTotalNumber, {
          number: countIncludedAccountsUUIDs === 0
            ? 'all'
            : countIncludedAccountsUUIDs,
          end: countIncludedAccountsUUIDs === 1
            ? ''
            : 's',
        })}
      </Typography>
      <div className={classes.selectAllContainer}>
        <RoundedButton
          variant='contained'
          color='primary'
          onClick={() => onSelectAllFilteredClick(allSelectedInTheList)}
          disabled={!accounts || !accounts.length || allSelectedInTheList}
        >
          {forms.selectAll}
        </RoundedButton>
        <div className={classes.tooltipBackground} />
        <Tooltip
          onClick={!accounts || !accounts.length || allSelectedInTheList ? null : () => onSelectAllFilteredClick(allSelectedInTheList)}
          title={forms.selectAllInfo}
          className={classes.tooltip}
        >
          <InfoIcon className={classes.automationIcon} />
        </Tooltip>
      </div>
    </div>
    <div className={cx(classes.undoContainer, {
      [classes.hiddenUndoContainer]: !hasPrevCheckboxesState,
    })}>
      <Typography className={classes.text}>
        {forms.allAccountsSelected}
      </Typography>
      <Typography className={classes.link} onClick={onUndoClick}>
        {forms.undo}
      </Typography>
    </div>
  </>;
};

export default AllowedAccountsModalHeader;
