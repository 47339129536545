import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-fetching-library';
import PatientEditModal from './PatientEditModal';
import AssessmentCreate from '../Assessments/Item/AssessmentCreate';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import ResourceCreate from '../Resources/ResourceCreate';
import useMetadataUpdate from '../../hooks/useMetadataUpdate';
import { checkPermission } from '../../helpers/checkPermissions';
import permissions from '../../config/permissions';
import { useSelector } from 'react-redux';

const PatientModals = ({
  openPatientEditModal,
  handleClosePatientEditModal,
  openAssessmentModal,
  handleCloseAssessmentModal,
  openResourceModal,
  handleCloseResourceModal,
  reloadData,
}) => {
  const { patientInfo } = useContext(ActivePatientContext);
  const [account, setAccount] = useState();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const {
    metadata: { currentAccountPermissions },
  } = useSelector((state) => state.user);
  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${paramAccountUuid}`,
    },
    false,
  ]);

  useEffect(() => {
    if (paramAccountUuid) {
      query().then(({ error, payload }) => {
        if (!error) {
          setAccount(payload);
        }
      });
    }
  }, [paramAccountUuid]);

  const isResourcesAvailable = checkPermission(
    currentAccountPermissions,
    permissions.GET_RESOURCES,
  );

  return (
    <>
      <PatientEditModal
        open={openPatientEditModal}
        patientUuid={paramsPatientUuid}
        accountUuid={paramAccountUuid}
        handleClose={handleClosePatientEditModal}
      />
      {account ? (
        <>
          <AssessmentCreate
            patient={patientInfo}
            open={openAssessmentModal}
            accountUuid={paramAccountUuid}
            handleClose={handleCloseAssessmentModal}
            reloadData={reloadData}
            scheduleDeliverTime={account?.scheduleDeliverTime ?? null}
          />
          {isResourcesAvailable && (
            <ResourceCreate
              patient={patientInfo}
              open={openResourceModal}
              accountUuid={paramAccountUuid}
              handleClose={handleCloseResourceModal}
              reloadData={reloadData}
              scheduleDeliverTime={account?.scheduleDeliverTime ?? null}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

PatientModals.propTypes = {
  handleCloseAssessmentModal: PropTypes.func.isRequired,
  openAssessmentModal: PropTypes.bool.isRequired,
  handleClosePatientEditModal: PropTypes.func.isRequired,
  openPatientEditModal: PropTypes.bool.isRequired,
  reloadData: PropTypes.func.isRequired,
  openResourceModal: PropTypes.bool.isRequired,
  handleCloseResourceModal: PropTypes.func.isRequired,
};

PatientModals.defaultProps = {};

export default PatientModals;
