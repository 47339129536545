import { useState, useEffect } from 'react';
import { useQuery } from 'react-fetching-library';

import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { getEndpoint, getTokenEndpoint } from 'helpers/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import showServerError from '../helpers/showError';
import { REQUEST_STATUS } from '../redux_store/constants';

const useExportCsv = ({ baseEndpoint, baseParams, sort, filter }) => {
  const [list, setList] = useState({});
  const [status, setStatus] = useState(REQUEST_STATUS.idle);
  const dispatch = useDispatch();
  const { en: { errors } } = useSelector((state) => state.i18n);
  const [params, setParams] = useState({
    endpoint: '',
    allFetched: null, // all items fetched flag
  });
  const { endpoint, allFetched } = params;

  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint,
    },
    false,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setStatus(REQUEST_STATUS.loading);
      const { error, payload, status } = await query();
      if (!error) {
        const newList = {
          ...payload,
        };
        if (endpoint.includes('continuation-token')) {
          newList.items = [].concat(list.items, payload.items);
        }
        setList(newList);

        if (newList.continuationToken) {
          setParams({
            allFetched: false,
            endpoint: getTokenEndpoint(baseEndpoint, newList.continuationToken),
          });
        } else {
          setParams({
            allFetched: true,
            endpoint: '',
          });
          setStatus(REQUEST_STATUS.succeeded);
          setTimeout(() => {
            setStatus(REQUEST_STATUS.idle);
          }, 2000);
        }
      } else {
        const options = payload && {
          correlationUUID: payload.correlationUUID,
        };
        switch (status) {
          case 422: {
            showServerError(dispatch, errors.validationError);
            break;
          }
          default: {
            showServerError(dispatch, '', options);
            break;
          }
        }
        setParams({
          allFetched: true,
          endpoint: '',
        });
        setStatus(REQUEST_STATUS.failed);
        setTimeout(() => {
          setStatus(REQUEST_STATUS.idle);
        }, 2000);
      }
    };

    if (endpoint) {
      fetchData();
    }
  }, [endpoint, query]);

  const fetchExportItems = () => {
    setParams({
      allFetched: false,
      endpoint: getEndpoint(baseEndpoint, baseParams, sort, filter),
    });
  };

  return {
    fetchExportItems,
    exportItems: allFetched ? list.items : null,
    exportItemsLoadingStatus: status,
  };
};

export default useExportCsv;
