/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Box,
  fade,
  Grid,
  makeStyles,
} from '@material-ui/core';
import CollapseBlock from 'components/Shared/CollapseBlock';
import ReportViewTable from '../../Shared/ReportViewTable';
import MarkdownParser from './../widgets/MarkdownParser';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  collapseTabsContainer: {
    position: 'relative',
    marginRight: 56,
    transition: 'margin-right 0.2s linear',
  },
  pinnedNavigation: {
    marginRight: 280,
  }
}));

const ReportContent = ({
                         surveysInfo,
                         refs,
                         updateCurrentBlockId,
                         currentBlockId,
                         setOpenBlock,
                         isNavigationPinned,
                         isComparing,
                       }) => {
  const classes = useStyles();
  return <div
    id={'scrollArea'}
    className={cx(classes.collapseTabsContainer, {
    [classes.pinnedNavigation]: isNavigationPinned
  })}>
    <Box pl='4px' pt={'16px'}>
      {
        surveysInfo.surveyItems.map(({
                                       title,
                                       surveyItems,
                                       colors = {},
                                       reportContent,
                                       hideAutoGeneratedReportContent,
                                     }) => (
            !!hideAutoGeneratedReportContent === true && !!reportContent
              ? null
              : <CollapseBlock
                refs={refs}
                colors={colors}
                setCurrentBlockId={updateCurrentBlockId}
                title={title}
                currentBlockId={currentBlockId}
                setOpenBlock={setOpenBlock}
                reportContent={reportContent}
              >
                {
                  reportContent && <MarkdownParser content={reportContent} />
                }
                {
                  !!hideAutoGeneratedReportContent === true && !reportContent
                    ? null
                    : <ReportViewTable
                      surveyList={surveyItems}
                      counterColoredAnswers={colors}
                      isComparing={isComparing}
                    />
                }
              </CollapseBlock>
          ),
        )
      }
    </Box>
  </div>;
};

export default ReportContent;

