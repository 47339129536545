import React from 'react';
import TablePlaceholder from '../../../../../../../../Shared/TablePlaceholder';
import { getTransitionWithVariable } from '../../../../../../../../../redux_store/reducer/reducers/i18nReducer';
import { useSelector } from 'react-redux';

const NoCptWidget = () => {
  const { en: { btn, placeholders } } = useSelector((state) => state.i18n);
  return <div>
    <TablePlaceholder
      itemsNotFoundText={placeholders.noCPTCodes}
      message={getTransitionWithVariable(placeholders.addCPTCodes, {
        item: btn.addNew,
      })}
    />
  </div>;
};

export default NoCptWidget;
