import React  from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import theme from 'config/theme';
import i18n from 'config/i18n';
import history from 'config/history';
import 'index.css';
import App from 'components/App/App';
import store from './redux_store/store';
import NotificationsProvider from './contexts/NotificationsProvider';
import ClientProvider from './contexts/ClientProvider';

ReactDOM.render(
  <>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <NotificationsProvider>
          <Provider store={store}>
            <ClientProvider>
              <BrowserRouter history={history}>
                <App />
              </BrowserRouter>
            </ClientProvider>
          </Provider>
        </NotificationsProvider>
      </I18nextProvider>
    </MuiThemeProvider>
  </>,
  document.getElementById('root'),
);
