import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  MenuItem,
  TextField,
  Popover,
  InputAdornment,
  Checkbox,
  Divider,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import cx from 'classnames';
import ReactPaginate from 'react-paginate';
import './index.css';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TablePlaceholder from '../../Shared/TablePlaceholder';
import accountsPlaceholderImage from '../../../assets/placeholders/accounts.svg';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import Loader from '../../Shared/Loader';

const defaultItemsPerPage = 6;

const selectAllOption = {
  label: 'Select All Option',
  value: 'selectAllOption',
};

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: '100%',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  activitiesError: {
    color: theme.palette.error.main,
  },
  fullWidth: {
    width: '100%',
    margin: '0 !important',
  },
  statesSelect: {
    marginTop: theme.spacing(1),
    height: 44,
  },
  popover: {
    width: '100%',
    maxWidth: '100%',
  },
  popoverItemContainer: {
    height: 'auto',
    margin: '16px 0',
  },
  noItemsFound: {
    margin: 20,
  },
  popoverItemContainerWithSearch: {
    height: 36 * defaultItemsPerPage,
  },
  searchInput: {
    width: 'calc(100% - 40px)',
    margin: 20,
    marginBottom: 4,
  },
  iconContainer: {
    right: 6,
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  automationIcon: {
    color: '#0366d6',
    position: 'absolute',
    left: 16,
    height: 38,

    '&$checked': {
      color: '#0366d6',
    },
  },
  warningIcon: {
    color: 'red',
    width: 16,
    marginLeft: 4,

    '&$checked': {
      color: 'red',
    },
  },
  input: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  automationCheckbox: {
    position: 'absolute',
    zIndex: 2,
    height: 38,
    marginLeft: 6,
  },
  disabledCheckbox: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
      cursor: 'not-allowed',
    },
  },
  redStyle: {
    color: 'red',
  },
  selectorIcon: {
    top: 'revert',
  },
}));

const PaginationMenuSelect = ({
                                filterValue,
                                dataKey,
                                label,
                                columnData: { options },
                                onChange,
                                onClose,
                                error,
                                multiSelect,
                                disabled,
                                optionalCheckBoxKey,
                                onOptionalCheckboxTap,
                                hideOptionalCheckBox,
                                customItemsPerPage,
                                customChild,
                                required,
                                hideEmptyValue,
                                underSearchChild,
                                showEmptyWidget,
                                minHeight,
                                fixWidth,
                                shouldAlwaysShowSearch,
                                showLoadingWidget,
                              }) => {
  const { t } = useTranslation(['placeholders', 'titles', 'forms']);
  const classes = useStyles();
  const inputRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [showedInputValue, setShowedInputValue] = useState('');
  const [itemOffset, setItemOffset] = useState(0);
  const open = Boolean(anchorEl);
  const hasOptionalCheckBox = !!optionalCheckBoxKey;

  const itemsPerPage = customItemsPerPage ?? defaultItemsPerPage;

  const isAllSelected = () => {
    const filteredArray = options.filter(value => filterValue.includes(value.value));
    return !showLoadingWidget && options.length && options.length === filteredArray.length;
  };

  const getOptions = (options) => {
    return multiSelect ? [selectAllOption, ...options] : options;
  };

  const allItems = getOptions(options);
  const [filteredOptions, setFilteredOptions] = useState(allItems);
  const getLabelById = (id) => {
    return allItems.find((option) => option.value === id)?.label;
  };

  useEffect(() => {
    if (filterValue === '') {
      inputRef.current.value = '';
    }
  }, [filterValue]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return,consistent-return
    const filtered = options.filter((option) => {
      if (option.label.toLowerCase().includes(searchValue.toLowerCase())) {
        return option;
      }
    });
    setFilteredOptions(getOptions(filtered));
    setItemOffset(0);
  }, [searchValue]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return,consistent-return
    const filtered = options.filter((option) => {
      if (option.label.toLowerCase().includes(searchValue.toLowerCase())) {
        return option;
      }
    });
    setFilteredOptions(getOptions(filtered));
  }, [options]);

  useEffect(() => {
    if (filterValue && !showedInputValue) {
      changeInputValue(filterValue);
    }
  }, []);

  const changeInputValue = (filterValue) => {
    if (multiSelect) {
      let result = '';
      filterValue.forEach((item) => {
        result =
          result +
          (result.length > 0 ? ', ' : '') +
          getLabelById(hasOptionalCheckBox ? item.key : item);
      });
      setShowedInputValue(result);
    } else {
      setShowedInputValue(
        getLabelById(filterValue) ?? (hideEmptyValue ? '' : ' '),
      );
    }
  };

  useEffect(() => {
    if (allItems) {
      changeInputValue(filterValue);
    }
  }, [allItems, filterValue]);

  const handlePopoverOpen = (event) => {
    inputRef.current && inputRef.current.focus();
    setAnchorEl(event.currentTarget);
    changeInputValue(filterValue);
  };

  const handlePopoverClose = () => {
    setSearchValue('');
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
    setItemOffset(0);
  };

  const currentItems = filteredOptions.slice(
    itemOffset * itemsPerPage,
    (itemOffset + 1) * itemsPerPage,
  );
  const pageCount = Math.ceil(filteredOptions.length / itemsPerPage);

  const handlePageClick = (event) => {
    if (event.nextSelectedPage === undefined) {
      return;
    }
    setItemOffset(event.nextSelectedPage);
  };

  const onDataChange = (value) => {
    if (!multiSelect) {
      onChange(dataKey, value);
      changeInputValue(value);
      handlePopoverClose();
    } else {
      if (value === selectAllOption.value) {
        let hasDiff = false;
        filteredOptions.forEach(({ value }) => {
          if (hasOptionalCheckBox) {
            const isValueExist =
              filterValue &&
              filterValue.find((selected) => {
                return selected.key === value;
              });
            if (!isValueExist && value !== selectAllOption.value) {
              hasDiff = true;
            }
          } else {
            if (
              !filterValue.includes(value) &&
              value !== selectAllOption.value
            ) {
              hasDiff = true;
            }
          }
        });
        const shouldClearList =
          !hasDiff && filterValue.length === filteredOptions.length - 1;
        if (shouldClearList) {
          onChange(dataKey, []);
          changeInputValue([]);
        } else {
          let newValues = [];
          filteredOptions.forEach((option) => {
            if (option.value !== selectAllOption.value) {
              const newValue = hasOptionalCheckBox
                ? {
                  key: option.value,
                  [optionalCheckBoxKey]: false,
                }
                : option.value;

              newValues = [...newValues, newValue];
            }
          });
          onChange(dataKey, newValues);
          changeInputValue([selectAllOption, ...newValues]);
        }
      } else {
        let valueIsSelectedList = [...filterValue].filter((_value) => {
          return hasOptionalCheckBox ? value !== _value.key : value !== _value;
        });
        if (valueIsSelectedList.length === filterValue.length) {
          const newValue = hasOptionalCheckBox
            ? {
              key: value,
              [optionalCheckBoxKey]: false,
            }
            : value;
          valueIsSelectedList = [...filterValue, newValue];
        }
        onChange(dataKey, valueIsSelectedList);
        changeInputValue(valueIsSelectedList);
      }
    }
  };

  const setDebounceSearchValue = debounce(setSearchValue, 500);
  const shouldShowSearch = allItems.length > itemsPerPage || shouldAlwaysShowSearch;
  const shouldShowPagination = filteredOptions.length > itemsPerPage;

  return (
    <>
      {!customChild && (
        <TextField
          id='outlined-select-currency'
          label={label}
          required={required}
          margin='dense'
          variant='outlined'
          error={error}
          value={showedInputValue}
          disabled={disabled}

          onBlur={() => {
            if (showedInputValue) {
              inputRef.current.value = showedInputValue;

            }
          }}
          inputRef={inputRef}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end' className={classes.iconContainer}>
                <ArrowDropDownIcon className={classes.icon} />
              </InputAdornment>
            ),
            style: {
              paddingRight: 6,
            },
            classes: {
              input: classes.input,
              icon: classes.selectorIcon,
            },
          }}
          onClick={disabled ? null : handlePopoverOpen}
          className={cx(classes.statesSelect, classes.fullWidth)}
        />
      )}
      {!!customChild && (
        <IconButton
          size='small'
          inputRef={inputRef}
          onClick={disabled ? null : handlePopoverOpen}
          className={classes.filterButton}
        >
          {customChild}
        </IconButton>
      )}
      <Popover
        classes={classes.popover}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: fixWidth || shouldShowSearch ? `410px` : 'auto',
            minWidth: 250,
            minHeight: minHeight,
          },
        }}
        onClose={handlePopoverClose}
      >
        {shouldShowSearch && (
          <TextField
            className={classes.searchInput}
            label='Search'
            onChange={(event) =>
              setDebounceSearchValue(event.currentTarget.value)
            }
          />
        )}
        {underSearchChild}
        <div
          className={cx(classes.popoverItemContainer, {
            [classes.popoverItemContainerWithSearch]:
            shouldShowSearch && shouldShowPagination,
          })}
        >
        {currentItems.map((option) => {
            let additionalCheckBoxValue;
            let isItemSelected = false;

            if (multiSelect && filterValue && Array.isArray(filterValue)) {
              filterValue.find((value) => {
                if (hasOptionalCheckBox && value.key === option.value) {
                  additionalCheckBoxValue = value[optionalCheckBoxKey];
                  isItemSelected = true;
                  return true;
                }
                if (!hasOptionalCheckBox && value === option.value) {
                  isItemSelected = true;
                  return true;
                }
                return false;
              });
            }

            const isOptionSelected =
              option.value === selectAllOption.value
                ? isAllSelected()
                : isItemSelected;

            return (
              <>
                {hasOptionalCheckBox &&
                !hideOptionalCheckBox &&
                option.value !== selectAllOption.value && (
                  <Checkbox
                    classes={{
                      root: classes.automationCheckbox,
                      checked: classes.automationCheckbox,
                      input: classes.automationCheckbox,
                      disabled: classes.disabledCheckbox,
                    }}
                    color='primary'
                    checked={!!additionalCheckBoxValue}
                    disabled={
                      !isOptionSelected || option.disableOptionCheckbox
                    }
                    onChange={(event) => {
                      onOptionalCheckboxTap(
                        event,
                        option.value,
                        !additionalCheckBoxValue,
                      );
                    }}
                  />
                )}
                {hasOptionalCheckBox &&
                !hideOptionalCheckBox &&
                option.value === selectAllOption.value && (
                  <Tooltip title='Enable Automation' style={{ zIndex: 100 }}>
                    <InfoIcon className={classes.automationIcon} />
                  </Tooltip>
                )}
                <MenuItem
                  style={{
                    height: 38,
                    paddingLeft:
                      multiSelect &&
                      hasOptionalCheckBox &&
                      !hideOptionalCheckBox
                        ? 40
                        : null,
                  }}
                  key={option.value}
                  value={option.value}
                  onClick={() => onDataChange(option.value)}
                >
                  {multiSelect && (
                    <Checkbox checked={!!isOptionSelected} color={'primary'} />
                  )}
                  <Typography
                    key={option.label}
                    noWrap
                    className={cx(classes.text, classes.currentTitle)}
                  >
                    {option.label}
                  </Typography>
                  {option.required && (
                    <Tooltip
                      title='At least one of the account columns must be selected for a Distribution Sponsor'>
                      <WarningIcon className={classes.warningIcon} />
                    </Tooltip>
                  )}
                </MenuItem>
                {option.value === selectAllOption.value && (
                  <Divider className={classes.divider} />
                )}
              </>
            );
          })}
        {(showEmptyWidget || filteredOptions.filter(item => item.value !== "selectAllOption").length === 0) && !showLoadingWidget && (
          <div className={classes.noItemsFound}>
            <TablePlaceholder
              image={accountsPlaceholderImage}
              itemsNotFoundText={t('placeholders:noItemsFound', {
                item: t('titles:items'),
              })}
              message={t('placeholders:pleaseChangeSearchText')}
            />
          </div>
        )}
        {showLoadingWidget && (
          <div className={classes.noItemsFound}>

            <TablePlaceholder
              customImageWidget={
                <div style={{
                  margin: 20,
                }}>
                  <Loader />
                </div>
              }
              itemsNotFoundText={t('titles:loading')}
              message={t('titles:pleaseWait')}
            />
          </div>
        )}
        </div>
        {shouldShowPagination && (
          <ReactPaginate
            onClick={handlePageClick}
            pageCount={pageCount}
            previousLabel='<'
            nextLabel='>'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            forcePage={itemOffset}
          />
        )}
      </Popover>
    </>
  );
};

PaginationMenuSelect.propTypes = {
  filterValue: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hideOptionalCheckBox: PropTypes.bool,
  dataKey: PropTypes.string.isRequired,
  columnData: PropTypes.shape({
    isFilterable: PropTypes.bool,
    options: PropTypes.array,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

PaginationMenuSelect.defaultProps = {
  error: '',
  filterValue: '',
  label: '',
  required: false,
  disabled: false,
  hideOptionalCheckBox: false,
};

export default PaginationMenuSelect;
