import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ContentWrapper from '../Wrappers/ContentWrapper';
import FilesDragAndDrop from '../Shared/FilesDragAndDrop';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  link: {
    fontSize: 16,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const steps = ['Select And Upload FIle', 'Validate', 'Import Patients'];

const PatientUpload = () => {
  const { t } = useTranslation('titles');
  const classes = useStyles();
  const { title } = useParams();

  return (
    <ContentWrapper
      titleText={t('titles:patientUploadForAccount', {
        accountName: title,
      })}
      className={classes.paper}
    >
      <FilesDragAndDrop
        hasValidation={true}
        name={'patient'}
        steps={steps}
      />
    </ContentWrapper>
  );
};

export default PatientUpload;
