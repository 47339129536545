import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-fetching-library';
import { Typography, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import ConfirmPasswordForm from 'components/Shared/ConfirmPasswordForm';
import AuthFormWrapper from 'components/Wrappers/AuthFormWrapper';
import { routes } from 'config/routes';
import showServerError from 'helpers/showError';
import { useDispatch } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { setHipaaCheckParams as setHipaaCheckParamsAction } from '../../redux_store/reducer/reducers/userReducer/userReducer';

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
}));

const AcceptInvitation = () => {
  const { t } = useTranslation(['btn', 'errors', 'titles', 'notifications']);
  const dispatch = useDispatch();
  const [backendErrors, setBackendErrors] = useState({});
  const classes = useStyles();
  const { token } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const acceptInvitationAction = (values) => ({
    method: 'POST',
    endpoint: `/user-invitations/${token}`,
    body: values,
  });
  const { loading, mutate } = useMutation(acceptInvitationAction);

  const acceptInvitation = async (values, { setSubmitting }) => {
    setBackendErrors({});
    const { payload, error, status } = await mutate(values);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      setSubmitting(false);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
      };

      switch (status) {
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        case 500: {
          switch (payload.errorCode) {
            case 1: {
              showServerError(dispatch, t('errors:noLongerValid'));
              break;
            }
            default: {
              showServerError(dispatch, '', options);
              break;
            }
          }
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:welcomeToQHS'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      if (!payload.authMetadata) {
        showServerError(
          dispatch,
          'You do not have access to any accounts on the QHS dashboard. If you feel this is an error, contact the administrator for your QHS account.',
        );
        return;
      }
      dispatch(
        setHipaaCheckParamsAction({
          newUser: payload.user,
          newMetadata: payload.authMetadata,
          newRoute: {
            path: routes.root.path,
          },
        }),
      );
    }
  };

  return (
    <AuthFormWrapper>
      <Typography className={classes.text}>
        {t('forms:acceptInvitation')}
      </Typography>
      <ConfirmPasswordForm
        backendErrors={backendErrors}
        onSubmit={acceptInvitation}
        loading={loading}
        isSuccess={isSuccess}
        isFailed={isFailed}
        cancelButtonRoute={routes.login}
        submitText={t('btn:setPasswordLogIn')}
      />
    </AuthFormWrapper>
  );
};

export default AcceptInvitation;
