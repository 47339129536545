/* eslint-disable */
import React from 'react';

const AccountsLogo = ({ fill, className }) => (
  <svg className={className} style={{ width: 20, height: 19}} viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>Account@3x</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard" transform="translate(-35.000000, -191.000000)" fill={fill || "#FFFFFF"}>
        <g id="Side" transform="translate(18.000000, 30.000000)">
          <g id="Tabs" transform="translate(0.000000, 83.000000)">
            <path d='M19.6086957,78.8636364 C19.6086957,78.386663 19.9980174,78 20.4782609,78 C20.9585043,78 21.3478261,78.386663 21.3478261,78.8636364 L21.3478261,80.5909091 C21.3478261,81.0678782 20.9585043,81.4545455 20.4782609,81.4545455 C19.9980174,81.4545455 19.6086957,81.0678782 19.6086957,80.5909091 L18.7391304,80.5909091 L18.7391304,84.0454545 C18.7391304,86.9072864 21.0750348,89.2272727 23.9565217,89.2272727 C26.838,89.2272727 29.173913,86.9072864 29.173913,84.0454545 L29.173913,80.5909091 L28.3043478,80.5909091 C28.3043478,81.0678782 27.9150435,81.4545455 27.4347826,81.4545455 C26.9545217,81.4545455 26.5652174,81.0678782 26.5652174,80.5909091 L26.5652174,78.8636364 C26.5652174,78.386663 26.9545217,78 27.4347826,78 C27.9150435,78 28.3043478,78.386663 28.3043478,78.8636364 L30.0434783,78.8636364 C30.5237391,78.8636364 30.9130435,79.2503036 30.9130435,79.7272727 L30.9130435,84.0454545 C30.9130435,87.5687455 28.2576522,90.4760909 24.826087,90.9010864 L24.826087,90.9545455 C24.826087,93.3393909 26.7726957,95.2727273 29.173913,95.2727273 C31.5751304,95.2727273 33.5217391,93.3393909 33.5217391,90.9545455 L33.5217391,89.9434864 C32.5085217,89.5878409 31.7826087,88.6280818 31.7826087,87.5 C31.7826087,86.0690841 32.9505217,84.9090909 34.3913043,84.9090909 C35.832087,84.9090909 37,86.0690841 37,87.5 C37,88.6280818 36.274087,89.5878409 35.2608696,89.9434864 L35.2608696,90.9545455 C35.2608696,94.2933636 32.5356522,97 29.173913,97 C25.8121739,97 23.0869565,94.2933636 23.0869565,90.9545455 L23.0869565,90.9010864 C19.6553826,90.4760909 17,87.5687455 17,84.0454545 L17,79.7272727 C17,79.2503036 17.3893174,78.8636364 17.8695652,78.8636364 L19.6086957,78.8636364 Z M34.3913043,88.3636364 C34.8715652,88.3636364 35.2608696,87.9769864 35.2608696,87.5 C35.2608696,87.0230136 34.8715652,86.6363636 34.3913043,86.6363636 C33.9110435,86.6363636 33.5217391,87.0230136 33.5217391,87.5 C33.5217391,87.9769864 33.9110435,88.3636364 34.3913043,88.3636364 Z' id='Account'/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AccountsLogo;
