import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-fetching-library';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import Modal from 'components/Shared/Modal';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { useDispatch, useSelector } from 'react-redux';
import showServerError from '../../../helpers/showError';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';

const EnableClaimModal = ({
  open,
  handleClose,
  accountUuid,
  codeType,
  uuid,
  updateClaimableByUuid,
}) => {
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const closeModal = () => {
    handleClose();
  };
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const changeIsClaimableAction = () => ({
    method: 'GET',
    endpoint: `/accounts/${accountUuid}/surveys/${uuid}/generate-claim`,
  });

  const { loading, mutate } = useMetadataUpdate(useMutation, [
    changeIsClaimableAction,
  ]);

  const handleSubmit = async () => {
    const { payload, error, status } = await mutate();
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);

      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 422: {
          if (payload.validationErrors) {
            const errorText = payload.validationErrors[Object.keys(payload.validationErrors)[0]]
            showServerError(dispatch, errorText);
          } else {
            showServerError(dispatch, t('errors:validationError'));
          }
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
        closeModal(true);
        updateClaimableByUuid(uuid);
        dispatch(
          enqueueSnackbar({
            message: t('notifications:claimEnabled'),
            options: {
              variant: SNACKBAR_VARIANTS.success,
            },
          }),
        );
      }, 1000);
    }
  };

  const uppercaseCodeTitle =
    codeType.charAt(0).toUpperCase() + codeType.slice(1);

  return (
    <Modal
      open={open}
      title={t('titles:enableClaim', {
        source: uppercaseCodeTitle,
      })}
      secondTitle={t('titles:enableClaimSubtitle', {
        source: uppercaseCodeTitle,
      })}
      handleClose={() => closeModal(false)}
    >
      <Box p={2} display="flex" justifyContent="space-between">
        <RoundedButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => closeModal(false)}
        >
          {t('btn:cancel')}
        </RoundedButton>
        <RoundedButton
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSubmit}
          loading={loading}
          isSuccess={isSuccess}
          isFailed={isFailed}
        >
          {t('btn:enableClaim')}
        </RoundedButton>
      </Box>
    </Modal>
  );
};

EnableClaimModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  codeType: PropTypes.string.isRequired,
  accountUuid: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  updateClaimableByUuid: PropTypes.func.isRequired,
};

export default EnableClaimModal;
