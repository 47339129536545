import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TableCell, IconButton, Grid, makeStyles } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import cx from 'classnames';

import CustomMenu, { CUSTOM_MENU_TYPES } from 'components/Shared/CustomMenu';
import CustomMenuItem from 'components/Shared/CustomMenuItem';
import MenuDivider from 'components/Shared/MenuDivider';
import LoadingWrapper from 'components/Shared/LoadingWrapper';
import { has } from 'lodash';
import CellPlaceholder from './CellContent/CellPlaceholder';

const useStyles = makeStyles((theme) => ({
  hover: {
    backgroundColor: theme.palette.action.hover,
  },
  button: {
    color: theme.colors.lightBlue,
  },
  option: {
    paddingLeft: theme.spacing(3),
  },
  removeItem: {
    color: theme.palette.error.main,
  },
  removeItemIcon: {
    fill: theme.palette.error.main,
    // stroke: theme.palette.error.main,
  },
}));

const TableCellMore = ({
  rowData,
  rowData: { uuid, moreTitle, disabledRowMenu },
  actionProps: { actions, options, optionsEntity },
  selected,
  ...restProps
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const classes = useStyles();

  const handleOpenMoreMenu = (e) => {
    e.stopPropagation();
    setAnchorPosition({ top: e.clientY, left: e.clientX });
    setHover(false);
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMoreMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleItemClick = (e, { callback, value, loading }) => {
    if (!loading) {
      handleCloseMoreMenu(e);
    }
    if (callback) {
      callback(e, rowData, selected, value);
    }
  };

  // to prevent excess rendering
  const menu = useMemo(
    () => (
      <CustomMenu
        type={CUSTOM_MENU_TYPES.MENU}
        id={`action-menu-${uuid}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMoreMenu}
        title={moreTitle}
        reCalcDimensionsTrigger={
          anchorPosition ? anchorPosition.top.toString() : ''
        }
        disabled={rowData.disabledRowMenu}
      >
        {options &&
          options.map(
            ({ value, label, icon, onActionClick, addDivider, isDisabled }) => {
              const isCurrentSelected = label === rowData[optionsEntity];
              return (
                <div key={value}>
                  <CustomMenuItem
                    disabled={isDisabled && isDisabled(uuid)}
                    selected={isCurrentSelected}
                    icon={icon}
                    onClick={(e) =>
                      handleItemClick(e, { callback: onActionClick, value })
                    }
                  >
                    {label}
                  </CustomMenuItem>
                  {addDivider && <MenuDivider />}
                </div>
              );
            },
          )}
        {actions && options && <MenuDivider />}

        {actions &&
          actions.map(
            ({
              key,
              children,
              onActionClick,
              loading,
              addDivider,
              isRemove,
              showConditionFlag,
              showConditionValue,
              disabledKey,
              isDisabled,
              ...rest
            }) => {
              const showAction =
                rowData[showConditionFlag] === showConditionValue;
              return showAction ? (
                <div key={key}>
                  <CustomMenuItem
                    disabled={
                      // eslint-disable-next-line no-nested-ternary
                      isDisabled && isDisabled(uuid)
                        ? true
                        : has(rowData, key)
                        ? rowData[key]
                        : false
                    }
                    className={isRemove ? classes.removeItem : undefined}
                    iconClassName={
                      isRemove ? classes.removeItemIcon : undefined
                    }
                    onClick={(e) =>
                      handleItemClick(e, { callback: onActionClick, loading })
                    }
                    {...rest}
                  >
                    <LoadingWrapper isLoading={loading} size="small">
                      {children}
                    </LoadingWrapper>
                  </CustomMenuItem>
                  {addDivider && <MenuDivider />}
                </div>
              ) : null;
            },
          )}
      </CustomMenu>
    ),
    [anchorEl, anchorPosition],
  );

  return (
    <TableCell
      component="div"
      variant="body"
      onClick={disabledRowMenu ? () => {} : handleOpenMoreMenu}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      {...restProps}
    >
      {uuid ? (
        <Grid container direction="column" justify="center" alignItems="center">
          <IconButton
            disabled={disabledRowMenu}
            size="small"
            className={cx(
              {
                [classes.hover]: hover,
              },
              classes.button,
            )}
          >
            <MoreHorizIcon />
          </IconButton>
          {menu}
        </Grid>
      ) : (
        <CellPlaceholder />
      )}
    </TableCell>
  );
};

TableCellMore.propTypes = {
  rowData: PropTypes.shape({
    uuid: PropTypes.string,
    moreTitle: PropTypes.string,
    status: PropTypes.string,
    disabledRowMenu: PropTypes.bool,
  }).isRequired,
  actionProps: PropTypes.shape({
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        onActionClick: PropTypes.func.isRequired,
        isDisabled: PropTypes.func,
        children: PropTypes.elementType.isRequired,
      }),
    ).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        onActionClick: PropTypes.func.isRequired,
        label: PropTypes.elementType.isRequired,
      }),
    ),
    optionsEntity: PropTypes.string,
    disabledKey: PropTypes.string,
  }).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableCellMore;
