import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Link, makeStyles } from '@material-ui/core';
import { ReactComponent as UpdateIcon } from 'assets/icons/update.svg';
import Modal from './components/Shared/Modal';
import { routes } from './config/routes';
import { AppRouteContext } from './contexts';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsMaintenanceMode } from './redux_store/reducer/reducers/userReducer/userReducer';
import { checkIsMaintenanceMode } from './helpers/downForMaintenance';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  modal: {
    alignItems: 'center',
    paddingBottom: theme.spacing(7),
    width: '100%',
    maxWidth: '766px',
  },
  skipNow: {
    color: theme.colors.lightBlue,
    cursor: 'pointer',
    fontSize: theme.spacing(2),
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
  iconButton: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: theme.spacing(4),
    width: 'max-content',
    borderRadius: theme.spacing(1),
    color: theme.colors.blue2,
    fontSize: theme.spacing(3),
    fontWeight: 600,
  },
}));

const CacheBuster = ({ children }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(['dialogs']);
  const dispatch = useDispatch();
  const { setAppRoute } = useContext(AppRouteContext);
  const { isMaintenanceMode } = useSelector((state) => state.user);
  const [prevIsMaintenanceMode, setPrevIsMaintenanceMode] = useState();
  const [showPopup, setShowPopup] = useState({
    version: null,
    open: false,
  });

  const refreshCacheAndReload = () => {
    // eslint-disable-next-line no-console
    console.log('Clearing cache and hard reloading...');
    if (window.caches) {
      // Service worker cache should be cleared with window.caches.delete()
      window.caches.keys().then((names) => {
        names.forEach((name) => window.caches.delete(name));
      });
    }
    localStorage.clear();
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  const checkVersion = () => {
    fetch(`${process.env.REACT_APP_API_URL}/system/dashboard/version`)
      .then((response) => response.json())
      .then((meta) => {
        const currentVersion = process.env.REACT_APP_BUILD_NUMBER;
        const latestVersion = meta.data;
        const shouldForceRefresh = latestVersion > currentVersion;
        if (shouldForceRefresh) {
          // eslint-disable-next-line no-console
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`,
          );
          setShowPopup({
            version: latestVersion,
            open: true,
          });
        } else {
          // eslint-disable-next-line no-console
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`,
          );
        }
      });
  };

  const checkMaintenance = async () => {
    const response = await checkIsMaintenanceMode();
    if (response.status === 200) {
      dispatch(updateIsMaintenanceMode(true));
      setPrevIsMaintenanceMode(true);
    } else {
      dispatch(updateIsMaintenanceMode(false));
      setPrevIsMaintenanceMode(false);
    }
  };

  useEffect(() => {
    checkMaintenance();
    checkVersion();

    try {
      setInterval(async () => {
        checkVersion();
      }, 1000 * 60 * 60 * 24);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (isMaintenanceMode && !prevIsMaintenanceMode) {
      redirectToDownForMaintenance();
    }
    setPrevIsMaintenanceMode(isMaintenanceMode);
  }, [isMaintenanceMode]);

  const redirectToDownForMaintenance = () => {
    setAppRoute({
      path: routes.downForMaintenance.path,
    });
    navigate(routes.downForMaintenance.path)
  };

  const closeModal = () => {
    setShowPopup({
      version: null,
      open: false,
    });
  };

  return (
    <>
      <Modal
        open={showPopup.open}
        handleClose={closeModal}
        classes={{ paper: classes.modal, container: classes.modalContainer }}
        title={t('dialogs:updateRequired')}
        secondTitle={t('dialogs:updateVersionSubtitle')}
        secondTitleStyle={classes.modalSecondTitle}
      >
        <IconButton
          aria-label='update'
          className={classes.iconButton}
          onClick={() => {
            refreshCacheAndReload();
          }}
          classes={{ label: classes.label }}
        >
          <UpdateIcon />
          {t('dialogs:newVersion')}
          {showPopup.version}
        </IconButton>
        <Link className={classes.skipNow} onClick={closeModal}>
          {t('dialogs:skipNow')}
        </Link>
      </Modal>
      {children}
    </>
  );
};

export default CacheBuster;
