import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import RoundedButton from '../../../../../../../../Shared/RoundedButton';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));


const CptsModal = ({
                     values,
                     closeModal,
                     backendErrors,
                     form,
                     setErrors,
                   }) => {
  const { en: { btn } } = useSelector((state) => state.i18n);
  const classes = useStyles();
  const [previousValues, setPreviousValues] = useState();
  const [previousErrors, setPreviousErrors] = useState();

  useEffect(() => {
    setPreviousValues(values)
    setPreviousErrors(backendErrors)
  }, []);

  const closeModalAndResetChanges = async () => {
    await form.setValues(previousValues);
    await setErrors(previousErrors);
    closeModal()
  }

  return <Grid item xs={12} sm={12} className={classes.footer}>
    <RoundedButton
      onClick={closeModalAndResetChanges}
      variant="contained"
      color="secondary"
      size="small"
      className={classes.btn}
    >
      {btn.cancelLastChanges}
    </RoundedButton>
    <RoundedButton
      onClick={closeModal}
      variant="contained"
      color="primary"
      size="small"
      className={classes.btn}
    >
      {btn.applyChanges}
    </RoundedButton>
  </Grid>;
};

export default CptsModal;
