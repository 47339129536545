import React from 'react';
import { Button, Popover } from '@material-ui/core';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import ExportCSVButton from './ExportCSVButton';

const ExportFilesButtonPopover = ({
                                    anchorEl,
                                    downloadedZIPFile,
                                    classes,
                                    setExporting,
                                    fetchCSVData,
                                    handleClose,
                                  }) => {
  const { en: { btn: tBtn } } = useSelector((state) => state.i18n);
  const open = Boolean(anchorEl);

  return <Popover
    id={open ? 'simple-popover' : undefined}
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
    <div className={classes.selectMenuTitle}>{tBtn.download}</div>
    <div className={classes.selectMenuContainer}>
      <Button
        className={classes.selectorMenu}
        onClick={downloadedZIPFile}
      >
        {tBtn.downloadZIP}
      </Button>
    </div>
    <div className={cx(classes.driver)} />
    <div className={cx(classes.selectMenuContainer)}>
      <ExportCSVButton
        handleClose={handleClose}
        classes={classes}
        setExporting={setExporting}
        fetchCSVData={fetchCSVData}
      />
    </div>
  </Popover>;
};

export default ExportFilesButtonPopover;
