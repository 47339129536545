import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-fetching-library';
import Loader from 'components/Shared/Loader';
import showServerError from 'helpers/showError';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import RoundedButton from 'components/Shared/RoundedButton';
import Modal from '../../Shared/Modal';
import CustomMenu, { CUSTOM_MENU_TYPES } from '../../Shared/CustomMenu';
import CustomMenuItem from '../../Shared/CustomMenuItem';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selector: {
    backgroundColor: '#D9D9D9',
  },
  acceptResultBtnLabel: {
    color: theme.palette.common.white,
    letterSpacing: 2,
    fontWeight: 700,
  },
  acceptResult: {
    background: theme.colors.green,
    padding: theme.spacing(1.25, 2),
  },
}));

const ProvidersModal = ({ updateReport, openProvidersModal, toggleProvidersModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    en: {
      btn,
      titles,
      dialogs,
      errors,
    },
  } = useSelector((state) => state.i18n);
  const { user } = useSelector((state) => state.user);
  const { accountUuid: paramAccountUuid, assessmentUuid } = useParams();
  const [currentProvider, setCurrentProvider] = useState({});
  const [providers, setProviders] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const acceptReportAsDSAction = (values) => ({
    method: 'POST',
    endpoint: `/accounts/${paramAccountUuid}/surveys/${assessmentUuid}/accept-results`,
    body: values,
  });

  const { loading: loadingDS, mutate: mutateDS } = useMetadataUpdate(useMutation, [
    acceptReportAsDSAction,
    false,
  ]);

  const { query: getProviders, loading: loadingProviders } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${paramAccountUuid}/providers`,
    },
    false,
  ]);

  const handleAcceptReportForChildDSAccount = async () => {
    let body = {
      providerUserUUID: currentProvider.uuid,
    };

    const { payload, error, status } = await mutateDS(body);

    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 422: {
          showServerError(dispatch, errors.validationError);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      updateReport();
      toggleProvidersModal(false);
    }
  };

  useEffect(() => {
    if (openProvidersModal && providers.length === 0 && getProviders) {
      getProviders().then(({ error, payload }) => {
        if (!error) {
          setCurrentProvider(payload[0]);
          setProviders([ ...payload ]);
        }
      });
    }
  }, [openProvidersModal, providers, getProviders]);

  const updateCurrentProvider = (e) => {
    const newCurrentProvider = providers.find((el) => el.uuid === e.target.value);
    setCurrentProvider(newCurrentProvider);
  }

  return (
    <Modal
      open={openProvidersModal}
      handleClose={() => toggleProvidersModal(false)}
      classes={{ paper: classes.modal, container: classes.modalContainer }}
      title={dialogs.chooseProviders}
    >
      <>
          <div className={classes.contentContainer}>
            {loadingProviders || !currentProvider.uuid || providers.length === 0 ?
              <div className={classes.loaderContainer}>
                <Loader />
              </div>
              : <CustomMenu
                type={CUSTOM_MENU_TYPES.SELECT}
                title={titles.providers}
                value={currentProvider.uuid}
                onChange={updateCurrentProvider}
                customSelectStyle={classes.selector}
              >
                {providers.map((provider) => (
                  <CustomMenuItem key={provider.uuid} value={provider.uuid}>
                    {provider.providerTitle + ' ' + provider.firstName + ' ' + provider.lastName}
                  </CustomMenuItem>
                ))}
              </CustomMenu>
            }
            <Box py={3} alignItems="center">
              <RoundedButton
                disabled={loadingProviders}
                variant="contained"
                isLoading={loadingDS}
                isSuccess={isSuccess}
                isFailed={isFailed}
                onClick={handleAcceptReportForChildDSAccount}
                className={classes.acceptResult}
                classes={{
                  label: classes.acceptResultBtnLabel,
                }}
              >
                {btn.acceptResults}
              </RoundedButton>
            </Box>
          </div>
        </>

    </Modal>
  );
};

export default ProvidersModal;

