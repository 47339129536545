import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  makeStyles,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Divider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'formik';
import cx from 'classnames';

import AddressSelectField from 'components/Orders/AddressSelectField';
import FormikTextField from 'components/FormikFields/FormikTextField';
import FormikCheckBoxTextField from 'components/FormikFields/FormikCheckBoxTextField';
import FormikSelectField from 'components/FormikFields/FormikSelectField';
import FormikMultiSelectField from 'components/FormikFields/FormikMultiSelectField';
import HelperText from 'components/Shared/HelperText';
import Radio from 'components/Shared/Radio';
import {
  orderTypes,
  scitItems,
  deprecatedScitItems,
  allergenItems,
} from 'components/Orders/constants';
import { getLocalizedDate, getLocalizedLabel } from 'helpers/localize';
import genderItems from 'components/Patients/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(2),
  },
  categoryLabel: {
    margin: theme.spacing(0, 1, 0, 0.375),
    width: 125,
  },
  title: {
    marginLeft: theme.spacing(1.875),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  margin0: {
    margin: theme.spacing(0),
  },
  fullWidth: {
    width: '100%',
    maxWidth: 'none',
  },
  slitContainer: {
    position: 'relative',
    top: -6,
  },
  marginTop16: {
    marginTop: theme.spacing(2),
  },
  marginLeft16: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  maxWidth: {
    maxWidth: 1000,
  },
}));

const OrderForm = ({
  values,
  backendErrors,
  metadata: { patientInfo, addresses, providers },
  isEditable,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms', 'errors', 'notifications']);

  const providerItems = providers.map((provider) => ({
    label: provider.name,
    value: provider.uuid,
  }));

  return (
    <Form>
      <Grid container direction="column" className={classes.root}>
        <Grid container spacing={3} className={classes.maxWidth}>
          <Grid item xs={6} container>
            <Grid item xs={12}>
              <FormikTextField
                type="text"
                name="patient"
                label={t('forms:patient')}
                value={patientInfo.name}
                disabled
              />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={5}>
                <FormikTextField
                  type="text"
                  name="gender"
                  label={t('forms:gender')}
                  value={getLocalizedLabel(genderItems, patientInfo.gender)}
                  disabled
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <FormikTextField
                  type="text"
                  name="dateOfBirth"
                  label={t('forms:dateOfBirth')}
                  value={getLocalizedDate(patientInfo.dateOfBirth, 'UTC')}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              type="text"
              name="dob"
              label={t('forms:allergies')}
              value={patientInfo.drugAllergies || t('forms:noDrugAllergies')}
              multiline
              rows="5"
              disabled
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Grid container className={classes.maxWidth}>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={6}>
              <FormikSelectField
                name="providerUserUUID"
                label={t('forms:provider')}
                type="text"
                menuItems={providerItems}
                required
                backendError={backendErrors.providerUserUUID}
                className={cx(classes.fullWidth, classes.marginTop16)}
                disabled={!isEditable}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.margin0} spacing={3}>
            <Grid item xs={4}>
              <Field name="category" type="checkbox">
                {({ field, form }) => (
                  <FormControl
                    required
                    error={!!backendErrors.category}
                    disabled={!isEditable}
                  >
                    <Grid container alignItems="center">
                      <FormLabel className={classes.categoryLabel}>
                        {t('forms:orderCategory')}
                      </FormLabel>
                      <RadioGroup
                        row
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        className={classes.marginLeft16}
                      >
                        <FormControlLabel
                          value={orderTypes.SLIT}
                          control={<Radio />}
                          label={t('forms:slit')}
                        />
                        <FormControlLabel
                          value={orderTypes.SCIT}
                          control={<Radio />}
                          label={t('forms:scit')}
                        />
                      </RadioGroup>
                    </Grid>
                    <HelperText>{backendErrors.category}</HelperText>
                  </FormControl>
                )}
              </Field>
            </Grid>

            {!!values.category && (
              <>
                {values.category === orderTypes.SCIT && (
                  <>
                    <Grid item xs={4}>
                      <FormikSelectField
                        name="scitType"
                        label={t('forms:orderType')}
                        type="text"
                        menuItems={
                          values.scitType === deprecatedScitItems[0].value
                            ? [deprecatedScitItems[0], ...scitItems]
                            : scitItems
                        }
                        required
                        backendError={backendErrors.scitType}
                        className={cx(classes.margin0, classes.fullWidth)}
                        disabled={!isEditable}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikMultiSelectField
                        name="scitAllergens"
                        type="text"
                        items={allergenItems}
                        label={t('forms:allergens')}
                        labelWidth={78}
                        required
                        backendError={backendErrors.scitAllergens}
                        className={cx(classes.margin0, classes.fullWidth)}
                        disabled={!isEditable}
                      />
                    </Grid>
                  </>
                )}
                {values.category === orderTypes.SLIT && (
                  <Grid
                    item
                    xs={8}
                    container
                    direction="column"
                    className={classes.slitContainer}
                  >
                    <FormikCheckBoxTextField
                      name="slitLowDoseQuantity"
                      type="number"
                      inputProps={{
                        min: '0',
                      }}
                      label={t('forms:quantity')}
                      checkBoxName="slitLowDose"
                      checkBoxLabel={t('forms:lowDose')}
                      initialValue={values.slitLowDoseQuantity}
                      backendError={backendErrors.slitLowDoseQuantity}
                      disabled={!isEditable}
                    />
                    <FormikCheckBoxTextField
                      name="slitMaintenanceQuantity"
                      type="number"
                      inputProps={{
                        min: '0',
                      }}
                      label={t('forms:quantity')}
                      checkBoxName="slitMaintenance"
                      checkBoxLabel={t('forms:maintenance')}
                      initialValue={values.slitMaintenanceQuantity}
                      backendError={backendErrors.slitMaintenanceQuantity}
                      disabled={!isEditable}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Grid container className={classes.maxWidth} spacing={3}>
          <Grid item xs={12}>
            <FormikTextField
              type="text"
              name="comments"
              label={t('forms:comments')}
              multiline
              rows="4"
              backendError={backendErrors.comments}
              className={classes.fullWidth}
              disabled={!isEditable}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <AddressSelectField
          name="address"
          type="text"
          required
          addresses={addresses}
          disabled={!isEditable}
        />
      </Grid>
    </Form>
  );
};

OrderForm.propTypes = {
  values: PropTypes.shape({
    category: PropTypes.string,
    slitLowDoseQuantity: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    slitMaintenanceQuantity: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  }).isRequired,
  backendErrors: PropTypes.shape({
    providerUserUUID: PropTypes.string,
    category: PropTypes.string,
    scitType: PropTypes.string,
    scitAllergens: PropTypes.string,
    address: PropTypes.string,
    comments: PropTypes.string,
    slitLowDoseQuantity: PropTypes.string,
    slitMaintenanceQuantity: PropTypes.string,
  }),
  metadata: PropTypes.shape({
    patientInfo: PropTypes.object,
    addresses: PropTypes.array,
    providers: PropTypes.array,
  }).isRequired,
  isEditable: PropTypes.bool,
};

OrderForm.defaultProps = {
  backendErrors: undefined,
  isEditable: true,
};

export default OrderForm;
