import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import FormikTextField from '../../../../../FormikFields/FormikTextField';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    marginLeft: theme.spacing(2),
  },
  dataContainer: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
  },
  addMarginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const CustomEmailSectionRow = ({
                                 isDistributionSponsor,
                                 rowName,
                                 firstFieldName,
                                 secondFieldName,
                                 idDefaultName,
                                 backendErrors,
                                 defaultFirstFieldText,
                                 defaultSecondFieldText,
                               }) => {

  const defaultTextInputs = useMemo(
    () => (
      <SectionRowLayout
        isDistributionSponsor={isDistributionSponsor}
        rowName={rowName}
        firstFieldName={'loading'}
        secondFieldName={'loading'}
        idDefaultName={idDefaultName}
        form={{
          values: {},
        }}
        backendErrors={{}}
        isDisabledFields={true}
        changeIsCheck={() => {
        }}
      />
    ), [defaultFirstFieldText, defaultSecondFieldText]);

  if (!defaultFirstFieldText || !defaultSecondFieldText) {
    return defaultTextInputs;
  }

  return (
    <Field name={'customEmailSection'}>
      {({ form }) => {
        return <SectionRow
          isDistributionSponsor={isDistributionSponsor}
          rowName={rowName}
          firstFieldName={firstFieldName}
          secondFieldName={secondFieldName}
          idDefaultName={idDefaultName}
          form={form}
          backendErrors={backendErrors}
          defaultFirstFieldText={defaultFirstFieldText}
          defaultSecondFieldText={defaultSecondFieldText}
        />;
      }}
    </Field>
  );
};

const SectionRow = ({
                      isDistributionSponsor,
                      rowName,
                      firstFieldName,
                      secondFieldName,
                      idDefaultName,
                      backendErrors,
                      form,
                      defaultFirstFieldText,
                      defaultSecondFieldText,
                    }) => {
  const [temporaryValues, setTemporaryValues] = useState({
    [firstFieldName]: undefined,
    [secondFieldName]: undefined,
  });
  const [shouldUpdateValueAfterSaving, setShouldUpdateValueAfterSaving] = useState('init');

  const changeIsCheck = (checked) => {
    if (checked) {
      form.setFieldValue(firstFieldName, temporaryValues[firstFieldName] ?? defaultFirstFieldText);
      form.setFieldValue(secondFieldName, temporaryValues[secondFieldName] ?? defaultSecondFieldText);
      form.setFieldValue(idDefaultName, false);
    } else {
      form.setFieldValue(firstFieldName, defaultFirstFieldText);
      form.setFieldValue(secondFieldName, defaultSecondFieldText);
      form.setFieldValue(idDefaultName, true);
    }
  };

  useEffect(() => {
    setTemporaryValues({
      [firstFieldName]: form.values[firstFieldName],
      [secondFieldName]: form.values[secondFieldName],
    });
  }, []);

  useEffect(() => {
    if (shouldUpdateValueAfterSaving === 'saved') {
      if (!form.values[idDefaultName]) {
        setTemporaryValues({
          [firstFieldName]: form.values[firstFieldName],
          [secondFieldName]: form.values[secondFieldName],
        });
      } else {
        form.setFieldValue(firstFieldName, defaultFirstFieldText);
        form.setFieldValue(secondFieldName, defaultSecondFieldText);
      }
    }
  }, [shouldUpdateValueAfterSaving]);

  useEffect(() => {
    if (!form.values[idDefaultName]) {
      setTemporaryValues({
        [firstFieldName]: form.values[firstFieldName],
        [secondFieldName]: form.values[secondFieldName],
      });
    }
  }, [form.values[firstFieldName], form.values[secondFieldName]]);

  useEffect(() => {
    if (form.isSubmitting && (shouldUpdateValueAfterSaving === 'init' || shouldUpdateValueAfterSaving === 'saved')) {
      setShouldUpdateValueAfterSaving('saving');
    } else if (!form.isSubmitting && shouldUpdateValueAfterSaving === 'saving') {
      setShouldUpdateValueAfterSaving('saved');
    }
  }, [form.isSubmitting]);


  return (
    <SectionRowLayout
      isDistributionSponsor={isDistributionSponsor}
      rowName={rowName}
      firstFieldName={firstFieldName}
      secondFieldName={secondFieldName}
      idDefaultName={idDefaultName}
      form={form}
      backendErrors={backendErrors}
      isDisabledFields={false}
      changeIsCheck={changeIsCheck}
    />
  );
};

const SectionRowLayout = ({
                            isDistributionSponsor,
                            rowName,
                            firstFieldName,
                            secondFieldName,
                            idDefaultName,
                            backendErrors,
                            form,
                            isDisabledFields,
                            changeIsCheck,
                          }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms']);

  const parsedErrorFirstKey = firstFieldName.startsWith('email') ? 'emailConfiguration' : 'smsConfiguration';
  const parsedErrorSecondKey = firstFieldName.replace('email', '').replace('sms', '').replace('English', '').replace('Spanish', '');
  const errorName = `${parsedErrorFirstKey}.${parsedErrorSecondKey}`;
  const firstFieldNameError = backendErrors && (backendErrors[`${errorName}[0].text`] || backendErrors[`${errorName}[0]`] || backendErrors[`${errorName}[0].local`]);
  const secondFieldNameError = backendErrors && (backendErrors[`${errorName}[1].text`] || backendErrors[`${errorName}[1]`] || backendErrors[`${errorName}[1].local`]);

  return (
    <Grid container spacing={3} className={cx(classes.dataContainer, {
      [classes.addMarginBottom]: firstFieldNameError || secondFieldNameError,
    })}>
      <Grid item xs={2} md={2} style={{ marginBottom: 19 }}>
        <Typography className={classes.tableHeader}>
          {rowName}:
        </Typography>
      </Grid>
      <Tooltip
        disableFocusListener={isDistributionSponsor}
        disableHoverListener={isDistributionSponsor}
        disableTouchListener={isDistributionSponsor}
        title='Available only for DS account'
      >
        <Grid container item xs={1} md={1} style={{ marginBottom: 19 }} alignItems='center'
              justify='center'>
          <Checkbox
            name={'isFieldsDefault'}
            checked={!form.values[idDefaultName]}
            color='primary'
            onChange={(e, checked) => {
              changeIsCheck(checked);
            }}
            disabled={isDisabledFields || !isDistributionSponsor}
          />
        </Grid>
      </Tooltip>
      <Grid item xs={4} md={4}>
        <FormikTextField
          type='text'
          name={firstFieldName}
          label={t('forms:english')}
          rowsMax={6}
          rows={6}
          multiline
          disabled={isDisabledFields || form.values[idDefaultName] && isDistributionSponsor}
          backendError={firstFieldNameError}
        />
      </Grid>
      <Grid item xs={4} md={4} mb={50}>
        <FormikTextField
          type='text'
          name={secondFieldName}
          label={t('forms:spanish')}
          rowsMax={6}
          rows={6}
          multiline
          disabled={isDisabledFields || form.values[idDefaultName] && isDistributionSponsor}
          backendError={secondFieldNameError}
        />
      </Grid>
    </Grid>
  );
};

export default CustomEmailSectionRow;
