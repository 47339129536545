import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { SortDirection } from 'react-virtualized';
import { get, isEmpty } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import useFetchList from 'hooks/useFetchList';
import {
  orderStatuses,
  formulaItems,
  ORDERS_FOR_PATIENT_FIELDS,
  FIELDS,
  ORDER_TYPES,
} from 'components/Orders/constants';
import {
  getLocalizedDate,
  getLocalizedLabel,
  getExpectedDeliveryDate,
} from 'helpers/localize';
import { useDispatch, useSelector } from 'react-redux';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import { NULL_QUERY_DELAY } from '../Shared/constants';
import PatientAdditionalNode from '../Patients/PatientAdditionalNode';
import PatientInfoBlock from '../Patients/PatientInfoBlock';
import { FAKE_TITLE_DATA } from '../Shared/FakeDataGenerator';
import OrdersTable from './widgets/OrdersTable';
import OrdersPatientActions from './widgets/OrdersPatientActions';
import OrdersRemoveDialog from './widgets/OrdersRemoveDialog';
import OrdersConfirmationDialog from './widgets/OrdersConfirmationDialog';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

const useStyles = makeStyles((theme) => ({
  addNewButton: {
    height: '100%',
    marginRight: theme.spacing(2),
  },
  paper: {
    overflow: 'hidden',
  },
  dot: {
    padding: theme.spacing(0, 0.5),
    color: theme.colors.lightBlue,
  },
  patientInfoBox: {
    display: 'flex',
  },
  flex: {
    display: 'flex',
  },
  refreshIcon: {
    marginLeft: theme.spacing(2),
  },
}));

/*
  could be rendered in 2 cases:
  - all account orders
  - orders for a special user (patientUuid in URL params)
*/

const OrdersList = () => {
  const { en } = useSelector((state) => state.i18n);
  const { titles: tTitles, tables: tTables } = en;
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbar(...args));
  const { metadata: { currentAccount } } = useSelector((state) => state.user);
  const [isOpenRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [filterStyle, setFilterStyle] = useState(false);
  const [isOpenStatusDialog, setOpenStatusDialog] = useState(false);
  const [removingOrder, setRemovingOrder] = useState({});
  const [statusChanging, setStatusChanging] = useState({});
  const [modifiedOrders, setModifiedOrders] = useState();
  const { patientUuid, patientInfo } = useContext(ActivePatientContext);
  const [prevTotalCount, setPrevTotalCount] = useState();

  const baseEndpoint = `/accounts/${
    paramAccountUuid || currentAccount.uuid
  }/orders`;
  const {
    items,
    totalCount,
    exportItems,
    fetchExportItems,
    exportItemsLoadingStatus,
    clearFilter,
    reloadData,
    filter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint,
    baseParams: {
      fields: (isPatientPage ? ORDERS_FOR_PATIENT_FIELDS : FIELDS).join(),
      q: isPatientPage ? `patientUUID:${paramsPatientUuid}` : undefined,
    },
    exportEndpoint: `${baseEndpoint}/csv`,
    baseSort: {
      sortBy: 'orderDate',
      sortDirection: SortDirection.DESC,
    },
  });

  useEffect(() => {
    if (items) {
      const newModifiedOrders = items.map((order) => ({
        ...order,
        patientDateOfBirth: getLocalizedDate(order.patientDateOfBirth, 'UTC'),
        accountCustomIdentifier: order.accountCustomIdentifier
          ? order.accountCustomIdentifier
          : 'N/A',
        orderDate: getLocalizedDate(order.orderDate, 'UTC'),
        expectedDeliveryDate: getExpectedDeliveryDate(order.orderDate),
        patientMrnNumber: !order.patientMrnNumber ? '-' : order.patientMrnNumber,
        orderStatus: getLocalizedLabel(orderStatuses, order.orderStatus),
        orderType: getLocalizedLabel(ORDER_TYPES, order.orderType),
        orderFormula: getLocalizedLabel(formulaItems, order.orderFormula),
        moreTitle: `${order.patientFirstName} ${order.patientLastName}`,
        accountCustomTags: order.accountCustomTags ? order.accountCustomTags.map(item => getLocalizedLabel(
          CUSTOM_TAGS_OPTIONS,
          item,
        )) : [],
      }));
      setModifiedOrders(newModifiedOrders);
    }
  }, [items]);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  const reloadOrdersList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');
  const titleText = isPatientPage
    ? (patientInfo && `${patientLastName}, ${patientFirstName}`) ||
    FAKE_TITLE_DATA
    : tTitles.orders;

  const modifiedExportItems =
    exportItems &&
    exportItems.map((item) => ({
      ...item,
      dob: getLocalizedDate(item.dob, 'UTC'),
      date: getLocalizedDate(item.date),
    }));

  return (
    <>
      <ContentWrapper
        titleText={titleText}
        className={classes.paper}
        hasTopPaddingForSmallScreen={isPatientPage}
        underTitleText={
          isPatientPage ? (
            <PatientInfoBlock
              isForInitLoading={
                (!items && patientUuid !== paramsPatientUuid) || !patientInfo
              }
            />
          ) : (
            ''
          )
        }
        count={isPatientPage ? null : totalCount || FAKE_TITLE_DATA}
        countFiltered={filterStyle}
        actions={
          <OrdersPatientActions
            reloadOrdersList={reloadOrdersList}
            dataLoading={dataLoading}
            modifiedExportItems={modifiedExportItems}
            exportItemsLoadingStatus={exportItemsLoadingStatus}
            fetchExportItems={fetchExportItems}
          />
        }
        additionalNode={
          paramsPatientUuid && (
            <PatientAdditionalNode
              activeItemNumber={2}
              modifiedData={modifiedOrders}
              totalCount={totalCount}
              reloadData={reloadOrdersList}
              dataLoading={dataLoading}
              historyTitle={tTables.ordersHistory}
            />
          )
        }
      >
        <OrdersTable
          modifiedOrders={modifiedOrders}
          setOpenRemoveDialog={setOpenRemoveDialog}
          setRemovingOrder={setRemovingOrder}
          setStatusChanging={setStatusChanging}
          setOpenStatusDialog={setOpenStatusDialog}
          dataLoading={dataLoading}
          totalCount={totalCount}
          restFetchProps={{ ...restFetchProps, filter: filter }}
        />
        <OrdersRemoveDialog
          reloadData={reloadData}
          removingOrder={removingOrder}
          setOpenRemoveDialog={setOpenRemoveDialog}
          setRemovingOrder={setRemovingOrder}
          isOpenRemoveDialog={isOpenRemoveDialog}
        />
        <OrdersConfirmationDialog
          isOpenStatusDialog={isOpenStatusDialog}
          setOpenStatusDialog={setOpenStatusDialog}
          statusChanging={statusChanging}
          setStatusChanging={setStatusChanging}
          items={items}
          reloadData={reloadData}
        />
      </ContentWrapper>
    </>
  );
};

export default OrdersList;
