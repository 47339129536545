import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cx from 'classnames';

import RoundedButton from 'components/Shared/RoundedButton';
import { AppRouteContext } from 'contexts';

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  btn: {
    minWidth: 75,
  },
}));

const FormControlButtons = ({
  dirty,
  loading,
  isValid,
  submitText,
  isSubmitting,
  cancelButtonRoute,
  isSuccess,
  isFailed,
}) => {
  const { t } = useTranslation(['btn']);
  const classes = useStyles();
  const { setAppRoute } = useContext(AppRouteContext);

  const handleCancelClick = () => {
    setAppRoute({
      path: cancelButtonRoute.path,
      removeQueryString: true,
    });
  };

  return (
    <Grid container direction="row" justify="space-between">
      <RoundedButton
        variant="contained"
        color="secondary"
        className={cx(classes.submitBtn, classes.btn)}
        disabled={isSubmitting}
        onClick={handleCancelClick}
      >
        {t('btn:cancel')}
      </RoundedButton>
      <RoundedButton
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        isLoading={loading}
        isSuccess={isSuccess}
        isFailed={isFailed}
        disabled={!isValid || isSubmitting || !dirty}
      >
        {submitText}
      </RoundedButton>
    </Grid>
  );
};

FormControlButtons.propTypes = {
  dirty: PropTypes.bool,
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  submitText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  cancelButtonRoute: PropTypes.PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

FormControlButtons.defaultProps = {
  dirty: false,
  isValid: false,
  loading: false,
  isSuccess: false,
  isFailed: false,
  isSubmitting: false,
  submitText: i18next.t('btn:submit'),
};

export default FormControlButtons;
