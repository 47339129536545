import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  DialogContentText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RoundedButton from 'components/Shared/RoundedButton';

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(1, 3, 2, 3),
  },
}));

const InfoDialog = ({ open, onClose, title, content, ...rest }) => {
  const { t } = useTranslation(['btn']);
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="info-title"
      disableBackdropClick
      disableEscapeKeyDown
      {...rest}
    >
      <DialogTitle id="info-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <RoundedButton
          onClick={onClose}
          variant="contained"
          color="primary"
          size="small"
        >
          {t('btn:ok')}
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

InfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default InfoDialog;
