import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { getNewAlertsNotification } from '../reducers/alertsReducer/api';
import { enqueueSnackbar, SNACKBAR_VARIANTS } from '../reducers/notificationsReducer';

const connection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl( `${process.env.REACT_SIGNALAR_APP_API_URL}/dashboardHub`, {
    skipNegotiation: true,
    logMessageContent: true,
    transport: HttpTransportType.WebSockets,
  })
  .withAutomaticReconnect()
  .build();

export async function startAlertsConnection() {
  await connection.start();
}

export default function signalRMiddleware({ dispatch, getState }) {
  connection.on('ReceiveAlert', () => {
    dispatch(
      enqueueSnackbar({
        message: `You have New Alerts!"`,
        options: {
          variant: SNACKBAR_VARIANTS.info,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      }),
    );
    setTimeout(() => {
      dispatch(getNewAlertsNotification(getState().user.metadata?.currentAccount?.uuid));
    }, 500);
  });

  let prevAccountUuid;

  return next => async action => {
    switch (action.type) {
      case CHANGE_CURRENT_ACCOUNT_FOR_ALERTS_NOTIFICATION:
        if (action.payload) {
          if (prevAccountUuid) {
            await connection.invoke('LeaveGroup', prevAccountUuid).catch((error) => {
              console.log(`error: ${error.toString()}`);
            });
          }
          const accountUuid = action.payload;
          prevAccountUuid = action.payload;
          await connection.invoke('JoinGroup', accountUuid).catch((error) => {
            console.log(`error: ${error.toString()}`);
          });
          dispatch(getNewAlertsNotification(accountUuid));
        }
        break;
      default: {
        break;
      }
    }

    return next(action);
  };
}

const CHANGE_CURRENT_ACCOUNT_FOR_ALERTS_NOTIFICATION = 'alerts/changeCurrentAccountForAlertsNotifications';

export function changeCurrentAccountForAlertsNotifications(accountUuid) {
  return {
    type: CHANGE_CURRENT_ACCOUNT_FOR_ALERTS_NOTIFICATION,
    payload: accountUuid,
  };
}
