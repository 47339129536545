import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import RoundedButton from 'components/Shared/RoundedButton';

const AGREEMENT = `
### HIPAA BUSINESS ASSOCIATE AGREEMENT

THIS BUSINESS ASSOCIATE AGREEMENT (“Agreement”) is made on the “Effective Date”
by Customer and between and Medical Practice Income, Inc. (“Company”) having principal executive
offices at 901 Northpoint Parkway, Suite 302, West Palm Beach, FL 33407 and the Customer. Medical
Practice Income, Inc., (MPI), a Florida corporation, who has a relationship with the Customer, referenced
in this Agreement, but only to the extent such Business Associate creates, receives, maintains, or
transmits Protected Health Information (as defined below) for or on behalf of Customer through the
performance of Services (as defined below) to.

#### RECITALS

WHEREAS, Business Associate now and in the future may have relationships with Customer in
which Business Associate creates, receives, maintains or transmits Protected Health Information (as defined
below) for or on behalf of Customer through the performance of Services to Customer.

WHEREAS, Business Associate and Customer (each a “Party” and collectively the “Parties”)
desire to meet their obligations, to the extent applicable, under the Standards for Privacy of Individually
Identifiable Health Information (the “Privacy Regulation”) and the Health Insurance Reform: Security
Standards (the “Security Regulation”) published by the U.S. Department of Health and Human Services
(“HHS”) at 45 C.F.R. parts 160 and 164 under the Health Insurance Portability and Accountability Act of
1996, as amended (“HIPAA”).

WHEREAS, the Parties desire to set forth the terms and conditions pursuant to which Protected
Health Information, as defined in 45 C.F.R.160.103, received from, or received, maintained, created or
transmitted on behalf of Customer by Business Associate in the performance of Services for or on behalf
of Customer (“Protected Health Information”), will be used and disclosed.

NOW THEREFORE, in consideration of the foregoing and for other good and valuable
consideration, the receipt and sufficiency of which are hereby acknowledged, the Parties hereby agree as
follows:

#### TERMS AND CONDITIONS

#### 1. PERMITTED USES AND DISCLOSURES OF PROTECTED HEALTH INFORMATION

1.1. Services. (a) Business Associate provides products and services (which may include transaction services
as well as software products) (“Services”) that involve the use and/or disclosure of Protected Health
Information. These Services are provided to Customer under various agreements (&quot;Service Agreements&quot;)
that specify the Services to be provided by Business Associate. Except as otherwise specified herein, the
Business Associate may make any and all uses and disclosures of Protected Health Information created or
received from or on behalf of Customer necessary to perform its obligations under the Service
Agreements.

(b) Business Associate may perform Data Aggregation services for the Health Care Operations of Customer.

1.2. Business Activities of the Business Associate. Unless otherwise limited herein, the Business Associate
may:

(a) consistent with 45 C.F.R.164.504(e)(4), use and disclose the Protected Health Information in its
possession for its proper management and administration and to fulfill any present or future legal
responsibilities of the Business Associate, provided that (i) the disclosures are Required by Law; or (ii) any
third party to which Business Associate discloses Protected Health Information for those purposes provides
reasonable assurances that the information will be held confidentially and used or further disclosed only
as Required by Law or for the purpose for which it was disclosed to the third party, and the third party
promptly will notify Business Associate of any instances of which it becomes aware in which the
confidentiality of the information has been breached; and

(b) de-identify any and all Protected Health Information in accordance with 45 C.F.R.164.514(b).
Customer acknowledges and agrees that de-identified information is not Protected Health Information and
that Business Associate may use such de-identified information for any lawful purpose.

#### 2. RESPONSIBILITIES OF THE PARTNERS WITH RESPECT TO PROTECTED HEALTH INFORMATION

2.1. Responsibilities of the Business Associate. Business Associate agrees to:

(a) not use or further disclose the Protected Health Information other than as permitted or required by this
Agreement or as otherwise Required by Law; provided that, to the extent Business Associate is to carry out
Customer’s obligations under the Privacy Regulation, Business Associate will comply with the requirements
of the Privacy Regulation that apply to Customer in the performance of those obligations.

(b) report to the Customer any use and/or disclosure of the Protected Health Information of which
Business Associate becomes aware that is not permitted or required by this Agreement, including but not
limited to any breach of unsecured Protected Health Information as required by 45 C.F.R. 164.410;

(c) report to Customer any Security Incident of which it becomes aware in accordance with 45
C.F.R.164.314(a)(2)(i)(C);

(d) implement and use appropriate administrative, physical, and technical safeguards and, as of
September 23, 2013, comply with applicable Security Regulation requirements with respect to electronic
Protected Health Information, to prevent use or disclosure of Protected Health Information other than as
provided for by this Agreement.

(e) in accordance with 45 C.F.R.164.502(e)(1)(ii) and 45 C.F.R.164.308(b)(2), ensure that any
subcontractors of Business Associate that create, receive, maintain or transmit Protected Health Information
on behalf of Business Associate agree to the same restrictions and conditions on the use and/or disclosure
of Protected Health Information that apply to the Business Associate, including complying with the
applicable Security Regulation requirements with respect to electronic Protected Health Information;

(f) make available its internal practices, books and records relating to the use and/or disclosure of
Protected Health Information to the Secretary of HHS for purposes of determining the Customer’s
compliance with the Privacy Regulation;

(g) document and, within thirty (30) days of receiving a written request from Customer, make available
to Customer such information necessary for Customer to make an accounting of disclosures of an
individual&#39;s Protected Health Information in accordance with 45 C.F.R.164.528 and, as of the date
compliance is required by final regulations, 42 U.S.C.17935(c);

(h) within fifteen (15) days of receiving a written request from Customer, make available Protected
Health Information, in accordance with 45 C.F.R.164.524, as necessary for Customer to respond to
individuals’ requests for access to Protected Health Information about them, including, as of September
23, 2013, providing or sending a copy to a designated third party and providing or sending a copy in
electronic format, to the extent that the Protected Health Information in Business Associate’s possession
constitutes a Designated Record Set;

(i) within thirty (30) days of receiving a written request from Customer, incorporate any amendments
or corrections to the Protected Health Information in accordance with 45 C.F.R. § 164.526, to the extent
that the Protected Health Information in Business Associate’s possession constitutes a Designated Record
Set; and

2.2. Responsibilities of the Customer.

(a) With regard to the use and/or disclosure of Protected Health Information by the Business Associate,
the Customer agrees: (i) to obtain any consent, authorization or permission that may be required by the
Privacy Regulation or any other applicable federal, state or local laws and/or regulations prior to
furnishing Business Associate the Protected Health Information pertaining to an individual; and (ii) that it
will not furnish Business Associate Protected Health Information that is subject to any arrangements
permitted or required of the Covered Entity, including but not
limited to, arrangements agreed to by Customer under 45 C.F.R.164.522 that may impact in any manner
the use and/or disclosure of Protected Health Information by the Business Associate under this Agreement
and the Service Agreement(s).

(b) Customer represents and warrants that its notice of privacy practices permits Customer to use and
disclose Protected Health Information in the manner that Business Associate is authorized to use and
disclose Protected Health Information under this Agreement.

#### 3. TERM AND TERMINATION

3.1. Term. Unless otherwise specified in this Agreement, each term and condition of this Agreement
shall become effective on the Effective Date. This Agreement shall continue in effect unless terminated as
provided in this Section 3, provided, that certain provisions and requirements of this Agreement shall
survive the expiration or termination of this Agreement in accordance with Section 4.4 herein.

3.2. Termination. If either Party knows of a pattern of activity or practice of the other Party that
constitutes a material breach or violation of this Agreement then the non-breaching Party shall provide
written notice of the breach or violation to the other Party that specifies the nature of the breach or
violation. The breaching Party must cure the breach or end the violation on or before thirty (30) days after
receipt of the written notice. In the absence of a cure reasonably satisfactory to the non-breaching Party
within the specified timeframe, or in the event the breach is reasonably incapable of cure, then the non-
breaching Party may terminate this Agreement and any related Service Agreement(s) to the extent that the
Service Agreement(s) requires Business Associate to create, receive, maintain or transmit Protected
Health Information.

3.3. Automatic Termination. This Agreement will automatically terminate with respect to any
Business Associate without any further action of the Parties upon the termination or expiration of all Service
Agreement(s) between Customer and such Business Associate.

3.4. Effect of Termination. Within sixty (60) days after the termination of this agreement with respect
to any one or more Business Associates, such Business Associate(s) agrees to return or destroy all Protected
Health Information, including such information in possession of such Business Associate&#39;s
subcontractors, if it is feasible to do so. If return or destruction of said Protected Health Information is not
feasible, such Business Associate(s) will extend any and all protections, limitations and restrictions
contained in this Agreement to the Business Associate’s use and/or disclosure of any Protected Health
Information retained after the termination of this Agreement, and limit any further uses and/or disclosures
to the purposes that make the return or destruction of the Protected Health Information infeasible.

#### 4. MISCELLANEOUS

4.1. Entire Agreement. This Agreement, and all attachments, schedules and exhibits hereto, constitutes
the entire agreement and understanding between the Parties with respect to the subject matter hereof and
supersedes any prior or contemporaneous written or oral memoranda, negotiations, arrangements,
contracts or understandings of any nature or kind between the Parties with respect to the subject matter
hereof.

4.2. Change of Law. Customer shall notify Business Associate within ninety (90) days of any
amendment to any provision of HIPAA, its implementing regulations set forth at 45 C.F.R. parts 160
through 164, or other applicable law which materially alters either Party’s or the Parties’ obligations
under this Agreement. The Parties agree to negotiate in good faith mutually acceptable and appropriate
amendment(s) to this Agreement to give effect to such revised obligations; provided, however, that if the
Parties are unable to agree on mutually acceptable amendment(s) within ninety (90) days of the relevant
change of law, either Party may terminate this Agreement consistent with Sections 3.4 and 4.4.

4.3. Construction of Terms. The terms of this Agreement shall be construed in light of any
interpretation and/or guidance on HIPAA, the Privacy Regulation and/or the Security Regulation issued by
HHS from time to time.

4.4. Survival. Sections 3.4, 4.3, 4.6, 4.8, 4.11, 5, 6 and this Section 4.4, and any other provisions of this
Agreement that by their terms are intended to survive, shall survive the termination of this Agreement.

4.5. Amendment; Waiver. This Agreement may not be modified, nor shall any provision hereof be
waived or amended, except in a writing duly signed by authorized representatives of the Parties. A waiver
with respect to one event shall not be construed as continuing, or as a bar to or waiver of any right or remedy
as to subsequent events.

4.6. Notices. Any notices to be given hereunder to a Party shall be made via U.S. Mail or express courier to
such Party’s address given above. Each Party may change its address and that of its representative for notice
by the giving of notice thereof in the manner herein above provided.

4.7. Counterparts; Facsimiles. This Agreement may be executed in any number of counterparts, each of
which shall be deemed an original. Facsimile copies hereof shall be deemed to be originals.

4.8. Disputes. If any controversy, dispute or claim arises between the Parties with respect to this
Agreement, the Parties shall make good faith efforts to resolve such matters informally.

4.9. Effective Date. The Effective Date of this Agreement shall be the last date on which both Parties have
executed the Agreement.

4.10. Binding Agreement; New Parties; Agency.

This Agreement shall be binding upon the Parties and their successors and permitted assigns. Any one or
more additional subsidiaries of MPI with a relationship with Customer in which such entity creates,
receives, maintains or transmits Protected Health Information for use in providing Services to Customer
(each a “New Party”) may join this Agreement as a Party and a Business Associate by executing and
delivering a counterpart of this Agreement. Each HIPAA BA Sub that creates, receives, maintains or
transmits Protected Health Information through the performance of Services to Customer shall be deemed
to be a New Party without further action by any Party hereto.

4.11. No Third Party Beneficiaries. Nothing in this Agreement shall confer upon any person other than the
Parties and their respective successors or assigns, any rights, remedies, obligations, or liabilities whatsoever.

4.12. Contradictory Terms. This Agreement hereby amends, modifies, supplements and is made part of the Service
Agreement(s), provided that any provision of the Service Agreement(s), including all exhibits or other attachments thereto
and all documents incorporated therein by reference, that is directly contradictory to one or more terms of this Agreement
(“Contradictory Term”) shall be superseded by the terms of this Agreement as of the date such terms become effective
pursuant to Section 3.1, to the extent and only to the extent of the contradiction and only to the extent that it is reasonably
impossible to comply with both the Contradictory Term and the terms of this Agreement.

4.13. Independent Contractor. Business Associate and Customer are and shall remain independent contractors
throughout the term. Nothing in this Agreement shall be construed to constitute Business Associate and Customer
as partners, joint venturers, agents or anything other than independent contractors.

#### 5. LIMITATION OF LIABILITY

NEITHER PARTY SHALL BE LIABLE TO THE OTHER PARTY FOR ANY INCIDENTAL,
CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES OF ANY KIND OR NATURE, WHETHER SUCH
LIABILITY IS ASSERTED ON THE BASIS OF CONTRACT, TORT (INCLUDING NEGLIGENCE OR
STRICT LIABILITY), OR OTHERWISE, EVEN IF THE OTHER PARTY HAS BEEN ADVISED OF THE
POSSIBILITY OF SUCH LOSS OR DAMAGES.

#### 6. DEFINITIONS

Regulatory citations in this Agreement are to the United States Code of Federal Regulations Title 45 parts 160 through
164, as interpreted and amended from time to time by HHS, for so long as such regulations are in effect. Unless
otherwise specified in this Agreement, all capitalized terms not otherwise defined shall have the meaning established
for purposes of Title 45 parts 160 through 164 of the United States Code of Federal Regulations, as amended from
time to time.

IN WITNESS WHEREOF, each of the undersigned has caused this Business Associate Agreement to be duly
executed effective as of Today’s Date.`;

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
  },
  content: {
    backgroundColor: theme.palette.grey[50],
  },
}));

const HipaaDialog = ({ open, onClose, onSubmit, accountName }) => {
  const { t } = useTranslation(['btn', 'dialogs']);
  const classes = useStyles();
  const [isChecked, setChecked] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="invite-title"
      fullWidth
      maxWidth="lg"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="invite-title" disableTypography>
        <Typography variant="h6">
          {t('dialogs:hipaaAgreementFor', { accountName })}
        </Typography>
        <Typography>{t('dialogs:scrollToTheBottom')}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ReactMarkdown source={AGREEMENT} />
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              color="primary"
              onChange={(e, checked) => {
                setChecked(checked);
              }}
            />
          }
          className={classes.checkbox}
          label={t('dialogs:readAndAccept')}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <RoundedButton
          onClick={onClose}
          variant="contained"
          color="secondary"
          size="small"
        >
          {t('btn:cancel')}
        </RoundedButton>
        <RoundedButton
          onClick={onSubmit}
          variant="contained"
          color="primary"
          size="small"
          disabled={!isChecked}
        >
          {t('btn:acceptContinue')}
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

HipaaDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  accountName: PropTypes.string.isRequired,
};

export default HipaaDialog;
