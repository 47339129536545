import React, { useEffect, useMemo, useState } from 'react';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import useFetchList from 'hooks/useFetchList';
import accountsPlaceholderImage from 'assets/placeholders/accounts.svg';
import { useDispatch, useSelector } from 'react-redux';
import getFakeDataList, { FAKE_TITLE_DATA } from '../../Shared/FakeDataGenerator';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import RefreshTableButton from '../../Shared/RefreshTableButton';
import {
  ALERT_FIELDS, ALERT_TAGS_OPTIONS, ALERTS_TYPE_SELECTOR_OPTIONS,
  FAKE_ALERTS,
  GET_ALERT_COLUMNS, STATUS_OPTIONS,
} from '../constants';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';
import { useParams } from 'react-router-dom';
import { NULL_QUERY_DELAY } from '../../Shared/constants';
import {
  getLocalizedDate,
  getLocalizedLabel,
} from '../../../helpers/localize';
import { CUSTOM_TAGS_OPTIONS } from '../../Shared/CustomTags';
import { CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS } from '../../ClaimsReporting/constants';
import {
  getNewAlertsNotification,
  sendViewedAlertsUuids,
} from '../../../redux_store/reducer/reducers/alertsReducer/api';
import { REQUEST_STATUS } from '../../../redux_store/constants';
import { checkPermission } from '../../../helpers/checkPermissions';
import permissions from '../../../config/permissions';

let timeoutID;

const AlertList = () => {
  const { en: { titles, placeholders } } = useSelector((state) => state.i18n);
  const { tablePreFilters, statusSendingViewedAlertsUuids } = useSelector((state) => state.alerts);
  const {
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);
  const { accountUuid: paramAccountUuid, patientUuid: paramsPatientUuid } = useParams();
  const dispatch = useDispatch();
  const isPatientPage = Boolean(paramsPatientUuid);
  const [modifiedAlerts, setModifiedAlerts] = useState();
  const [notViewedAlertsUuids, setNotViewedAlertsUuids] = useState([]);
  const [newViewedAlerts, setNewViewedAlerts] = useState([]);
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );
  const {
    items,
    totalCount,
    reloadData,
    filter,
    onFilter,
    clearFilter,
    onMultiColumnFilter,
    totalAccountsCount,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint: `/accounts/${paramAccountUuid || currentAccount.uuid}/alerts`,
    baseParams: {
      fields: ALERT_FIELDS.join(),
    },
  });

  const sendToBackendViewedAlertsUuids = () => {
    const viewedAlerts = newViewedAlerts.filter((uuid => notViewedAlertsUuids.includes(uuid)));
    setNewViewedAlerts([]);
    if (viewedAlerts.length) {
      dispatch(sendViewedAlertsUuids({
        accountUuid: paramAccountUuid || currentAccount.uuid,
        viewedAlertsUuids: viewedAlerts,
      }));
    }
  };

  useEffect(() => {
    if (statusSendingViewedAlertsUuids === REQUEST_STATUS.succeeded) {
      setTimeout(() => {
        dispatch(getNewAlertsNotification(paramAccountUuid || currentAccount.uuid));
      }, 500);
    }
  }, [statusSendingViewedAlertsUuids]);

  useEffect(() => {
    if (newViewedAlerts.length) {
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        sendToBackendViewedAlertsUuids();
      }, 1000);
    }
  }, [newViewedAlerts]);

  useEffect(() => {
    onMultiColumnFilter(tablePreFilters);
  }, [tablePreFilters]);

  useEffect(() => {
    if (items) {
      let newAlertsUuids = [];
      const newModifiedAlerts = items.map((alert) => {
        if (!alert.viewedBy) {
          newAlertsUuids.push(alert.uuid);
        }
        return {
          ...alert,
          accountCustomTags: alert.accountCustomTags ? alert.accountCustomTags.map(item => getLocalizedLabel(
            CUSTOM_TAGS_OPTIONS,
            item,
          )) : [],
          patientDateOfBirth: getLocalizedDate(alert.patientDateOfBirth, 'UTC'),
          alertTags: alert.alertTags ? alert.alertTags.map(item => getLocalizedLabel(
            ALERT_TAGS_OPTIONS,
            item,
          )) : [],
          orderDate: getLocalizedDate(alert.orderDate, 'UTC'),
          appointmentTimestamp: getLocalizedDate(alert.appointmentTimestamp, 'UTC'),
          invitedTimestamp: getLocalizedDate(alert.invitedTimestamp, 'UTC'),
          viewedTimestamp: getLocalizedDate(alert.viewedTimestamp, 'UTC'),
          entityType: alert?.entityType ? getLocalizedLabel(
            CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS,
            alert.entityType,
          ) : '-',
          alertType: alert?.alertType ? getLocalizedLabel(
            ALERTS_TYPE_SELECTOR_OPTIONS,
            alert.alertType,
          ) : '-',
          acknowledgeStatus: alert?.acknowledgeStatus ? getLocalizedLabel(
            STATUS_OPTIONS,
            alert.acknowledgeStatus,
          ) : '-',
          acknowledgedTimestamp: getLocalizedDate(alert.acknowledgedTimestamp, 'UTC'),
          wasViewed: !!alert.viewedBy,
        };
      });
      setModifiedAlerts(newModifiedAlerts);
      setNotViewedAlertsUuids(newAlertsUuids);
    }
  }, [items]);


  // const actions = () => [
  //   {
  //     key: '1',
  //     icon: UsersIcon,
  //     onActionClick: () => {
  //     },
  //     showConditionFlag: 'action',
  //     showConditionValue: ALERT_ACTIONS.acknowledge,
  //     children: titles.acknowledge,
  //   },
  //   {
  //     key: '2',
  //     icon: EditIcon,
  //     onActionClick: () => {
  //     },
  //     showConditionFlag: 'action',
  //     showConditionValue: ALERT_ACTIONS.oneMore,
  //     children: titles.oneMore,
  //   },
  // ];

  const isTableDataLoading = dataLoading;

  const FAKE_ALERT_LIST = getFakeDataList(FAKE_ALERTS);

  const reloadAlertList = (hasFilter) => {
    if (!hasFilter) {
      // clearFilter();
      onMultiColumnFilter(tablePreFilters)
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const columns = useMemo(
    () => GET_ALERT_COLUMNS(
      isPatientPage,
      paramAccountUuid,
      totalAccountsCount,
    ),
    [totalAccountsCount, isPatientPage, paramAccountUuid],
  );

  // const onCustomFilter = (dataKey, value) => {
  //   console.log(dataKey, value);
  //   let newFilters  = { ...filter, [dataKey]: value, 'alertCategory': null }
  //   console.log(newFilters)
  //   // delete newFilters['alertCategory']
  //
  //   console.log(newFilters)
  //   onMultiColumnFilter(newFilters)
  // };

  return (
    <ContentWrapper
      titleText={titles.alerts}
      count={totalCount || FAKE_TITLE_DATA}
      actions={[
        <RefreshTableButton
          reloadData={reloadAlertList}
          hasRightPadding
          isLoading={dataLoading}
        />,
      ]}
    >
      <VirtualizedTable
        tableKey={isDistributionSponsor ? TABLE_KEYS.alertsDS : TABLE_KEYS.alerts}
        hasColumnsSelect={true}
        fakeRows={FAKE_ALERT_LIST}
        rowCount={totalCount || modifiedAlerts?.length}
        rows={modifiedAlerts}
        columns={columns}
        FetchProps={{ ...restFetchProps,
          filter,
          onFilter: onFilter, //onCustomFilter,
        }}
        currentAccountPermissions={currentAccountPermissions}
        onDataLoading={isTableDataLoading}
        onRowViewed={(newUuids) => {
          setNewViewedAlerts([...newViewedAlerts, ...newUuids]);
        }}
        placeholder={
          <TablePlaceholder
            image={accountsPlaceholderImage}
            itemsNotFoundText={getTransitionWithVariable(placeholders.noItemsFound, {
              item: titles.alerts,
            })}
            message={getTransitionWithVariable(placeholders.reviseFiltersOrCreateYourFirstItem, {
              item: titles.alerts,
            })}
          />
        }
      />
    </ContentWrapper>
  );
};

export default AlertList;

