import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  errorText: {
    color: theme.palette.error.light,
    fontSize: 14,
    fontWeight: '400',
  },
}));

const UnknownError = ({ options }) => {
  const classes = useStyles();
  const { t } = useTranslation(['general', 'errors']);

  return (
    <div>
      <div>{t('errors:unknownError')}</div>
      {options && (
        <div className={classes.errorText}>{t('pleaseContactUs')}</div>
      )}
    </div>
  );
};

UnknownError.propTypes = {
  options: PropTypes.shape({
    correlationUUID: PropTypes.string.isRequired,
    userUUID: PropTypes.string,
  }),
};

UnknownError.defaultProps = {
  options: undefined,
};

export default UnknownError;
