import PropTypes from 'prop-types';
import React from 'react';
import 'intersection-observer';
import './styles.css';
import { ANSWER_COLORS_LIST, EMPTY_SURVEY_VALUE } from '../constants';
import MarkdownParser from '../../Reports/widgets/MarkdownParser';

const MultiSelect = ({ survey }) => {
  const { title, values, reportContent } = survey;

  if (!values.length) {
    return (
      <>
        <tr className="list-break-marker">
          <td>{title}</td>
          <td />
          <td />
          <td />
          <td className="empty-value">{EMPTY_SURVEY_VALUE}</td>
        </tr>
        {reportContent && (
          <tr className="list-break-marker">
            <td>
              <MarkdownParser content={reportContent} />
            </td>
          </tr>
        )}
      </>
    );
  }

  return (
    <>
      <tr className="list-break-marker">
        <td>{title}</td>
        <td>
          {values
            .filter((item) => item.valueUIClass === ANSWER_COLORS_LIST.success)
            .map((item) => item.title)
            .map((value) => (
              <span>
                {`${value}`}
                <br />
              </span>
            ))}
        </td>
        <td>
          {values
            .filter((item) => item.valueUIClass === ANSWER_COLORS_LIST.warning)
            .map((item) => item.title)
            .map((value) => (
              <span>
                {`${value}`}
                <br />
              </span>
            ))}
        </td>
        <td>
          {values
            .filter((item) => item.valueUIClass === ANSWER_COLORS_LIST.error)
            .map((item) => item.title)
            .map((value) => (
              <span>
                {`${value}`}
                <br />
              </span>
            ))}
        </td>
        <td>
          {values
            .filter(
              (item) =>
                item.valueUIClass === ANSWER_COLORS_LIST.usual ||
                item.valueUIClass === ANSWER_COLORS_LIST.info ||
                !item.valueUIClass,
            )
            .map((item) => item.title)
            .map((value) => (
              <span>
                {`${value}`}
                <br />
              </span>
            ))}
        </td>
      </tr>
      {reportContent && (
        <tr className="list-break-marker">
          <td>
            <MarkdownParser content={reportContent} />
          </td>
        </tr>
      )}
    </>
  );
};

MultiSelect.propTypes = {
  survey: PropTypes.object,
};

MultiSelect.defaultProps = {
  survey: {},
};

export default MultiSelect;
