export const CUSTOM_EMAIL_SECTION_FIELDS = [
  {
    rowNameFirst: 'survey',
    rowNameSecond: 'invitationEmail',
    firstFieldName: 'emailSurveyInvitationEnglish',
    secondFieldName: 'emailSurveyInvitationSpanish',
    idDefaultName: 'emailSurveyInvitationIsDefault',
  },
  {
    rowNameFirst: 'resource',
    rowNameSecond: 'invitationEmail',
    firstFieldName: 'emailResourceInvitationEnglish',
    secondFieldName: 'emailResourceInvitationSpanish',
    idDefaultName: 'emailResourceInvitationIsDefault',
  },
  {
    rowNameFirst: 'survey',
    rowNameSecond: 'invitationSMS',
    firstFieldName: 'smsSurveyInvitationEnglish',
    secondFieldName: 'smsSurveyInvitationSpanish',
    idDefaultName: 'smsSurveyInvitationIsDefault',
  },
  {
    rowNameFirst: 'resource',
    rowNameSecond: 'invitationSMS',
    firstFieldName: 'smsResourceInvitationEnglish',
    secondFieldName: 'smsResourceInvitationSpanish',
    idDefaultName: 'smsResourceInvitationIsDefault',
  },
  {
    rowNameFirst: 'survey',
    rowNameSecond: 'reminderEmail',
    firstFieldName: 'emailSurveyReminderEnglish',
    secondFieldName: 'emailSurveyReminderSpanish',
    idDefaultName: 'emailSurveyReminderIsDefault',
  },
  {
    rowNameFirst: 'resource',
    rowNameSecond: 'reminderEmail',
    firstFieldName: 'emailResourceReminderEnglish',
    secondFieldName: 'emailResourceReminderSpanish',
    idDefaultName: 'emailResourceReminderIsDefault',
  },
  {
    rowNameFirst: 'survey',
    rowNameSecond: 'reminderSMS',
    firstFieldName: 'smsSurveyReminderEnglish',
    secondFieldName: 'smsSurveyReminderSpanish',
    idDefaultName: 'smsSurveyReminderIsDefault',
  },
  {
    rowNameFirst: 'resource',
    rowNameSecond: 'reminderSMS',
    firstFieldName: 'smsResourceReminderEnglish',
    secondFieldName: 'smsResourceReminderSpanish',
    idDefaultName: 'smsResourceReminderIsDefault',
  },
  {
    rowNameFirst: 'survey',
    rowNameSecond: 'completionEmail',
    firstFieldName: 'emailSurveyCompletionEnglish',
    secondFieldName: 'emailSurveyCompletionSpanish',
    idDefaultName: 'emailSurveyCompletionIsDefault',
  },
  {
    rowNameFirst: 'survey',
    rowNameSecond: 'completionSMS',
    firstFieldName: 'smsSurveyCompletionEnglish',
    secondFieldName: 'smsSurveyCompletionSpanish',
    idDefaultName: 'smsSurveyCompletionIsDefault',
  },
];
