import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import cx from 'classnames';

export const useStyles = makeStyles((theme) => ({
  small:{
    height: 36,
  }
}));

const ModalButton = ({ disabled, onClick, title, small, variant }) => {
  const classes = useStyles();
  return <Button
    color={small ? 'secondary' : 'primary'}
    disabled={disabled}
    variant={variant || 'contained'}
    onClick={onClick}
    className={cx({
      [classes.small]: small,
    })}
  >
    {title}
  </Button>;
};

export default ModalButton;
