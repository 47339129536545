import React from 'react';
import { useParams } from 'react-router-dom';
import PatientActions from '../../Patients/PatientActions';
import RefreshTableButton from '../../Shared/RefreshTableButton';
import ExportFilesButton from './ExportFilesButton/ExportFilesButton';

const OrdersPatientActions = ({
                                reloadOrdersList,
                                dataLoading,
                                modifiedExportItems,
                                exportItemsLoadingStatus,
                                fetchExportItems,
                              }) => {
  const { patientUuid: paramsPatientUuid } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);

  return <>
    {isPatientPage ? (
      <PatientActions reloadList={reloadOrdersList}/>
    ) : (
      <>
        <RefreshTableButton
          reloadData={reloadOrdersList}
          isLoading={dataLoading}
        />
        <ExportFilesButton
          CSVData={modifiedExportItems}
          CSVDataLoadingStatus={exportItemsLoadingStatus}
          fetchCSVData={fetchExportItems}
        />
      </>
    )}
  </>;
};

export default OrdersPatientActions;
