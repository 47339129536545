import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation } from 'react-fetching-library';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import showServerError from 'helpers/showError';
import { routes } from 'config/routes';
import UserForm from './widgets/UserForm';
import { AppRouteContext } from 'contexts';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import BackButton from 'components/Shared/BackButton';
import getFullName from 'helpers/user';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';

const UserCreate = () => {
  const { accountUuid } = useParams();
  const { t } = useTranslation(['errors', 'notifications', 'btn']);
  const [backendErrors, setBackendErrors] = useState({});
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { setAppRoute } = useContext(AppRouteContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const userCreateAction = (values) => ({
    method: 'PUT',
    endpoint: `/accounts/${accountUuid}/users/`,
    body: values,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [
    userCreateAction,
  ]);

  const createUser = async (values, { setSubmitting }) => {
    setBackendErrors({});
    const parsedValues = {
      ...values,
      userPermissions: {
        managesUsers: values.managesUsers,
        receiveClaimsEmails: values.receiveClaimsEmails,
        receiveSurveysEmails: values.receiveSurveysEmails,
        imminentRiskEmails: values.imminentRiskEmails,
        administerClaims: values.administerClaims,
        administerClaimReports: values.administerClaimReports,
      },
    };
    delete parsedValues.managesUsers;
    delete parsedValues.receiveClaimsEmails;
    delete parsedValues.receiveSurveysEmails;
    delete parsedValues.imminentRiskEmails;
    delete parsedValues.administerClaims;
    delete parsedValues.administerClaimReports;

    const { payload, error, status } = await mutate(parsedValues);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      setSubmitting(false);
      switch (status) {
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:userCreated', {
            name: getFullName(values),
          }),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      // no title needed cause this route should be in breadcrumbs already
      setAppRoute(
        { path: routes.accountUsers.path, params: { accountUuid } },
        { addDelay: true },
      );
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        providerTitle: '',
        providerNPINumber: '',
        managesUsers: false,
        administerClaims: false,
        administerClaimReports: false,
        receiveClaimsEmails: false,
        receiveSurveysEmails: false,
        imminentRiskEmails: false,
      }}
      onSubmit={createUser}
    >
      {({ isValid, values, handleSubmit, isSubmitting, dirty }) => (
        <ContentWrapper
          titleText={t('titles:createUser')}
          actions={
            <>
              <BackButton />
              <RoundedButton
                variant="contained"
                color="primary"
                size="small"
                disabled={!isValid || isSubmitting || !dirty}
                onClick={handleSubmit}
                isLoading={loading}
                isSuccess={isSuccess}
                isFailed={isFailed}
              >
                {t('btn:create')}
              </RoundedButton>
            </>
          }
        >
          <UserForm
            values={values}
            backendErrors={backendErrors}
            setBackendErrors={setBackendErrors}
          />
        </ContentWrapper>
      )}
    </Formik>
  );
};

export default UserCreate;
