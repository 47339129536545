import qs from 'qs';
import { SortDirection } from 'react-virtualized';

export const getEndpoint = (
  baseEndpoint,
  baseParams,
  { sortDirection, sortBy },
  newFilter,
) => {
  const filters = Object.entries(newFilter).filter(([filterKey, filterValue]) => {
    if (Array.isArray(filterValue)) {
      return filterValue.length > 0 && filterValue.join('').replace(' ', '').length > 0;
    } else {
      return !!filterValue;
    }
  }).map(
    ([filterKey, filterValue]) => {
      let resultFilter = '';
      if (Array.isArray(filterValue)) {
        filterValue.forEach(filterKey => {
          const base64 = window.btoa(filterKey);
          resultFilter += base64 + ',';
        });
        resultFilter = resultFilter.slice(0, resultFilter.length - 1);
      } else {
        resultFilter += filterValue;
      }
      return `${filterKey}:${resultFilter}`;
    },
  );

  if (baseParams.q) {
    filters.push(baseParams.q);
  }

  let sort;
  if (sortBy) {
    const direction = sortDirection === SortDirection.DESC ? '-' : '';
    sort = `${direction}${sortBy}`;
  }
  const qsParams = {
    ...baseParams,
    q: filters,
    sort,
  };
  return `${baseEndpoint}${qs.stringify(qsParams, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  })}`;
};

export const getTokenEndpoint = (baseEndpoint, token) =>
  `${baseEndpoint}${qs.stringify(
    {
      'continuation-token': token,
    },
    { addQueryPrefix: true },
  )}`;
