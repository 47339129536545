import * as React from 'react';
import {
  Box,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { stepsNameConstants } from '../constants';


const useStyles = makeStyles((theme) => ({
  successStyle: {
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: `${theme.palette.success.main} !important`,
    },
  },
  title: {
    height: 40,
    display: 'flex',
    padding: 12,
  },
}));

function HorizontalLinearStepper({ stepName, setStep, hasValidationError, steps }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const classes = useStyles();

  useEffect(() => {
    let newActiveStep = 0;
    switch (stepName) {
      case stepsNameConstants.validate: {
        newActiveStep = 1;
        break;
      }
      case stepsNameConstants.finish:
      case stepsNameConstants.import: {
        newActiveStep = 2;
        break;
      }
      default: {
        break;
      }
    }
    setActiveStep(newActiveStep);
  }, [stepName]);

  const onStepClick = (newStep) => {
    let newStepName = '';
    switch (newStep) {
      case 0: {
        newStepName = stepsNameConstants.selectFile;
        break;
      }
      case 1: {
        if (hasValidationError) {
          newStepName = stepsNameConstants.validate;
        }
        break;
      }
      default: {
        break;
      }
    }

    if (newStepName) {
      setStep(newStepName);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (stepName === stepsNameConstants.finish && index === 2) {
            stepProps.completed = true;
          }

          if (hasValidationError && index === 1) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                Validation failed
              </Typography>
            );

            labelProps.error = true;
          }

          return (
            <Step
              key={label}
              {...stepProps}
              onClick={index < activeStep ? () => onStepClick(index) : null}
              style={{
                cursor: index < activeStep ? 'pointer' : 'inherit',
              }}
              classes={{
                horizontal: classes.title,
                completed: classes.successStyle,
              }}
            >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

HorizontalLinearStepper.propTypes = {
  stepName: PropTypes.string,
  setStep: PropTypes.func,
  hasValidationError: PropTypes.bool,
  hasValidation: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

HorizontalLinearStepper.defaultProps = {
  stepName: undefined,
  setStep: undefined,
  hasValidationError: undefined,
  hasValidation: true,
};

export default HorizontalLinearStepper;
