import React from 'react';
import ContentWrapper from '../Wrappers/ContentWrapper';
import Loader from './Loader';

const EmptyPageLoader = () => {
  return (
    <ContentWrapper>
      <Loader fullHeight />
    </ContentWrapper>
  );
};

EmptyPageLoader.propTypes = {};

EmptyPageLoader.defaultProps = {};

export default EmptyPageLoader;
