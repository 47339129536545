import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  breadcrumbsContainer: {
    borderBottom: '1px solid #8E8E8E',
    display: 'flex',
    justifyContent: 'space-between',
    height: 20,
    width: 350,
  },
  breadcrumbs: {
    backgroundColor: 'white',
    padding: 10,
    color: '#8E8E8E',
    fontWeight: 500,
    transition: 'color 0.5s ease',
  },
  activeBreadcrumbs: {
    color: '#00A8FF',
  },
  doneBreadcrumbs: {
    color: 'black',
  },
}));

const Breadcrumbs = ({ activeStep, steps }) => {
  const classes = useStyles();

  return (
    <div className={classes.breadcrumbsContainer}>
      <div
        className={cx(classes.breadcrumbs, {
          [classes.activeBreadcrumbs]: activeStep === 0,
          [classes.doneBreadcrumbs]: activeStep > 0,
        })}
      >
        SELECT
      </div>
      <div
        className={cx(classes.breadcrumbs, {
          [classes.activeBreadcrumbs]: activeStep > 0 && activeStep <= steps[0],
          [classes.doneBreadcrumbs]: activeStep > steps[0],
        })}
      >
        CHOOSE
      </div>
      <div
        className={cx(classes.breadcrumbs, {
          [classes.activeBreadcrumbs]: activeStep >= steps[1],
        })}
      >
        CONFIRM
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default Breadcrumbs;
