import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  iconBox: {
    width: 73,
    height: 73,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.lightBlue,
  },
  checkIcon: {
    width: 50,
    height: 35,
    color: theme.palette.common.white,
  },
  title: {
    paddingTop: theme.spacing(2),
    color: theme.colors.lightBlue,
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
  createButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
  },
}));

const InvitationSent = ({ handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'titles']);

  return (
    <Box pt={6}>
      <Box display="flex" justifyContent="center">
        <Box className={classes.iconBox}>
          <CheckIcon className={classes.checkIcon} />
        </Box>
      </Box>
      <Typography className={classes.title}>
        {t('titles:invitationSent')}
      </Typography>
      <Box pt={9} pb={2} display="flex" justifyContent="center">
        <RoundedButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleClose}
          className={classes.createButton}
        >
          {t('btn:close')}
        </RoundedButton>
      </Box>
    </Box>
  );
};

InvitationSent.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default InvitationSent;
