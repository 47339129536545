import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { conformToMask } from 'react-text-mask';
import { useMutation } from 'react-fetching-library';
import { compare } from 'fast-json-patch';
import FormikTextField from 'components/FormikFields/FormikTextField';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import { FormattedPhone, MASK } from 'components/Shared/FormattedPhone';
import getDigitPhone from 'helpers/phone';
import showServerError from 'helpers/showError';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import BackButton from 'components/Shared/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { setUser } from '../../redux_store/reducer/reducers/userReducer/userReducer';

const ProfileEdit = () => {
  const { t } = useTranslation(['titles', 'forms', 'notifications', 'btn']);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [backendErrors, setBackendErrors] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const profileEditAction = (patches) => ({
    method: 'PATCH',
    endpoint: '/user',
    body: patches,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [
    profileEditAction,
  ]);

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
  };

  const editProfile = async (values, { setSubmitting }) => {
    // remove non-digits from phone
    const formattedValues = { ...values, phone: getDigitPhone(values.phone) };
    const patches = compare(initialValues, formattedValues);

    if (patches.length) {
      setBackendErrors({});
      const { payload, error, status } = await mutate(patches);
      if (error) {
        setIsFailed(true);
        setTimeout(() => {
          setIsFailed(false);
        }, 2000);
        const options = payload && {
          correlationUUID: payload.correlationUUID,
          userUUID: user.uuid,
        };
        switch (status) {
          case 422: {
            showServerError(dispatch, t('errors:validationError'));
            setBackendErrors(payload.validationErrors);
            break;
          }
          default: {
            showServerError(dispatch, '', options);
            break;
          }
        }
      } else if (payload) {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
        dispatch(setUser(payload));
        dispatch(
          enqueueSnackbar({
            message: t('notifications:profileUpdated'),
            options: {
              variant: SNACKBAR_VARIANTS.success,
            },
          }),
        );
      }
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        phone: conformToMask(user.phone, MASK).conformedValue, // phone number need to be formatted
      }}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={editProfile}
    >
      {({ isValid, isSubmitting, dirty, handleSubmit }) => (
        <ContentWrapper
          titleText={t('titles:editProfile')}
          actions={
            <>
              <BackButton />
              <RoundedButton
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                disabled={!isValid || isSubmitting || !dirty}
                isLoading={loading}
                isSuccess={isSuccess}
                isFailed={isFailed}
                onClick={handleSubmit}
              >
                {t('btn:save')}
              </RoundedButton>
            </>
          }
        >
          <Form>
            <Grid container direction="column">
              <FormikTextField
                type="text"
                name="firstName"
                label={t('forms:firstName')}
                backendError={backendErrors.firstName}
                required
              />
              <FormikTextField
                type="text"
                name="lastName"
                label={t('forms:lastName')}
                backendError={backendErrors.lastName}
                required
              />
              <FormikTextField
                type="email"
                name="email"
                label={t('forms:email')}
                autoComplete="email"
                backendError={backendErrors.email}
                required
              />
              <FormikTextField
                type="tel"
                name="phone"
                label={t('forms:phone')}
                backendError={backendErrors.phone}
                InputProps={{
                  inputComponent: FormattedPhone,
                }}
                required
              />
            </Grid>
          </Form>
        </ContentWrapper>
      )}
    </Formik>
  );
};

export default ProfileEdit;
