import { createClient, createCache } from 'react-fetching-library';
import { setAuthorizationExpiresTimeout } from '../redux_store/reducer/reducers/appSettingsReducer';

const requestInterceptor = (host) => () => async (action) => {
  return {
    ...action,
    headers: {
      ...action.headers,
      'Authorization-SetAsCookie': 'true',
      'Client': 'dashboard',
    },
    credentials: 'include',
    endpoint: `${host}${action.endpoint}`,
  };
};

const responseInterceptor = (dispatch) => () => async (action, response) => {
  if (response.headers.has('Authorization-Expires')) {
    dispatch(setAuthorizationExpiresTimeout(response.headers.get('Authorization-Expires').toString()));
  }

  if (response.payload.data !== undefined) {
    return {
      ...response,
      payload: response.payload.data,
    };
  }

  if (response.status === 410) {
    console.log(response);
    window.location.href = '/login#logout';
    return;
  }

  return response;
};

const CACHE_TIME = 3000;

const cache = createCache(
  (action) => action.method === 'GET',
  (response) => new Date().getTime() - response.timestamp < CACHE_TIME,
);

const client = (dispatch) => createClient({
  requestInterceptors: [requestInterceptor(process.env.REACT_APP_API_URL)],
  responseInterceptors: [responseInterceptor(dispatch)],
  cacheProvider: cache,
});

export default client;
