import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import MenuRow from './MenuRow';
import NoOptions from './NoOptions';
import { useSelector } from 'react-redux';
import ModalButton from './ModalButton';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    width: 380,
    flexDirection: 'column',
    padding: 24,
    paddingBottom: 0,
    maxHeight: 500,
    overflow: 'auto',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    fontSize: 16,
    color: theme.colors.blue2,
  },
  selectedOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
}));

const SelectedSection = ({
                           selectedOptions = [],
                           setSelectedOptions,
                           handleClose,
                           clearAllFilter,
                         }) => {

  const { en: { tables,btn } } = useSelector((state) => state.i18n);
  const classes = useStyles();
  const hasSelectedOptions = !!(selectedOptions && selectedOptions.length);
  return <div className={classes.mainContainer}>
    <Typography className={classes.title}>
      {tables.selectedOptions}
    </Typography>
    <div style={{
      marginBottom: 16,
    }}>
      <ModalButton
        selectedOptions={[]}
        onClick={clearAllFilter}
        filterValue={'1'}
        title={btn.clearFilter}
        small={true}
      />
    </div>
    {
      hasSelectedOptions &&
      <div className={classes.selectedOptionsContainer}>
        {
          selectedOptions.map((option) => (
            <MenuRow
              option={option}
              selected={selectedOptions}
              onItemClick={(option) => {
                setSelectedOptions(selectedOptions.filter(item => item !== option));
              }}
              applyCurrentFilter={(e) => handleClose(e, option)}
            />
          ))}
      </div>
    }
    {!hasSelectedOptions && <NoOptions title={tables.noSelectedOptions} />}
  </div>;
};

export default SelectedSection;
