import React, { useContext } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { UserContext } from '../../../contexts';
import BlurScreen from '../../Shared/blur_screen';
import NavWrapper from './widgets/NavWrapper';
import NavFooter from './widgets/NavFooter';
import NavLogo from './widgets/NavLogo';

const useStyles = makeStyles((theme) => ({
  navBar: {
    flexShrink: 0,
    width: 70,
    minWidth: 70,
    transition: '0.3s',
    flexWrap: 'nowrap',
    background: 'linear-gradient(180deg, #2E48D3 0%, #00A8FF 100%)',
    height: '100vh',
    position: 'relative',
  },
  pinnedNavBar: {
    width: 240,
    flexWrap: 'nowrap',
    background: 'linear-gradient(180deg, #2E48D3 0%, #00A8FF 100%)',
    height: '100vh',
    transition: '0.3s',
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);
  const { changeAccountLoading } = useContext(UserContext);
  return (
    <Grid
      container
      direction='column'
      id={'navBar'}
      className={cx(classes.navBar, {
        [classes.pinnedNavBar]: isSideMenuPinned,
      })}
    >
      <NavLogo />
      <BlurScreen
        isBlurShown={changeAccountLoading}
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <NavWrapper />
        <NavFooter />
      </BlurScreen>
    </Grid>
  );
};

export default NavBar;
