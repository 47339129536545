import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from '../../client';
import { getLocalizedDate } from '../../../helpers/localize';
import { CHARTS } from '../../../components/Dashboard/constants';
import { REQUEST_STATUS } from '../../constants';

const checkCharts = () => {
  let charts = window.localStorage.getItem('charts');
  return JSON.parse(charts);
};

const setCharts = (charts) => {
  localStorage.setItem('charts', JSON.stringify(charts));
};

export const getMetrics = createAsyncThunk('metrics/getMetrics', (...args) =>
  client.get(`/accounts/${args[0]}/metrics`, ...args),
);

export const markHelpSeekingBehaviorAlertAsReviewed = createAsyncThunk('metrics/markHelpSeekingBehaviorAlertAsReviewed', (...args) => {
    return client.get(`/accounts/${args[0].accountUUID}/surveys/${args[0].surveyUUID}/provide-help`, ...args);
  },
);

export const chartsSlice = createSlice({
  name: 'charts',
  initialState: {
    metrics: null,
    charts: null,
    assessmentAlerts: null,
    helpSeekingBehaviorAlerts: null,
    status: REQUEST_STATUS.idle,
  },
  reducers: {
    getCharts: (state) => {
      state.charts = checkCharts() || CHARTS;
      if (!checkCharts()) {
        setCharts(CHARTS);
      }
    },
    updateCharts: (state, action) => {
      state.charts = action.payload;
      setCharts(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMetrics.pending, (state) => {
        state.status = REQUEST_STATUS.loading;
      })
      .addCase(getMetrics.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.status = REQUEST_STATUS.succeeded;
          const metrics = action.payload.data.data;
          state.metrics = metrics;
          state.assessmentAlerts = metrics.chartData.assessmentAlerts.map(
            (assessment) => {
              return {
                ...assessment,
                uuid: assessment.surveyUUID,
                surveyName: assessment.title ?? '',
                completedDate: getLocalizedDate(assessment.completedTimestamp),
              };
            },
          );
          state.helpSeekingBehaviorAlerts = metrics.chartData.helpSeekingBehaviorAlerts.map(
            (assessment) => {
              return {
                ...assessment,
                uuid: assessment.surveyUUID,
                surveyName: assessment.title ?? '',
                completedDate: getLocalizedDate(assessment.completedTimestamp),
              };
            },
          );
        } else {
          state.status = REQUEST_STATUS.failed;
        }
      })
      .addCase(markHelpSeekingBehaviorAlertAsReviewed.pending, (state, action) => {
        const { meta: { arg: { surveyUUID } } } = action;
        state.helpSeekingBehaviorAlerts = [...state.helpSeekingBehaviorAlerts].filter(
          (assessment) => {
            return assessment.uuid !== surveyUUID;
          },
        );
      })
      .addCase(markHelpSeekingBehaviorAlertAsReviewed.fulfilled, () => {
        // if (action.payload.ok) {
        //   // state.status = REQUEST_STATUS.succeeded;
        // } else {
        //   // state.status = REQUEST_STATUS.failed;
        // }
      });
  },
});

export const { updateCharts, getCharts } = chartsSlice.actions;

export default chartsSlice.reducer;
