import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import RoundedButton from '../../../../../Shared/RoundedButton';

const AllowedAccountsModalFooter = ({
                                      applyChanges,
                                      closeModal,
                                    }) => {
  const { en: { btn } } = useSelector((state) => state.i18n);
  return <Box p={2} pb={0} display='flex' justifyContent='space-between'>
    <RoundedButton
      variant='outlined'
      color='primary'
      size='small'
      onClick={closeModal}
    >
      {btn.cancel}
    </RoundedButton>
    <RoundedButton
      variant='contained'
      color='primary'
      size='small'
      onClick={applyChanges}
    >
      {btn.applyChanges}
    </RoundedButton>
  </Box>;
};

export default AllowedAccountsModalFooter;
