import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RoundedButton from 'components/Shared/RoundedButton';

const EMAIL = 'support@test.localhost';

const useStyles = makeStyles((theme) => ({
  contactBtn: {
    border: `2px solid ${theme.palette.error.light}`,
    color: theme.palette.error.main,
    backgroundColor: theme.colors.errorPinkLight,
    marginRight: theme.spacing(1),
  },
}));

const SupportButton = ({ options }) => {
  const { correlationUUID, userUUID } = options;
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors']);
  const userStr = userUUID ? `UserUUID: ${userUUID}` : '';

  const emailSubject = t('errors:supportRequest');
  const emailBody = `${t('errors:pleaseEnterDescription')}

----------------------

CorrelationUUID: ${correlationUUID}
Timestamp: ${Date.now()}
${userStr}
  `;

  return (
    <RoundedButton
      variant="outlined"
      className={classes.contactBtn}
      component="a"
      href={`mailto:${EMAIL}?subject=${encodeURIComponent(
        emailSubject,
      )}&body=${encodeURIComponent(emailBody)}`}
    >
      {t('btn:contactSupport')}
    </RoundedButton>
  );
};

SupportButton.propTypes = {
  options: PropTypes.shape({
    correlationUUID: PropTypes.string.isRequired,
    userUUID: PropTypes.string,
  }).isRequired,
};

export default SupportButton;
