import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import i18next from 'i18next';
import { Form } from 'formik';

import FormikTextField from 'components/FormikFields/FormikTextField';
import FormControlButtons from 'components/Shared/FormControlButtons';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%',
  },
}));

const ResetPasswordTextField = ({
  name,
  type,
  dirty,
  loading,
  isValid,
  submitText,
  isSubmitting,
  cancelButtonRoute,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <Form className={classes.form}>
      <FormikTextField type={type} name={name} {...restProps} />
      <FormControlButtons
        loading={loading}
        isValid={isValid}
        submitText={submitText}
        isSubmitting={isSubmitting}
        cancelButtonRoute={cancelButtonRoute}
        dirty={dirty}
      />
    </Form>
  );
};

ResetPasswordTextField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  submitText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
  isValid: PropTypes.bool,
  cancelButtonRoute: PropTypes.PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

ResetPasswordTextField.defaultProps = {
  loading: false,
  isSubmitting: false,
  dirty: false,
  isValid: false,
  submitText: i18next.t('btn:submit'),
};

export default ResetPasswordTextField;
