import { useEffect, useContext } from 'react';

import { routes } from 'config/routes';
import { AppRouteContext } from 'contexts';
import { findAllowedRoute } from 'helpers/checkPermissions';
import { useSelector } from 'react-redux';

const Home = () => {
  const { setAppRoute } = useContext(AppRouteContext);
  const {
    metadata: { currentAccountPermissions },
  } = useSelector((state) => state.user);
  const { disabledFeatures } = useSelector((state) => state.appSettings);

  useEffect(() => {
    const newRoute = findAllowedRoute(
      routes.dashboard,
      currentAccountPermissions,
      disabledFeatures,
    );
    setAppRoute({
      path: newRoute.path,
      title: newRoute.additional.title,
      clearBreadcrumbs: true,
    });
  }, []);
  return null;
};

export default Home;
