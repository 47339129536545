import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import React  from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import { useDispatch } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const useStyles = makeStyles((theme) => ({
  createButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
    maxHeight: 40,
  },
  copyIcon: {
    width: 69,
    marginLeft: 15,
  },
  link: {
    cursor: 'default',
    textAlign: 'center',
    display: 'flex',
    fontSize: 25,
    fontWeight: 500,
    padding: theme.spacing(2, 6),
    msUserSelect: 'none',
    userSelect: 'none',
  },
  linkBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.colors.gray1,
    marginLeft: 84,
  },
}));

const AssessmentUrl = ({
                         onCloseModal,
                         link,
                       }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['btn', 'errors', 'notifications', 'titles']);

  const copyToClipboard = (event) => {
    const val = event.currentTarget.dataset.value;
    const range = document.createRange();
    const selection = document.getSelection();
    const mark = document.createElement('span');
    mark.textContent = val;
    mark.style.all = 'unset';
    mark.style.position = 'fixed';
    mark.style.top = 0;
    mark.style.clip = 'rect(0, 0, 0, 0)';
    mark.style.whiteSpace = 'pre';
    mark.style.webkitUserSelect = 'text';
    mark.style.MozUserSelect = 'text';
    mark.style.msUserSelect = 'text';
    mark.style.userSelect = 'text';
    mark.addEventListener('copy', (e) => {
      e.stopPropagation();
    });

    document.body.appendChild(mark);

    if (selection.rangeCount > 0) {
      selection.removeAllRanges();
    }

    range.selectNodeContents(mark);
    selection.addRange(range);
    document.execCommand('copy');
    document.body.removeChild(mark);
    dispatch(
      enqueueSnackbar({
        message: t('notifications:surveyURLCopied'),
        options: {
          variant: SNACKBAR_VARIANTS.success,
        },
      }),
    );
  };

  function openInNewTab() {
    if (link) {
      window.open(link, '_blank').focus();
    }
  }

  return (
    <>
      <Box display='flex' justifyContent='center'>
        <Box className={classes.linkBox}>
          <Typography className={classes.link}>{link}</Typography>
        </Box>
        <IconButton
          data-value={link}
          aria-label='delete'
          className={classes.copyIcon}
          size='medium'
          onClick={copyToClipboard}
        >
          <FileCopyOutlinedIcon fontSize='inherit' />
        </IconButton>
      </Box>
      <Box pt={3} display='flex' justifyContent='center'>
        <RoundedButton
          variant='contained'
          color='primary'
          size='small'
          onClick={openInNewTab}
          className={classes.createButton}
        >
          {t('btn:openAssessmentIntoNewTab')}
        </RoundedButton>
      </Box>
      <Box pt={7} pb={2} display='flex' justifyContent='center'>
        <RoundedButton
          variant='outlined'
          color='primary'
          size='small'
          onClick={onCloseModal}
          className={classes.createButton}
        >
          {t('btn:close')}
        </RoundedButton>
      </Box>
    </>
  );
};

AssessmentUrl.propTypes = {
  link: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default AssessmentUrl;
