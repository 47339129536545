import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  helperText: {
    marginLeft: theme.spacing(1.75),
    marginTop: 0,
  },
}));

const HelperText = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <FormHelperText className={classes.helperText} {...rest}>
      {children}
    </FormHelperText>
  );
};

HelperText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

HelperText.defaultProps = {
  children: undefined,
};

export default HelperText;
