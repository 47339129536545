import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import useFetchList from 'hooks/useFetchList';
import { getLocalizedDate, getLocalizedLabel } from 'helpers/localize';
import { useSelector } from 'react-redux';
import { SortDirection } from 'react-virtualized';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import { NULL_QUERY_DELAY } from '../Shared/constants';
import ordersPlaceholderImage from '../../assets/placeholders/orders.svg';
import RefreshTableButton from '../Shared/RefreshTableButton';
import PatientActions from '../Patients/PatientActions';
import PatientAdditionalNode from '../Patients/PatientAdditionalNode';
import PatientInfoBlock from '../Patients/PatientInfoBlock';
import {
  GET_RESOURCES_COLUMNS,
  RESOURCE_FOR_PATIENT_FIELDS,
  RESOURCE_STATUSES_OPTIONS,
  FIELDS,
  FAKE_RESOURCE,
  RESOURCE_STATUSES,
} from './constants';
import ConfirmResendingInvitation from '../Assessments/List/widgets/ConfirmResendingInvitation';
import { ReactComponent as PendingIcon } from '../../assets/icons/pending.svg';
import getFakeDataList, { FAKE_TITLE_DATA } from '../Shared/FakeDataGenerator';
import ResourcesCSVButton from './ResourcesCSV';
import { TABLE_KEYS } from '../../redux_store/reducer/reducers/tableColumnsReducer';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

const defaultConfirmResendInvitationState = {
  patientUUID: '',
  resource: '',
  accountUUID: '',
};

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'hidden',

    '@media print': {
      display: 'none',
    },
  },
}));

const ResourcesList = () => {
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);
  const classes = useStyles();
  const { patientUuid: paramsPatientUuid, accountUuid: paramAccountUuid } =
    useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const {
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);
  const [filterStyle, setFilterStyle] = useState(false);
  const [modifiedItems, setModifiedItems] = useState();
  const { patientUuid, patientInfo } = useContext(ActivePatientContext);
  const [tableFilter, setTableFilter] = useState();
  const [tableSort, setTableSort] = useState({
    sortBy: 'orderDate',
    sortDirection: SortDirection.DESC,
  });
  const [openConfirmResentDialog, toggleConfirmResentDialog] = useState(
    defaultConfirmResendInvitationState,
  );

  const handleConfirmResentDialog = (
    e,
    { patientUUID, uuid, patientEmail, patientPhone, accountUUID },
  ) => {
    toggleConfirmResentDialog({
      sendInviteToPatientEmail: patientEmail,
      sendInviteToPatientPhone: patientPhone,
      patientUUID,
      resource: uuid,
      accountUUID,
    });
  };

  const baseEndpoint = isPatientPage
    ? `/accounts/${
        paramAccountUuid || currentAccount.uuid
      }/patients/${paramsPatientUuid}/resources`
    : `/accounts/${paramAccountUuid || currentAccount.uuid}/resources`;

  const {
    items,
    totalCount,
    totalAccountsCount,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint,
    baseParams: {
      fields: (isPatientPage ? RESOURCE_FOR_PATIENT_FIELDS : FIELDS).join(),
      q: isPatientPage ? `patientUUID:${paramsPatientUuid}` : undefined,
    },
    baseSort: {
      sortBy: 'orderTimestamp',
      sortDirection: SortDirection.DESC,
    },
  });

  const { filter, sort } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);
  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');

  useEffect(() => {
    if (items) {
      const newModifiedItems = items.map((item) => ({
        ...item,
        dateOfBirth: getLocalizedDate(item.dateOfBirth, 'UTC'),
        orderTimestamp: !item.orderTimestamp
          ? '-'
          : getLocalizedDate(item.orderTimestamp, 'UTC'),
        appointmentTimestamp: !item.appointmentTimestamp
          ? '-'
          : getLocalizedDate(item.appointmentTimestamp, 'UTC'),
        engagedTimestamp: !item.engagedTimestamp
          ? '-'
          : getLocalizedDate(item.engagedTimestamp, 'UTC'),
        invitedTimestamp: item.invitedTimestamp
          ? getLocalizedDate(item.invitedTimestamp, 'UTC')
          : '-',
        createdBy:
          item.createdBy === 'manuallyCreated'
            ? t('tables:manuallyCreated')
            : item.createdBy === 'automated'
            ? t('tables:automated')
            : item.createdBy,
        patientMrnNumber: !item.patientMrnNumber ? '-' : item.patientMrnNumber,
        patientFirstName: item.patientFirstName,
        patientLastName: item.patientLastName,
        resource: item.title,
        resourceStatus: getLocalizedLabel(
          RESOURCE_STATUSES_OPTIONS,
          item.status,
        ),
        claimable: item.claimExists === false,
        accountCustomIdentifier: item.accountCustomIdentifier
          ? item.accountCustomIdentifier
          : 'N/A',
        accountCustomTags: item.accountCustomTags ? item.accountCustomTags.map(item => getLocalizedLabel(
          CUSTOM_TAGS_OPTIONS,
          item,
        )) : [],
        moreTitle: isPatientPage
          ? `${item.title}`
          : `${item.patientFirstName} ${item.patientLastName}`,
      }));

      setModifiedItems(newModifiedItems);
    }
  }, [items]);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  const handleRowClick = () => {
    //
  };

  useEffect(() => {
    if (sort) {
      setTableSort(sort);
    }
    if (filter) {
      setTableFilter(filter);
    }
  }, [sort, filter]);

  const reloadResourceList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const checkIsResendingDisabled = (resourceUuid) => {
    if (dataLoading || !resourceUuid) {
      return true;
    }
    let isDisabled = false;

    modifiedItems.forEach((resource) => {
      if (resource.uuid === resourceUuid) {
        isDisabled =
          resource.status.toLowerCase() ===
          RESOURCE_STATUSES.scheduled.toLowerCase();
      }
    });
    return isDisabled;
  };

  const titleText = isPatientPage
    ? (patientInfo && `${patientLastName}, ${patientFirstName}`) ||
      FAKE_TITLE_DATA
    : t('titles:resources');

  const isTableDataLoading = dataLoading || !modifiedItems;
  const FAKE_RESOURCE_LIST = getFakeDataList(FAKE_RESOURCE);
  return (
    <>
      {openConfirmResentDialog.resource && (
        <ConfirmResendingInvitation
          source="resource"
          handleClose={() =>
            toggleConfirmResentDialog(defaultConfirmResendInvitationState)
          }
          reloadData={reloadData}
          resendInvitationData={openConfirmResentDialog}
        />
      )}
      <ContentWrapper
        titleText={titleText}
        className={classes.paper}
        hasTopPaddingForSmallScreen={isPatientPage}
        underTitleText={
          isPatientPage ? (
            <PatientInfoBlock
              isForInitLoading={
                (!items && patientUuid !== paramsPatientUuid) || !patientInfo
              }
            />
          ) : (
            ''
          )
        }
        count={isPatientPage ? null : totalCount || FAKE_TITLE_DATA}
        countFiltered={filterStyle}
        actions={
          <>
            {isPatientPage ? (
              <PatientActions reloadList={reloadResourceList}/>
            ) : (
              <>
                <RefreshTableButton
                  reloadData={reloadResourceList}
                  isLoading={dataLoading}
                />
                <ResourcesCSVButton
                  tableFilter={tableFilter}
                  tableSort={tableSort}
                />
              </>
            )}
          </>
        }
        additionalNode={
          isPatientPage && (
            <PatientAdditionalNode
              activeItemNumber={1}
              modifiedData={modifiedItems}
              totalCount={totalCount}
              reloadData={reloadResourceList}
              dataLoading={dataLoading}
              historyTitle={t('tables:resourcesHistory')}
            />
          )
        }
      >
        <VirtualizedTable
          tableKey={
            paramAccountUuid ? TABLE_KEYS.resourcesDS : TABLE_KEYS.resources
          }
          hasColumnsSelect={true}
          onDataLoading={isTableDataLoading}
          rowCount={totalCount}
          rows={modifiedItems}
          fakeRows={FAKE_RESOURCE_LIST}
          columns={GET_RESOURCES_COLUMNS(isPatientPage, paramAccountUuid, totalAccountsCount)}
          onRowClick={handleRowClick}
          FetchProps={{ ...restFetchProps }}
          currentAccountPermissions={currentAccountPermissions}
          actionProps={{
            actions: [
              {
                key: 'resendInvitationAction',
                icon: PendingIcon,
                onActionClick: handleConfirmResentDialog,
                children: t('titles:resendInvitation'),
                isDisabled: checkIsResendingDisabled,
              },
            ],
          }}
          placeholder={
            <TablePlaceholder
              image={ordersPlaceholderImage}
              itemsNotFoundText={t('placeholders:noItemsFound', {
                item: t('titles:resources'),
              })}
              message={t('placeholders:reviseFiltersOrCreateYourFirstItem', {
                item: t('titles:resource'),
              })}
            />
          }
        />
      </ContentWrapper>
    </>
  );
};

export default ResourcesList;
