import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const NoOptions = ({ title }) => {
  const classes = useStyles();
  return <div className={classes.titleContainer}>
    <Typography className={classes.title}>
      {title}
    </Typography>
  </div>;
};

export default NoOptions;
