import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from 'helpers/localize';
import CalendarPopover from '../FormikFields/Calendar/CalendarPopover';

const DateFilter = ({
  filterValue,
  dataKey,
  columnData: { isFilterable, shouldRange, hasNoMaxDate },
  onChange,
}) => {
  const handleDateChange = (newDate) => {
    if (
      (shouldRange && newDate[0] == null && shouldRange[1] == null) ||
      (!shouldRange && !newDate)
    ) {
      onChange(dataKey, '');
    } else if (!shouldRange) {
      onChange(dataKey, getFormattedDate(newDate));
    } else {
      const parsedVal = newDate.map((data) => getFormattedDate(data));
      onChange(dataKey, parsedVal.join(','));
    }
  };

  let value: null | * | Date;
  if (!filterValue) {
    value = null;
  } else {
    value = shouldRange
      ? filterValue.split(',').map((data) => new Date(data))
      : new Date(filterValue);
  }

  return (
    <CalendarPopover
      width={100}
      shouldReduceFontSize
      disabled={!isFilterable}
      shouldRange={shouldRange}
      shortView={true}
      onChange={handleDateChange}
      date={value}
      maxDate={hasNoMaxDate ? null : new Date()}
    />
  );
};

DateFilter.propTypes = {
  filterValue: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  columnData: PropTypes.shape({
    isFilterable: PropTypes.bool,
    shouldRange: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
};

DateFilter.defaultProps = {
  filterValue: '',
  onChange: undefined,
};

export default DateFilter;
