import i18next from 'i18next';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

const USER_ROLES = {
  PROVIDER: 'provider',
  ASSISTANT: 'assistant',
};

export default USER_ROLES;

export const FAKE_USER = {
  uuid: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  canResendInvitation: false,
  moreTitle: '',
};
export const ALLOWED_ACCOUNT_FIELDS = ['customTags', 'businessName', 'customIdentifier'];

export const ALLOWED_ACCOUNT_COLUMNS = [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'customTags',
    hasBubble: true,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:account'),
    dataKey: 'businessName',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 1,
  },
  {
    width: 140,
    label: i18next.t('tables:customId'),
    dataKey: 'customIdentifier',
    columnData: {
      isFilterable: true,
    },
    requiredColumn: true,
  },
  {
    width: 160,
    label: i18next.t('tables:accessGranted'),
    dataKey: 'isAllowed',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.CHECKBOX,
      options: [
        { label: i18next.t('forms:allowed'), value: 'true' },
        { label: i18next.t('forms:notAllowed'), value: 'false' },
      ],
    },
  },
];


export const USERS_FIELDS = [
  'firstName',
  'lastName',
  'role',
  'email',
  'canResendInvitation',
];

export const USERS_COLUMNS = [
  {
    width: 180,
    label: i18next.t('tables:firstName'),
    dataKey: 'firstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
  },
  {
    width: 180,
    label: i18next.t('tables:lastName'),
    dataKey: 'lastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
  },
  {
    width: 180,
    label: i18next.t('tables:roles'),
    dataKey: 'role',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 2,
  },
  {
    width: 220,
    label: i18next.t('forms:email'),
    dataKey: 'email',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
  },
];
