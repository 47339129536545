export const parseDateToString = (dateFormatValue) => {
  try {
    return `${
      dateFormatValue.getMonth() > 8
        ? dateFormatValue.getMonth() + 1
        : `0${dateFormatValue.getMonth() + 1}`
    }/${
      dateFormatValue.getDate() > 9
        ? dateFormatValue.getDate()
        : `0${dateFormatValue.getDate()}`
    }/${dateFormatValue.getFullYear()}`;
  } catch (e) {
    return null;
  }
};

export const parseStringToDate = (dateAsString) => {
  const parts = dateAsString.split('/');
  return new Date(parts[2], parts[0] - 1, parts[1]);
};

export function checkValue(value, max, forceAddition) {
  let str = value;
  if (str.charAt(0) !== '0' || str === '00') {
    let num = parseInt(str, 10);
    if (Number.isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      (num > parseInt(max.toString().charAt(0), 10) &&
        num.toString().length === 1) ||
      forceAddition
        ? `0${num}`
        : num.toString();
  }
  return str;
}

export function checkIsValidDate(testDate) {
  const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return !!dateRegex.test(testDate);
}

export function checkYearValue(str) {
  const yearAsNum = parseInt(str, 10);

  const strDate = new Date();
  const shortYear = strDate.getFullYear();
  const twoDigitYear = shortYear.toString().substr(-2);
  const twoDigitYearAsNum = parseInt(twoDigitYear, 10);

  if (yearAsNum > 20 && str.length === 2) {
    return (yearAsNum > twoDigitYearAsNum ? '19' : '20') + str;
  }
  return str;
}

export function parseDate(dateAsString, prewValue) {
  let input = dateAsString;
  if (
    !!prewValue &&
    prewValue.length > dateAsString.length &&
    dateAsString.length < 6 &&
    /\d{2}$/.test(input)
  ) {
    input = input.substr(0, input.length - 1);
  }

  const forceAddition =
    (input.endsWith('/') || input.endsWith('-')) &&
    !!prewValue &&
    (dateAsString.length === 2 || dateAsString.length === 5) &&
    prewValue.length < dateAsString.length;

  const values = input.split('/').map((v) => {
    return v.replace(/\D/g, '');
  });
  if (values[0]) values[0] = checkValue(values[0], 12, forceAddition);
  if (values[1]) values[1] = checkValue(values[1], 31, forceAddition);
  if (values[2]) values[2] = checkYearValue(values[2]);
  const output = values.map((v, i) => {
    return v.length === 2 && i < 2 ? `${v}/` : v;
  });
  return output.join('').substr(0, 10);
}

export function parsedOnBlurInput(dateAsString) {
  const values = dateAsString.split('/').map((v) => {
    return v.replace(/\D/g, '');
  });
  let output = '';

  if (values.length === 3) {
    const year =
      values[2].length !== 4
        ? parseInt(values[2], 10) + 2000
        : parseInt(values[2], 10);
    const month = parseInt(values[0], 10) - 1;
    const day = parseInt(values[1], 10);
    const d = new Date(year, month, day);
    if (!Number.isNaN(d)) {
      const dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
      output = dates
        .map((item) => {
          const v = item.toString();
          return v.length === 1 ? `0${v}` : v;
        })
        .join('/');
    }
  }
  return output;
}
