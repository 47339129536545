import React, { useEffect, useState } from 'react';
import IdleDialog from 'components/Dialogs/IdleDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, logout } from '../../redux_store/reducer/reducers/userReducer/api';
import {
  changeIsTabActive,
  checkAuthorizationExpiresTimeout,
} from '../../redux_store/reducer/reducers/appSettingsReducer';

const LOGOUT_TIMEOUT = 1000 * 60; //ms / s / m / h
let timeoutID;

export const getUTCNow = () => {
  const now = new Date();
  const time = now.getTime();
  const offset = now.getTimezoneOffset() * 60000;
  return time + offset;
};

const Idle = () => {
  const { authorizationExpiresTimeout, isTabActive } = useSelector((state) => state.appSettings);
  const { isLogin } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => setDialogOpen(false);

  const onStayLogin = () => {
    if (isLogin) {
      dispatch(getUser());
    }
    handleDialogClose(false);
  };

  useEffect(() => {
    window.onfocus = function() {
      dispatch(changeIsTabActive(true));
    };

    window.onblur = function() {
      dispatch(changeIsTabActive(false));
    };
  }, []);

  useEffect(() => {
    if (isTabActive && (!authorizationExpiresTimeout || authorizationExpiresTimeout - getUTCNow() < LOGOUT_TIMEOUT)) {
      dispatch(checkAuthorizationExpiresTimeout());
    }
  }, [isTabActive]);

  const handleLogout = (text) => {
    const _isLogin = isLogin;
    dispatch(logout());
    if (_isLogin) {
      window.location.href = `/login${text ?? ''}`;
    }
    handleDialogClose();
  };

  useEffect(() => {
    if (authorizationExpiresTimeout) {
      clearTimeout(timeoutID);
      setDialogOpen(false);
      const diff = authorizationExpiresTimeout - getUTCNow();
      if (diff > 0) {
        timeoutID = setTimeout(() => {
          if (localStorage.getItem('isLogin')) {
            setDialogOpen(true);
          }
        }, diff - LOGOUT_TIMEOUT);
      } else {
        handleLogout('#logout_without_notification');
      }
    }
  }, [authorizationExpiresTimeout]);

  return <IdleDialog
    open={isDialogOpen && isLogin}
    logout={handleLogout}
    onClose={onStayLogin}
    onLogout={handleLogout}
    logoutTimeout={LOGOUT_TIMEOUT / 1000}
  />;
};

export default Idle;
