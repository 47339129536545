import PropTypes from 'prop-types';
import React from 'react';
import 'intersection-observer';
import './styles.css';
import { SURVEYS_QUESTION_TYPES } from '../constants';
import GridSelect from './GridSelectView';
import MultiSelect from './MultiSelectView';
import SingleSelect from './SingleSelectView';
import TableView from './TableView';
import { getLocalizedDate } from '../../../helpers/localize';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    maxWidth: 1000,
    padding: '0 40px 40px 40px',
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },

  date: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  resultText: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.colors.blue2,
  },

  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.colors.blue2,
    fontSize: '1.2rem',
  },

  title: {
    fontSize: '1rem !important',
    color: theme.colors.blue2,
    fontWeight: 500,
  },
  titleDate: {
    fontSize: '.7rem !important',
    color: theme.colors.blue2,
  },
}));

const DATE_TITLE = {
  today: 'Today',
  more30days: '30-90 Days Ago',
  more91days: '91+ Days Ago',
};
const ReportViewTable = ({ surveyList, counterColoredAnswers, isComparing = false }) => {
  const getDateSubTitle = (date_as_string) => {
    const today = new Date();
    const date = new Date(date_as_string);
    const diffTime = Math.abs(today - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    let diffDaysTitle = DATE_TITLE.more91days;

    if (diffDays < 30) {
      diffDaysTitle = DATE_TITLE.today;
    } else if (30 <= diffDays <= 90) {
      diffDaysTitle = DATE_TITLE.more30days;
    }

    return `(${diffDaysTitle})`;
  };

  const classes = useStyles();

  return (
    <>
      {surveyList.length > 0 && (
        <table className='styled_table'>
          {
            isComparing ?
              <tr className='list-break-marker'>
                <th></th>
                <th>
                  <Typography className={classes.title}>
                    {'Survey 1'}
                  </Typography>
                  <Typography className={classes.titleDate} style={{
                    flex: 1,
                    flexShrink: 1,
                  }}>
                    {getLocalizedDate('2023-05-15T00:00:00Z', 'UTC')}
                  </Typography>
                  <Typography className={classes.titleDate} style={{
                    flex: 1,
                    flexShrink: 1,
                  }}>
                    {getDateSubTitle('2023-05-15T00:00:00Z')}
                  </Typography>
                </th>
                <th>
                  <Typography className={classes.title}>
                    {'Survey 2'}
                  </Typography>
                  <Typography className={classes.titleDate} style={{
                    flex: 1,
                    flexShrink: 1,
                  }}>
                    {getLocalizedDate('2024-09-15T00:00:00Z', 'UTC')}
                  </Typography>
                  <Typography className={classes.titleDate} style={{
                    flex: 1,
                    flexShrink: 1,
                  }}>
                    {getDateSubTitle('2024-09-15T00:00:00Z')}
                  </Typography>
                </th>
                <th>
                  {'Result'}
                </th>
              </tr> : <tr className='list-break-marker'>
                <th></th>
                <th>
                  <div className='dot green-dot-bgcolor'>
                    {counterColoredAnswers.success}
                  </div>
                </th>
                <th>
                  <div className='dot orange-dot-bgcolor'>
                    {counterColoredAnswers.warning}
                  </div>
                </th>
                <th>
                  <div className='dot red-dot-bgcolor'>
                    {counterColoredAnswers.error}
                  </div>
                </th>
                <th>
                  <div className='dot black-dot-bgcolor'>
                    {counterColoredAnswers.info}
                  </div>
                </th>
              </tr>
          }
          {surveyList.length > 0 &&
          surveyList.map((survey) => {
            if (
              survey.itemType === SURVEYS_QUESTION_TYPES.GridSelect ||
              survey.itemType === SURVEYS_QUESTION_TYPES.GridNumericalSelector
            ) {
              return <GridSelect survey={survey} />;
            }
            if (
              survey.itemType ===
              SURVEYS_QUESTION_TYPES.BasicNumericalSelector
            ) {
              return <SingleSelect survey={survey} />;
            }
            if (
              survey.itemType === SURVEYS_QUESTION_TYPES.Table
            ) {
              return <TableView survey={survey} />;
            }
            if (
              survey.values ||
              survey.itemType === SURVEYS_QUESTION_TYPES.MultiSelect
            ) {
              return <MultiSelect survey={survey} />;
            }
            return <SingleSelect survey={survey} />;
          })}
        </table>
      )}
    </>
  );
};

ReportViewTable.propTypes = {
  surveyList: PropTypes.arrayOf(PropTypes.object),
  counterColoredAnswers: PropTypes.shape({
    success: PropTypes.number,
    warning: PropTypes.number,
    error: PropTypes.number,
    info: PropTypes.number,
  }).isRequired,
};

ReportViewTable.defaultProps = {
  surveyList: [],
};

export default ReportViewTable;
