import i18next from 'i18next';

export const CUSTOM_TAGS_STATUSES = {
  'ISP': 'ISP',
  'License': 'License',
  'Orders Only': 'Orders Only',
  'Trial': 'Trial',
  'Demo': 'Demo',
  'Other': 'Other',
  'In Active': 'In Active',
};

export const PATIENTS_CUSTOM_TAGS_STATUSES = {
  'control': 'control',
  'experimental': 'experimental',
};

export const PATIENTS_CUSTOM_TAGS_COLORS_STATUSES = {
  'control': 'Control',
  'experimental': 'Experimental',
};

export const CUSTOM_TAGS_OPTIONS = [
  {
    label: i18next.t('tables:isp'),
    value: CUSTOM_TAGS_STATUSES['ISP'],
  },
  {
    label: i18next.t('tables:license'),
    value: CUSTOM_TAGS_STATUSES['License'],
  },
  {
    label: i18next.t('tables:ordersOnly'),
    value: CUSTOM_TAGS_STATUSES['Orders Only'],
  },
  {
    label: i18next.t('tables:trial'),
    value: CUSTOM_TAGS_STATUSES['Trial'],
  },
  {
    label: i18next.t('tables:demo'),
    value: CUSTOM_TAGS_STATUSES['Demo'],
  },
  {
    label: i18next.t('tables:other'),
    value: CUSTOM_TAGS_STATUSES['Other'],
  },
  {
    label: i18next.t('tables:inactive'),
    value: CUSTOM_TAGS_STATUSES['In Active'],
  },
];

export const PATIENT_CUSTOM_TAGS_OPTIONS = [
  {
    label: i18next.t('tables:control'),
    value: PATIENTS_CUSTOM_TAGS_STATUSES.control,
  },
  {
    label: i18next.t('tables:experimental'),
    value: PATIENTS_CUSTOM_TAGS_STATUSES.experimental,
  },
];

export const SURVEY_TAGS_STATUSES = {
  'Has Claim': 'hasClaim',
  'Has Program': 'hasProgram',
  'hasClaim': 'Has Claim',
  'hasProgram': 'Has Program',
}
export const SURVEY_TAGS_OPTIONS = [
  {
    label: i18next.t('tables:hasClaim'),
    value: SURVEY_TAGS_STATUSES['Has Claim'],
  },
  {
    label: i18next.t('tables:hasProgram'),
    value: SURVEY_TAGS_STATUSES['Has Program'],
  },
];

