import { Box, makeStyles } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-fetching-library';
import PropTypes from 'prop-types';
import { compare } from 'fast-json-patch';
import { conformToMask } from 'react-text-mask';
import { get } from 'lodash';
import RoundedButton from 'components/Shared/RoundedButton';
import Modal from 'components/Shared/Modal';
import getDigitPhone, { formatPhoneNumber } from 'helpers/phone';
import showServerError from 'helpers/showError';
import PatientForm from 'components/Patients/PatientForm';
import { MASK } from 'components/Shared/FormattedPhone';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import getFullName from 'helpers/user';
import { useDispatch, useSelector } from 'react-redux';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import { getLocalizedDate, getUtcDate } from '../../helpers/localize';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import Loader from '../Shared/Loader';

const useStyles = makeStyles((theme) => ({
  modal: {
    minWidth: 1000,
  },
  loaderStyle: {
    minHeight: 500,
  },
  createButton: {
    marginRight: theme.spacing(2),
  },
}));

const PatientEditModal = ({ open, handleClose, accountUuid, patientUuid }) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const [backendErrors, setBackendErrors] = useState({});
  const closeModal = (hasThePatientBeenUpdated) => {
    handleClose(hasThePatientBeenUpdated);
    setBackendErrors({});
    setPatient(null);
  };
  const [patient, setPatient] = useState();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { setPatientInfo } = useContext(ActivePatientContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/patients/${patientUuid}`,
    },
    false,
  ]);

  useEffect(() => {
    if (open && query) {
      query().then(({ error, payload }) => {
        if (!error) {
          setPatient(payload);
        }
      });
    }
  }, [query, open]);

  const patientEditAction = (patches) => ({
    method: 'PATCH',
    endpoint: `/accounts/${accountUuid}/patients/${patientUuid}`,
    body: patches,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [
    patientEditAction,
  ]);

  const editPatient = async (values, { setSubmitting }) => {
    const patches = compare(
      {
        ...patient,
        phone: {
          phone: patient.phone,
        },
      },
      {
        ...values,
        dateOfBirth: values.dateOfBirth.includes('Z')
          ? values.dateOfBirth
          : getUtcDate(values.dateOfBirth),
        phone: {
          phone: getDigitPhone(values.phone),
        },
      },
    );

    if (patches.length) {
      setBackendErrors({});
      const { payload, error, status } = await mutate(patches);
      if (error) {
        setIsFailed(true);
        setTimeout(() => {
          setIsFailed(false);
        }, 2000);
        const options = payload && {
          correlationUUID: payload.correlationUUID,
          userUUID: user.uuid,
        };
        switch (status) {
          case 422: {
            showServerError(dispatch, t('errors:validationError'));
            setBackendErrors(payload.validationErrors);
            break;
          }
          default: {
            showServerError(dispatch, '', options);
            break;
          }
        }
      } else if (payload) {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
        setPatient(payload);
        setPatientInfo({
          ...payload,
          dateOfBirth: getLocalizedDate(patient.dateOfBirth, 'UTC'),
          phone: formatPhoneNumber(
            payload.phone != null ? payload.phone.phone : '',
          ),
        });
        closeModal(true);
        dispatch(
          enqueueSnackbar({
            message: t('notifications:patientEdited', {
              name: getFullName(values),
            }),
            options: {
              variant: SNACKBAR_VARIANTS.success,
            },
          }),
        );
      }
      setSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      classes={{ paper: classes.modal }}
      title={t('titles:editPatient')}
      handleClose={() => closeModal(false)}
    >
      {
        !patient && <Loader
          fullHeight
          className={classes.loaderStyle}
        />
      }
      {patient && <Formik
        initialValues={{
          ...patient,
          phone: conformToMask(get(patient, 'phone.phone', ''), MASK)
            .conformedValue,
        }}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={editPatient}
      >
        {({ isValid, isSubmitting, dirty, handleSubmit, values }) => (
          <Box p={4}>
            <PatientForm
              values={values}
              backendErrors={backendErrors}
              actions={
                <Box py={2} display='flex' justifyContent='space-between'>
                  <RoundedButton
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={() => closeModal(false)}
                    className={classes.createButton}
                  >
                    {t('btn:cancel')}
                  </RoundedButton>
                  <RoundedButton
                    variant='contained'
                    color='primary'
                    size='small'
                    disabled={!isValid || isSubmitting || !dirty}
                    onClick={handleSubmit}
                    isLoading={loading}
                    isSuccess={isSuccess}
                    isFailed={isFailed}
                    className={classes.createButton}
                  >
                    {t('btn:save')}
                  </RoundedButton>
                </Box>
              }
            />
          </Box>
        )}
      </Formik>}
    </Modal>
  );
};

PatientEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  patientUuid: PropTypes.string.isRequired,
  accountUuid: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PatientEditModal;
