import React, { useRef, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { DEBOUNCE_DELAY } from '../../../Shared/constants';
import { Checkbox, Tooltip } from '@material-ui/core';
import { NEW_LINE_MARKER } from '../../../../helpers/localize';

const CellSimpleText = props => {
  const [hoverStatus, setHover] = useState(false);
  const textElementRef = useRef();

  if (props && typeof props.value == 'boolean') {
    return <Checkbox
        style={{
          padding: 0,
          marginLeft: 46
        }}
        checked={props?.value ?? false}
        color='primary'
        {...props} />
  }

  const text = props && props.value ?
    (Array.isArray(props.value)
      ? (props.value.length === 0 ? '-' : props.value.join(', %%%'))
      : props.value)
      .split(NEW_LINE_MARKER) : '-';

  const compareSize = () => {
    if (textElementRef && textElementRef.current && textElementRef.current.scrollWidth && textElementRef.current.clientWidth) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', debounce(compareSize, DEBOUNCE_DELAY));
  }, []);

  useEffect(() => () => {
    window.removeEventListener('resize', debounce(compareSize, DEBOUNCE_DELAY));
  }, []);

  return (
    <Tooltip
      title={text.toString()}
      disableHoverListener={!hoverStatus}
      disableTouchListener={!hoverStatus}
      disableFocusListener={!hoverStatus}
      interactive={true}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {
          props && props.startAdornment ? `${props.startAdornment}  ` : ''
        }
        {
          text.length <= 1
            ? text
            : text.map((dataLine) => <div key={dataLine}>{dataLine}</div>)
        }
      </div>
    </Tooltip>
  );
};

export default CellSimpleText;
